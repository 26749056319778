/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import P from 'components/htmlElements/P';
import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';
import { spacer } from 'styles/utilities';
import * as styles from './Instructions.styles';

export const InstructionsContainer = ({ children }) => (
  <div css={styles.instructionContainer}>{children}</div>
);

InstructionsContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export const InstructionHeader = ({ topic }) => (
  <P instruction>
    <strong>Instructions - {topic}</strong>
  </P>
);

InstructionHeader.propTypes = {
  topic: PropTypes.string.isRequired
};

export const InstructionLine = ({ text }) => (
  <div css={spacer.padBT10}>
    <P instruction noMargin as="div">
      {ReactHtmlParser(DOMPurify.sanitize(text))}
    </P>
  </div>
);

InstructionLine.propTypes = {
  text: PropTypes.string.isRequired
};
