/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types';

import P from 'components/htmlElements/P';

import { spacer } from 'styles/utilities';
import * as styles from './TestNoteBar.styles';

const TestNoteBar = ({ testNote }) => (
  <div css={[styles.background, spacer.padBT15, spacer.padLR15]}>
    <P css={styles.noMargin}>
      <strong>NOTE</strong>: {testNote}
    </P>
  </div>
);

TestNoteBar.propTypes = {
  testNote: PropTypes.string.isRequired
};

export default TestNoteBar;
