import React, { Fragment } from 'react';

import { Redirect } from 'components/Router';
import PageLoader from 'components/PageLoader';
import { ROUTE_PATHS } from 'globals/constants';
import MetaWrapper from 'components/MetaWrapper';

import { useUnassignedProducts, useCandidates, useGroups } from 'api/schools';

import SchoolDashboard from './page';

const SchoolDashboardContainer = () => {
  const {
    data: unassignedProductsData,
    isSuccess: unassignedProductsIsSuccess,
    isError: unassignedProductsIsError
  } = useUnassignedProducts();

  const { data: groupsData, isSuccess: groupsIsSuccess, isError: groupsIsError } = useGroups();

  const { data: candidatesData, isSuccess, isError } = useCandidates();

  if (unassignedProductsIsError || groupsIsError || isError) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (!unassignedProductsIsSuccess || !groupsIsSuccess || !isSuccess) {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  return (
    <Fragment>
      <MetaWrapper title="Dashboard" />
      <SchoolDashboard
        unassignedProducts={unassignedProductsData || []}
        groups={groupsData || []}
        candidates={candidatesData || []}
      />
    </Fragment>
  );
};

export default SchoolDashboardContainer;
