/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

import { colors } from 'styles/utilities';

const BlueBgPattern = styled.div`
  background-color: ${colors.blue};
  position: relative;
  z-index: 0;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAJElEQVQIW2P8//+/DyMj4xYGJMAIYqNLgAXRJeCCyBIogjAJAA+zE0+2WHGzAAAAAElFTkSuQmCC);
    background-repeat: repeat;
    opacity: 0.3;
    z-index: -1;
  }
`;

export default BlueBgPattern;
