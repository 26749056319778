/** @jsxImportSource @emotion/react */
import { useEffect, Fragment, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { restrictToWindowEdges, snapCenterToCursor } from '@dnd-kit/modifiers';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';
import { findKey } from 'lodash-es';

import { Draggable } from 'components/QuestionTypes/shared-components/DndkitComponents';
import Wordbox, {
  WordboxPlaceholder,
  DroppableWordbox
} from 'components/QuestionTypes/shared-components/Wordbox';
import P from 'components/htmlElements/P';
import SolutionExplainer from 'components/SolutionExplainer';

import { Container, Row, Col } from 'components/Grid';
import { InstructionLine } from 'components/Instructions';

import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';

import { colors, spacer, txtColor } from 'styles/utilities';
import * as sharedStyles from 'components/QuestionTypes/shared-components/shared.styles';
import QuestionsCommonWrapperContainer from 'components/QuestionTypes/shared-components/QuestionsCommonWrapper';

import arrayIndexesIntersection from 'utils/arrayIndexesIntersection';

import * as styles from './SelectedLetters.styles';

const indexStringGenerator = (index, suffix) => `${index.toString()}-${suffix.toString()}`;
const ANAGRAM_DROPZONE = 'selectedLetters-dropzone';

const SelectedLetters = ({ currentQuestion }) => {
  // console.info(currentQuestion);
  const {
    instructionsText,
    questionText,
    mixedWordOrder,
    solutionExplaination,
    noOfBlanks,
    answer
  } = currentQuestion.selectedLetters;
  const { ttAnswers } = currentQuestion;

  const {
    // attemptFinished,
    skippingAllowed,
    showNextButton,
    answersEditableBeforeSubmission,
    updateAnswerPayload,
    isQuestionUsageFinal
  } = TestModuleInfoContainer.useContainer();

  const { attemptFinished, isNoteOrSampleQuestion, isPracticeQuestion } =
    QuestionsCommonWrapperContainer.useContainer();

  // Save checked answer options
  const [checkedAnswers, setCheckedAnswers] = useState([]);
  useEffect(
    // This effect simply clears the checkedAnswers on question change. It ensures on question switch the previous state is cleared
    () => () => {
      setCheckedAnswers([]);
    },
    [currentQuestion._id]
  );

  // Update `checkedAnswers` if already answered and/or attempt is completed
  useEffect(() => {
    if (ttAnswers && ttAnswers.length) {
      setCheckedAnswers(ttAnswers);
    }

    // Clear the checked answers array on unmount
    // Without this there's a bug which messes up the next question if it's of the same type
    return () => {
      setCheckedAnswers([]);
    };
  }, [ttAnswers]);

  // Handle disabling of answering
  const [answeringDisabled, handleAnsweringDisabled] = useState(false);
  useEffect(() => {
    // If editable and not answered - can answer
    // If not editable and not answered - can answer
    // If not editable and answered - cannot answer
    if (!attemptFinished) {
      if (!ttAnswers) {
        // This means it's not answered
        handleAnsweringDisabled(false);
      } else if (!answersEditableBeforeSubmission) {
        handleAnsweringDisabled(true);
      }
    } else {
      // If attempt is over answering is disabled
      handleAnsweringDisabled(true);
    }

    return () => {
      // Reset state
      handleAnsweringDisabled(!answersEditableBeforeSubmission);
    };
  }, [answersEditableBeforeSubmission, attemptFinished, ttAnswers]);

  // Toggle next button visibility
  useEffect(() => {
    // checkedAnswers.length === mixedWordOrder.length equality check to ensure all boxes are filled
    if (
      !skippingAllowed &&
      checkedAnswers.filter((item) => item.length)?.length !== noOfBlanks &&
      !isNoteOrSampleQuestion
    ) {
      showNextButton(false);
    } else {
      showNextButton(true);
    }
  }, [
    checkedAnswers,
    isNoteOrSampleQuestion,
    mixedWordOrder,
    noOfBlanks,
    showNextButton,
    skippingAllowed
  ]);

  // Handle updating answer payload
  useEffect(() => {
    updateAnswerPayload({
      questionId: currentQuestion._id,
      answerArray: checkedAnswers
    });
  }, [checkedAnswers, currentQuestion._id, updateAnswerPayload]);

  // Drag drop stuff starts here
  const [droppableParent, setDroppableParent] = useState(null);
  const [activeDrag, setActiveDrag] = useState(null); // Current drag

  const listOfWordsIds = useMemo(
    () => mixedWordOrder.map((item, index) => indexStringGenerator(index, 'letter')),
    [mixedWordOrder]
  );

  // Setup droppable parents array
  useEffect(() => {
    const parentsObject = {};

    // if (!answeringDisabled || !attemptFinished) {
    // if (ttAnswers && ttAnswers.length) {
    // To avoid getting duplicate indexes if the target word has multiple instances of same letter
    const letterIndexes = arrayIndexesIntersection(
      ttAnswers && ttAnswers.length ? ttAnswers : [],
      mixedWordOrder.map((i) => i.text)
    );
    // }

    for (let i = 0; i < noOfBlanks; i += 1) {
      let wordId = null;

      // Here we are checking if the user's answer exists.
      // If it exists, we find the index of that letter in the original mixedWordOrder received from API
      // Once we have the index we get its `id` required for the dndkit draggable item and set it in
      wordId = listOfWordsIds[letterIndexes[i]];

      parentsObject[indexStringGenerator(i, ANAGRAM_DROPZONE)] = { wordId };
    }

    setDroppableParent(parentsObject);

    // }
  }, [answeringDisabled, attemptFinished, listOfWordsIds, mixedWordOrder, noOfBlanks, ttAnswers]);

  // This is purely for only practice questions types
  useEffect(() => {
    if (attemptFinished && isPracticeQuestion) {
      const parentsObject = {};

      const letterIndexes = arrayIndexesIntersection(
        checkedAnswers && checkedAnswers.length ? checkedAnswers : [],
        mixedWordOrder.map((i) => i.text)
      );

      for (let i = 0; i < noOfBlanks; i += 1) {
        let wordId = null;

        // Here we are checking if the user's answer exists.
        // If it exists, we find the index of that letter in the original mixedWordOrder received from API
        // Once we have the index we get its `id` required for the dndkit draggable item and set it in
        wordId = listOfWordsIds[letterIndexes[i]];

        parentsObject[indexStringGenerator(i, ANAGRAM_DROPZONE)] = { wordId };
      }

      setTimeout(() => {
        setDroppableParent(parentsObject);
      }, 0);
    }
  }, [
    attemptFinished,
    checkedAnswers,
    isPracticeQuestion,
    listOfWordsIds,
    mixedWordOrder,
    noOfBlanks
  ]);

  function handleDragStart(event) {
    setActiveDrag(event.active.id);
  }

  function updateCheckedAnswerArrayOnDragEnd(updatedState) {
    const answerOrderArray =
      droppableParent &&
      Object.keys(updatedState).map(
        (parentKey) =>
          mixedWordOrder[listOfWordsIds.indexOf(updatedState[parentKey].wordId)]?.text || ''
      );

    // Set the answer
    setCheckedAnswers(answerOrderArray);
  }

  function handleDragEnd(event) {
    const { over, active } = event;

    setActiveDrag(null);

    if (over) {
      setDroppableParent((prevState) => {
        const prevItemsWithActiveId = Object.keys(prevState).filter(
          (item) => prevState[item].wordId === active.id
        );

        const updatedState = {
          ...prevState
        };
        if (prevItemsWithActiveId.length) {
          prevItemsWithActiveId.forEach((item) => {
            updatedState[item].wordId = null;
          });
        }

        updatedState[over.id].wordId = active.id;

        // Update checkedAnswer
        updateCheckedAnswerArrayOnDragEnd(updatedState);

        return {
          ...updatedState
        };
      });

      // Set the answer
      // setCheckedAnswers([mixedWordOrder[parseInt(active.id)].text]);
    } else if (findKey(droppableParent, ['wordId', active.id])) {
      // This is dragged out of draggable area

      const dropzoneKey = findKey(droppableParent, ['wordId', active.id]);
      setDroppableParent((prevState) => {
        const updatedState = {
          ...prevState
        };
        updatedState[dropzoneKey].wordId = null;

        // Update checkedAnswer
        updateCheckedAnswerArrayOnDragEnd(updatedState);

        return updatedState;
      });
    } else {
      // setDroppableParent((prevState) => prevState);
      // setDraggedItemId((prevState) => prevState);
    }
  }

  return (
    <div css={[spacer.padT30]}>
      <Container>
        <Row>
          <Col>
            {instructionsText && <InstructionLine text={instructionsText} />}
            <div
              css={[spacer.mrT60, spacer.mrB40, sharedStyles.types, sharedStyles.richTextQuestion]}
              className="ck-content"
            >
              {ReactHtmlParser(DOMPurify.sanitize(questionText))}
            </div>

            {/* DND stuff */}
            <DndContext
              onDragEnd={handleDragEnd}
              onDragStart={handleDragStart}
              modifiers={[restrictToWindowEdges, snapCenterToCursor]}
            >
              <div css={styles.mixedWordOrderWrapper}>
                {mixedWordOrder.map((item, index) =>
                  !findKey(droppableParent, ['wordId', listOfWordsIds[index]]) ? (
                    <Draggable
                      id={listOfWordsIds[index]}
                      key={listOfWordsIds[index]}
                      disabled={answeringDisabled}
                    >
                      <Wordbox text={item.text} type="square" fixedSize />
                    </Draggable>
                  ) : (
                    <WordboxPlaceholder
                      text={item.text}
                      key={listOfWordsIds[index]}
                      type="square"
                      fixedSize
                    />
                  )
                )}
              </div>

              <div css={styles.mixedWordOrderWrapper}>
                {droppableParent &&
                  Object.keys(droppableParent).map((droppableKey) => (
                    <DroppableWordbox id={droppableKey} fixedSize key={droppableKey}>
                      {droppableParent[droppableKey].wordId && (
                        <Draggable
                          id={droppableParent[droppableKey].wordId}
                          disabled={answeringDisabled}
                        >
                          <Wordbox
                            text={
                              mixedWordOrder[
                                listOfWordsIds.indexOf(droppableParent[droppableKey].wordId)
                              ]?.text
                            }
                            type="square"
                            fixedSize
                          />
                        </Draggable>
                      )}
                    </DroppableWordbox>
                  ))}
              </div>

              <DragOverlay key={currentQuestion._id}>
                {activeDrag ? (
                  <Wordbox
                    text={mixedWordOrder[listOfWordsIds.indexOf(activeDrag)]?.text}
                    type="square"
                    fixedSize
                  />
                ) : null}
              </DragOverlay>
            </DndContext>

            {attemptFinished && !ttAnswers?.length && isQuestionUsageFinal && (
              <P color={colors.red} large>
                Question not attempted.
              </P>
            )}

            {attemptFinished && (
              <Fragment>
                <P large css={spacer.mrT20}>
                  <span>The correct answer is </span>
                  <span css={txtColor.green}>{answer.join('')}</span>
                  {checkedAnswers.join('') === answer.join('') ? (
                    <P as="span" large>
                      . You answered correctly.
                    </P>
                  ) : (
                    ''
                  )}
                </P>
                <SolutionExplainer text={solutionExplaination} />
              </Fragment>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

SelectedLetters.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

const WithQuestionProvider = (props) => {
  const { questionSettings } = props.currentQuestion.selectedLetters;

  return (
    <QuestionsCommonWrapperContainer.Provider
      initialState={{ currentQuestion: props.currentQuestion, questionSettings }}
    >
      <SelectedLetters {...props} />
    </QuestionsCommonWrapperContainer.Provider>
  );
};
WithQuestionProvider.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

export default WithQuestionProvider;
