import React, { Fragment } from 'react';

import { Redirect } from 'components/Router';
import PageLoader from 'components/PageLoader';
import { ROUTE_PATHS } from 'globals/constants';
import MetaWrapper from 'components/MetaWrapper';

import { useUnassignedProducts, useCandidates } from 'api/parents';

import ParentDashboard from './page';

const ParentDashboardContainer = () => {
  const {
    data: unassignedProductsData,
    isSuccess: unassignedProductsIsSuccess,
    isError: unassignedProductsIsError
  } = useUnassignedProducts();

  const { data: candidatesData, isSuccess, isError } = useCandidates();

  // const {
  //   data: paginatedCandidatesData,
  //   isSuccess: paginatedCandidatesIsSuccess,
  //   isError: paginatedCandidatesIsError,
  //   fetchNextPage,
  //   hasNextPage,
  //   isFetchingNextPage
  // } = useCandidatesPaginated();

  if (unassignedProductsIsError || isError) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (!unassignedProductsIsSuccess || !isSuccess) {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  return (
    <Fragment>
      <MetaWrapper title="Dashboard" />
      <ParentDashboard
        unassignedProducts={unassignedProductsData || []}
        candidates={candidatesData || []}
        // candidatesPaginated={paginatedCandidatesData?.data || []}
        // fetchNextPage={fetchNextPage}
        // hasNextPage={hasNextPage}
        // isFetchingNextPage={isFetchingNextPage}
      />
    </Fragment>
  );
};

export default ParentDashboardContainer;
