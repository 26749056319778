import React, { Fragment } from 'react';

import { Redirect } from 'components/Router';
import PageLoader from 'components/PageLoader';
import { ROUTE_PATHS } from 'globals/constants';
import MetaWrapper from 'components/MetaWrapper';

import { useCandidates, useGetAllAnalyticsCohort, useGetPurchasedProducts } from 'api/schools';

import Cohort from './page';

const SchoolCohortContainer = () => {
  const { data, isSuccess, isError } = useGetPurchasedProducts();
  const { data: candidates } = useCandidates();
  const { data: allAnalyticsCohort, isError: analyticsCohortError } = useGetAllAnalyticsCohort();

  if (isError || analyticsCohortError) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (!isSuccess) {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  return (
    <Fragment>
      <MetaWrapper title="Products" />
      <Cohort
        products={data || []}
        candidates={candidates || []}
        analyticsCohort={allAnalyticsCohort || []}
      />
    </Fragment>
  );
};

export default SchoolCohortContainer;
