/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types';
import { getProductCategory, getProductColor } from 'components/ProductCard/helpers';
import { spacer } from 'styles/utilities';
import H3 from 'components/htmlElements/H3';
import A from 'components/htmlElements/A';
import { useMemo } from 'react';
import P from 'components/htmlElements/P';
import { useDeleteAnalyticsCohort, useUpdateAnalyticsCohort } from 'api/schools';
import Modal, { useModal } from 'components/Modal/Modal';
import Button from 'components/htmlElements/Button';
import CohortModal from 'components/CohortModal/CohortModal';
import { useQueryClient } from 'react-query';
import * as styles from './CohortBand.styles';

const CohortBand = ({ cohort, cohortProduct, allCandidates }) => {
  const { candidates } = cohort;
  const { deleteAnalyticsCohort } = useDeleteAnalyticsCohort(cohort?.id);
  const { updateAnalyticsCohort } = useUpdateAnalyticsCohort(cohort?.id);
  const queryClient = useQueryClient();

  const {
    isOpen: isDeleteAnalyticsCohortOpen,
    toggle: toggleDeleteAnalyticsCohort,
    closeModal: closeDeleteAnalyticsCohort
  } = useModal();
  const {
    isOpen: isUpdateAnalyticsCohortOpen,
    toggle: toggleUpdateAnalyticsCohort,
    closeModal: closeUpdateAnalyticsCohort
  } = useModal();

  const productColor = useMemo(
    () => getProductColor(getProductCategory(cohortProduct?.product_cat)),
    [cohortProduct?.product_cat]
  );

  const candidatesNameString = useMemo(
    () =>
      candidates
        .map((candidate) => `${candidate?.firstName ?? ''} ${candidate?.lastName ?? ''}`)
        .filter((name) => name.trim().length > 0)
        .join(', '),
    [candidates]
  );

  const storedCandidatesId = useMemo(() => candidates.map(({ id }) => id), [candidates]);

  const deleteCohortHandler = async () => {
    await deleteAnalyticsCohort();
    await queryClient.invalidateQueries('allAnalyticsCohort');
    closeDeleteAnalyticsCohort();
  };

  return (
    <div
      className="d-flex justify-content-between w-100 cohort-band"
      css={[spacer.padBT20, styles.bandWrapper]}
    >
      <div className="d-flex align-items-center">
        <styles.ProductIcon color={productColor} css={spacer.mrR20} />
        <div>
          <H3 gBold color={productColor} className="m-0">
            {cohortProduct?.name}
          </H3>

          <div className="d-flex">
            <P large>Cohort:&nbsp;</P>{' '}
            <P large css={styles.nameStringClamp}>
              {candidatesNameString}
            </P>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center" css={spacer.padLR20}>
        <A
          large
          href="#"
          underline
          className="text-center"
          onClick={(e) => {
            e.preventDefault();
            toggleUpdateAnalyticsCohort();
          }}
        >
          Edit Cohort
        </A>

        <A
          large
          href="#"
          underline
          className="text-center delete-button"
          color="red"
          css={spacer.mrL30}
          onClick={(e) => {
            e.preventDefault();
            toggleDeleteAnalyticsCohort();
          }}
        >
          Delete Cohort
        </A>
      </div>

      {isDeleteAnalyticsCohortOpen && (
        <div className="is-relative">
          <Modal isOpen={isDeleteAnalyticsCohortOpen} toggle={toggleDeleteAnalyticsCohort}>
            <div css={[spacer.padBT30, spacer.padLR30]}>
              <H3 gBook css={spacer.mrB30}>
                Delete Cohort
              </H3>

              <P css={spacer.padR30} color="grey">
                Are you sure you want to delete {cohortProduct.name} analytics cohort?
              </P>

              <Button
                product
                wide
                rounded
                textCenter
                css={spacer.mrT30}
                onClick={deleteCohortHandler}
              >
                Delete Cohort
              </Button>
            </div>
          </Modal>
        </div>
      )}

      {isUpdateAnalyticsCohortOpen && (
        <CohortModal
          test={cohortProduct}
          isOpen={isUpdateAnalyticsCohortOpen}
          toggleModal={toggleUpdateAnalyticsCohort}
          closeModal={closeUpdateAnalyticsCohort}
          storedCandidates={storedCandidatesId}
          candidates={allCandidates}
          handleCohortRequest={updateAnalyticsCohort}
          header="Update Cohort"
          isUpdateCohortModal
        />
      )}
    </div>
  );
};

CohortBand.propTypes = {
  cohort: PropTypes.object.isRequired,
  cohortProduct: PropTypes.object.isRequired,
  allCandidates: PropTypes.array.isRequired
};

export default CohortBand;
