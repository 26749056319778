import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { site } from 'globals/constants';

const MetaWrapper = ({ title, description, image, customMeta, children }) => (
  <Helmet
    titleTemplate={`%s | ${site.name}`}
    title={title || site.name}
    meta={[
      { name: 'theme-color', content: site.themeColor },
      { name: 'robots', content: 'noodp, noydir' },
      { name: 'og:url', content: window.location.origin },
      { name: 'og:locale', content: 'en_GB' },
      { name: 'og:site_name', content: site.name },
      { name: 'og:type', content: 'website' },
      { name: 'og:title', content: title },
      { name: 'og:description', content: description || site.description },
      { name: 'description', content: description || site.description },
      { name: 'og:image', content: image || '' },
      ...customMeta
    ]}
    link={[{ rel: 'canonical', href: window.location }]}
  >
    {children}
  </Helmet>
);

MetaWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  customMeta: PropTypes.array,
  children: PropTypes.node
};

MetaWrapper.defaultProps = {
  customMeta: [],
  image: '',
  children: null,
  description: ''
};

export default MetaWrapper;
