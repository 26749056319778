/** @jsxImportSource @emotion/react */
import React from 'react';
import A from 'components/htmlElements/A';
import PropTypes from 'prop-types';
import { PRODUCT_CARD_TYPES } from 'globals/constants';
import { colors, spacer } from 'styles/utilities';
import H3 from 'components/htmlElements/H3';
import AssignProductModal from 'components/AssignProductModal/AssignProductModal';
import { useModal } from 'components/Modal/Modal';
import getCurrencySymbol from 'utils/getCurrencySymbol';
import Button from 'components/htmlElements/Button';
import ProgressSvg from 'components/ProgressSvg/ProgressSvg';
import { UnstyledLink } from 'components/Router';
import P from 'components/htmlElements/P';
import { getProductColor } from 'components/ProductCard/helpers';
import MockExamModal from 'components/MockExamModal/MockExamModal';
import * as styles from './ProductBand.styles';

// const getProductColor = (examType) => {
//   const name = examType;

//   if (name === products.CAT.name) {
//     return products.CAT.color;
//   }
//   if (name === products.CEM.name) {
//     return products.CEM.color;
//   }
//   if (name === products.UKISET.name) {
//     return products.UKISET.color;
//   }
//   if (name === products.PRETEST.name) {
//     return products.PRETEST.color;
//   }
//   if (name === products.PROGRESS.name) {
//     return products.PROGRESS.color;
//   }
//   return '';
// };

const ProductBand = (props) => {
  const {
    examType,
    productName,
    productBandType,
    quantity,
    assigned,
    items,
    handleClick,
    price,
    productPageLink,
    progress,
    totalModules,
    totalCompletedModules,
    hideCtaButton,
    isRecommended,
    mockExamData
  } = props;

  const { isOpen, openModal, toggle, closeModal } = useModal();
  const {
    isOpen: isMockExamModalOpen,
    openModal: openMockExamModal,
    toggle: toggleMockExamModal,
    closeModal: closeMockExamModal
  } = useModal();

  const assignProductHandler = (e) => {
    e.preventDefault();

    // close modal after successfully adding the event to calendar
    if (productBandType === PRODUCT_CARD_TYPES.mockExam) {
      setTimeout(() => {
        const btn = document.getElementById('atcb-button');
        const slideObserver = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (
              mutation.type === 'attributes' &&
              mutation.target.getAttribute('atcb-last-event').includes('success')
            ) {
              closeMockExamModal();
            }
          });
        });

        slideObserver.observe(btn, {
          attributes: true, // configure it to listen to attribute changes
          attributeFilter: ['atcb-last-event'] // filter your attributes
        });
      }, [500]);

      openMockExamModal();
    } else openModal();
  };

  return (
    <React.Fragment>
      {productBandType === PRODUCT_CARD_TYPES.assignmentDetails && (
        <UnstyledLink
          to={productPageLink}
          className="d-flex justify-content-between align-items-center"
          css={[
            spacer.padBT20,
            spacer.padL10,
            spacer.padR40,
            styles.ProductWrapper({ hoverColor: colors.green, alpha: 0.1 })
          ]}
        >
          <div className="d-flex align-items-center">
            <ProgressSvg size={40} progress={progress} />
            <div css={spacer.mrL20}>
              <H3 gBold color={getProductColor(examType)} className="m-0">
                {productName}
              </H3>

              <P large gBook color={colors.mediumGrey} css={[spacer.mrT10, spacer.mrB0]}>
                {totalCompletedModules}/{totalModules} Modules answered
              </P>
            </div>
          </div>

          <A large href={productPageLink} underline className="text-center">
            View Details
          </A>
        </UnstyledLink>
      )}

      {productBandType === PRODUCT_CARD_TYPES.assignTest && (
        <React.Fragment>
          <div
            onClick={!hideCtaButton && assignProductHandler}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            className="d-flex justify-content-between align-items-center"
            css={[
              spacer.padBT20,
              spacer.padL10,
              spacer.padR40,
              styles.ProductWrapper({ hoverColor: colors.green, alpha: 0.1, hideCtaButton })
            ]}
          >
            <div className="d-flex align-items-center ">
              <styles.ProductIcon color={getProductColor(examType)} css={spacer.mrR20} />
              <div>
                <H3 gBold color={getProductColor(examType)} className="m-0">
                  {productName}
                </H3>
                {quantity > 0 && (
                  <P large gBook color={colors.mediumGrey} css={[spacer.mrT10, spacer.mrB0]}>
                    {assigned}/{quantity} copies assigned
                  </P>
                )}
              </div>
            </div>

            {!hideCtaButton && (
              <A large href="#" underline className="text-center" onClick={assignProductHandler}>
                Assign Product
              </A>
            )}
          </div>

          {!hideCtaButton && (
            <AssignProductModal
              isOpen={isOpen}
              toggleModal={toggle}
              closeModal={closeModal}
              handleAssign={handleClick}
              color={getProductColor(examType)}
              header={productName}
              assigned={assigned}
              items={items}
              quantity={quantity}
            />
          )}
        </React.Fragment>
      )}

      {productBandType === '' && (
        <A
          href={productPageLink}
          target="_blank"
          className="d-flex justify-content-between align-items-center"
          css={[
            spacer.padBT20,
            spacer.padLR10,
            styles.ProductWrapper(
              isRecommended
                ? {
                    hoverColor: colors.green,
                    alpha: 0.1
                  }
                : {
                    hoverColor: colors.white,
                    alpha: 0.2
                  }
            )
          ]}
        >
          <div className="d-flex align-items-center">
            <styles.ProductIcon color={getProductColor(examType)} css={spacer.mrR20} />
            <div>
              <H3
                gBold
                color={isRecommended ? getProductColor(examType) : colors.white}
                className="m-0"
              >
                {productName}
              </H3>

              {price && (
                <P
                  large
                  gBook
                  color={isRecommended ? colors.black : colors.white}
                  css={[spacer.mrT10, spacer.mrB0]}
                >
                  Price {getCurrencySymbol}
                  {price}
                </P>
              )}
            </div>
          </div>

          <Button
            rounded
            bgHover={getProductColor(examType)}
            href={productPageLink}
            target="_blank"
            as="a"
          >
            Purchase Now
          </Button>
        </A>
      )}

      {productBandType === PRODUCT_CARD_TYPES.mockExam && (
        <React.Fragment>
          <div
            onClick={assignProductHandler}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            className="d-flex justify-content-between align-items-center"
            css={[
              spacer.padBT20,
              spacer.padL10,
              spacer.padR40,
              styles.ProductWrapper({ hoverColor: colors.green, alpha: 0.1, hideCtaButton })
            ]}
          >
            <div className="d-flex align-items-center ">
              <styles.ProductIcon color={getProductColor(examType)} css={spacer.mrR20} />
              <div>
                <H3 gBold color={getProductColor(examType)} className="m-0">
                  {productName}
                </H3>
                {quantity > 0 && (
                  <P
                    large
                    gBook
                    color={colors.mediumGrey}
                    css={[spacer.mrT10, spacer.mrB0]}
                    className="d-flex align-items-center"
                  >
                    {mockExamData?.details?.livemocks_date} <span className="divider" /> Slots
                    booked: {quantity}
                  </P>
                )}
              </div>
            </div>

            <A large href="#" underline className="text-center" onClick={assignProductHandler}>
              View Details
            </A>
          </div>

          <MockExamModal
            isOpen={isMockExamModalOpen}
            toggleModal={toggleMockExamModal}
            color={getProductColor(examType)}
            header={productName}
            quantity={quantity}
            examData={mockExamData}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ProductBand;

ProductBand.propTypes = {
  examType: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  assigned: PropTypes.number,
  items: PropTypes.array,
  handleClick: PropTypes.func,
  price: PropTypes.string,
  productPageLink: PropTypes.string,
  productBandType: PropTypes.string,
  progress: PropTypes.number,
  totalModules: PropTypes.number,
  totalCompletedModules: PropTypes.number,
  hideCtaButton: PropTypes.bool,
  isRecommended: PropTypes.bool,
  mockExamData: PropTypes.object
};

ProductBand.defaultProps = {
  handleClick: null,
  items: [],
  progress: null,
  quantity: null,
  assigned: null,
  price: '',
  productPageLink: '',
  productBandType: '',
  totalModules: null,
  totalCompletedModules: null,
  hideCtaButton: false,
  isRecommended: false,
  mockExamData: {}
};
