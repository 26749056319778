//* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors } from 'styles/utilities';

export const container = css`
  border-bottom: 1px solid ${colors.grey2};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const sectionName = css`
  margin: 0;
  text-transform: uppercase;
`;
