/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types';
import P from 'components/htmlElements/P';
import { colors, spacer } from 'styles/utilities';
import { Fragment } from 'react';
import H5 from 'components/htmlElements/H5';
import * as styles from './StatsItem.styles';

const StatsItem = ({ stat, value, isAdmin }) => (
  <Fragment>
    {isAdmin ? (
      <div>
        <P css={styles.small} color={colors.mediumGrey2} className="small" noMargin>
          {stat}
        </P>
        <H5 css={styles.large} color={colors.mediumGrey2} gBold noMargin>
          {value}
        </H5>
      </div>
    ) : (
      <div css={[spacer.mrB10, styles.Stat]}>
        <P instruction={!isAdmin} color={colors.mediumGrey2} noMargin className="d-inline">
          {stat}:
        </P>{' '}
        <P instruction={!isAdmin} color={colors.mediumGrey2} noMargin className="d-inline">
          {value}
        </P>
      </div>
    )}
  </Fragment>
);

StatsItem.propTypes = {
  stat: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool
};

StatsItem.defaultProps = {
  isAdmin: false
};

export default StatsItem;
