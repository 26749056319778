/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import P from 'components/htmlElements/P';
import Link from 'components/htmlElements/Link';
import A from 'components/htmlElements/A';
import NavsContainer from 'hooks/useNavs';

import { spacer, colors } from 'styles/utilities';
import ProgressSvg from 'components/ProgressSvg/ProgressSvg';
import H5 from 'components/htmlElements/H5';
import * as styles from './Sidebar.styles';

const Sidebar = ({ children, ...props }) => {
  const sidebarRef = React.createRef();

  const { navHeight } = NavsContainer.useContainer();

  // Adjusting `faux-nav` height
  const [sidebarHeight, setSidebarHeight] = useState(0);

  useEffect(() => {
    const handleSetSidebarHeight = () => {
      const windowHeight = window.innerHeight;
      setSidebarHeight(`${windowHeight - navHeight}px`);
    };
    handleSetSidebarHeight();
    window.addEventListener('resize', handleSetSidebarHeight);
    return () => {
      window.removeEventListener('resize', handleSetSidebarHeight);
    };
  }, [navHeight]);

  return (
    <div css={[styles.sidebarContainer]} {...props}>
      <div
        ref={sidebarRef}
        className="sidebar-main"
        style={{ height: sidebarHeight, top: navHeight }}
      >
        {children}
      </div>
    </div>
  );
};
Sidebar.propTypes = {
  children: PropTypes.node.isRequired
};

export const SidebarHeader = ({ header, link, onClick }) => (
  <div css={[styles.header, spacer.padLR15, spacer.padB15, spacer.padT20]}>
    <H5 gBook css={spacer.mrL10}>
      {header}
    </H5>
    <A as={H5} onClick={onClick}>
      {link}
    </A>
  </div>
);

SidebarHeader.propTypes = {
  header: PropTypes.string.isRequired,
  link: PropTypes.string,
  onClick: PropTypes.func
};

SidebarHeader.defaultProps = {
  link: '',
  onClick: null
};

export const SidebarItem = ({ text, link }) => (
  <div css={[spacer.padBT10, spacer.padLR20]}>
    <P as={Link} color={colors.mediumGrey2} to={link}>
      {text}
    </P>
  </div>
);

SidebarItem.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export const StudentSidebarItem = ({ text, link, progress, active }) => (
  <div css={[styles.studentSidebarItemContainer({ active })]}>
    <P as={Link} to={link}>
      <div className="progress">
        <ProgressSvg size={32} progress={progress} />
      </div>
      <div className="data-display">
        <span>{text}</span>
      </div>
    </P>
  </div>
);

StudentSidebarItem.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  active: PropTypes.bool
};
StudentSidebarItem.defaultProps = {
  active: false
};

export const SidebarModalItem = ({ text, onClick }) => {
  const handleClick = (event) => {
    event.preventDefault();
    onClick();
  };
  return (
    <div css={[spacer.padBT10, spacer.padLR20]}>
      <P as="a" color={colors.mediumGrey2} href="#" onClick={handleClick}>
        {text}
      </P>
    </div>
  );
};

SidebarModalItem.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Sidebar;
