//* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { misc } from 'styles/utilities';

export const loginCard = css`
  box-shadow: ${misc.loginBoxShadow};
  position: relative;
  border-radius: ${misc.mediumBorderRadius};
`;

export const logoContainer = css`
  text-align: center;
  img {
    width: 210px;
  }
`;
