const colors = {
  white: '#ffffff',
  whiteTransparent40: 'rgba(255, 255, 255, 0.4)',
  black: '#000000',
  blackTransparent10: 'rgba(0, 0, 0, 0.1)',
  blackTransparent20: 'rgba(0, 0, 0, 0.2)',
  blackTransparent25: 'rgba(0, 0, 0, 0.25)',
  blackTransparent50: 'rgba(0, 0, 0, 0.5)',
  blackTransparent60: 'rgba(0, 0, 0, 0.6)',
  blackTransparent80: 'rgba(0, 0, 0, 0.80)',
  blackTransparent90: 'rgba(0, 0, 0, 0.90)',

  black2: '#222222',
  black3: '#333333',
  black8: '#888888',

  mediumGrey: '#878787',
  mediumGrey2: '#868686',
  grey: '#E0E0E0',
  grey2: '#EAEAEA',
  grey3: '#fAfAfA',
  grey4: '#DDDDDD',
  grey5: '#f5f5f5',
  checkboxGrey: '#858585',
  questionPill: '#f6f6f6',
  questionPillHover: '#EDEDED',
  questionPillUnattempted: '#808080',
  navMenuItem: '#ecf0f1',

  blue: '#0059b2',
  lightBlue: '#21EADE',
  // lighterBlue: '#3391ff',
  mildBlue: 'rgb(204, 243, 225)',
  mathOption: '#ccf3e1',
  cream: '#f5f0ea',
  teal: '#008083',

  red: '#E94B35',
  orange: '#ff5200',
  orange2: '#ff4b00',
  green: '#2ECC71',
  green2: '#1EF078', // hover color if link is already green
  green3: '#CCF3E1',
  green4: '#54a65d',
  purple: '#9a399b',
  yellow: '#eab000',
  lightYellow: '#fffa90',
  progressTestOrange: '#f2966f',
  londonConsortiumGreen: '#96BD39',
  sevenPlus: '#f17034',
  eightPlus: '#4ac2b8',
  tenPlus: '#efcc06',
  elevenPlus: '#41d0ee',
  thirteenPlus: '#95c13d',
  incas: '#e92683',
  adaptiveAdmissionsTest: '#9f4341',
  pre11plus: '#ba3173',

  violet: '#9065E6',
  violetButtonHover: '#8250E8',

  selectBlack: '#4B5468', // dropdown value color

  primaryLinkHover: '#1EF078',
  primaryLinkActive: '#2ECC71',
  secondaryLinkHover: 'rgba(218, 235, 255, 0.5)',
  secondaryLinkActive: '#daebff'
};

export const colorAlias = {
  primaryAnchorDefaultColor: colors.green
};

const analyticsColors = {
  correctAnswer: '#C2DF94',
  incorrectAnswer: '#FF9388',
  blank: '#91A7D9',
  performance: '#8FD2AB',
  cohortPerformance: '#8DA8C3',
  lowestScore: '#99B9D9',
  averageScore: '#4F9BE8',
  highestScore: '#478CD2',
  medianScore: '#9AAFC5',
  scoreBandExcellent: '#82BD9A',
  scoreBandGood: '#B7DCA0',
  scoreBandAverage: '#91A7D9',
  scoreBandBelowAverage: '#EEA0BF',
  scoreBandNeedImprovement: '#FFAD98',
  fastestTime: '#9AAFE3',
  slowestTime: '#A1D6B0'
};

export default { ...colors, ...colorAlias, ...analyticsColors };
