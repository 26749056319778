import { css } from '@emotion/react';
import { colors } from 'styles/utilities';

export const container = css`
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  color: ${colors.mediumGrey};
`;

export const solution = css`
  border-top: 1px solid ${colors.mediumGrey};
  padding-top: 10px;
  margin-top: 10px;
  * {
    color: ${colors.mediumGrey};
  }
`;
