import styled from '@emotion/styled';
import Button from 'components/htmlElements/Button';

import { colors, mixins, typography } from 'styles/utilities';

const FilterButton = styled(Button)`
  background-color: transparent;
  border: 2px solid ${colors.blackTransparent10};
  color: ${colors.mediumGrey};
  padding: 15px 20px;
  font-family: ${typography.fonts.gothamBook.fontFamily};
  &.active,
  &:hover {
    color: ${colors.primaryAnchorDefaultColor};
    background-color: ${mixins.toRgba(colors.primaryAnchorDefaultColor, 0.1)};
    border: 2px solid ${colors.primaryAnchorDefaultColor};
  }
  &.active:hover {
    opacity: 0.7;
  }
`;

FilterButton.defaultProps = {
  small: true,
  rounded: true,
  secondary: true
};

export default FilterButton;
