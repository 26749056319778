/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
// import { navigate } from 'components/Router';

import { Col } from 'components/Grid';
import H1 from 'components/htmlElements/H1';
import SectionBand from 'components/SectionBand';

import ProductCard from 'components/ProductCard';
import { PRODUCT_CARD_TYPES } from 'globals/constants';

import { spacer, colors } from 'styles/utilities';
import { getProductCategory } from 'components/ProductCard/helpers';
// import * as styles from './page.styles';

const SchoolProduct = ({ products }) => (
  <Col>
    <div css={spacer.mrBT30}>
      <SectionBand sectionName="products" />

      <div className="d-flex flex-wrap">
        {products.length ? (
          products.map((product) => (
            <div css={[spacer.mrB20, spacer.mrR20]} key={product.product_id}>
              <ProductCard
                productCardType={PRODUCT_CARD_TYPES.assignmentDetails}
                examType={getProductCategory(product.product_cat)}
                productType={product.product_collection[0].slug}
                productName={product.name}
                progress={product.assigned / product.quantity}
                progressText="Assigned"
                totalCompletedModules={product.assigned}
                totalModules={product.quantity}
                hideCtaButton
                // ctaText="View Details"
                // productPageLink={`../../products/${product.product_id}`}
                // handleClick={() => navigate(`../../products/${product.product_id}`)}
              />
            </div>
          ))
        ) : (
          <H1 gBlack css={spacer.mrB30} color={colors.grey}>
            No products purchased yet
          </H1>
        )}
      </div>
    </div>
  </Col>
);

SchoolProduct.propTypes = {
  products: PropTypes.array.isRequired
};

export default SchoolProduct;
