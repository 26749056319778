/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';
import PropTypes from 'prop-types';

import A from 'components/htmlElements/A';
import H6 from 'components/htmlElements/H6';
import Button from 'components/htmlElements/Button';
import { spacer, bgColor, colors } from 'styles/utilities';
import { getProductColor, ProductCardTop } from '../helpers';

import * as styles from '../ProductCard.styles';

const ProductCardNoStats = (props) => {
  const { examType, productType, productPageLink, productName, productPrice } = props;

  return (
    <Fragment>
      <A href={productPageLink} target="_blank">
        <div css={styles.cardOuter(getProductColor(examType))} className="card-outer">
          <ProductCardTop productName={productName} productType={productType} examType={examType} />

          <div css={[styles.borderTop, spacer.padLR15, spacer.padBT10, bgColor.grey2]}>
            <H6 gBold bold color={colors.green} noMargin>
              {productPrice}
            </H6>
          </div>
        </div>
      </A>

      <Button
        wide
        bgHover={getProductColor(examType)}
        href={productPageLink}
        target="_blank"
        as="a"
      >
        Purchase Product
      </Button>
    </Fragment>
  );
};

ProductCardNoStats.propTypes = {
  examType: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  productPageLink: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  productPrice: PropTypes.string.isRequired
};

export default ProductCardNoStats;
