import React, { useContext, createContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useModal } from 'components/Modal';

const WelcomeTourContext = createContext();

export const useWelcomeTourContext = () => useContext(WelcomeTourContext);

const WelcomeTourProvider = ({ children, showTour }) => {
  const { isOpen, openModal, closeModal } = useModal(showTour || false);

  const closeWelcomeTourModal = useCallback(() => {
    closeModal();
  }, [closeModal]);
  const showWelcomeTourModal = useCallback(() => {
    openModal();
  }, [openModal]);

  const value = useMemo(
    () => ({ showWelcomeTourModal, closeWelcomeTourModal, isOpen }),
    [showWelcomeTourModal, closeWelcomeTourModal, isOpen]
  );

  return <WelcomeTourContext.Provider value={value}>{children}</WelcomeTourContext.Provider>;
};

WelcomeTourProvider.propTypes = {
  children: PropTypes.any.isRequired,
  showTour: PropTypes.bool
};
WelcomeTourProvider.defaultProps = {
  showTour: false
};

export default WelcomeTourProvider;
