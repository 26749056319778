const removeDuplicates = (duplicates, propertyToCheck) => {
  const flag = {};
  const unique = [];
  if (duplicates) {
    duplicates.forEach((elem) => {
      if (!flag[elem[propertyToCheck]]) {
        flag[elem[propertyToCheck]] = true;
        unique.push({
          ...elem,
          count: duplicates.filter((dup) => dup.product_id === elem.product_id).length
        });
      }
    });
  }

  return unique;
};

export default removeDuplicates;
