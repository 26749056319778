/** @jsxImportSource @emotion/react */
import { useEffect, Fragment } from 'react';
import { Router } from 'components/Router';
import { ROUTE_PATHS } from 'globals/constants';

import Navbar, { useComponentVisible, NavMenuItem, DropdownMenu } from 'components/Navbar';
import NavsContainer from 'hooks/useNavs';

import { useLogout, UserInfoContainer } from 'api/auth';
import Layout from 'layouts';
import A from 'components/htmlElements/A';

import UserAccountContextsProvider, {
  useUserAccountContext
} from 'components/EditAccount/userAccountContext';

// Load up all modals used for parent accounts
import EditAccountModal from 'components/EditAccount';
import ChangePassword from 'components/ChangePassword/ChangePassword';
import EditCandidateAccount from 'components/EditCandidateAccount';

import SchoolDashboardContainer from 'pages/School/Dashboard/container';
import SchoolProductContainer from 'pages/School/Products/container';
// import ProductDetailsContainer from 'pages/School/ProductDetails/container';
import StudentContainer from 'pages/School/Student/container';
import NotFound from 'pages/NotFound/page';

// Candidate screens
import TestDetailsContainer from 'pages/Candidate/TestDetails/container';
import TestContainer from 'pages/Test/container';
import CandidateCourse from 'pages/Candidate/Course';
import CandidateDashboardContainer from 'pages/Candidate/Dashboard/container';

import { spacer, colors } from 'styles/utilities';
import SchoolCohortContainer from 'pages/School/Cohort/container';

const SchoolNav = () => {
  const { ref, isComponentVisible, toggleVisibility } = useComponentVisible(false);
  const logout = useLogout();
  const { openEditAccountModal, openChangePasswordModal } = useUserAccountContext();
  const { id: schoolId } = UserInfoContainer.useContainer();

  const handleEditAccountClick = (event) => {
    event.preventDefault();
    openEditAccountModal();
  };

  const handleChangePasswordClick = (event) => {
    event.preventDefault();
    openChangePasswordModal();
  };

  const handleLogout = (event) => {
    event.preventDefault();
    logout();
  };

  return (
    <Fragment>
      <div className="d-flex align-items-center">
        <NavMenuItem text="Home" link="/" />
        <NavMenuItem text="Products" link="/products" />
        {[1726, 21592].includes(schoolId) && <NavMenuItem text="Cohort" link="/cohort" />}
        <NavMenuItem account onClick={toggleVisibility} ref={ref} />
      </div>
      <DropdownMenu show={isComponentVisible}>
        <A
          href="#"
          onClick={handleEditAccountClick}
          color={colors.mediumGrey}
          transform="uppercase"
          css={[spacer.padBT10, spacer.padLR10]}
        >
          Edit Account
        </A>
        <A
          href="#"
          onClick={handleChangePasswordClick}
          color={colors.mediumGrey}
          transform="uppercase"
          css={[spacer.padBT10, spacer.padLR10]}
        >
          Change Password
        </A>
        <A
          href="#"
          onClick={handleLogout}
          color={colors.mediumGrey}
          transform="uppercase"
          css={[spacer.padBT10, spacer.padLR10]}
        >
          Logout
        </A>
      </DropdownMenu>
    </Fragment>
  );
};

const SchoolRoutes = () => {
  const { userIs, id: schoolId } = UserInfoContainer.useContainer();
  const logout = useLogout();

  const { navHidden } = NavsContainer.useContainer();

  useEffect(() => {
    if (userIs !== 'school') {
      logout();
    }
  }, [logout, userIs]);

  return (
    <UserAccountContextsProvider>
      {!navHidden && (
        <Navbar>
          <SchoolNav />
        </Navbar>
      )}

      <Router>
        <Layout component={SchoolDashboardContainer} path="/" />
        <Layout component={SchoolProductContainer} path={ROUTE_PATHS.schoolPurchasedProducts} />
        {[1726, 21592].includes(schoolId) && (
          <Layout component={SchoolCohortContainer} path={ROUTE_PATHS.schoolCohort} />
        )}
        {/* <Layout
          component={ProductDetailsContainer}
          path={ROUTE_PATHS.schoolPurchasedProductDetails}
        /> */}

        <Layout component={StudentContainer} path={ROUTE_PATHS.schoolCandidate}>
          <CandidateDashboardContainer path="/" />
          <TestDetailsContainer path={ROUTE_PATHS.candidate.testDetails} />
          <TestContainer path={ROUTE_PATHS.candidate.testFlow} />
          <CandidateCourse path={ROUTE_PATHS.candidate.coursePage} />
        </Layout>

        <Layout component={NotFound} default />
      </Router>

      {/* Modal forms */}
      <EditAccountModal />
      <ChangePassword />
      <EditCandidateAccount />
    </UserAccountContextsProvider>
  );
};

export default SchoolRoutes;
