//* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors } from 'styles/utilities';

export const link = css`
  text-transform: uppercase;
  margin: 0;
  /* line-height: 1; */
`;

export const arrow = css`
  color: ${colors.green};
`;

export const crumbs = css`
  display: flex;
  align-items: center;
`;
