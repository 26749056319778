/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors } from 'styles/utilities';

export const questionContents = css`
  img {
    width: 100%;
  }
  figure.image {
    height: 400px;
    overflow: auto;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    border: 1px solid ${colors.grey2};
  }
`;
