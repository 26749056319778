import styled from '@emotion/styled';
import { colors, mixins, spacer } from 'styles/utilities';

import H5 from 'components/htmlElements/H5';

export const StyledTabs = styled.div`
  [role='tablist'] {
    margin-top: 0;
    margin-left: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
    overflow: hidden;
    > * {
      border-bottom: 4px solid transparent;
      white-space: pre;
      ${[spacer.padBT15, spacer.padLR60]}
      text-align: center;
      cursor: pointer;
      outline: none;
      color: ${({ color }) => color};
      ${mixins.transition()}
      &[aria-selected='true'] {
        border-bottom-color: ${colors.primaryLinkActive};
        color: ${colors.black};
        background-color: ${colors.cream};
      }
      &:hover {
        border-bottom-color: ${colors.primaryLinkHover};
        color: ${colors.primaryLinkHover};
        background-color: ${colors.cream};
      }
      &:active {
        border-bottom-color: ${colors.primaryLinkActive};
        color: ${colors.primaryLinkActive};
      }
    }
  }
`;

export const StyledTab = styled(H5)`
  margin: 0;
`;
