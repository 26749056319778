/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types';

import { PRODUCT_CARD_TYPES } from 'globals/constants';
import ProductCardNoStats from './variants/ProductCardNoStats';
import ProductCardWithStats from './variants/ProductCardWithStats';
import ProductCardAdminAssigned from './variants/ProductCardAdminAssigned';
import ProductCardAssignTest from './variants/ProductCardAssignTest';
import ProductCardAssignmetDetails from './variants/ProductCardAssignmentDetails';

import * as styles from './ProductCard.styles';

const ProductCard = ({
  productCardType,
  examType,
  productType,
  productPageLink,
  productName,
  productPrice,
  progress,
  totalModules,
  totalCompletedModules,
  onAssignTestHandle,
  ...restProps
}) => {
  const allProps = {
    examType,
    productType,
    productPageLink,
    productName,
    productPrice,
    progress,
    totalModules,
    totalCompletedModules,
    onAssignTestHandle,
    ...restProps
  };

  return (
    <div css={styles.productCardWrapper}>
      {productCardType === '' && <ProductCardNoStats {...allProps} />}

      {productCardType === PRODUCT_CARD_TYPES.adminAssigned && (
        <ProductCardAdminAssigned {...allProps} />
      )}

      {productCardType === PRODUCT_CARD_TYPES.assignmentDetails && (
        <ProductCardAssignmetDetails {...allProps} />
      )}

      {productCardType === PRODUCT_CARD_TYPES.assignTest && <ProductCardAssignTest {...allProps} />}

      {productCardType === PRODUCT_CARD_TYPES.withStats && <ProductCardWithStats {...allProps} />}
    </div>
  );
};

ProductCard.propTypes = {
  productCardType: PropTypes.string,
  examType: PropTypes.string.isRequired,
  productType: PropTypes.string,
  productPageLink: PropTypes.string,
  productName: PropTypes.string.isRequired,
  productPrice: PropTypes.string,
  progress: PropTypes.number,
  totalModules: PropTypes.number,
  totalCompletedModules: PropTypes.number,
  onAssignTestHandle: PropTypes.func
};

ProductCard.defaultProps = {
  productCardType: '',
  productPrice: '',
  productType: '',
  productPageLink: '',
  progress: null,
  totalModules: null,
  totalCompletedModules: null,
  onAssignTestHandle: null
};

export default ProductCard;
