/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Button from 'components/htmlElements/Button';
import * as hexToRgba from 'hex-to-rgba';
import { colors, misc } from 'styles/utilities';

export const answerOptionImageSize = 450 / 4; // 40px is buffer for space around. Note: This is also used for Picture image sizing

export const audioQuestionImageSize = 450;
export const audioQuestionImageContainerSize = 14;

export const AudioAnimation = css`
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.green};
  border-radius: ${misc.mediumBorderRadius};

  &.animate {
    .bar {
      animation: sound 0ms -800ms linear infinite alternate;
    }
  }

  .bar {
    background: ${colors.white};
    height: 30px;
    width: 6px;
    border-radius: ${misc.mediumBorderRadius};
  }

  @keyframes sound {
    0% {
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(0.45);
    }
  }

  .bar:nth-of-type(1) {
    animation-duration: 474ms;
  }

  .bar:nth-of-type(2) {
    animation-duration: 433ms;
    margin: 0px 4px;
    height: 20px;
  }

  .bar:nth-of-type(3) {
    animation-duration: 407ms;
  }
`;

export const questionContentWrapper = css`
  width: fit-content;
  border: 1px solid ${colors.mediumGrey};
  border-radius: ${misc.largeBorderRadius};
  padding: 20px;
`;

export const inputContainer = css`
  max-width: 880px;
  height: auto;
  margin: 30px auto;

  &.no-pointer {
    .keyboard-buttons {
      pointer-events: none;
    }
  }
`;

export const input = css`
  min-height: 55px;
  border: 1px solid ${colors.mediumGrey};
  border-radius: ${misc.mediumBorderRadius};
  white-space: pre;
`;

export const KeyboardContainer = css`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px auto;
  border: 1px solid ${colors.mediumGrey};
  border-radius: ${misc.largeBorderRadius};
`;

export const KeyboardWrapper = css`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px -10px;
`;

export const KeyboardButton = styled(Button)`
  min-width: 60px;
  min-height: 60px;
  border-radius: ${misc.largeBorderRadius};
  margin: 0px 10px 15px 10px;
  background-color: ${hexToRgba(colors.mediumGrey, 0.1)};
  border: 1px solid ${colors.mediumGrey};
  color: ${colors.black};
  fill: ${colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 22px;

  &:hover,
  &:active,
  &:focus {
    color: ${colors.black};
    fill: ${colors.black};
  }
`;

export const BottomButton = css`
  background-color: ${hexToRgba(colors.primaryLinkActive, 0.2)};
  border: 1px solid ${colors.primaryLinkActive};
  color: ${colors.black};
  fill: ${colors.black};
  padding: 15px 40px;

  &:hover,
  &:active,
  &:focus {
    color: ${colors.black};
    fill: ${colors.black};
  }
`;

export const answerOptionsWrapper = ({ isImageQuestion }) => css`
  max-width: fit-content;
  margin: 0 auto;
  ${
    '' /* flex-wrap: wrap;
  gap: 20px; */
  }
  margin-top: 0 !important;

  &.section-answer-options {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      'a b c'
      'd b e';
    align-items: center;
    grid-gap: 20px;
    justify-items: center;
  }

  .answer-wrapper {
    ${isImageQuestion &&
    css`
      width: ${audioQuestionImageContainerSize}vw;
      max-height: ${audioQuestionImageContainerSize}vw;
    `};

    min-width: ${audioQuestionImageContainerSize}vw;
    max-width: fit-content;

    &:nth-of-type(1) {
      grid-area: a;
    }
    &:nth-of-type(2) {
      grid-area: b;
    }
    &:nth-of-type(3) {
      grid-area: c;
    }
    &:nth-of-type(4) {
      grid-area: d;
    }
    &:nth-of-type(5) {
      grid-area: e;
    }
  }

  ${
    '' /* .answer-wrapper {
    max-width: 350px;
    width: 50%;
    flex: 0 0 50%;
  }

  .answer-wrapper:nth-of-type(3n) {
    max-width: unset;
    flex: 1 1 100%;

    .option-box-wrapper {
      max-width: 350px;
      width: 50%;
      margin: auto;
    }
  } */
  }

  .answer-contents .image {
    img {
      width: 100%;
      object-position: center;
      object-fit: contain;
    }
  }
`;
