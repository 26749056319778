/* eslint-disable import/prefer-default-export */
import GifAssignTest from 'images/test-tour/assign-test.gif';
import GifChildAccount from 'images/test-tour/child-account.gif';
import GifPurchaseTest from 'images/test-tour/purchase-test.gif';
import GifViewSolution from 'images/test-tour/view-solution2.0.gif';
import GifViewProgress from 'images/test-tour/view-progress2.0.gif';
import GifViewHelp from 'images/test-tour/view-help-section.gif';

export const testStaticSlides = [
  {
    firstSlide: true,
    title: (name) => `Welcome to your dashboard, ${name}!`,
    subtitle:
      "Create accounts for your children and assign the tests and courses you've purchased. You can then keep track of their progress and results.",
    gifURL: ''
  },
  {
    firstSlide: false,
    title: "Create your child's account",
    subtitle:
      'This account can be used by your child to take their test or start their course. You can create separate accounts for different children if you like, each with a unique login.',
    gifURL: GifChildAccount
  },
  {
    firstSlide: false,

    title: "Assign tests or courses to a child's account so they can take it.",
    subtitle:
      "Assign the test or course to a child's account so they can access it. Each one can be assigned to one account only. Each test can be taken once. Course videos can be rewatched as many times as required.",
    gifURL: GifAssignTest
  },
  {
    firstSlide: false,

    title: 'For each test, modules can be taken separately',
    subtitle:
      'The tests are divided into modules that can be taken at different times. A comprehensive report will be generated once the whole test has been completed.',
    gifURL: GifViewSolution
  },
  {
    firstSlide: false,

    title: 'Review and feedback for tests',
    subtitle:
      'Once a module is complete, it will be marked automatically and will then be available for review with feedback and explanations for each question.',
    gifURL: GifViewProgress
  },
  {
    firstSlide: false,

    title: 'More tests and courses, anytime',
    subtitle:
      "Easily purchase more tests and courses for your child directly from your dashboard. We wish you the best of luck with the assessment process. Please don't hesitate to get in touch with us if you have questions or queries. We are always happy to help and advise!",
    gifURL: GifPurchaseTest
  },
  {
    firstSlide: false,

    title: 'Take the tour, anytime',
    subtitle: "Click the HELP button if you'd like to take this tour again in the future.",
    gifURL: GifViewHelp
  }
];
