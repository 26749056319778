/** @jsxImportSource @emotion/react */

import { Fragment } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { FieldError } from 'components/htmlElements/Fields';
import Button from 'components/htmlElements/Button';
import LoaderOverlay from 'components/LoaderOverlay';
import { colors, spacer } from 'styles/utilities';
import A from 'components/htmlElements/A';
import { useUserAccountContext } from 'components/EditAccount/userAccountContext';
import H3 from 'components/htmlElements/H3';
import H5 from 'components/htmlElements/H5';
import Checkbox from 'components/CheckBox/CheckBox';
import H4 from 'components/htmlElements/H4';
import P from 'components/htmlElements/P';
import * as styles from './AssignProductModal.styles';

const assignProductValidationSchema = Yup.object().shape({
  assignProductOptions: Yup.array().required('Required')
});

// Assign Product Modal
const AssignProductModal = ({
  isOpen,
  toggleModal,
  closeModal,
  handleAssign,
  color,
  header,
  items,
  assigned,
  quantity
}) => {
  const { openAddCandidateModal } = useUserAccountContext();

  const handleFormSubmission = async (values, actions) => {
    actions.setSubmitting(true);

    if (values.assignProductOptions.length > quantity) {
      return;
    }

    await Promise.all(
      values.assignProductOptions.map(async (value) => {
        await handleAssign(Number(value));
      })
    );

    actions.resetForm();
    actions.setSubmitting(false);
    closeModal();
  };

  const handleAddChildAccount = (event) => {
    event.preventDefault();
    toggleModal();
    openAddCandidateModal();
  };

  return (
    <div className="is-relative">
      <Modal isOpen={isOpen} toggle={toggleModal}>
        <Formik
          initialValues={{
            assignProductOptions: []
          }}
          validationSchema={assignProductValidationSchema}
          onSubmit={handleFormSubmission}
        >
          {({ isSubmitting, values }) => (
            <Fragment>
              <div css={styles.ModalHeader({ color })}>
                <styles.TopBand color={color} />

                <div css={[spacer.padBT30, spacer.padLR30]}>
                  <H3 gBook css={spacer.mrBT0}>
                    {header}
                  </H3>

                  <P large gBook color={colors.mediumGrey} css={spacer.mrT15}>
                    {assigned + values.assignProductOptions.length}/{quantity} copies assigned
                  </P>
                </div>
              </div>

              <div css={[spacer.padB30, spacer.padT20, spacer.padLR30]}>
                <Form>
                  <div>
                    <H4
                      gBook
                      as="label"
                      htmlFor="assignProductOptions"
                      css={[spacer.mrB20, spacer.mrT0]}
                    >
                      Select child account to assign
                    </H4>

                    <div css={styles.CheckboxContainer}>
                      {items.map((item) => (
                        <div css={styles.CheckBoxWrapper} key={item.id}>
                          <Field
                            name="assignProductOptions"
                            id={item.id}
                            label={
                              <H5
                                as={P}
                                gBook
                                color={colors.mediumGrey}
                                className="label-text"
                                css={[spacer.mrL15, spacer.mrBT0]}
                              >{`${item.firstName} ${item.lastName}`}</H5>
                            }
                            value={item.id}
                            disabled={
                              assigned + values.assignProductOptions.length === quantity &&
                              !values.assignProductOptions.includes(item.id.toString())
                            }
                            as={Checkbox}
                          />
                        </div>
                      ))}
                      <ErrorMessage name="assignProductOptions" component={FieldError} />
                    </div>
                  </div>

                  <div css={spacer.padBT30}>
                    <A large href="#" onClick={handleAddChildAccount}>
                      +Add new child account
                    </A>
                  </div>

                  <Button wide rounded type="submit">
                    Assign Product
                  </Button>
                </Form>

                {isSubmitting && <LoaderOverlay />}
              </div>
            </Fragment>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

AssignProductModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleAssign: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  items: PropTypes.array,
  assigned: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired
};

AssignProductModal.defaultProps = {
  items: []
};

export default AssignProductModal;
