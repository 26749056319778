/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors } from 'styles/utilities';

export const wrapper = css`
  padding: 5px 5px;
  background-color: ${colors.red};
  position: relative;
`;

export const noticeText = css`
  padding: 5px 5px;
  background-color: ${colors.green};
`;
