/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';
import GracefulImage from 'react-graceful-image';
import * as LogRocket from 'logrocket';

const ReactGracefulImage = (props) => (
  <GracefulImage
    {...props}
    retry={{ count: 5, delay: 2, accumulate: 'add' }}
    onError={() => LogRocket.error(`Image ${props.src} failed to load`)}
  />
);

ReactGracefulImage.propTypes = {
  src: PropTypes.string.isRequired
};

export default ReactGracefulImage;
