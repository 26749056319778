/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors } from 'styles/utilities';

export const answerOptionWrapper = (props) => css`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  ${props.type === 'square' &&
  css`
    &:hover {
      cursor: pointer;
      .alphabet-box {
        background-color: ${colors.grey};
      }
      .answer-contents * {
        color: ${colors.black8};
      }
    }
  `}

  ${props.type === 'circle' &&
  css`
    .answer-contents .image {
      min-width: 60%;
      max-width: 60%;
      margin-bottom: 0px;

      & > img {
        max-width: 100%;
      }
    }

    &:hover {
      cursor: pointer;
      .option-circle {
        border-color: ${colors.yellow};
      }
      .answer-contents * {
        color: ${colors.black8};
      }
    }
  `}

  ${props.type === 'box' &&
  css`
    &:hover {
      cursor: pointer;
      .answer-contents {
        border-color: ${colors.yellow};
      }
    }
  `}
`;

// Square (aka checkbox input) styles
export const alphabetBox = css`
  position: relative;
  width: 40px;
  height: 40px;
  background-color: ${colors.grey2};
  margin-right: 15px;
  margin-left: 15px;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${colors.mediumGrey2};
  }
`;

export const correctAnswer = css`
  margin-left: 40px;
`;

// Circle type (aka checkbox input) styles
export const optionCircleWrapper = css`
  input {
    display: none;
    &:checked + .option-circle {
      border-color: ${colors.blue};
    }
  }
`;

export const optionCircle = ({ isCorrectAnswer, checked }) => css`
  height: 32px;
  width: 32px;
  border: 4px solid ${colors.orange};
  border-radius: 20px;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  display: block;
  background-color: ${colors.white};
  text-align: center;
  line-height: 32px;
  padding: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  &.option-background {
    ${checked
      ? css`
          background-color: ${isCorrectAnswer ? colors.green : colors.red};
          border-color: ${isCorrectAnswer ? colors.green : colors.red};
        `
      : isCorrectAnswer &&
        css`
          background-color: ${colors.green};
          border-color: ${colors.green};
        `}
  }
`;

export const optionBoxWrapper = css`
  width: 100%;
  padding: 10px;
`;

export const optionBoxInner = ({ isCorrectAnswer, checked, attemptFinished }) => css`
  .answer-contents {
    border: 4px solid ${colors.orange};
    border-radius: 4px;
    min-height: 90px;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${checked && attemptFinished
      ? css`
          background-color: ${isCorrectAnswer ? colors.green : colors.red};
          border-color: ${isCorrectAnswer ? colors.green : colors.red};
        `
      : isCorrectAnswer &&
        css`
          background-color: ${colors.green};
          border-color: ${colors.green};
        `}
  }

  .answer-contents .image {
    margin: auto;
    margin-bottom: 0px;

    & > img {
      max-width: 100%;
    }
  }

  input {
    display: none;
    &:checked + .answer-contents {
      border-color: ${colors.blue};
    }
  }
`;
