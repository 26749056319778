// import { keyframes, css } from '@emotion/react';
// import styled from '@emotion/styled/src';
import styled from '@emotion/styled';
// import isPropValid from '@emotion/is-prop-valid';
import styledMap from 'styled-map';
import facepaint from 'facepaint';
import { typography, misc, mixins, colors } from 'styles/utilities';

const breakPoints = facepaint([mixins.respondTo(misc.viewport['d-vp'])]);

const baseButton = styled('button', {
  // shouldForwardProp: (prop) =>
  //   !['product', 'small', 'wide', 'secondary'].includes(prop) && isPropValid(prop)
  // shouldForwardProp: (prop) => isPropValid(prop)
})`
  display: inline-block;
  vertical-align: middle;
  padding: ${styledMap`
    small: 12px 10px;
    product: 17px;
    default: 20px;
  `};
  line-height: ${(props) => {
    if (props.icon) {
      return '1';
    }
    return 'unset';
  }};
  text-align: ${styledMap`
  textCenter: center;
   default: left;
`};
  border: none;
  border-radius: ${styledMap`
  rounded: ${misc.mediumBorderRadius};
   default: 0;
`};
  opacity: 1;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  ${mixins.fontHacks()}
  overflow: hidden;
  -webkit-appearance: none;
  transform: translateZ(0);
  ${mixins.transition()}
  width: ${styledMap`
    wide: 100%;
    default: auto;
  `};
  font-family: ${typography.fonts.gothamBold.fontFamily};
  background-color: ${styledMap`
    default: ${colors.green};
  `};
  color: ${styledMap`
    secondary: ${colors.navyBlue};
    default: ${colors.white};
  `};
  fill: ${styledMap`
    secondary: ${colors.navyBlue};
    default: ${colors.white};
  `};
  ${mixins.linkActiveStyles} {
    outline: none;
    text-decoration: none;
    color: ${styledMap`
      secondary: ${colors.navyBlue};
      default: ${colors.white};
    `};
    fill: ${styledMap`
      secondary: ${colors.navyBlue};
      default: ${colors.white};
    `};
  }
  &:hover {
    background-color: ${(props) => {
      if (props.bgHover) {
        return props.bgHover;
      }
      return `${colors.primaryLinkHover}`;
    }};
  }
  &:active {
    background-color: ${styledMap`
      secondary: ${colors.secondaryLinkActive};
      default: ${colors.primaryLinkActive};
    `};
  }
  &[disabled] {
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.4);
    color: ${colors.white};
    fill: ${colors.white};
    border-color: transparent;
  }
`;

const Button = styled(baseButton)((props) =>
  breakPoints({
    fontSize: props.large
      ? ['16px', typography.baseSizes.epsilon]
      : props.small
      ? [typography.baseSizes.eta]
      : props.product
      ? ['15px']
      : [typography.baseSizes.eta, '18px']
  })
);

export default Button;
