/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'components/QuestionTypes/shared-components/DndkitComponents';

import P from 'components/htmlElements/P';
import * as styles from './Wordbox.styles';

const Wordbox = ({ text, fixedSize, type, ...props }) => (
  <P as="div" role="button" large css={styles.wrapper({ fixedSize, type })} {...props}>
    <span>{text}</span>
  </P>
);
Wordbox.propTypes = {
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  fixedSize: PropTypes.bool,
  type: PropTypes.oneOf(['square', 'circle'])
};
Wordbox.defaultProps = {
  fixedSize: false,
  type: 'square'
};

export const WordboxPlaceholder = ({ text, ...props }) => (
  <div css={styles.placeholder}>
    <Wordbox text={text} className="wordbox" {...props} />
    {/* <P as="div" large className="wordbox" css={styles.wrapper}>
      {text}
    </P> */}
  </div>
);
WordboxPlaceholder.propTypes = {
  text: PropTypes.string.isRequired
};

const QuestionWordBox = ({ isOver, children, fixedSize, type }) => (
  <div css={styles.questionWordBox({ isOver })}>
    <Wordbox text="?" fixedSize={fixedSize} className="wordbox" type={type} />
    <div className="box-contents">{children}</div>
  </div>
);
QuestionWordBox.propTypes = {
  isOver: PropTypes.bool,
  children: PropTypes.node,
  fixedSize: PropTypes.bool,
  type: PropTypes.oneOf(['square', 'circle'])
};
QuestionWordBox.defaultProps = {
  isOver: false,
  children: null,
  fixedSize: false,
  type: 'square'
};

export const DroppableWordbox = ({ id, accepts, ...props }) => (
  <Droppable id={id} accepts={accepts}>
    <QuestionWordBox {...props} />
  </Droppable>
);
DroppableWordbox.propTypes = {
  id: PropTypes.string.isRequired,
  accepts: PropTypes.array
};
DroppableWordbox.defaultProps = {
  accepts: []
};

export default Wordbox;
