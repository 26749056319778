/** @jsxImportSource @emotion/react */
import { Col, Row } from 'components/Grid/Grid';
import { bgColor, colors, spacer } from 'styles/utilities';
import P from 'components/htmlElements/P';
import { Chart } from 'react-google-charts';
import H3 from 'components/htmlElements/H3';
import PropTypes from 'prop-types';

import H4 from 'components/htmlElements/H4';
import Icon from 'components/Icon/Icon';
import secondsToHms from 'utils/secondsToHms';
import Button from 'components/htmlElements/Button';
import { UnstyledLink } from 'components/Router/Router';
import A from 'components/htmlElements/A';
import { Fragment } from 'react';
import TooltipTrigger from 'components/Tooltip/TooltipTrigger';
import { site } from 'globals/constants';
import {
  donutChartOptions,
  getRank,
  getScoreMetric,
  marksDistributionOptions,
  scoreBands,
  topicPerformanceChartOptions
} from './helper';

import * as styles from './TestAnalytics.styles';

const ToolTipInner = () => (
  <div className="text-left">
    <P instruction css={spacer.mrB10}>
      it looks like no marks were scored in this module so there is no data to report. Please
      contact us to discuss this further.
    </P>

    <A href={`${site.url}/contact`} target="_blank" underline>
      Contact Us
    </A>
  </div>
);

export const TestScores = ({ scores, showTiming }) => (
  <Row>
    {scores.map(({ title, score, showScoreWarning }) => (
      <Col className="col-12 col-sm-6 col-xl-3" css={styles.column({ showTiming })} key={title}>
        <div
          css={[spacer.padLR30, spacer.padBT20, styles.cardStyles, bgColor.white]}
          className="d-flex align-items-center justify-content-center h-100"
        >
          <div className="text-center" css={styles.warningTooltip}>
            <P css={[spacer.mrB10, spacer.padB5, spacer.mrT0]} gBold color="grey">
              {title}
            </P>
            <Fragment>
              {showScoreWarning ? (
                <TooltipTrigger
                  tooltipInner={<ToolTipInner />}
                  placement="right"
                  trigger="hover"
                  closeOnClick={false}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <H3 color={colors.red} css={spacer.mrR5}>
                      {score}
                    </H3>
                    <Icon icon="warning" size={20} color={colors.red} />
                  </div>
                </TooltipTrigger>
              ) : (
                <H3>{score}</H3>
              )}
            </Fragment>
          </div>
        </div>
      </Col>
    ))}
  </Row>
);

TestScores.propTypes = {
  scores: PropTypes.array.isRequired,
  showTiming: PropTypes.bool.isRequired
};

export const PerformanceSummary = ({ performanceStats, candidateFirstName }) => (
  <div css={[spacer.padBT30, spacer.padLR20, spacer.mrB60, styles.cardStyles]}>
    <Row>
      <Col className="col-12 col-lg-3 d-flex d-lg-block">
        {performanceStats?.answers?.map(({ title, score }) => (
          <div css={[spacer.padLR20, spacer.padBT20]} key={title}>
            <P css={[spacer.mrB10, spacer.padB5, spacer.mrT0]} gBold color="grey">
              {title}
            </P>
            <H3>{score}</H3>
          </div>
        ))}
      </Col>

      <Col className="col-12 col-lg-9">
        <Row>
          <Col className="col-sm-6" css={spacer.padLR0}>
            <P large className="text-center" gBold color={colors.black3} css={spacer.mrB10}>
              {candidateFirstName}&apos;s Performance
            </P>

            <div css={styles.chartWrapper}>
              <Chart
                chartType="PieChart"
                height="100%"
                width="100%"
                data={performanceStats?.studentPerformanceChart}
                options={donutChartOptions}
                key="student-chart"
              />
            </div>
          </Col>

          {performanceStats?.cohortPerformanceChart && (
            <Col className="col-sm-6" css={spacer.padLR0}>
              <P large className="text-center" gBold color={colors.black3} css={spacer.mrB10}>
                Cohort Average Performance
              </P>
              <div css={styles.chartWrapper}>
                <Chart
                  chartType="PieChart"
                  height="100%"
                  width="100%"
                  data={performanceStats?.cohortPerformanceChart}
                  options={donutChartOptions}
                  key="cohort-chart"
                />
              </div>
            </Col>
          )}
        </Row>

        <div
          css={styles.chartLegend}
          className="d-flex align-items-center justify-content-center flex-wrap"
        >
          <div className="d-flex align-items-center" css={[spacer.mrR40, spacer.mrT10]}>
            <div className="legend-color" css={[bgColor.correctAnswer, spacer.mrR10]} />
            <P gBold color={colors.black3} className="legend-label">
              Correct answers
            </P>
          </div>
          <div className="d-flex align-items-center" css={[spacer.mrR40, spacer.mrT10]}>
            <div className="legend-color" css={[bgColor.incorrectAnswer, spacer.mrR10]} />
            <P gBold color={colors.black3} className="legend-label">
              Incorrect answers
            </P>
          </div>
          <div className="d-flex align-items-center" css={spacer.mrT10}>
            <div className="legend-color" css={[bgColor.blank, spacer.mrR10]} />
            <P gBold color={colors.black3} className="legend-label">
              Not attempted
            </P>
          </div>
        </div>
      </Col>
    </Row>
  </div>
);

PerformanceSummary.propTypes = {
  performanceStats: PropTypes.object.isRequired,
  candidateFirstName: PropTypes.string.isRequired
};

export const MarksDistribution = ({ distributionScores }) => (
  <div css={spacer.mrB60}>
    <H4>Candidate&apos;s Mark Distribution</H4>

    <Row css={[spacer.mrT30]}>
      <Col className="col-12 col-xl-3" css={[styles.tableWrapper, spacer.mrB30]}>
        <div className="card-default">
          {distributionScores.table.map(({ title, score }, index) => (
            <div className="d-flex align-items-center table-row" key={title}>
              <P instruction gBold className="cell-title" color={colors.black3}>
                {title}
              </P>
              <P
                gBold={index === 0}
                instruction
                className="text-center cell-score"
                color={colors.black3}
              >
                {score}
              </P>
            </div>
          ))}
        </div>
      </Col>

      <Col className="col-12 col-xl-9 chart-wrapper">
        <Chart
          chartType="BarChart"
          height="300px"
          width="100%"
          data={distributionScores?.chart}
          options={marksDistributionOptions({
            bars: distributionScores?.chart?.length - 1
          })}
        />
      </Col>
    </Row>
  </div>
);

MarksDistribution.propTypes = {
  distributionScores: PropTypes.object.isRequired
};

export const AnalyticsScoreBand = ({ markerPosition, copy }) => (
  <div css={spacer.mrB60}>
    <P large css={spacer.mrB60}>
      {copy ||
        `The chart below shows the distribution of results partitioned into FIVE groups. The groups
      range from Band 1 (the top 20% of students with the highest scores) to Band 5 (the bottom 20%
      of students with the lowest scores). Partitioning students into FIVE groups provides a clear
      understanding of relative performance.`}
    </P>

    <div
      css={styles.scoreBandWrapper({
        markerPosition
      })}
    >
      <div className="score-bands-wrapper d-flex w-100">
        {scoreBands.map(({ color }) => (
          <div className="band" css={styles.bandColor({ bg: color })} key={color} />
        ))}
      </div>

      {/* score band pin */}
      <Icon icon="scoreBandPin" className="score-band-pin" size="64" color={colors.black3} />

      <div className="d-flex w-100" css={spacer.mrT20}>
        {scoreBands.map(({ title, scoreRange }) => (
          <div className="score-band-text text-center" key={title}>
            <P large gBold>
              {title}
            </P>
            <P large gBold>
              {scoreRange}
            </P>
          </div>
        ))}
      </div>
    </div>
  </div>
);

AnalyticsScoreBand.propTypes = {
  markerPosition: PropTypes.number.isRequired,
  copy: PropTypes.string
};

AnalyticsScoreBand.defaultProps = {
  copy: ''
};

export const PercentileBand = ({ percentiles, candidatePercentage }) => {
  const { percentileRanges, totalScoresCount } = percentiles;

  const totalScoresBelowRange = percentileRanges
    ?.slice(0, candidatePercentage)
    .reduce((a, c) => a + c.scoresInRangeCount, 0);

  const percentile = (totalScoresBelowRange / totalScoresCount) * 100;

  return (
    <AnalyticsScoreBand
      markerPosition={percentile}
      copy="The chart below shows the percentile of results partitioned into FIVE groups. The groups
      range from Band 1 (the top 20% of students with the highest scores) to Band 5 (the bottom 20%
      of students with the lowest scores). Partitioning students into FIVE groups provides a clear
      understanding of relative performance."
    />
  );
};

PercentileBand.propTypes = {
  percentiles: PropTypes.object.isRequired,
  candidatePercentage: PropTypes.number.isRequired
};

export const TopicPerformanceChart = ({ topicPerformanceData, candidateFirstName }) => (
  <div
    css={[
      spacer.mrB60,
      spacer.padBT20,
      spacer.padL20,
      styles.topicPerformanceChartWrapper,
      styles.chartTooltipStyles
    ]}
  >
    <P large className="text-center" gBold color={colors.black3} css={spacer.mrB40}>
      {`Topic Performance: ${candidateFirstName}'s vs Cohort`}
    </P>

    <Chart
      chartType="ColumnChart"
      height="250px"
      width="100%"
      data={topicPerformanceData}
      options={topicPerformanceChartOptions}
    />

    <div
      css={[spacer.padBT20, styles.chartLegend]}
      className="d-flex align-items-center justify-content-center"
    >
      <div className="d-flex align-items-center" css={spacer.mrR20}>
        <div className="legend-color" css={[bgColor.scoreBandNeedImprovement, spacer.mrR10]} />
        <P gBold color={colors.black3} className="legend-label">
          {candidateFirstName}&apos;s Score
        </P>
      </div>

      <div className="d-flex align-items-center">
        <div className="legend-color" css={[bgColor.blank, spacer.mrR10]} />
        <P gBold color={colors.black3} className="legend-label">
          Cohort&apos;s Avg. Score
        </P>
      </div>
    </div>
  </div>
);

TopicPerformanceChart.propTypes = {
  topicPerformanceData: PropTypes.array.isRequired,
  candidateFirstName: PropTypes.string.isRequired
};

export const TopicSummaryModules = ({
  summaryModules,
  isCohortAnalytics,
  allCompletedModules,
  allCompletedAttempts,
  candidateFirstName,
  reportCTAHandler
}) => (
  <div css={spacer.mrB60}>
    <H4 css={spacer.padBT20}>Summary of Results by Subject</H4>

    {summaryModules?.length > 0 &&
      summaryModules.map(
        ({
          title,
          totalScore,
          showScoreWarning,
          scoredMarks,
          scorePercentage,
          cohortScore,
          moduleId,
          parentSectionId,
          timeTaken,
          containsSubSections,
          timeLimit
        }) => (
          <div css={styles.resultsSummaryBand} key={moduleId}>
            <div css={[spacer.padT20, spacer.padLR30]}>
              <Row className="align-items-center">
                <Col className="summary-band-wrapper" css={spacer.padB20}>
                  <P gBold instruction css={spacer.mrB10}>
                    {title}
                  </P>

                  {isCohortAnalytics && (
                    <P instruction gBook>
                      Rank:{' '}
                      {getRank({
                        currentModule: moduleId,
                        allCompletedModules,
                        candidateScore: scoredMarks
                      })}
                      /{allCompletedAttempts?.length}
                    </P>
                  )}
                </Col>

                <Col className="summary-band-wrapper" css={[spacer.padB20, styles.warningTooltip]}>
                  <P instruction css={spacer.mrB10}>
                    Total score
                  </P>

                  {showScoreWarning ? (
                    <div>
                      <TooltipTrigger
                        closeOnClick={false}
                        tooltipInner={<ToolTipInner />}
                        placement="right"
                        trigger="hover"
                      >
                        <div className="d-inline-flex justify-content-start align-items-center">
                          <P instruction gBold css={spacer.mrR5}>
                            {totalScore}
                          </P>
                          <Icon icon="warning" size={20} color={colors.red} />
                        </div>
                      </TooltipTrigger>
                    </div>
                  ) : (
                    <P instruction gBold>
                      {totalScore}
                    </P>
                  )}
                </Col>

                <Col className="summary-band-wrapper" css={spacer.padB20}>
                  <P instruction css={spacer.mrB10}>
                    {candidateFirstName}&apos;s % score
                  </P>
                  <P instruction gBold>
                    {getScoreMetric(scorePercentage)}%
                  </P>
                </Col>

                <Col className="summary-band-wrapper" css={spacer.padB20}>
                  <P instruction css={spacer.mrB10}>
                    Time taken
                  </P>
                  <P instruction gBold>
                    {secondsToHms(timeTaken > timeLimit ? timeLimit : timeTaken || 0).substring(
                      timeLimit >= 3600 ? 0 : 3
                    )}
                    {' / '}
                    {secondsToHms(timeLimit).substring(timeLimit >= 3600 ? 0 : 3)}
                  </P>
                </Col>

                <Col className="summary-band-wrapper" css={spacer.mrB10}>
                  <P instruction css={spacer.mrB10}>
                    Cohort avg. % score
                  </P>
                  <P instruction gBold>
                    {getScoreMetric(cohortScore)}%
                  </P>
                </Col>
              </Row>
            </div>

            <div css={[spacer.padLR30, spacer.padBT10]} className="summary-band-cta">
              <Button
                product
                rounded
                css={spacer.mrR30}
                onClick={() => reportCTAHandler(moduleId, parentSectionId)}
              >
                View Subject Report
              </Button>
              {!containsSubSections && (
                <A gBold medium to={moduleId} underline as={UnstyledLink}>
                  View Solutions
                </A>
              )}
            </div>
          </div>
        )
      )}
  </div>
);

TopicSummaryModules.propTypes = {
  summaryModules: PropTypes.array.isRequired,
  isCohortAnalytics: PropTypes.bool.isRequired,
  allCompletedModules: PropTypes.array.isRequired,
  allCompletedAttempts: PropTypes.array.isRequired,
  candidateFirstName: PropTypes.string.isRequired,
  reportCTAHandler: PropTypes.func.isRequired
};
