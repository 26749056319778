/* eslint-disable no-unused-vars */
import { Tab as UnstyledTab, Tabs as UnstyledTabs, TabPanel, TabList } from 'react-tabs';
import { StyledTabs, StyledTab } from './Tabs.styles';

// Fix @ref: https://github.com/reactjs/react-tabs/issues/148#issuecomment-368809171
const Tabs = StyledTabs.withComponent(UnstyledTabs);
Tabs.tabsRole = 'Tabs';

const Tab = StyledTab.withComponent(UnstyledTab);
Tab.tabsRole = 'Tab';
Tab.defaultProps = {
  gBold: true,
  selectedClassName: 'active'
};

UnstyledTab.defaultProps = {
  selectedClassName: 'active'
};

export { Tab, Tabs, TabPanel, TabList, UnstyledTab, UnstyledTabs };
