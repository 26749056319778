//* eslint-disable import/prefer-default-export */
import { keyframes, css } from '@emotion/react';
import { colors, misc, mixins } from 'styles/utilities';

const modalFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const modalGlobalStyles = css`
  .ReactModal__Body--open {
    overflow: hidden;
  }
  .ReactModal__Content--after-open {
    .modal-container {
      animation: 0.3s ease ${modalFadeIn} forwards;
    }
  }
`;

export const modalContainer = (props) => css`
  width: ${props.tour ? '480px' : '630px'};
  max-width: ${props.large ? '970px' : '630px'};
`;

export const modalWrapper = (props) => css`
  background-color: ${colors.blackTransparent60};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${misc.modalZindex};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  padding: 10px;
  > * {
    flex: 1 1 auto;
    pointer-events: all;
  }

  ${(() => {
    if (props.isAtTop) {
      return css`
        bottom: auto;
        height: 100%;
        .modal-container {
          height: 100%;
          overflow: auto;
        }
      `;
    }

    return css``;
  })()}
`;

export const contentsWrapper = css`
  background-color: ${colors.cream};
  box-shadow: ${misc.loginBoxShadow};
  border-radius: ${misc.mediumBorderRadius};
`;

export const contents = css``;

export const closeIcon = ({ largeCloseButton }) => css`
  position: absolute;
  top: 35px;
  right: 45px;
  outline: none;
  cursor: pointer;
  z-index: 1;
  box-shadow: none;
  border: 0;
  background: transparent;
  line-height: 1;

  .css-close-icon {
    position: relative;

    &:after {
      content: '';
      width: 5px;
      height: 18px;
      background: ${colors.mediumGrey};
      display: block;
      position: absolute;
      transform: rotate(45deg);
      top: 0;
      right: 0;
      ${mixins.transition()};
    }

    &:before {
      content: '';
      width: 5px;
      height: 18px;
      background: ${colors.mediumGrey};
      display: block;
      position: absolute;
      transform: rotate(-45deg);
      top: 0;
      right: 0;
      ${mixins.transition()};
    }
  }

  ${largeCloseButton &&
  css`
    right: 65px;

    .css-close-icon {
      &::before,
      &::after {
        width: 8px;
        height: 50px;
      }
    }
  `}

  ${mixins.linkActiveStyles()} {
    outline: none;
    background: transparent;
  }

  &:hover {
    .css-close-icon:before,
    .css-close-icon:after {
      background-color: ${colors.primaryLinkHover};
    }
  }
`;

export const tourClose = css`
  position: fixed;
  top: 15px;
  right: 25px;
  cursor: pointer;
`;
