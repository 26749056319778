/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors, misc } from 'styles/utilities';

export const matricesWrapper = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 40px;
`;

export const Dots = styled.div`
  width: 18px;
  height: 18px;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;

  &.selected {
    background-color: ${colors.blue};
  }

  &.selectable {
    background-color: ${colors.mediumGrey2};
  }

  &.correct {
    background-color: ${colors.green};
  }

  &.inCorrect {
    background-color: ${colors.red};
  }

  &:hover {
    opacity: 0.6;
  }

  ${({ noHover }) => noHover && `pointer-events: none`};
`;

export const matrixWrapper = css`
  max-width: 290px;
  min-width: 290px;
  border: ${misc.border.default};
  border-radius: ${misc.smallBorderRadius};
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  &.hide {
    opacity: 0;
    visibility: 0;
    pointer-events: none;
  }

  &.show {
    opacity: 1;
    visibility: 1;
  }
`;
