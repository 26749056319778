/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { spacer } from 'styles/utilities';

import * as styles from './DataTable.styles';

const PaginationButtons = ({
  canNextPage,
  canPreviousPage,
  handlePreviousPage,
  handleNextPage,
  handlePageClick,
  pageIndex,
  pageCount
}) => {
  const [paginationButtons, updatePaginationButtons] = useState([]);
  useEffect(() => {
    if (pageIndex < 4) {
      let itemsCount = [];
      if (pageCount <= 4) {
        for (let i = 1; i <= pageCount; i += 1) {
          itemsCount.push(i);
        }
      } else {
        itemsCount = [1, 2, 3, 4];
      }
      console.info(itemsCount);
      updatePaginationButtons(itemsCount);
    } else if (pageIndex >= 4 && pageIndex < pageCount - 4) {
      updatePaginationButtons([pageIndex - 1, pageIndex, pageIndex + 1]);
    } else if (pageIndex >= pageCount - 4) {
      updatePaginationButtons([pageCount - 5, pageCount - 4, pageCount - 3, pageCount - 2]);
    }
  }, [pageCount, pageIndex]);

  return (
    <div>
      <button
        type="button"
        css={styles.paginationButton}
        onClick={handlePreviousPage}
        disabled={!canPreviousPage}
      >
        Previous
      </button>

      <button
        type="button"
        css={styles.paginationButton}
        className={`${pageIndex === 0 ? 'active' : ''}`}
        onClick={(e) => handlePageClick(e, 0)}
      >
        1
      </button>

      {pageIndex >= 4 ? <span css={spacer.padLR15}>...</span> : null}

      {pageCount > 4 &&
        paginationButtons.map((val) => (
          <button
            key={val}
            type="button"
            css={styles.paginationButton}
            className={`${pageIndex === val ? 'active' : ''}`}
            onClick={(e) => handlePageClick(e, val)}
          >
            {val + 1}
          </button>
        ))}

      {/* {pageIndex < 4 ? (
        <Fragment>
          {[1, 2, 3, 4].map((val) => (
            <button
              key={val}
              type="button"
              className={`pg-btn ${pageIndex === val ? 'active' : ''}`}
              onClick={(e) => handlePageClick(e, val)}
            >
              {val + 1}
            </button>
          ))}
        </Fragment>
      ) : null}

      {pageIndex >= 4 && pageIndex < pageCount - 4 ? (
        <Fragment>
          {[pageIndex - 1, pageIndex, pageIndex + 1].map((val) => (
            <button
              key={val}
              type="button"
              className={`pg-btn ${pageIndex === val ? 'active' : ''}`}
              onClick={(e) => handlePageClick(e, val)}
            >
              {val + 1}
            </button>
          ))}
        </Fragment>
      ) : null}

      {pageIndex >= pageCount - 4 ? (
        <Fragment>
          {[pageCount - 5, pageCount - 4, pageCount - 3, pageCount - 2].map((val) => (
            <button
              key={val}
              type="button"
              className={`pg-btn ${pageIndex === val ? 'active' : ''}`}
              onClick={(e) => handlePageClick(e, val)}
            >
              {val + 1}
            </button>
          ))}
        </Fragment>
      ) : null} */}

      {pageIndex < pageCount - 4 ? <span css={spacer.padLR15}>...</span> : null}

      <button
        type="button"
        css={styles.paginationButton}
        className={`${pageIndex === pageCount - 1 ? 'active' : ''}`}
        onClick={(e) => handlePageClick(e, pageCount - 1)}
      >
        {pageCount}
      </button>

      <button
        type="button"
        css={styles.paginationButton}
        onClick={handleNextPage}
        disabled={!canNextPage}
      >
        Next
      </button>
    </div>
  );
};

PaginationButtons.propTypes = {
  canNextPage: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  handlePreviousPage: PropTypes.func.isRequired,
  handleNextPage: PropTypes.func.isRequired,
  handlePageClick: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired
};

export default PaginationButtons;
