/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

export const icon = css`
  width: 100px;
  height: 100px;
`;

export const iconContainer = css`
  padding: 40px 0 20px;
  text-align: center;
`;
