import { useState, useCallback } from 'react';
import { createContainer } from 'unstated-next';

const useExtraApiRequestHeaders = () => {
  const [extraHeaders, updateExtraHeaders] = useState({});

  const addHeaders = useCallback(
    (headers = {}) => updateExtraHeaders((prevState) => ({ ...prevState, ...headers })),
    []
  );

  const removeHeaders = useCallback(() => updateExtraHeaders({}), []);

  return {
    extraHeaders,
    addHeaders,
    removeHeaders
  };
};

const ApiRequestHeadersContainer = createContainer(useExtraApiRequestHeaders);
export default ApiRequestHeadersContainer;
