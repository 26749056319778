/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';

import P from 'components/htmlElements/P';
import SolutionExplainer from 'components/SolutionExplainer/SolutionExplainer';

import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';

import { InstructionLine } from 'components/Instructions';
import { spacer, colors } from 'styles/utilities';
import * as sharedStyles from 'components/QuestionTypes/shared-components/shared.styles';
import transformImgixUrls from 'components/QuestionTypes/shared-components/transformImgixUrls';

import QuestionsCommonWrapperContainer from 'components/QuestionTypes/shared-components/QuestionsCommonWrapper';
import Latex, { withLatex } from 'components/QuestionTypes/shared-components/Latex';
import Input from 'components/htmlElements/Fields/Input';
import * as styles from './Geogebra.styles';

const Geogebra = ({ currentQuestion }) => {
  const { instructionsText, questionText, solutionExplaination, geogebraApplet, answer } =
    currentQuestion.geogebra;
  const { ttAnswers } = currentQuestion;

  const {
    // attemptFinished,
    skippingAllowed,
    showNextButton,
    answersEditableBeforeSubmission,
    updateAnswerPayload,
    isQuestionUsageFinal
  } = TestModuleInfoContainer.useContainer();

  const { attemptFinished, isNoteOrSampleQuestion, isPracticeQuestion } =
    QuestionsCommonWrapperContainer.useContainer();

  // Save checked answer options
  const [answerInput, setAnswerInput] = useState([]);
  useEffect(
    // This effect simply clears the checkedAnswers on question change. It ensures on question switch the previous state is cleared
    () => () => {
      setAnswerInput([]);
    },
    [currentQuestion._id]
  );

  const handleAnswerChange = (e) => {
    const answerFieldValue = e.target.value;

    setAnswerInput([answerFieldValue]);

    // answer input validation
    if (answerFieldValue.length > 0 && answerFieldValue.trim() !== '') {
      showNextButton(true);
    } else {
      showNextButton(false);
    }
  };

  // Update `checkedAnswers` if already answered and/or attempt is completed
  useEffect(() => {
    if (ttAnswers && ttAnswers.length) {
      setAnswerInput(ttAnswers);
    }

    // Clear the checked answers array on unmount
    // Without this there's a bug which messes up the next question if it's of the same type
    return () => {
      setAnswerInput([]);
    };
  }, [ttAnswers]);

  // Handle disabling of answering
  const [answeringDisabled, handleAnsweringDisabled] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  useEffect(() => {
    // If editable and not answered - can answer
    // If not editable and not answered - can answer
    // If not editable and answered - cannot answer
    if (!attemptFinished) {
      if (!ttAnswers) {
        // This means it's not answered
        handleAnsweringDisabled(false);
      } else if (!answersEditableBeforeSubmission) {
        handleAnsweringDisabled(true);
      }
    } else {
      // If attempt is over answering is disabled
      handleAnsweringDisabled(true);
    }

    return () => {
      // Reset state
      handleAnsweringDisabled(!answersEditableBeforeSubmission);
    };
  }, [answersEditableBeforeSubmission, attemptFinished, ttAnswers]);

  // Toggle next button visibility
  useEffect(() => {
    if (!skippingAllowed && !isNoteOrSampleQuestion) {
      showNextButton(false);
    } else {
      showNextButton(true);
    }
  }, [isNoteOrSampleQuestion, showNextButton, skippingAllowed]);

  // Handle updating answer payload
  useEffect(() => {
    updateAnswerPayload({
      questionId: currentQuestion._id,
      answerArray: answerInput
    });
  }, [answerInput, currentQuestion._id, updateAnswerPayload]);

  useEffect(() => {
    const ggbApp = new window.GGBApplet(
      {
        showMenuBar: false,
        showToolBar: false,
        showAlgebraInput: false,
        showFullscreenButton: true,
        material_id: geogebraApplet || '',
        scaleContainerClass: 'geogebraContainer',
        borderColor: colors.blackTransparent50
      },
      true
    );
    ggbApp.inject('ggb-element');
  }, [geogebraApplet, currentQuestion._id]);

  useEffect(() => {
    if (attemptFinished && ttAnswers) {
      if (ttAnswers[0] === answer[0]) {
        setIsCorrect(true);
      }
    }

    if (isNoteOrSampleQuestion) {
      setAnswerInput(answer);
    }

    if (attemptFinished && isPracticeQuestion && answer) {
      if (answerInput[0] === answer[0]) {
        setIsCorrect(true);
      }
    }

    return () => setIsCorrect(false);
  }, [
    answer,
    attemptFinished,
    isNoteOrSampleQuestion,
    isPracticeQuestion,
    ttAnswers,
    currentQuestion._id,
    answerInput
  ]);

  const contentWrapperRef = useRef(null);
  const instructionTextRef = useRef(null);
  const appletWrapperRef = useRef(null);

  const appletHeightHandler = useCallback(() => {
    if (window.innerWidth > 992 && !attemptFinished) {
      appletWrapperRef.current.style.height = `${
        contentWrapperRef.current.clientHeight - instructionTextRef.current.clientHeight - 80
      }px`;
    } else {
      appletWrapperRef.current.style.height = 'auto';
    }
  }, [attemptFinished]);

  useEffect(() => {
    appletHeightHandler();
    window.addEventListener('resize', appletHeightHandler);

    return () => window.removeEventListener('resize', appletHeightHandler);
  }, [appletHeightHandler]);

  return (
    <div
      css={[spacer.padLR30, styles.appletContainer]}
      className={`${!attemptFinished ? 'h-100' : ''}`}
    >
      <div className="d-flex wrapper h-100" css={[spacer.padBT30]} ref={contentWrapperRef}>
        <div css={[styles.questionContainer, spacer.mrR30, spacer.mrB10]}>
          <div css={spacer.mrB10} ref={instructionTextRef}>
            {instructionsText && <InstructionLine text={instructionsText} />}
          </div>

          <div css={[styles.appletWrapper]} className="geogebraContainer" ref={appletWrapperRef}>
            <div id="ggb-element" key={geogebraApplet || 'applet'} />
          </div>
        </div>

        <div css={[sharedStyles.verticalQuestionLayout, styles.answerInputWrapper]}>
          <Latex
            css={[spacer.mrB10, spacer.padT10, sharedStyles.types, sharedStyles.richTextQuestion]}
            className="ck-content geogebraQuestion-text"
          >
            {ReactHtmlParser(DOMPurify.sanitize(withLatex(questionText)), {
              transform: (node) =>
                transformImgixUrls(node, { width: sharedStyles.imagesSizeInQuestionText })
            })}
          </Latex>

          <Input
            placeholder="Freetext answer..."
            name="answer"
            className="answerInput mx-auto"
            disabled={answeringDisabled}
            onChange={(e) => handleAnswerChange(e)}
            autoComplete="off"
            value={answerInput[0] ?? ''}
          />

          {attemptFinished && !isNoteOrSampleQuestion && ttAnswers && (
            <P
              css={spacer.mrT15}
              color={isCorrect ? colors.green : colors.red}
              large
              className="text-center"
            >
              {isCorrect ? 'Your answer is correct' : 'Your answer is incorrect'}
            </P>
          )}

          {isNoteOrSampleQuestion && (
            <P css={spacer.mrT15} color={colors.green} large className="text-center">
              Correct answer
            </P>
          )}

          {attemptFinished && !ttAnswers?.length && isQuestionUsageFinal && (
            <P css={spacer.mrT15} color={colors.red} large className="text-center">
              Question not attempted.
            </P>
          )}
        </div>
      </div>

      {attemptFinished && <SolutionExplainer text={solutionExplaination} />}
    </div>
  );
};

Geogebra.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

const WithQuestionProvider = (props) => {
  const { questionSettings } = props.currentQuestion.geogebra;

  return (
    <QuestionsCommonWrapperContainer.Provider
      initialState={{ currentQuestion: props.currentQuestion, questionSettings }}
    >
      <Geogebra {...props} />
    </QuestionsCommonWrapperContainer.Provider>
  );
};
WithQuestionProvider.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

export default WithQuestionProvider;
