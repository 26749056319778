/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types';

import P from 'components/htmlElements/P';

import { bgColor, spacer, colors, txtColor } from 'styles/utilities';
import * as styles from './FooterNote.styles';

const FooterNote = ({ note, author }) => (
  <div css={[styles.footerDiv, bgColor.black, spacer.padBT20, spacer.padLR20]}>
    <P color={colors.navMenuItem} noMargin>
      &quot;{note}&quot;
      <span css={txtColor.mediumGrey2}> {author} </span>
    </P>
  </div>
);

FooterNote.propTypes = {
  note: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired
};

export default FooterNote;
