import React, { useContext, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useModal } from 'components/Modal';

const UserAccountContext = createContext();

export const useUserAccountContext = () => useContext(UserAccountContext);

const UserAccountContextsProvider = ({ children }) => {
  const {
    isOpen: isEditAccountOpen,
    openModal: openEditAccountModal,
    closeModal: closeEditAccountModal,
    toggle: toggleEditAccountModal
  } = useModal();

  const {
    isOpen: isChangePasswordOpen,
    openModal: openChangePasswordModal,
    closeModal: closeChangePasswordModal,
    toggle: toggleChangePasswordModal
  } = useModal();

  const {
    isOpen: isAddCandidateOpen,
    openModal: openAddCandidateModal,
    closeModal: closeAddCandidateModal,
    toggle: toggleAddCandidateModal
  } = useModal();

  const {
    isOpen: isEditCandidateOpen,
    openModal: openEditCandidateModal,
    closeModal: closeEditCandidateModal,
    toggle: toggleEditCandidateModal
  } = useModal();

  const value = useMemo(
    () => ({
      isEditAccountOpen,
      openEditAccountModal,
      closeEditAccountModal,
      toggleEditAccountModal,
      isChangePasswordOpen,
      openChangePasswordModal,
      closeChangePasswordModal,
      toggleChangePasswordModal,
      isAddCandidateOpen,
      openAddCandidateModal,
      closeAddCandidateModal,
      toggleAddCandidateModal,
      isEditCandidateOpen,
      openEditCandidateModal,
      closeEditCandidateModal,
      toggleEditCandidateModal
    }),
    [
      isEditAccountOpen,
      openEditAccountModal,
      closeEditAccountModal,
      toggleEditAccountModal,
      isChangePasswordOpen,
      openChangePasswordModal,
      closeChangePasswordModal,
      toggleChangePasswordModal,
      isAddCandidateOpen,
      openAddCandidateModal,
      closeAddCandidateModal,
      toggleAddCandidateModal,
      isEditCandidateOpen,
      openEditCandidateModal,
      closeEditCandidateModal,
      toggleEditCandidateModal
    ]
  );

  return <UserAccountContext.Provider value={value}>{children}</UserAccountContext.Provider>;
};

UserAccountContextsProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default UserAccountContextsProvider;
