/** @jsxImportSource @emotion/react */

import { Fragment, useState, useMemo } from 'react';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { FieldError } from 'components/htmlElements/Fields';
import Button from 'components/htmlElements/Button';
import LoaderOverlay from 'components/LoaderOverlay';
import { colors, spacer } from 'styles/utilities';
import H3 from 'components/htmlElements/H3';
import H5 from 'components/htmlElements/H5';
import Checkbox from 'components/CheckBox/CheckBox';
import H4 from 'components/htmlElements/H4';
import P from 'components/htmlElements/P';
import { useQueryClient } from 'react-query';
import * as styles from './CohortModal.styles';
import CohortProductSelectionDropdown from './Dropdown';

const cohortValidationSchema = Yup.object().shape({
  cohortCandidates: Yup.array().required('Required')
});

const CohortModal = ({
  test,
  isOpen,
  toggleModal,
  closeModal,
  handleCohortRequest,
  header,
  products,
  candidates,
  storedCandidates,
  isUpdateCohortModal
}) => {
  const queryClient = useQueryClient();
  const [selectedProduct, setSelectedProduct] = useState(test ?? {});

  const filteredCandidates = useMemo(() => {
    if (candidates.length > 0 && selectedProduct?.product_id) {
      return candidates.filter((candidate) =>
        candidate?.candidateAssignedTests?.some(
          (t) => t?.wpProductId === selectedProduct?.product_id
        )
      );
    }

    return [];
  }, [candidates, selectedProduct?.product_id]);

  const handleFormSubmission = async (values, actions) => {
    actions.setSubmitting(true);

    const { cohortCandidates } = values;

    if (!cohortCandidates.length) {
      closeModal();
      return;
    }

    await handleCohortRequest({
      wpProductId: selectedProduct?.product_id,
      candidateIds: values.cohortCandidates
    });

    await actions.resetForm();
    await actions.setSubmitting(false);
    closeModal();
    await queryClient.invalidateQueries('allAnalyticsCohort');
  };

  const checkboxHandler = (push, remove, id, values) => {
    const index = values.findIndex((item) => item === id);

    if (index < 0) {
      push(id);
    } else {
      remove(index);
    }
  };

  return (
    <div className="is-relative">
      <Modal isOpen={isOpen} toggle={toggleModal}>
        <Formik
          initialValues={{
            cohortCandidates: storedCandidates || []
          }}
          validationSchema={cohortValidationSchema}
          onSubmit={handleFormSubmission}
        >
          {({ isSubmitting, values }) => (
            <Fragment>
              <div css={[spacer.padBT30, spacer.padLR30]}>
                <H3 gBook css={spacer.mrBT0}>
                  {header}
                </H3>
              </div>

              <div css={[spacer.padB30, spacer.padLR30]}>
                <div css={spacer.mrB20}>
                  <CohortProductSelectionDropdown
                    items={products}
                    handleSelect={setSelectedProduct}
                    selectedItem={test}
                    isUpdateCohortModal={isUpdateCohortModal}
                  />
                </div>

                {filteredCandidates.length > 0 && (
                  <Form>
                    <div css={spacer.mrB30}>
                      <H4
                        gBook
                        as="label"
                        htmlFor="cohortCandidates"
                        css={[spacer.mrB20, spacer.mrT0]}
                      >
                        Select candidate account(s) for cohort
                      </H4>

                      <div css={styles.CheckboxContainer}>
                        <FieldArray name="cohortCandidates">
                          {({ push, remove }) => (
                            <Fragment>
                              {filteredCandidates.map((item, i) => (
                                <div css={styles.CheckBoxWrapper} key={item.id}>
                                  <Field
                                    name={`cohortCandidatesoption-${i}`}
                                    id={item.id}
                                    label={
                                      <H5
                                        as={P}
                                        gBook
                                        color={colors.mediumGrey}
                                        className="label-text"
                                        css={[spacer.mrL15, spacer.mrBT0]}
                                      >{`${item.firstName ?? ''} ${item.lastName ?? ''}`}</H5>
                                    }
                                    value={item.id}
                                    checked={values.cohortCandidates.includes(item.id)}
                                    onChange={() =>
                                      checkboxHandler(
                                        push,
                                        remove,
                                        item.id,
                                        values.cohortCandidates
                                      )
                                    }
                                    as={Checkbox}
                                  />
                                </div>
                              ))}
                            </Fragment>
                          )}
                        </FieldArray>

                        <ErrorMessage name="cohortCandidates" component={FieldError} />
                      </div>
                    </div>

                    <Button wide rounded textCenter type="submit">
                      {isUpdateCohortModal ? 'Update Cohort' : 'Create Cohort'}
                    </Button>
                  </Form>
                )}

                {isSubmitting && <LoaderOverlay />}
              </div>
            </Fragment>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

CohortModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleCohortRequest: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  test: PropTypes.object,
  products: PropTypes.array,
  candidates: PropTypes.array.isRequired,
  storedCandidates: PropTypes.array,
  isUpdateCohortModal: PropTypes.bool
};

CohortModal.defaultProps = {
  test: null,
  products: [],
  isUpdateCohortModal: false,
  storedCandidates: []
};

export default CohortModal;
