/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import isTouchScreen from 'utils/isTouchDevice';
import P from 'components/htmlElements/P';
import { products } from 'globals/constants';

import * as styles from './TouchscreenNotice.styles';

const TouchscreenNotice = ({ testCategory }) => {
  const isCEMTtest = useMemo(() => testCategory === products.CEM.name, [testCategory]);
  const isINCASTtest = useMemo(() => testCategory === products.INCAS.name, [testCategory]);

  if ((isTouchScreen() && isCEMTtest) || (isTouchScreen() && isINCASTtest)) {
    return (
      <div css={styles.wrapper}>
        <P className="text-center">
          Please use a laptop or desktop computer to take this test. Do not use a touchscreen or
          tablet.
        </P>
      </div>
    );
  }

  return '';
};

TouchscreenNotice.propTypes = {
  testCategory: PropTypes.string.isRequired
};

export default TouchscreenNotice;
