/** @jsxImportSource @emotion/react */
import React from 'react';
import H3 from 'components/htmlElements/H3';
import P from 'components/htmlElements/P';
import { spacer } from 'styles/utilities';
// import PropTypes from 'prop-types';

import * as styles from './NetDisconnectedOverlay.styles';

const InternetDisconnectedSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="258px" height="195px" viewBox="0 0 258 195">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 2)">
        <path
          d="M7.5 176c-3.9 0-7-3.4-7-7.7V7.7C.5 3.5 3.6 0 7.5 0h223c3.9 0 7 3.4 7 7.7v160.7c0 4.2-3.1 7.7-7 7.7l-223-.1h0z"
          stroke="#205AA9"
          strokeWidth="3"
          fill="#191919"
        />
        <path stroke="#205AA9" strokeWidth="3" d="M.7 15H237v147H.7z" />
        <circle fill="#205AA9" cx="10" cy="7.1" r="2" />
        <circle fill="#205AA9" cx="17.9" cy="7.1" r="2" />
        <circle fill="#205AA9" cx="25.8" cy="7.1" r="2" />
      </g>
      <path
        d="M172.1 151.3c0-23.2 18.8-42 42-42s42 18.8 42 42-18.8 42-42 42-42-18.8-42-42h0z"
        stroke="#DD3636"
        strokeWidth="3"
        fill="#191919"
      />
      <g transform="translate(212 131)">
        <circle fill="#DD3636" cx="2.5" cy="37.7" r="2" />
        <path
          d="M2.5.9v25.8"
          stroke="#DD3636"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M121.8 132.3h-.3c-24.5 0-44.5-20-44.5-44.5v-.1c.1-24.4 20-44.3 44.5-44.3h.4c24.3.2 44.1 20 44.1 44.3v.1c0 24.4-19.8 44.3-44.2 44.5zM80.1 89.2c.7 20.8 16.7 37.7 37.1 39.9-2.5-2.2-6-5.7-9.5-10-8.1-10-12.6-20.3-13-29.8H80.1v-.1zm67.2 0c-.7 18.3-14.8 33.7-21.3 39.9 20.3-2.2 36.3-19.1 37-39.9h-15.7zm-49.6 0c.8 17.7 16.3 33 22.4 38.4V89.2H97.7zm25.6 0v38.3c5.9-5.5 20.2-20.7 21-38.3h-21zm24-3H163c-.8-20.5-16.5-37.1-36.5-39.5 6.8 5.8 20.1 19.7 20.8 39.5zm-24 0h21c-.7-19.6-15.2-33.5-21-38.3v38.3zm-25.6 0h22.4V47.8c-6.1 4.6-21.7 18.6-22.4 38.4zm-17.6 0h14.6c.7-19.7 14.8-33.7 22-39.6C96.6 49 80.9 65.7 80.1 86.2z"
        fill="#205AA9"
      />
    </g>
  </svg>
);

const NetDisconnectedModal = () => (
  <div
    css={styles.netDisconnectedOverlay}
    className="d-flex justify-content-center align-items-center"
  >
    <div className="d-flex flex-column align-items-center overlay-inner">
      <InternetDisconnectedSvg />

      <H3 color="#dd3636" gBlack>
        You’re disconnected from the internet
      </H3>

      <P color="white" noMargin>
        Your test will resume once it’s reconnected
      </P>

      <div css={[styles.spinner, spacer.mrT20]} />

      <P color="white">Trying to reconnect</P>
    </div>
  </div>
);

NetDisconnectedModal.propTypes = {};

export default NetDisconnectedModal;
