/** @jsxImportSource @emotion/react */
import { useGetPurchasableProducts } from 'api/parents';
import { Col } from 'components/Grid';
import H2 from 'components/htmlElements/H2';
import A from 'components/htmlElements/A';
import P from 'components/htmlElements/P';
// import ProductCard from 'components/ProductCard';
import BlueBgPattern from 'components/BlueBgPattern';
import { site } from 'globals/constants';
import PurchasableProductsBlock from 'components/PurchasableProductsBlock/PurchasableProductsBlock';
import { spacer, colors } from 'styles/utilities';

const PurchasableProducts = () => {
  const { data } = useGetPurchasableProducts();

  return (
    <BlueBgPattern as={Col} css={[spacer.padBT60, spacer.mrT60]}>
      <div css={spacer.mrB40}>
        <H2 color={colors.white} css={[spacer.mrB10]}>
          Products available to purchase
        </H2>
        <P as={A} large href={`${site.url}/products`} target="_blank" color={colors.green}>
          See all Products
        </P>
      </div>

      <PurchasableProductsBlock productsData={data} />

      {/* <div className="d-flex flex-wrap">
        {data.length &&
          data.map((product) => (
            <div key={product.id} css={[spacer.mrB10, spacer.mrR10]}>
              <ProductCard
                examType={product.categories[0]?.slug}
                productType={product.product_collection[0]?.slug}
                productName={product.name}
                productPrice={`£${product.price}`}
                productPageLink={`${site.url}/product/${product?.slug}`}
              />
            </div>
          ))}
      </div> */}
    </BlueBgPattern>
  );
};

export default PurchasableProducts;
