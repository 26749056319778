//* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors, mixins, misc, typography } from 'styles/utilities';

export const dropdownContainer = css`
  display: flex;
  outline: none;

  .dropdown-button {
    padding-top: 17px;
    padding-bottom: 17px;
  }
`;

export const inner = css`
  outline: none;
  position: relative;
  width: 100%;
  display: block;
  &:focus {
    outline: none;
  }
`;

export const dropdownItemlist = css`
  outline: none;
  position: absolute;
  max-height: 300px;
  width: 100%;
  max-width: 100%;
  border: 1px solid ${colors.grey2};
  box-shadow: ${misc.dropDownShadow};
  top: 90%;
  left: 0;
  margin: 0;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: ${misc.dropdownZindex};
  overflow: auto;
  border-radius: ${misc.smallBorderRadius};
  min-width: 200px;
  border-radius: ${misc.mediumBorderRadius};
  ${mixins.transition()};

  &.show {
    top: 105%;
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }

  .item-link {
    &:hover {
      background-color: ${mixins.toRgba(colors.green, 0.1)};

      .item {
        color: ${colors.primaryLinkHover};
      }
    }
  }

  .item {
    outline: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
    ${mixins.transition()};
    color: ${colors.black};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const container = css`
  cursor: pointer;
  color: ${colors.white};
  padding: 17px;
  font-size: ${typography.baseSizes.epsilon};
  font-family: ${typography.fonts.gothamBook.fontFamily};
  display: flex;
  align-items: center;
  border-radius: ${misc.mediumBorderRadius} 0px 0px ${misc.mediumBorderRadius};

  &.trigger::after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-top-color: white;
    right: 9px;
    top: 50%;
    margin-top: -3.5px;
  }
`;

export const buttonWrapper = css`
  flex: 1 0 120px;
  overflow: hidden;
  .dropdown-button {
    border-radius: 0px ${misc.mediumBorderRadius} ${misc.mediumBorderRadius} 0px;
  }
`;
