// @ref: https://stackoverflow.com/a/1885569/2623613
export default function arrayIndexesIntersection(array1, array2) {
  const tempArray = [...array2]; // Spread it to create a new array and not a reference;
  const filteredArray = array1.map((n) => {
    const indexIs = tempArray.indexOf(n);
    if (indexIs !== -1) {
      tempArray[indexIs] = null;
    }
    return indexIs;
  });
  return filteredArray;
}
