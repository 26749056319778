/** @jsxImportSource @emotion/react */

import { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';

import A from 'components/htmlElements/A';
import H6 from 'components/htmlElements/H6';
import ProgressSvg from 'components/ProgressSvg/';

import { useComponentVisible } from 'components/Navbar';

import { spacer, bgColor, colors } from 'styles/utilities';
import { ProductCardTop, getProductColor } from '../helpers';

import * as styles from '../ProductCard.styles';
import * as dropDownStyles from '../../AssignProductDropdown/AssignProductDropdown.styles';

const ProductCardAssignTest = (props) => {
  const { ref, isComponentVisible, toggleVisibility } = useComponentVisible(false);

  const { examType, productType, productName, progress, items, handleClick } = props;

  const handleAssignTest = useCallback(
    (e, item) => {
      handleClick(item);

      if (isComponentVisible) {
        toggleVisibility();
      }

      e.preventDefault();
    },
    [handleClick, isComponentVisible, toggleVisibility]
  );

  return (
    <Fragment>
      <div css={styles.cardOuter(getProductColor(examType))} className="card-outer">
        <ProductCardTop productName={productName} productType={productType} examType={examType} />
        {progress && (
          <div
            css={[styles.progress, styles.borderTop, spacer.padLR15, spacer.padBT10, bgColor.grey2]}
          >
            <H6 gBold bold color={colors.mediumGrey2} noMargin style={{ fontSize: '18px' }}>
              {progress} MODULES ANSWERED
            </H6>
            <ProgressSvg size={34} progress={progress} />
          </div>
        )}
      </div>

      <div
        ref={ref}
        href="#"
        css={dropDownStyles.inner}
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        onClick={toggleVisibility}
      >
        <div css={[dropDownStyles.container, bgColor.black3]} className="trigger">
          Select Child account
        </div>
      </div>
      <ul
        className={`options ${isComponentVisible ? 'show' : ''}`}
        css={[bgColor.white, spacer.padL0, dropDownStyles.dropdownItemlist]}
      >
        {items.map((item) => (
          <A as="span" onClick={(e) => handleAssignTest(e, item)} href="#" key={item.id}>
            <li className="item" css={[spacer.padLR10, spacer.padBT10]}>
              {item.firstName} {item.lastName}
            </li>
          </A>
        ))}
        {items.length === 0 && <p color={colors.grey}>There are no assigned accounts.</p>}
      </ul>
    </Fragment>
  );
};

ProductCardAssignTest.propTypes = {
  examType: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  progress: PropTypes.string,
  items: PropTypes.array,
  handleClick: PropTypes.func
};

ProductCardAssignTest.defaultProps = {
  handleClick: null,
  items: [],
  progress: ''
};

export default ProductCardAssignTest;
