/* eslint-disable import/prefer-default-export */
import GifViewCourse from 'images/course-tour/view-course.gif';
import GifAddBookmark from 'images/course-tour/adding-bookmarks.gif';
import GifReferBookmark from 'images/course-tour/referring-bookmarks.gif';
import GifCourseHelp from 'images/course-tour/course-help.gif';

export const courseStaticSlides = [
  {
    firstSlide: true,
    title: (name) => `${name}, here's a 1 minute tour on how Courses work`,
    subtitle:
      'Courses can be accessed and viewed at any time. The videos can be rewatched as many times as you wish.',
    gifURL: ''
  },
  {
    firstSlide: false,
    title: 'Course Content',
    subtitle:
      'Scroll down to see all the videos in the course. Click on any video to start that part of the course.',
    gifURL: GifViewCourse
  },
  {
    firstSlide: false,
    title: 'Add a bookmark',
    subtitle:
      'While watching a video, add a bookmark by typing in the sidebar and then pressing enter.',
    gifURL: GifAddBookmark
  },
  {
    firstSlide: false,
    title: 'Refer to previous bookmarks',
    subtitle:
      "The sidebar shows all your bookmarks for the video currently being watched. Click on a bookmark to jump to that part of the video. To see all your bookmarks, scroll down to the 'All bookmarks' tab.",
    gifURL: GifReferBookmark
  },
  {
    firstSlide: false,
    title: 'Take the tour, anytime',
    subtitle: "Click the COURSE HELP button if you'd like to take this tour again in the future.",
    gifURL: GifCourseHelp
  }
];
