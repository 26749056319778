/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState, Fragment } from 'react';
import { Input, FieldError, Wrapper } from 'components/htmlElements/Fields';

import ModalHeader from 'components/ModalHeader';
import Modal from 'components/Modal/Modal';

import Button from 'components/htmlElements/Button';
import { spacer } from 'styles/utilities';
import { useAddGroup } from 'api/schools';
import SuccessModal from 'components/SuccessModal/SuccessModal';
import LoaderOverlay from 'components/LoaderOverlay';

const addGroupValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required')
});

const AddSchoolGroup = ({ isOpen, toggleModal }) => {
  const { addGroup, data, isError } = useAddGroup();
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (data && (data.status === 200 || data.status === 201)) {
      setSuccess(true);
      setMessage(`The group ${data.response.name} has been created successfully`);
    }
    return () => {
      setSuccess(false);
      setMessage('');
    };
  }, [data]);

  const handleFormSubmission = async (values, actions) => {
    actions.setSubmitting(true);

    await addGroup(values);

    actions.setSubmitting(false);
  };

  const closeModalHandler = () => {
    toggleModal();
    setSuccess(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      {!success ? (
        <Fragment>
          <Formik
            initialValues={{
              name: ''
            }}
            validationSchema={addGroupValidationSchema}
            onSubmit={handleFormSubmission}
          >
            {({ isSubmitting }) => (
              <div css={[spacer.padBT30, spacer.padLR30]}>
                <ModalHeader header="Add Group" />
                <Form>
                  <div>
                    <Wrapper>
                      <Field
                        type="text"
                        name="name"
                        id="name"
                        as={Input}
                        placeholder="Enter A Group Name"
                      />
                      <ErrorMessage name="name" component={FieldError} />
                    </Wrapper>

                    {data && data.error ? (
                      <Wrapper>
                        <FieldError>{data.error}</FieldError>
                      </Wrapper>
                    ) : data?.response?.message ? (
                      <Wrapper>
                        <FieldError isSuccess>{data.response.message}</FieldError>
                      </Wrapper>
                    ) : (
                      ''
                    )}

                    {isError && (
                      <Wrapper>
                        <FieldError>There was a problem adding group, please try again</FieldError>
                      </Wrapper>
                    )}
                  </div>

                  <Button wide rounded textCenter type="submit">
                    Add Group
                  </Button>

                  {isSubmitting && <LoaderOverlay />}
                </Form>
              </div>
            )}
          </Formik>
        </Fragment>
      ) : (
        <SuccessModal message={message} onClick={closeModalHandler} />
      )}
    </Modal>
  );
};

AddSchoolGroup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default AddSchoolGroup;
