/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors } from 'styles/utilities';

export const questionWrapper = css`
  > * {
    margin: 5px 0;
  }
  .no-wrap-content {
    display: inline-flex;
    align-items: center;
  }
`;

export const input = css`
  width: 120px;
  border: 1px solid ${colors.mediumGrey2};
  outline: none;
  margin: 3px 0px;
  &:focus {
    border-color: ${colors.primaryLinkActive};
  }
`;

export const answer = ({ isCorrect }) => css`
  color: ${isCorrect ? colors.green : colors.red};
`;
