/** @jsxImportSource @emotion/react */
import { useState, useEffect, useMemo } from 'react';
import { createContainer } from 'unstated-next';

const useTestModuleInfo = ({ testInfo, moduleId, isTestAtSection, moduleQuestions }) => {
  const [testModule, updateTestModule] = useState();
  useEffect(() => {
    if (isTestAtSection) {
      const moduleInfo = testInfo.section.find((sectionInfo) => sectionInfo._id === moduleId);
      updateTestModule(moduleInfo);
    } else {
      testInfo.section.forEach((sectionInfo) => {
        const moduleInfo = sectionInfo.subSection.find(
          (subSectionInfo) => subSectionInfo._id === moduleId
        );

        if (moduleInfo && Object.keys(moduleInfo)) {
          updateTestModule(moduleInfo);
        }
      });
    }
  }, [isTestAtSection, moduleId, testInfo.section]);

  const [testRules, updateTestRules] = useState(testModule?.settings);
  useEffect(() => {
    updateTestRules(testModule?.settings);
  }, [testModule?.settings]);

  const [questions, updateQuestions] = useState(moduleQuestions);
  useEffect(() => {
    updateQuestions(moduleQuestions);
  }, [moduleQuestions]);

  // Handling individual test/section/subsection settings which would be used in TestSkeleton and other question types too
  const [backForthNavigationEnabled, enableBackForthNavigation] = useState(
    testModule?.settings.allowBackAndForthNavigation
  );
  useEffect(() => {
    enableBackForthNavigation(testModule?.settings.allowBackAndForthNavigation);
  }, [testModule?.settings.allowBackAndForthNavigation]);

  const [skippingAllowed, allowSkipping] = useState(testModule?.settings.allowSkipQuestions);
  useEffect(() => {
    allowSkipping(testModule?.settings.allowSkipQuestions);
  }, [testModule?.settings.allowSkipQuestions]);

  const [answersEditableBeforeSubmission, allowEditingAnswers] = useState(
    testModule?.settings.allowAnswerEditing
  );
  useEffect(() => {
    allowEditingAnswers(testModule?.settings.allowAnswerEditing);
  }, [testModule?.settings.allowAnswerEditing]);

  // Add boolean variables for prev/next to explicitly control the visibility of the buttons
  const [prevButtonVisible, showPrevButton] = useState(backForthNavigationEnabled);
  useEffect(() => {
    showPrevButton(backForthNavigationEnabled);
  }, [backForthNavigationEnabled]);

  const [nextButtonVisible, showNextButton] = useState(true);

  // Check if attempt is finished [Typically to show results screen]
  const [attemptFinished, setAttemptFinished] = useState(
    testModule?.timerData?.endedAt !== undefined
  );
  useEffect(() => {
    setAttemptFinished(testModule?.timerData?.endedAt !== undefined);
  }, [testModule?.timerData?.endedAt]);

  // Callback for submitting answers

  const answerPayloadInitialState = useMemo(
    () => ({
      questionId: null,
      answerArray: null
    }),
    []
  );

  const [answerPayload, updateAnswerPayload] = useState(answerPayloadInitialState);

  // timeLimit state will be updated after the /alive api call
  const [timeLimit, updateTimeLimit] = useState(
    testModule?.timerData?.timeLimit - testModule?.timerData?.secondsConsumed || 0
  );
  useEffect(() => {
    updateTimeLimit(testModule?.timerData?.timeLimit - testModule?.timerData?.secondsConsumed || 0);
  }, [testModule?.timerData?.secondsConsumed, testModule?.timerData?.timeLimit]);

  const [timerPaused, toggleTimerPaused] = useState(false);

  // Setup a isPracticeQuestion state to manage faux clicks on Next button
  const [isPracticeQuestion, toggleIsPracticeQuestion] = useState(false);
  const [practiceQuestionAnswered, togglePracticeQuestionAnswered] = useState(false);
  const [practiceQuestionCallback, setPracticeQuestionCallback] = useState({
    callbackFn: () => {}
  });

  // Setup a variable to check if question is final question or not
  const [isQuestionUsageFinal, toggleIsQuestionUsageFinal] = useState(false);

  // Special case: Handling a separate timer if question is "cloze" question
  const [clozeQuestionType, updateClozeQuestionType] = useState({
    isCloze: false,
    timeLimit: 0,
    id: ''
  });

  // Variable to get the test layout type
  const [layoutType, updateLayoutType] = useState(testModule?.metadata?.layoutType || 'default');
  useEffect(() => {
    updateLayoutType(testModule?.metadata?.layoutType || 'default');
  }, [testModule?.metadata?.layoutType]);

  // Variable to get the test layout type
  const [helpText, updateHelpText] = useState(testModule?.metadata?.helpText || '');
  useEffect(() => {
    updateHelpText(testModule?.metadata?.helpText || '');
  }, [testModule?.metadata?.helpText]);

  // Helper state to submit answer of last question the user is on
  const [submitLastQuestionPayload, setSubmitLastQuestionPayload] = useState(false);

  const memodValues = useMemo(
    () => ({
      testModule,
      testRules,
      questions,
      backForthNavigationEnabled,
      enableBackForthNavigation,
      skippingAllowed,
      answersEditableBeforeSubmission,
      prevButtonVisible,
      showPrevButton,
      nextButtonVisible,
      showNextButton,
      attemptFinished,
      answerPayload,
      updateAnswerPayload,
      answerPayloadInitialState,
      timerPaused,
      toggleTimerPaused,
      timeLimit,
      updateTimeLimit,
      isPracticeQuestion,
      toggleIsPracticeQuestion,
      practiceQuestionAnswered,
      togglePracticeQuestionAnswered,
      practiceQuestionCallback,
      setPracticeQuestionCallback,
      isQuestionUsageFinal,
      toggleIsQuestionUsageFinal,
      clozeQuestionType,
      updateClozeQuestionType,
      layoutType,
      helpText,
      submitLastQuestionPayload,
      setSubmitLastQuestionPayload
    }),
    [
      testModule,
      testRules,
      questions,
      backForthNavigationEnabled,
      skippingAllowed,
      answersEditableBeforeSubmission,
      prevButtonVisible,
      showPrevButton,
      nextButtonVisible,
      showNextButton,
      attemptFinished,
      answerPayload,
      updateAnswerPayload,
      answerPayloadInitialState,
      timerPaused,
      toggleTimerPaused,
      timeLimit,
      updateTimeLimit,
      isPracticeQuestion,
      toggleIsPracticeQuestion,
      practiceQuestionAnswered,
      togglePracticeQuestionAnswered,
      practiceQuestionCallback,
      setPracticeQuestionCallback,
      isQuestionUsageFinal,
      toggleIsQuestionUsageFinal,
      clozeQuestionType,
      updateClozeQuestionType,
      layoutType,
      helpText,
      submitLastQuestionPayload,
      setSubmitLastQuestionPayload
    ]
  );

  return memodValues;
};

const TestModuleInfoContainer = createContainer(useTestModuleInfo);
export default TestModuleInfoContainer;
