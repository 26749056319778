/** @jsxImportSource @emotion/react */
import { useRef } from 'react';
import PropTypes from 'prop-types';

export const withLatex = (domString) => domString;
// const tempDiv = document.createElement('div');
// tempDiv.innerHTML = domString;
// if (window.com?.wiris) {
//   window.com.wiris.js.JsPluginViewer.parseElement(tempDiv, false, () => {});
// }
// return tempDiv;

const Latex = (props) => {
  const node = useRef(null);

  // const [latexToRender, updateLatexToRender] = useState(
  //   ReactDOMServer.renderToStaticMarkup(props.children)
  // );

  // useEffect(() => {
  //   if (window.com?.wiris && node) {
  //     const tempDiv = document.createElement('div');
  //     tempDiv.innerHTML = ReactDOMServer.renderToStaticMarkup(props.children);
  //     window.com.wiris.js.JsPluginViewer.parseElement(tempDiv, false, () => {});
  //     updateLatexToRender(tempDiv);
  //   }

  //   //   // const renderMath = () => {
  //   //   //   if (window.MathJax) {
  //   //   //     window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, node.current]);
  //   //   //   }
  //   //   // };
  //   //   // renderMath();
  //   return () => {
  //     updateLatexToRender();
  //   };
  // }, [props.children]);

  return (
    <div ref={node} {...props}>
      {props.children}
      {/* {ReactHtmlParser(DOMPurify.sanitize(latexToRender))} */}
    </div>
  );
};

Latex.propTypes = {
  children: PropTypes.node.isRequired
};

export default Latex;
