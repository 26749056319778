/* eslint-disable consistent-return */
import { convertNodeToElement } from 'react-html-parser';
import { renderToString } from 'react-dom/server';
import appendQueryStringToUrl from 'utils/appendQueryStringToUrl';
import GracefulImage from 'components/GracefulImage';

export const replaceS3withImgix = (url) =>
  url.replace(process.env.S3_DOMAIN, process.env.IMGIX_DOMAIN);

export const setSrcSet = (url, { width = 'auto' } = {}) => `
  ${appendQueryStringToUrl(replaceS3withImgix(url), `dpr=1&auto=format&fit=clip&w=${width}`)} 1x,
  ${appendQueryStringToUrl(
    replaceS3withImgix(url),
    `dpr=2&auto=format&fit=clip&w=${width === 'auto' ? width : parseInt(width) * 2}`
  )} 2x,
  ${appendQueryStringToUrl(
    replaceS3withImgix(url),
    `dpr=3&auto=format&fit=clip&w=${width === 'auto' ? width : parseInt(width) * 3}`
  )} 3x
`;

export function transformMathMl(node) {
  // Create a temporary div to render the node
  const reactNode = convertNodeToElement(node);

  // Convert to string
  const stringNode = renderToString(reactNode);

  const mathml = window.MathJax.mathml2svg(stringNode);

  // Convert math to string
  const mathString = new XMLSerializer().serializeToString(mathml);

  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={{ __html: mathString }} />;
}

function transformImgixUrls(node, options) {
  if (node.type === 'tag' && node.name === 'math') {
    return transformMathMl(node);
  }

  if (node.type === 'tag' && node.name === 'img' && node.attribs.class !== 'Wirisformula') {
    return (
      <GracefulImage
        key={node.attribs.src.replace(process.env.S3_DOMAIN, process.env.IMGIX_DOMAIN)}
        src={node.attribs.src.replace(process.env.S3_DOMAIN, process.env.IMGIX_DOMAIN)}
        alt={node.attribs.alt || ''}
        srcSet={setSrcSet(
          node.attribs.src.replace(process.env.S3_DOMAIN, process.env.IMGIX_DOMAIN),
          options
        )}
      />
    );
  }
}

export default transformImgixUrls;
