/** @jsxImportSource @emotion/react */
import { useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { restrictToWindowEdges, snapCenterToCursor } from '@dnd-kit/modifiers';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';
import { isEqual } from 'lodash-es';

import { Draggable } from 'components/QuestionTypes/shared-components/DndkitComponents';
import Wordbox, { DroppableWordbox } from 'components/QuestionTypes/shared-components/Wordbox';
import P from 'components/htmlElements/P';
import SolutionExplainer from 'components/SolutionExplainer';

import { Container, Row, Col } from 'components/Grid';
import { InstructionLine } from 'components/Instructions';

import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';

import { colors, spacer, txtColor } from 'styles/utilities';
import * as sharedStyles from 'components/QuestionTypes/shared-components/shared.styles';
import QuestionsCommonWrapperContainer from 'components/QuestionTypes/shared-components/QuestionsCommonWrapper';
import Latex, { withLatex } from 'components/QuestionTypes/shared-components/Latex';

import * as styles from './MathAnagram.styles';

const indexStringGenerator = (index, suffix) => `${index.toString()}-${suffix.toString()}`;

const numbersArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
const operatorsArray = ['+', '-', 'x', '÷'];
const DRAGGABLE_TYPES = {
  number: 'number',
  operator: 'operator'
};

const MathAnagram = ({ currentQuestion }) => {
  // console.info(currentQuestion);
  const {
    instructionsText,
    questionText,
    answerParts,
    showNumbers,
    showOperators,
    solutionExplaination,
    answer
  } = currentQuestion.anagramsMathEq;
  const { ttAnswers } = currentQuestion;

  const {
    // attemptFinished,
    skippingAllowed,
    showNextButton,
    answersEditableBeforeSubmission,
    updateAnswerPayload,
    isQuestionUsageFinal
  } = TestModuleInfoContainer.useContainer();

  const { attemptFinished, isNoteOrSampleQuestion } =
    QuestionsCommonWrapperContainer.useContainer();

  // Save checked answer options
  const [checkedAnswers, setCheckedAnswers] = useState([]);
  useEffect(
    // This effect simply clears the checkedAnswers on question change. It ensures on question switch the previous state is cleared
    () => () => {
      setCheckedAnswers([]);
    },
    [currentQuestion._id]
  );

  // Update `checkedAnswers` if already answered and/or attempt is completed
  useEffect(() => {
    if (ttAnswers && ttAnswers.length) {
      setCheckedAnswers(ttAnswers);
    }

    // Clear the checked answers array on unmount
    // Without this there's a bug which messes up the next question if it's of the same type
    return () => {
      setCheckedAnswers([]);
    };
  }, [ttAnswers]);

  // Handle disabling of answering
  const [answeringDisabled, handleAnsweringDisabled] = useState(false);
  useEffect(() => {
    // If editable and not answered - can answer
    // If not editable and not answered - can answer
    // If not editable and answered - cannot answer
    if (!attemptFinished) {
      if (!ttAnswers) {
        // This means it's not answered
        handleAnsweringDisabled(false);
      } else if (!answersEditableBeforeSubmission) {
        handleAnsweringDisabled(true);
      }
    } else {
      // If attempt is over answering is disabled
      handleAnsweringDisabled(true);
    }

    return () => {
      // Reset state
      handleAnsweringDisabled(!answersEditableBeforeSubmission);
    };
  }, [answersEditableBeforeSubmission, attemptFinished, ttAnswers]);

  // Toggle next button visibility
  useEffect(() => {
    // checkedAnswers.length === mixedWordOrder.length equality check to ensure all boxes are filled
    const totalFillableBlanks =
      answerParts && answerParts.filter((item) => item.type !== 'text').length;
    const totalFilledBlanks = checkedAnswers.filter((item) => item.text.length)?.length;

    if (!skippingAllowed && totalFilledBlanks !== totalFillableBlanks && !isNoteOrSampleQuestion) {
      showNextButton(false);
    } else {
      showNextButton(true);
    }
  }, [answerParts, checkedAnswers, isNoteOrSampleQuestion, showNextButton, skippingAllowed]);

  // Handle updating answer payload
  useEffect(() => {
    updateAnswerPayload({
      questionId: currentQuestion._id,
      answerArray: checkedAnswers
    });
  }, [checkedAnswers, currentQuestion._id, updateAnswerPayload]);

  // Drag drop stuff starts here
  const [droppableParent, setDroppableParent] = useState(null);
  const [activeDrag, setActiveDrag] = useState(null); // Current drag

  // Setup Numbers and operators
  const [draggableNumbers, setDraggableNumbers] = useState([]);
  useEffect(() => {
    if (showNumbers) {
      setDraggableNumbers(
        numbersArray.map((number) => ({
          number,
          id: indexStringGenerator(number, 'number-item')
        }))
      );
    }
    return () => {
      setDraggableNumbers([]);
    };
  }, [showNumbers]);

  const [draggableOperators, setDraggableOperators] = useState([]);
  useEffect(() => {
    if (showOperators) {
      setDraggableOperators(
        operatorsArray.map((operator) => ({
          operator,
          id: indexStringGenerator(operator, 'operator-item')
        }))
      );
    }
    return () => {
      setDraggableOperators([]);
    };
  }, [showOperators]);

  // Setup droppable parents array
  useEffect(() => {
    const parentsObject = {};

    answerParts.forEach((answerPart) => {
      if (answerPart.type !== 'text') {
        let wordId = null;

        if (ttAnswers && ttAnswers.length) {
          // Here we are first getting the correct object from ttAnswers array
          // Once we have the correct ttAnswers object, we compare it's value with draggable numbers or operators
          // If it matches we set `wordId` to the matched number/operator
          const correctTTAnswerObject = ttAnswers.find(
            (userAnswer) => userAnswer.shortId === answerPart.shortId
          );

          if (answerPart.type === DRAGGABLE_TYPES.number) {
            wordId = draggableNumbers.find((num) => num.number === correctTTAnswerObject.text)?.id;
          } else {
            wordId = draggableOperators.find(
              (opt) => opt.operator === correctTTAnswerObject.text
            )?.id;
          }
        }

        parentsObject[answerPart.shortId] = { wordId, type: answerPart.type };
      }
    });

    setDroppableParent(parentsObject);
  }, [answerParts, draggableNumbers, draggableOperators, ttAnswers]);

  function handleDragStart(event) {
    setActiveDrag(event.active.id);
  }

  function updateCheckedAnswerArrayOnDragEnd(updatedState) {
    const answerOrderArray =
      droppableParent &&
      Object.keys(updatedState).map((parentKey) => {
        // Explicitly check if it's number because if it's 0 it will render empty
        if (
          draggableNumbers
            .find((num) => num.id === updatedState[parentKey].wordId)
            ?.number.toString()
        ) {
          return {
            text: draggableNumbers.find((num) => num.id === updatedState[parentKey].wordId)?.number,
            shortId: parentKey,
            type: updatedState[parentKey].type
          };
        }

        return {
          text:
            draggableOperators.find((opt) => opt.id === updatedState[parentKey].wordId)?.operator ||
            '',
          shortId: parentKey,
          type: updatedState[parentKey].type
        };
      });

    // Set the answer
    setCheckedAnswers(answerOrderArray);
  }

  function handleDragEnd(event) {
    const { over, active } = event;

    setActiveDrag(null);

    if (over) {
      if (over.data.current.accepts.includes(active.data.current.type)) {
        // if the draggable and droppable types match, do stuff here
        setDroppableParent((prevState) => {
          const updatedState = {
            ...prevState
          };

          updatedState[over.id].wordId = active.id;

          // Update checkedAnswer
          updateCheckedAnswerArrayOnDragEnd(updatedState);

          return {
            ...updatedState
          };
        });
      }
    }
  }

  const [correctAnswerArray, updateCorrectAnswerArray] = useState([]);
  useEffect(() => {
    if (attemptFinished && answerParts) {
      const answerArray = answerParts.map((part) => {
        if (part.type !== 'text') {
          return answer
            ?.find((correctAnswerItem) => correctAnswerItem.shortId === part.shortId)
            .text.toString()
            .trim();
        }
        return part.text.toString();
      });

      updateCorrectAnswerArray(answerArray);
    }
  }, [answer, answerParts, attemptFinished]);

  return (
    <div css={[spacer.padT30]}>
      <Container>
        <Row>
          <Col>
            {instructionsText && <InstructionLine text={instructionsText} />}
            <Latex
              css={[spacer.mrT60, spacer.mrB40, sharedStyles.types, sharedStyles.richTextQuestion]}
              className="ck-content"
            >
              {ReactHtmlParser(DOMPurify.sanitize(withLatex(questionText)))}
            </Latex>

            {/* DND stuff */}
            <DndContext
              onDragEnd={handleDragEnd}
              onDragStart={handleDragStart}
              modifiers={[restrictToWindowEdges, snapCenterToCursor]}
            >
              {draggableNumbers.length ? (
                <div css={styles.draggrableItemsWrapper}>
                  {draggableNumbers.map((number) => (
                    <Draggable
                      id={number.id}
                      key={number.id}
                      type={DRAGGABLE_TYPES.number}
                      disabled={answeringDisabled}
                    >
                      <Wordbox text={number.number} type="square" fixedSize />
                    </Draggable>
                  ))}
                </div>
              ) : null}
              {draggableOperators.length ? (
                <div css={styles.draggrableItemsWrapper}>
                  {draggableOperators.map((operator) => (
                    <Draggable
                      id={operator.id}
                      key={operator.id}
                      type={DRAGGABLE_TYPES.operator}
                      disabled={answeringDisabled}
                    >
                      <Wordbox text={operator.operator} type="circle" fixedSize />
                    </Draggable>
                  ))}
                </div>
              ) : null}

              {answerParts.length ? (
                <div css={styles.draggrableItemsWrapper} className="answer-parts-wrapper">
                  {answerParts.map((answerPart) => {
                    if (answerPart.type !== 'text') {
                      const type = answerPart.type === 'operator' ? 'circle' : 'square';
                      const accepts =
                        answerPart.type === 'operator'
                          ? [DRAGGABLE_TYPES.operator]
                          : [DRAGGABLE_TYPES.number];
                      return (
                        <DroppableWordbox
                          id={answerPart.shortId}
                          fixedSize
                          key={answerPart.shortId}
                          type={type}
                          accepts={accepts}
                        >
                          {droppableParent && droppableParent[answerPart.shortId]?.wordId && (
                            <Wordbox
                              text={
                                draggableNumbers
                                  .find(
                                    (num) => num.id === droppableParent[answerPart.shortId].wordId
                                  )
                                  ?.number.toString() ||
                                draggableOperators.find(
                                  (opt) => opt.id === droppableParent[answerPart.shortId].wordId
                                )?.operator
                              }
                              type={
                                draggableNumbers.find(
                                  (num) => num.id === droppableParent[answerPart.shortId].wordId
                                )
                                  ? 'square'
                                  : 'circle'
                              }
                              fixedSize
                            />
                          )}
                        </DroppableWordbox>
                      );
                    }
                    return (
                      <P
                        large
                        key={answerPart.shortId}
                        css={[spacer.mrLR5]}
                        class="answer-parts-text"
                      >
                        {answerPart.text}
                      </P>
                    );
                  })}
                </div>
              ) : null}

              <DragOverlay dropAnimation={null} key={currentQuestion._id}>
                {activeDrag ? (
                  <Wordbox
                    text={
                      draggableNumbers.find((num) => num.id === activeDrag)?.number ||
                      draggableOperators.find((opt) => opt.id === activeDrag)?.operator
                    }
                    type={
                      draggableNumbers.find((num) => num.id === activeDrag) ? 'square' : 'circle'
                    }
                    fixedSize
                  />
                ) : null}
              </DragOverlay>
            </DndContext>

            {attemptFinished && !ttAnswers?.length && isQuestionUsageFinal && (
              <P color={colors.red} large>
                Question not attempted.
              </P>
            )}

            {attemptFinished ? (
              <Fragment>
                <P large css={spacer.mrT20}>
                  <span>The correct answer is </span>
                  <span css={txtColor.green}>
                    {correctAnswerArray.map((item, index) => (
                      <span key={`${item}-${index.toString()}`} css={spacer.padLR1}>
                        {item}
                      </span>
                    ))}
                  </span>
                  {isEqual(checkedAnswers, answer) ? (
                    <P as="span" large>
                      . You answered correctly.
                    </P>
                  ) : (
                    ''
                  )}
                </P>
                <SolutionExplainer text={solutionExplaination} />
              </Fragment>
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

MathAnagram.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

const WithQuestionProvider = (props) => {
  const { questionSettings } = props.currentQuestion.anagramsMathEq;

  return (
    <QuestionsCommonWrapperContainer.Provider
      initialState={{ currentQuestion: props.currentQuestion, questionSettings }}
    >
      <MathAnagram {...props} />
    </QuestionsCommonWrapperContainer.Provider>
  );
};
WithQuestionProvider.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

export default WithQuestionProvider;
