import { useState, useEffect, useMemo } from 'react';
import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from 'react-query';
import request from 'utils/request';
import { apiEndpoints } from 'globals/constants';
import { useValidateToken } from 'api/auth';

export const useUnassignedProducts = () => {
  const { data, isSuccess, isError } = useQuery('unassignedProducts', async () => {
    const { response } = await request({
      method: 'GET',
      endpoint: apiEndpoints.parent.unassignedProducts
    });
    return response;
  });
  return { data, isSuccess, isError };
};

export const useCandidates = () => {
  const { data, isSuccess, isError } = useQuery('assignedCandidates', async () => {
    const { response } = await request({
      method: 'GET',
      endpoint: apiEndpoints.parent.candidates,
      payload: {
        candidateAssignedTests: false,
        candidateAssignedCourses: false
      }
    });
    return response;
  });
  return { data, isSuccess, isError };
};

export const useCandidatesPaginated = () => {
  const FETCHING_LIMIT = 1;
  const { data, isSuccess, isError, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      'assignedCandidatesPaginated',
      async ({ pageParam = 0 }) => {
        const { response } = await request({
          method: 'GET',
          endpoint: `${apiEndpoints.parent.candidates}?skip=${pageParam}&take=${FETCHING_LIMIT}`
        });
        return response;
      },
      {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage, allPages) =>
          lastPage.length ? allPages.length * FETCHING_LIMIT : undefined
      }
    );

  const [candidatesData, updateCandidatesData] = useState(data);
  useEffect(() => {
    // if (data && data.data !== 'undefined') {
    if (data && data !== 'undefined' && data.pages.length) {
      const updatedData = data;

      // Needed since infinite query returns data in pages. Check react-query docs for more info
      const combinedPagesData = data.pages.map((pageData) => pageData).flat();
      updatedData.data = combinedPagesData;

      updateCandidatesData(updatedData);
    }
  }, [data]);

  return {
    data: candidatesData,
    isSuccess,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  };
};

export const useCandidate = (candidateId) => {
  const { data, isError, isSuccess } = useQuery(['getCandidate', { candidateId }], async () => {
    const { response } = await request({
      method: 'GET',
      endpoint: apiEndpoints.parent.getCandidate(candidateId)
    });
    return response;
  });
  return { data, isError, isSuccess };
};

export const useAssignProduct = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isSuccess, isError } = useMutation(
    async ({ candidateId, wpProductId }) => {
      const { response } = await request({
        method: 'POST',
        endpoint: apiEndpoints.parent.assignProduct,
        payload: { candidateId, wpProductId }
      });
      return response;
    },
    {
      onSettled(data, error, variables) {
        // Refetch unassinged products
        queryClient.refetchQueries('unassignedProducts');

        // Refetch all user
        queryClient.refetchQueries('assignedCandidates');
        queryClient.refetchQueries('assignedCandidatesPaginated');

        // Refetch that specific user's tests and courses too
        queryClient.refetchQueries(['getAllAssignedTests', { candidateId: variables.candidateId }]);
        queryClient.refetchQueries([
          'getAllAssignedCourses',
          { candidateId: variables.candidateId }
        ]);
      }
    }
  );

  return { assignProduct: mutateAsync, isSuccess, isError };
};

export const useEditAccount = () => {
  // const queryClient = useQueryClient();
  const { validateToken } = useValidateToken();

  const { mutateAsync, isSuccess, isError, reset } = useMutation(
    async (payload) => {
      const { response } = await request({
        method: 'PATCH',
        endpoint: apiEndpoints.parent.editAccount,
        payload
      });
      return response;
    },
    {
      onSettled() {
        // Refetch user here
        validateToken();
      }
    }
  );

  return { editAccount: mutateAsync, isSuccess, isError, reset };
};

export const useChangePassword = () => {
  const { mutateAsync, isSuccess, isError, reset } = useMutation(async (payload) => {
    const { response } = await request({
      method: 'PATCH',
      endpoint: apiEndpoints.parent.changePassword,
      payload
    });
    return response;
  });

  return { changePassword: mutateAsync, isSuccess, isError, reset };
};

export const useAddChild = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (payload) => {
      const { response, status } = await request({
        method: 'POST',
        endpoint: apiEndpoints.parent.addChild,
        payload
      });

      return { response, status };
    },
    {
      onSettled() {
        // Refetch candidates here
        queryClient.refetchQueries('assignedCandidates');
        queryClient.refetchQueries('assignedCandidatesPaginated');
      }
    }
  );

  const { mutateAsync, data, isError, isSuccess, reset } = mutation;
  return { addChild: mutateAsync, data, isError, isSuccess, reset };
};

export const useGetPurchasableProducts = () => {
  const FETCHING_LIMIT = 100;
  const {
    data,
    isError,
    isLoading,
    isSuccess,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteQuery(
    'purchasableProducts',
    async ({ pageParam = 1 }) => {
      const { response } = await request({
        method: 'GET',
        endpoint: apiEndpoints.parent.purchasableProducts,
        payload: {
          per_page: FETCHING_LIMIT,
          page: pageParam
        }
      });
      return response;
    },
    {
      getNextPageParam: (lastPage, allPages) => (lastPage.length ? allPages.length + 1 : undefined)
    }
  );

  // Fetch all data upfront
  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const productsData = useMemo(() => {
    // if (data && data.data !== 'undefined') {
    if (data && data !== 'undefined' && data.pages.length) {
      // Needed since infinite query returns data in pages. Check react-query docs for more info
      const combinedPagesData = data.pages.map((pageData) => pageData).flat();

      return combinedPagesData;
    }
    return [];
  }, [data]);

  return {
    data: productsData,
    isError,
    isLoading,
    isSuccess,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  };
};
