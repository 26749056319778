/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mixins } from 'styles/utilities';

// import { colors } from 'styles/utilities';

// export const error = css`
//   color: red;
//   padding: 5px 0;
//   text-transform: uppercase;
//   font-weight: 400;
//   font-size: 11px;
// `;

// export const successText = css`
//   color: ${colors.green};
//   padding: 5px 0;
//   text-transform: uppercase;
//   font-weight: 400;
//   font-size: 11px;
// `;

export const ModalHeader = (props) => css`
  background-color: ${mixins.toRgba(props.color, 0.1)};
`;

export const TopBand = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${({ color }) => color};
  border-radius: 6px 6px 0px 0px;
`;

// export const sectionName = css`
//   padding: 0 30px 20px 30px;
//   font-size: 38px;
//   font-family: Gotham SSm Black A, Gotham SSm Black B;
//   text-transform: uppercase;
// `;

// export const header = css`
//   font-size: 18px;
//   font-family: Gotham SSm Black A, Gotham SSm Black B;
//   color: ${colors.mediumGrey};
//   margin: 5px 0 5px;
// `;
// export const summary = css`
//   display: block;
//   width: 100%;
//   justify-content: space-between;
//   padding: 0 30px 26px 30px;
//   font-family: Gotham SSm Black A, Gotham SSm Black B;
//   color: ${colors.mediumGrey};
//   .first {
//     margin-right: 60px;
//     width: auto;
//     max-width: none;
//     float: left;
//     display: inline-block;
//     margin: 0 60px 0 0;
//     text-align: left;
//   }
//   .txt-small {
//     font-size: 10px;
//     color: ${colors.black3};
//     font-family: gotham ssm book a, gotham ssm book b;
//   }
// `;
