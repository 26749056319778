import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import styledMap from 'styled-map';
import { typography, colors, misc } from 'styles/utilities';

const P = styled('p', {
  shouldForwardProp: (prop) => !['color'].includes(prop) && isPropValid(prop)
})`
  font-family: ${styledMap`
    gBold: ${typography.fonts.gothamBold.fontFamily};
    default: ${typography.fonts.gothamBook.fontFamily};
  `};
  line-height: 125%;
  font-style: normal;
  letter-spacing: normal;
  text-decoration: ${styledMap`
  underline: underline;
  default: none;
  `};
  color: ${(props) => (props.color ? props.color : colors.black)};
  font-size: ${(props) => {
    if (props.large) {
      return '18px';
    }
    if (props.instruction) {
      return '16px';
    }
    if (props.small) {
      return typography.baseSizes.theta;
    }
    if (props.tiny) {
      return typography.baseSizes.iota;
    }
    return typography.baseSizes.eta;
  }};
  margin: ${(props) => {
    if (props.noMargin) {
      return 0;
    }
    return `${misc.defaultTextMargin}`;
  }};
`;

export default P;

// font-weight: ${styledMap`
// gBold: ${typography.fonts.gothamBold.fontWeight.medium};
// bold : ${typography.fonts.gothamBlack.fontWeight.semiBold};
//   default: 'normal';
// `};
