import { css } from '@emotion/react';

export const container = css`
  display: flex;
  justify-content: center;
`;

export const image = css`
  width: 80px;
  height: 80px;
`;
