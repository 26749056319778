//* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors } from 'styles/utilities';

export const background = css`
  background-color: ${colors.grey2};
`;

export const noMargin = css`
  margin: 0;
`;
