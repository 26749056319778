//* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { breakpoints, colors, spacer, mixins, misc } from 'styles/utilities';

export const container = css`
  display: flex;
  flex-direction: column;
`;

export const modalContainerStyles = ({ slideNumber, isLastSlide }) => css`
  ${mixins.respondToMax(misc.viewport['b-vp'])} {
    & .container,
    & .row,
    & .row,
    & .row > div,
    & .modal-contents,
    & .modal-content-wrapper {
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      & > div {
        border-radius: 0;
      }
    }

    .modal-content-wrapper {
      display: flex;
      flex-direction: column;
      .slick-slider,
      .slick-list,
      .slick-track {
        height: 100%;
        min-height: 0;
        min-width: 0;
      }
      .slick-slide > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
  .modal-padding {
    padding: 0;
  }
  .slick-slider {
    position: relative;
    .slick-arrow {
      .carousel-icon {
        transition: 0.4s all ease-in-out;
        fill: ${colors.white};
        &:hover {
          fill: ${colors.green};
        }
      }
    }
    .slick-prev,
    .slick-next {
      z-index: 1;
      position: absolute;
      top: auto;
      bottom: 20%;
      pointer-events: ${slideNumber === 0 ? 'none' : ''};
      ${breakpoints({
        opacity: [0, slideNumber === 0 ? 0 : 1]
      })}
      &::hover {
        .carousel-icon > svg {
          fill: ${colors.primaryLinkHover};
        }
      }
    }
    .slick-prev {
      left: 3%;
      cursor: pointer;
    }
    .slick-next {
      right: 3%;
      color: ${isLastSlide ? colors['grey-shade-1'] : colors.primaryLinkHover};
      cursor: pointer;
      opacity: ${slideNumber === 0 || isLastSlide ? 0 : 1};
      pointer-events: ${isLastSlide ? 'none' : ''};
    }
    .slick-dots {
      display: flex !important;
      margin: 0;
      padding: 0;
      justify-content: center;
      margin: 0 auto;
      & > li {
        position: relative;
        cursor: pointer;
        ${spacer.mrR10};
        width: 8px;
        height: 8px;
        display: inline-block;
        transition: 0.3s all ease-in-out;
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        text-align: center;
        color: transparent;
        button {
          font-size: 0;
          background: transparent;
          outline: none;
          border: none;
          position: absolute;
          left: 0;
          top: 1px;
          cursor: pointer;
          height: 8px;
          width: 8px;
        }
        &:hover {
          background-color: ${colors.primaryLinkHover};
        }
        &.slick-active {
          background-color: ${colors.white};
          &:hover {
            background-color: ${colors.white};
          }
        }
      }
      display: flex;
    }
  }
`;

export const controlArrowStyles = breakpoints({
  fontSize: [14, 14, 18, 24]
});

export const slideContainerStyles = css`
  ${mixins.respondToMax(misc.viewport['b-vp'])} {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  text-align: center;
  position: relative;
`;

export const imageBorder = css`
  border-bottom: 1px solid ${colors.black};
`;

export const logo = css`
  display: flex;
  justify-content: center;
`;

export const alignCenter = css`
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const tourControl = css`
  text-align: center;
`;

export const emptyDiv = css`
  opacity: 0;
  pointer-events: none;
`;

export const firstSlide = css`
  background-image: url(${require('../../static/images/welcome-tour-background.svg').default});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const bgPtn = css`
  position: relative;
  z-index: 0;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAJElEQVQIW2P8//+/DyMj4xYGJMAIYqNLgAXRJeCCyBIogjAJAA+zE0+2WHGzAAAAAElFTkSuQmCC);
    background-repeat: repeat;
    opacity: 0.3;
    z-index: -1;
  }
`;
