/** @jsxImportSource @emotion/react */
import React from 'react';
// import PropTypes from 'prop-types';

import P from 'components/htmlElements/P';
import A from 'components/htmlElements/A';
// import Icon from 'components/Icon';
import { cutoffDate, oldDashboardUrl } from 'globals/constants';
import { dateInFMY } from 'utils/dateFormats';

import * as styles from './CutoffNotice.styles';

// const engine = require('store/src/store-engine');
// const storages = [require('store/storages/cookieStorage')];
// const plugins = [require('store/plugins/defaults'), require('store/plugins/expire')];

// const store = engine.createStore(storages, plugins);

const CutoffNotice = () => (
  // const [showNotice, toggleNotice] = useState(true);
  // useEffect(() => {
  //   const noticeShown = store.get('ptpNoticeShown');
  //   if (!noticeShown) {
  //     toggleNotice(true);
  //   }
  // }, []);

  // const handleClick = (event) => {
  //   event.preventDefault();
  //   const expiryDate = new Date();
  //   expiryDate.setTime(expiryDate.getTime() + 31556952000); // 1 year - 31556952000

  //   // store.set has a bug in cookie store which doesn't set the expire duration
  //   document.cookie = `ptpNoticeShown=${true};expires=${expiryDate.toUTCString()}; path=/`;

  //   toggleNotice(false);
  // };

  // if (!showNotice) {
  //   return null;
  // }

  <div css={styles.wrapper}>
    <P className="text-center">
      <strong>Important Notice:</strong> To access your purchases before {dateInFMY(cutoffDate)},
      please login to the{' '}
      <A
        href={oldDashboardUrl}
        target="_blank"
        rel="noreferrer"
        className="dashboard-link"
        color="white"
      >
        old dashboard here.
      </A>
    </P>
    {/* <P css={styles.closeNotice} onClick={handleClick}>
        <Icon icon="close" />
      </P> */}
  </div>
);
CutoffNotice.propTypes = {};

export default CutoffNotice;
