/** @jsxImportSource @emotion/react */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';
import P from 'components/htmlElements/P';
import Checkbox from 'components/CheckBox';
import Latex, { withLatex } from 'components/QuestionTypes/shared-components/Latex';
import transformImgixUrls from 'components/QuestionTypes/shared-components/transformImgixUrls';
import * as sharedStyles from 'components/QuestionTypes/shared-components/shared.styles';
import { colors } from 'styles/utilities';
import * as styles from './AnswerOption.styles';

const alphabet = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z'
];
const AnswerOption = ({
  html,
  type,
  index,
  shortId,
  disabled,
  onChange,
  checked,
  isCorrectAnswer,
  attemptFinished,
  imageWidth
}) => {
  const [userFeedbackText, updateFeedbackText] = useState('');

  useEffect(() => {
    if (isCorrectAnswer && checked) {
      updateFeedbackText('✓ Your answer is correct');
    } else if (isCorrectAnswer) {
      updateFeedbackText('✓ Correct answer');
    } else if (checked && !isCorrectAnswer) {
      updateFeedbackText('X Your answer is wrong');
    }
  }, [checked, isCorrectAnswer]);

  return (
    <label
      htmlFor={shortId}
      css={[sharedStyles.types, styles.answerOptionWrapper({ type })]}
      className="answer-wrapper"
    >
      {type === 'square' && (
        <Fragment>
          <Checkbox
            id={shortId}
            disabled={disabled}
            onChange={(event) => onChange(event, shortId)}
            checked={checked}
          />
          <P css={styles.alphabetBox} className="alphabet-box">
            <span>{alphabet[index]}</span>
          </P>
        </Fragment>
      )}

      {type === 'circle' && (
        <Fragment>
          <div css={styles.optionCircleWrapper}>
            <input
              type="checkbox"
              id={shortId}
              disabled={disabled}
              onChange={(event) => onChange(event, shortId)}
              checked={checked}
            />
            {attemptFinished ? (
              <span
                css={styles.optionCircle({ isCorrectAnswer, checked, attemptFinished })}
                className="option-circle option-background"
              />
            ) : (
              <span css={styles.optionCircle} className="option-circle" />
            )}
          </div>
        </Fragment>
      )}

      {type === 'box' ? (
        <Fragment>
          <div css={styles.optionBoxWrapper} className="option-box-wrapper">
            <div
              css={styles.optionBoxInner({ isCorrectAnswer, checked })}
              className={isCorrectAnswer ? 'option-box-inner correct-answer' : 'option-box-inner'}
            >
              <input
                type="checkbox"
                id={shortId}
                disabled={disabled}
                onChange={(event) => onChange(event, shortId)}
                checked={checked}
              />
              <Latex className="answer-contents">
                {/* {ReactHtmlParser(DOMPurify.sanitize(withLatex(html)))} */}
                {ReactHtmlParser(DOMPurify.sanitize(withLatex(html)), {
                  transform: (node) => transformImgixUrls(node, { width: imageWidth })
                })}
              </Latex>
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="d-flex align-items-center">
          <Latex className="answer-contents">
            {ReactHtmlParser(DOMPurify.sanitize(withLatex(html)))}
          </Latex>
          {type === 'square' && attemptFinished && (
            <Fragment>
              {userFeedbackText.length ? (
                <P css={styles.correctAnswer} color={isCorrectAnswer ? colors.green : colors.red}>
                  {userFeedbackText}
                </P>
              ) : (
                ''
              )}
            </Fragment>
          )}
        </div>
      )}
    </label>
  );
};

AnswerOption.propTypes = {
  html: PropTypes.string.isRequired,
  shortId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['square', 'circle', 'box']).isRequired,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  isCorrectAnswer: PropTypes.bool,
  onChange: PropTypes.func,
  attemptFinished: PropTypes.bool.isRequired,
  imageWidth: PropTypes.number
};

AnswerOption.defaultProps = {
  index: 0,
  disabled: false,
  checked: false,
  isCorrectAnswer: false,
  onChange: () => {},
  imageWidth: 'auto'
};

export default AnswerOption;
