/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';
import PropTypes from 'prop-types';

import DOMPurify from 'dompurify';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';

import { spacer, colors } from 'styles/utilities';
import useShowTestResult from 'hooks/useShowTestResult';

import P from 'components/htmlElements/P';
import Button from 'components/htmlElements/Button';

import ScoreBand from 'components/ScoreBand/ScoreBand';
import H3 from 'components/htmlElements/H3';
import * as styles from './ModuleBand.styles';

const extractCATSupHeading = (string) => {
  const regExp = /\(([^)]+)\)/;
  const matches = regExp.exec(string);
  return matches?.length ? matches[1] : string;
};

const extractCATTitle = (string) => string.substring(0, string.indexOf('(')).trim();

const ModuleBand = ({
  children,
  heading,
  supHeading,
  subHeading,
  scoreCard,
  statistics,
  moduleCtaText,
  moduleCta,
  extraTimeCtaText,
  extraTimeCta,
  disableAttempts,
  isCATtest,
  isTestAtSubSection,
  upsellText,
  showUpsellText,
  endedAt
}) => {
  const { showResult, endedAtTime12Hours } = useShowTestResult({ endedAt });

  return (
    <div css={[styles.wrapper({ hideSolutionsLink: !showResult }), spacer.mrB20]}>
      <div css={[styles.container]}>
        <div css={[styles.leftSection, spacer.padLR20, spacer.padBT30]}>
          {supHeading && (
            <P gBold color={colors.mediumGrey2}>
              {isCATtest && isTestAtSubSection ? extractCATSupHeading(heading) : supHeading}
            </P>
          )}
          <H3 gBlack color={colors.mediumGrey2} css={spacer.mrT0} className="text-uppercase">
            {isCATtest && !isTestAtSubSection ? extractCATTitle(heading) : heading}
          </H3>

          {subHeading && (
            <P large color={colors.mediumGrey2} className="m-0">
              {subHeading}
            </P>
          )}

          {children}
        </div>

        <div css={[styles.rightSection, spacer.padR20]}>
          {Object.keys(statistics).length > 0 && (
            <Fragment>
              {showResult ? (
                <ScoreBand score={scoreCard} statistics={statistics} />
              ) : (
                <P large color={colors.mediumGrey2}>
                  Results will be displayed at {endedAtTime12Hours}
                </P>
              )}
            </Fragment>
          )}

          {!disableAttempts && (
            <Fragment>
              {extraTimeCtaText && (
                <Button rounded onClick={extraTimeCta} product css={spacer.mrR20}>
                  {extraTimeCtaText}
                </Button>
              )}
              {moduleCtaText && (
                <Button rounded onClick={moduleCta} product>
                  {moduleCtaText}
                </Button>
              )}
            </Fragment>
          )}
        </div>
      </div>

      {showUpsellText && (
        <div css={styles.upsellText}>
          {ReactHtmlParser(DOMPurify.sanitize(upsellText), {
            // eslint-disable-next-line consistent-return
            transform: (node, index) => {
              if (node.type === 'tag' && node.name === 'a') {
                // eslint-disable-next-line no-param-reassign
                node.attribs.target = '_blank';
                return convertNodeToElement(node, index);
              }
            }
          })}
        </div>
      )}
    </div>
  );
};

ModuleBand.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string.isRequired,
  supHeading: PropTypes.string,
  subHeading: PropTypes.string,
  scoreCard: PropTypes.string,
  statistics: PropTypes.object,
  moduleCtaText: PropTypes.string,
  moduleCta: PropTypes.func,
  extraTimeCtaText: PropTypes.string,
  extraTimeCta: PropTypes.func,
  disableAttempts: PropTypes.bool,
  isCATtest: PropTypes.bool,
  isTestAtSubSection: PropTypes.bool.isRequired,
  upsellText: PropTypes.string,
  showUpsellText: PropTypes.bool,
  endedAt: PropTypes.string
};

ModuleBand.defaultProps = {
  children: null,
  moduleCtaText: '',
  supHeading: '',
  subHeading: '',
  scoreCard: '',
  statistics: {},
  moduleCta: () => {},
  extraTimeCtaText: '',
  extraTimeCta: () => {},
  disableAttempts: false,
  isCATtest: false,
  upsellText: '',
  showUpsellText: false,
  endedAt: ''
};

export default ModuleBand;
