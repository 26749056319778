/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { breakpoints } from 'styles/utilities';

export const appletContainer = css`
  width: 100%;
  max-width: 2000px;
  margin: auto;

  .wrapper {
    ${breakpoints({
      flexDirection: ['column', 'column', '', 'row', '']
    })};
  }
`;

export const questionContainer = css`
  flex-grow: 1;
  ${breakpoints({
    width: ['100%', '', '', '65%', '']
  })};
`;

export const appletWrapper = css`
  max-width: 100%;
`;

export const answerOptionsWrapper = css`
  ${breakpoints({
    width: ['100%', '', '', '35%', '']
  })};

  .geogebraQuestion-text {
    font-size: 21px;
  }

  .answer-options {
    .option-box-wrapper {
      padding: 0px 0px 5px 0px;
    }

    .answer-contents {
      min-height: unset;
    }
  }
`;
