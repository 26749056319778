//* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { mixins } from 'styles/utilities';

export const container = css`
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .group {
    text-transform: capitalize;
  }

  .students {
    text-transform: uppercase;
  }
`;

export const content = css`
  .animate {
    ${mixins.transition('0.2s', 'all', 'ease-in-out')}
  }
`;
