/** @jsxImportSource @emotion/react */

import H5 from 'components/htmlElements/H5';
import Icon from 'components/Icon';
import { useState, useCallback, Fragment } from 'react';
import { colors, spacer } from 'styles/utilities';
import P from 'components/htmlElements/P';
import PropTypes from 'prop-types';
import TestTimer from 'components/TestTimer';
import { Helmet } from 'react-helmet';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import { disableSpaceBarClick } from 'utils/testsHelpers';
import Popup from './Popup';
import * as styles from './IsebTestSkeleton.styles';

const IsebTestSkeleton = (props) => {
  const {
    testAssignmentId,
    testName,
    children,
    attemptFinished,
    timeLimit,
    timerPaused,
    timerOnComplete,
    updatePageTitleTimer,
    practiceQuestionAnswered,
    isLastQuestion,
    isFirstQuestion,
    handleNextClick,
    handlePrevClick,
    prevQuestionId,
    answerPayload,
    currentQuestion,
    Score,
    index,
    questions,
    setCurrentQuestionIndex
  } = props;

  const [activePopup, setActivePopup] = useState(null);
  const [fontSize, setFontSize] = useState('medium');
  const [selectedTint, setSelectedTint] = useState({ color: colors.black, alpha: 0 });
  const [isTimerVisible, setIsTimerVisible] = useState(true);
  const [isProgressBarVisible, setIsProgressBarVisible] = useState(true);

  const popupHandler = (type) => {
    setActivePopup(type);
    document.querySelector('body').style.overflow = 'hidden';
  };

  const closePopup = useCallback((e) => {
    if (e.target.classList.contains('popup-backdrop')) {
      setActivePopup(null);
      document.querySelector('body').style.overflow = 'auto';
    }
  }, []);

  const getButtonInfo = useCallback(
    (type) => {
      switch (type) {
        case 'note':
          return { text: 'Next', disabled: false };
        case 'sample':
          return { text: 'Next', disabled: false };
        case 'practice':
          return { text: 'Next', disabled: !answerPayload?.answerArray?.length };
        case 'final':
          return { text: 'Next', disabled: !answerPayload?.answerArray?.length };
        default:
          return { text: 'Next', disabled: true };
      }
    },
    [answerPayload?.answerArray?.length]
  );

  return (
    <Fragment>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/bcm7wcr.css" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin={+true} />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div css={styles.skeletonWrapper} className={`${fontSize}-font`}>
        {/* header */}
        <div css={[styles.header, spacer.padBT15, spacer.padLR15]} className="flex-shrink-1">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              {attemptFinished && (
                <styles.HeaderButton
                  as="a"
                  href={`../../test/${testAssignmentId}`}
                  type="button"
                  css={spacer.mrR20}
                >
                  <Icon icon="icon-arrow-back" color={colors.checkboxGrey} size="12" />
                </styles.HeaderButton>
              )}

              <H5 gBold className="text-uppercase">
                {testName}
              </H5>
            </div>

            <div className="features">
              {!attemptFinished && (
                <Fragment>
                  {timeLimit && (
                    <div className={`${!isTimerVisible ? 'd-none' : ''}`}>
                      <TestTimer
                        time={timeLimit}
                        pauseTimer={timerPaused}
                        timerOnComplete={timerOnComplete}
                        updatePageTitleTimer={updatePageTitleTimer}
                        color={colors.violet}
                        isebLayout
                      />
                    </div>
                  )}
                </Fragment>
              )}

              {!attemptFinished && isProgressBarVisible && (
                <styles.progress
                  css={styles.progress}
                  testProgress={(60 / questions.length) * index}
                >
                  <div className="d-flex align-items-center">
                    <Icon icon="flag" css={spacer.mrR10} color={colors.violet} size="20" />
                    <div className="progress-bar">
                      <div className="progress-bar-fill" />
                    </div>
                  </div>
                </styles.progress>
              )}

              <styles.HeaderButton
                onClick={() => popupHandler('settings')}
                className={activePopup === 'settings' ? 'active' : ''}
              >
                <Icon
                  icon="settings"
                  color={activePopup === 'settings' ? colors.white : colors.violet}
                  size="24"
                />
              </styles.HeaderButton>

              <styles.HeaderButton
                onClick={() => popupHandler('tint')}
                className={activePopup === 'tint' ? 'active' : ''}
              >
                <Icon
                  icon="icon-tint"
                  color={activePopup === 'tint' ? colors.white : colors.violet}
                  size="20"
                />
              </styles.HeaderButton>

              <styles.HeaderButton
                onClick={() => popupHandler('info')}
                className={activePopup === 'info' ? 'active' : ''}
              >
                <Icon
                  icon="icon-info"
                  color={activePopup === 'info' ? colors.white : colors.violet}
                  size="20"
                />
              </styles.HeaderButton>
            </div>
          </div>
        </div>

        {/* question card */}
        <div className="question-container-bg flex-grow-1">
          <div css={styles.contentWrapper}>
            <div className="question-container">
              {/* Module Score Band */}
              {attemptFinished && <Score />}

              {/* question component */}
              <div css={styles.content({ tint: selectedTint })}>{children}</div>

              {/* Buttons */}
              <div className="d-flex justify-content-between">
                {prevQuestionId && attemptFinished && !isFirstQuestion && (
                  <styles.SubmitButton onClick={handlePrevClick} onKeyUp={disableSpaceBarClick}>
                    <P className="m-0" instruction color={colors.white} gBook>
                      Back
                    </P>
                  </styles.SubmitButton>
                )}

                <div className="ml-auto">
                  {attemptFinished || practiceQuestionAnswered ? (
                    isLastQuestion ? (
                      ''
                    ) : (
                      <styles.SubmitButton
                        onClick={() => handleNextClick()}
                        onKeyUp={disableSpaceBarClick}
                      >
                        <P className="m-0" instruction color={colors.white} gBook>
                          Next
                        </P>
                      </styles.SubmitButton>
                    )
                  ) : (
                    <styles.SubmitButton
                      disabled={getButtonInfo(currentQuestion?.usage).disabled}
                      onClick={handleNextClick}
                      onKeyUp={disableSpaceBarClick}
                    >
                      <P className="m-0" instruction color={colors.white} gBook>
                        {isLastQuestion ? 'Submit' : getButtonInfo(currentQuestion?.usage).text}
                      </P>
                    </styles.SubmitButton>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {attemptFinished && (
          <div
            css={[styles.progressBarWrapper({ attemptFinished }), spacer.padBT20]}
            className="br-t"
          >
            <ProgressBar
              index={index}
              questions={questions}
              attemptFinished={attemptFinished}
              setCurrentQuestionIndex={setCurrentQuestionIndex}
            />
          </div>
        )}

        {activePopup && (
          <Popup
            closePopup={closePopup}
            active={activePopup}
            setFontSize={setFontSize}
            selectedTint={selectedTint}
            setSelectedTint={setSelectedTint}
            timerVisible={isTimerVisible}
            timerToggle={setIsTimerVisible}
            selectedFontSize={fontSize}
            progressBarVisible={isProgressBarVisible}
            progressBarToggle={setIsProgressBarVisible}
          />
        )}
      </div>
    </Fragment>
  );
};

export default IsebTestSkeleton;

IsebTestSkeleton.propTypes = {
  testAssignmentId: PropTypes.string.isRequired,
  testName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  attemptFinished: PropTypes.bool.isRequired,
  timeLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  timerPaused: PropTypes.bool.isRequired,
  timerOnComplete: PropTypes.func.isRequired,
  updatePageTitleTimer: PropTypes.func.isRequired,
  practiceQuestionAnswered: PropTypes.bool.isRequired,
  isLastQuestion: PropTypes.bool.isRequired,
  isFirstQuestion: PropTypes.bool.isRequired,
  handleNextClick: PropTypes.func.isRequired,
  handlePrevClick: PropTypes.func.isRequired,
  prevQuestionId: PropTypes.string.isRequired,
  answerPayload: PropTypes.object.isRequired,
  currentQuestion: PropTypes.object.isRequired,
  Score: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
  setCurrentQuestionIndex: PropTypes.func.isRequired
};
