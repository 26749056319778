/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Button from 'components/htmlElements/Button';

import Modal from 'components/Modal';
import H5 from 'components/htmlElements/H5';
import P from 'components/htmlElements/P';

import { getProductColor } from 'components/ProductCard/helpers';
import { UnstyledLink } from 'components/Router';
import TouchscreenNotice from 'components/TouchscreenNotice';
import { secondsToHmsNiceName } from 'utils/secondsToHms';

import { colors, spacer } from 'styles/utilities';
import H3 from 'components/htmlElements/H3';
import * as styles from './ModulePreview.styles';

const formatTimeLimit = (timeLimit, extraTime = 0) => {
  const totalTimeLimit = parseInt(timeLimit) + parseInt(extraTime);
  return secondsToHmsNiceName(totalTimeLimit);
};

const ModulePreview = ({ isOpen, toggle, moduleInfo, isPretesttest, isLondonConsortiumTest }) => (
  <div className="is-relative">
    <Modal isOpen={isOpen} toggle={toggle}>
      <div css={styles.ModalHeader({ color: getProductColor(moduleInfo.wpProductInfo.examType) })}>
        <styles.TopBand color={getProductColor(moduleInfo.wpProductInfo.examType)} />

        <div css={[spacer.padBT30, spacer.padLR30]}>
          <P large gBook css={spacer.mrB15} color={colors.mediumGrey}>
            {moduleInfo.wpProductInfo.name}
          </P>

          <H3 gBook css={spacer.mrBT0}>
            {moduleInfo.currentModule.name}
          </H3>
        </div>
      </div>

      <div css={[spacer.padBT30, spacer.padLR30]}>
        <div className="d-flex" css={spacer.mrB30}>
          <div css={spacer.mrR80}>
            <P color={colors.mediumGrey}>Time Limit</P>
            <H5 color={colors.black} gBook>
              {moduleInfo.hasExtraTime
                ? formatTimeLimit(
                    moduleInfo.currentModule.settings.timeLimit,
                    moduleInfo.currentModule.settings.timeLimit *
                      (moduleInfo.currentModule.settings.allowedExtraTime / 100) // Extra time always in percentage
                  )
                : formatTimeLimit(moduleInfo.currentModule.settings.timeLimit)}
            </H5>
          </div>

          {!isPretesttest && !isLondonConsortiumTest && (
            <div>
              <P color={colors.mediumGrey}>Total Questions</P>
              <H5 color={colors.black} gBook>
                {moduleInfo.currentModule.totalQuestions} questions
              </H5>
            </div>
          )}
        </div>

        <TouchscreenNotice testCategory={moduleInfo.wpProductInfo.examType} />

        <Button
          as={UnstyledLink}
          to={moduleInfo.currentModule._id}
          state={{ hasExtraTime: moduleInfo.hasExtraTime }}
          wide
          rounded
          textCenter
        >
          Start Module
        </Button>
      </div>
    </Modal>
  </div>
);

ModulePreview.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  moduleInfo: PropTypes.object.isRequired,
  isPretesttest: PropTypes.bool.isRequired,
  isLondonConsortiumTest: PropTypes.bool.isRequired
};

export default ModulePreview;
