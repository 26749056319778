/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types';
import { Fragment } from 'react';

import A from 'components/htmlElements/A';
import H6 from 'components/htmlElements/H6';
import Button from 'components/htmlElements/Button';
import { spacer, bgColor, colors } from 'styles/utilities';
import { getProductColor, ProductCardTop } from '../helpers';

import * as styles from '../ProductCard.styles';

const ProductCardAdminAssigned = (props) => {
  const { examType, productType, productPageLink, productName, progress } = props;
  console.info(examType);
  return (
    <Fragment>
      <A href={productPageLink}>
        <div css={styles.cardOuter(getProductColor(examType))} className="card-outer">
          <ProductCardTop productName={productName} productType={productType} examType={examType} />

          <div
            css={[styles.progress, styles.borderTop, spacer.padLR15, spacer.padBT15, bgColor.grey2]}
          >
            <H6 gBold bold color={colors.mediumGrey2} noMargin style={{ fontSize: '18px' }}>
              {progress} ASSIGNED
            </H6>
          </div>
        </div>
      </A>

      <Button wide bgHover={getProductColor(examType)} product>
        See Details
      </Button>
    </Fragment>
  );
};

ProductCardAdminAssigned.propTypes = {
  examType: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  productPageLink: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired
};

export default ProductCardAdminAssigned;
