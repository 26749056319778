/** @jsxImportSource @emotion/react */
import { Fragment, useEffect } from 'react';
import { Router } from 'components/Router';
// import { ROUTE_PATHS } from 'globals/constants';

import Navbar, { useComponentVisible, NavMenuItem, DropdownMenu } from 'components/Navbar';
import NavsContainer from 'hooks/useNavs';

import { ROUTE_PATHS } from 'globals/constants';
import { useLogout, UserInfoContainer } from 'api/auth';
import Layout from 'layouts';
import A from 'components/htmlElements/A';

import UserAccountContextsProvider, {
  useUserAccountContext
} from 'components/EditAccount/userAccountContext';

import EditCandidateAccount from 'components/EditCandidateAccount';

import NotFound from 'pages/NotFound/page';
import CandidateDashboardContainer from 'pages/Candidate/Dashboard/container';
import TestDetailsContainer from 'pages/Candidate/TestDetails/container';
import TestContainer from 'pages/Test/container';
import CandidateCourse from 'pages/Candidate/Course';

import { spacer, colors } from 'styles/utilities';

const CandidateNav = () => {
  const { ref, isComponentVisible, toggleVisibility } = useComponentVisible(false);

  const { openEditCandidateModal } = useUserAccountContext();

  const logout = useLogout();

  const handleEditAccountClick = (event) => {
    event.preventDefault();
    openEditCandidateModal();
  };

  const handleLogout = (event) => {
    event.preventDefault();
    logout();
  };

  return (
    <Fragment>
      <div className="d-flex align-items-center">
        <NavMenuItem text="Home" link="/" />
        <NavMenuItem text="Edit Account" onClick={handleEditAccountClick} link="#" />
        <NavMenuItem account onClick={toggleVisibility} ref={ref} />
      </div>
      <DropdownMenu show={isComponentVisible}>
        <A
          href="#"
          onClick={handleEditAccountClick}
          color={colors.mediumGrey}
          transform="uppercase"
          css={[spacer.padBT10, spacer.padLR10]}
        >
          Edit Account
        </A>
        <A
          href="#"
          onClick={handleLogout}
          color={colors.mediumGrey}
          transform="uppercase"
          css={[spacer.padBT10, spacer.padLR10]}
        >
          Logout
        </A>
      </DropdownMenu>
    </Fragment>
  );
};

const CandidateRoutes = () => {
  const { userIs } = UserInfoContainer.useContainer();
  const logout = useLogout();

  const { navHidden } = NavsContainer.useContainer();

  useEffect(() => {
    if (userIs !== 'candidate') {
      logout();
    }
  }, [logout, userIs]);

  return (
    <UserAccountContextsProvider>
      {!navHidden && (
        <Navbar>
          <CandidateNav />
        </Navbar>
      )}

      <Router>
        <Layout component={CandidateDashboardContainer} path="/" />
        <Layout component={TestDetailsContainer} path={ROUTE_PATHS.candidate.testDetails} />
        <Layout component={TestContainer} path={ROUTE_PATHS.candidate.testFlow} />
        <Layout component={CandidateCourse} path={ROUTE_PATHS.candidate.coursePage} />

        <Layout component={NotFound} default />
      </Router>

      <EditCandidateAccount />
    </UserAccountContextsProvider>
  );
};

export default CandidateRoutes;
