import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

import P from 'components/htmlElements/P';
import { colors, misc, mixins } from 'styles/utilities';

export const StyledInputCSSObject = {
  margin: 0,
  border: `1px solid ${colors.grey2}`,
  borderRadius: `${misc.mediumBorderRadius}`,
  boxShadow: `none`,
  boxSizing: 'border-box',
  backgroundColor: `${colors.white}`,
  width: `100%`,
  WebkitAppearance: 'none',
  outline: 'none',
  lineHeight: 1,
  fontWeight: 'inherit',
  fontSize: '14px',
  color: `${colors.black3}`,
  '&:focus': {
    borderColor: `${colors.primaryLinkActive}`
  },
  '&::-webkit-input-placeholder': {
    color: `${colors.black8}`,
    opacity: 0.9
  },
  '&[disabled]': {
    pointerEvents: 'none',
    background: `rgba(0, 0, 0, 0.07)`
  }
};

const inheritPStyles = P.withComponent('input');
const StyledInput = styled(inheritPStyles, {
  shouldForwardProp: (prop) => isPropValid(prop)
})`
  ${StyledInputCSSObject};
  padding: ${(props) =>
    props.iconPrefix
      ? `${misc.input.paddingVertical} ${parseInt(misc.input.paddingHorizontal) + 12}px`
      : `${misc.input.paddingVertical} ${misc.input.paddingHorizontal}`};
  ${mixins.linkActiveStyles} {
    outline: none;
  }
`;

export default StyledInput;
