/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, Fragment } from 'react';

import Link from 'components/htmlElements/Link';
import A from 'components/htmlElements/A';
import PretestLogo from 'images/logopretest.svg';
import NavsContainer from 'hooks/useNavs';
import CutoffNotice from 'components/CutoffNotice';

import { spacer, bgColor } from 'styles/utilities';
import { UserInfoContainer } from 'api/auth';
import * as styles from './Navbar.styles';

export const useComponentVisible = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const toggleVisibility = () => setIsComponentVisible((s) => !s);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, toggleVisibility };
};

// const Navbar = ({ menuItems, dropdownItems }) => {
const Navbar = ({ children }) => {
  const { isAdmin } = UserInfoContainer.useContainer();

  // const { ref, isComponentVisible, toggleVisibility } = useComponentVisible(false);
  const { updateNavHeight } = NavsContainer.useContainer();

  const primaryNav = React.createRef();
  const primaryNavFaux = React.createRef();

  // Adjusting `faux-nav` height
  const [navHeight, setNavHeight] = useState(0);
  function handleSetNavHeight() {
    setNavHeight(`${primaryNav.current?.clientHeight}px`);

    // Set nav height in unstated-next container which will be used to refer it in other components
    updateNavHeight(primaryNav.current?.clientHeight);
  }
  useEffect(() => {
    handleSetNavHeight();
    window.addEventListener('resize', handleSetNavHeight);
    return () => {
      window.removeEventListener('resize', handleSetNavHeight);
    };
  });

  return (
    <Fragment>
      <div className="faux-nav" ref={primaryNavFaux} style={{ height: navHeight }} />
      <header css={[styles.container, bgColor.black2]} ref={primaryNav}>
        {!isAdmin ? <CutoffNotice /> : null}

        <div css={[styles.innerNav, spacer.padBT10]}>
          <div css={spacer.padL10}>
            <img src={PretestLogo} alt="pretest-logo" />
          </div>
          <div css={styles.right}>
            {children}
            {/* {menuItems.map(({ text, link }, index) => {
              if (index === menuItems.length - 1) {
                return (
                  <div ref={ref} key="acc">
                    <NavMenuItem text={text} link={link} onClick={toggleVisibility} account />
                  </div>
                );
              }
              return <NavMenuItem text={text} link={link} key={text} />;
            })} */}
            {/* <DropdownMenu
              show={isComponentVisible}
              dropdownItems={['Edit account', 'Change Password', 'Logout']}
            /> */}
          </div>
        </div>
      </header>
    </Fragment>
  );
};

Navbar.propTypes = {
  // menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  // dropdownItems: PropTypes.arrayOf(PropTypes.string).isRequired
  children: PropTypes.node.isRequired
};

export const NavMenuItem = React.forwardRef(({ text, link, account, onClick }, ref) => {
  const { displayName } = UserInfoContainer.useContainer();
  const Element = onClick ? A : Link;

  const variableProps = {
    ...(!onClick && { to: link }),
    ...(onClick && { href: link }),
    ...(onClick && { onClick })
  };

  if (account) {
    delete variableProps.to;
    delete variableProps.href;
  }

  return (
    <Element
      ref={ref}
      {...variableProps}
      css={[
        styles.menuItem,
        spacer.padLR15,
        spacer.padT15,
        spacer.padB10,
        spacer.mrBT5,
        spacer.mrLR5
      ]}
      className="text-uppercase"
      getProps={({ isCurrent }) =>
        // the object returned here is passed to the
        // anchor element's props
        ({
          isactive: isCurrent ? 'true' : 'false'
        })
      }
    >
      {account ? (
        <span css={styles.userItem} className="d-flex align-items-center">
          {displayName}
          <span className="caret" css={spacer.mrL5} />
        </span>
      ) : (
        text
      )}
    </Element>
  );
});

NavMenuItem.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  account: PropTypes.bool,
  onClick: PropTypes.func
};

NavMenuItem.defaultProps = {
  text: '',
  account: false,
  onClick: null,
  link: '#'
};

// const dropDownItemStyles = [spacer.padBT10, spacer.padLR10];

export const DropdownMenu = ({ show, children }) => (
  <div css={[styles.dropDownContainer, bgColor.white]} className={`${show ? 'animate' : ''}`}>
    {children}
  </div>
);

DropdownMenu.propTypes = {
  show: PropTypes.bool,
  // dropdownItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired
};

DropdownMenu.defaultProps = {
  show: false
};

export default Navbar;
