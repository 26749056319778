/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';

import { Container, Row, Col } from 'components/Grid';
import P from 'components/htmlElements/P';
import SolutionExplainer from 'components/SolutionExplainer/SolutionExplainer';

import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';

import { InstructionLine } from 'components/Instructions';

import { spacer, colors } from 'styles/utilities';
import * as sharedStyles from 'components/QuestionTypes/shared-components/shared.styles';
import transformImgixUrls, {
  setSrcSet,
  replaceS3withImgix
} from 'components/QuestionTypes/shared-components/transformImgixUrls';
import QuestionsCommonWrapperContainer from 'components/QuestionTypes/shared-components/QuestionsCommonWrapper';
import Latex, { withLatex } from 'components/QuestionTypes/shared-components/Latex';

import * as styles from './PictureImage.styles';
import { answerOptionImageSize } from '../AudioQuestion/AudioQuestion.styles';

const PictureImage = ({ currentQuestion }) => {
  // console.info(currentQuestion);
  const { instructionsText, questionText, answerOptions, solutionExplaination, answer } =
    currentQuestion.pictureImage;
  const { ttAnswers } = currentQuestion;

  const {
    // attemptFinished,
    skippingAllowed,
    showNextButton,
    answersEditableBeforeSubmission,
    updateAnswerPayload,
    isQuestionUsageFinal
  } = TestModuleInfoContainer.useContainer();

  const { attemptFinished, isNoteOrSampleQuestion } =
    QuestionsCommonWrapperContainer.useContainer();

  // Save checked answer options
  const [checkedAnswers, setCheckedAnswers] = useState([]);
  useEffect(
    // This effect simply clears the checkedAnswers on question change. It ensures on question switch the previous state is cleared
    () => () => {
      setCheckedAnswers([]);
    },
    [currentQuestion._id]
  );

  const handleAnswerChange = (event, shortId) => {
    if (event.target.checked) {
      setCheckedAnswers([shortId]);
    }
  };

  // Update `checkedAnswers` if already answered and/or attempt is completed
  useEffect(() => {
    if (ttAnswers && ttAnswers.length) {
      setCheckedAnswers(ttAnswers);
    }

    // Clear the checked answers array on unmount
    // Without this there's a bug which messes up the next question if it's of the same type
    return () => {
      setCheckedAnswers([]);
    };
  }, [ttAnswers]);

  // Handle disabling of answering
  const [answeringDisabled, handleAnsweringDisabled] = useState(false);
  useEffect(() => {
    // If editable and not answered - can answer
    // If not editable and not answered - can answer
    // If not editable and answered - cannot answer
    if (!attemptFinished) {
      if (!ttAnswers) {
        // This means it's not answered
        handleAnsweringDisabled(false);
      } else if (!answersEditableBeforeSubmission) {
        handleAnsweringDisabled(true);
      }
    } else {
      // If attempt is over answering is disabled
      handleAnsweringDisabled(true);
    }

    return () => {
      // Reset state
      handleAnsweringDisabled(!answersEditableBeforeSubmission);
    };
  }, [answersEditableBeforeSubmission, attemptFinished, ttAnswers]);

  // Toggle next button visibility
  useEffect(() => {
    if (!skippingAllowed && !checkedAnswers.length && !isNoteOrSampleQuestion) {
      showNextButton(false);
    } else {
      showNextButton(true);
    }
  }, [checkedAnswers.length, isNoteOrSampleQuestion, showNextButton, skippingAllowed]);

  // Handle updating answer payload
  useEffect(() => {
    updateAnswerPayload({
      questionId: currentQuestion._id,
      answerArray: checkedAnswers
    });
  }, [checkedAnswers, currentQuestion._id, updateAnswerPayload]);

  return (
    <div css={[spacer.mrT30]}>
      <Container>
        <Row>
          <Col>
            {instructionsText && <InstructionLine text={instructionsText} />}

            <div css={[spacer.mrT20, styles.questionContainer]}>
              <div className="question-column">
                <Latex
                  css={[
                    spacer.mrB40,
                    sharedStyles.types,
                    sharedStyles.richTextQuestion,
                    styles.questionContents
                  ]}
                  className="ck-content"
                >
                  {ReactHtmlParser(DOMPurify.sanitize(withLatex(questionText)), {
                    transform: (node) => transformImgixUrls(node, { width: styles.mainImageSize })
                  })}
                </Latex>
              </div>
              <div className="question-column" css={spacer.padL30}>
                {attemptFinished && !ttAnswers?.length && isQuestionUsageFinal && (
                  <P color={colors.red} large>
                    Question not attempted.
                  </P>
                )}

                <div css={styles.answerOptionsWrapper}>
                  {answerOptions &&
                    answerOptions.map((answerOption) => (
                      <label
                        css={styles.answerBlock({
                          isCorrectAnswer:
                            attemptFinished && answer?.includes(answerOption.shortId),
                          checked: checkedAnswers.includes(answerOption.shortId),
                          attemptFinished
                        })}
                        key={answerOption.shortId}
                        htmlFor={answerOption.shortId}
                      >
                        <input
                          type="radio"
                          id={answerOption.shortId}
                          disabled={answeringDisabled}
                          onChange={(event) => handleAnswerChange(event, answerOption.shortId)}
                          checked={checkedAnswers.includes(answerOption.shortId)}
                        />
                        <div className="answer-image">
                          <img
                            src={replaceS3withImgix(answerOption.url)}
                            alt=""
                            srcSet={setSrcSet(answerOption.url, {
                              width: answerOptionImageSize
                            })}
                          />
                        </div>
                      </label>
                    ))}
                </div>
              </div>
            </div>

            {attemptFinished && <SolutionExplainer text={solutionExplaination} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

PictureImage.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

const WithQuestionProvider = (props) => {
  const { questionSettings } = props.currentQuestion.pictureImage;

  return (
    <QuestionsCommonWrapperContainer.Provider
      initialState={{ currentQuestion: props.currentQuestion, questionSettings }}
    >
      <PictureImage {...props} />
    </QuestionsCommonWrapperContainer.Provider>
  );
};
WithQuestionProvider.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

export default WithQuestionProvider;
