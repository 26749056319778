/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useUnassignTestFromCandidate } from 'api/admin';
import { navigate } from 'components/Router';

import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader/ModalHeader';
import Button from 'components/htmlElements/Button';

import { spacer, colors } from 'styles/utilities';
import P from 'components/htmlElements/P';

import SuccessModal from 'components/SuccessModal';
import LoaderOverlay from 'components/LoaderOverlay';
// import * as styles from './UnassignTestConfirmationModal.styles';

const UnassignTestConfirmationModal = ({
  productName,
  isOpen,
  toggle,
  assignmentId,
  candidateId
}) => {
  const { unassignTest, isSuccess, isLoading } = useUnassignTestFromCandidate();

  const handleConfirmation = async () => {
    // candidateId is purely for refetching data once mutation is done
    await unassignTest({ candidateId, assignmentId });
  };

  const handleSuccessModalClick = () => {
    toggle();
    navigate('../', { replace: true }); // This leads back to candidate page
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      {!isSuccess ? (
        <Fragment>
          <div css={[spacer.padBT30, spacer.padLR30]}>
            <ModalHeader header="Unassign Test" />

            <P bold color={colors.mediumGrey} className="no-margin" css={spacer.mrB30}>
              Are you sure to unassign {productName}
            </P>

            <Button wide rounded textCenter onClick={handleConfirmation}>
              Confirm
            </Button>

            {isLoading && <LoaderOverlay />}
          </div>
        </Fragment>
      ) : (
        <SuccessModal
          message={`${productName} has been successfully unassigned`}
          onClick={handleSuccessModalClick}
        />
      )}
    </Modal>
  );
};

UnassignTestConfirmationModal.propTypes = {
  productName: PropTypes.string.isRequired,
  assignmentId: PropTypes.string.isRequired,
  candidateId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default UnassignTestConfirmationModal;
