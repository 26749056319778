/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Link from 'components/htmlElements/Link';
import P from 'components/htmlElements/P';

import { colors } from 'styles/utilities';
import * as styles from './Breadcrumb.styles';

const Breadcrumb = ({ links }) => (
  <Fragment>
    <div css={styles.crumbs}>
      {links.map((val, index) => (
        <div key={val.name} className="d-flex">
          {val.link ? (
            <P as={Link} to={val.link} css={styles.link} color={colors.green} transform="uppercase">
              {index !== 0 ? <span>&nbsp;</span> : null}
              {val.name}&nbsp;
            </P>
          ) : (
            <P as="span" css={styles.link} color={colors.black8}>
              {index !== 0 ? <span>&nbsp;</span> : null}
              {val.name}&nbsp;
            </P>
          )}

          {links.length - 1 !== index ? <span css={styles.arrow}>&gt;</span> : null}
        </div>
      ))}
    </div>
  </Fragment>
);

Breadcrumb.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Breadcrumb;
