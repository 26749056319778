/** @jsxImportSource @emotion/react */
import { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';

import { Container, Row, Col } from 'components/Grid';
import P from 'components/htmlElements/P';
import SolutionExplainer from 'components/SolutionExplainer/SolutionExplainer';
import { InstructionLine } from 'components/Instructions';

import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';

import { spacer, colors } from 'styles/utilities';
import * as sharedStyles from 'components/QuestionTypes/shared-components/shared.styles';
import QuestionsCommonWrapperContainer from 'components/QuestionTypes/shared-components/QuestionsCommonWrapper';

import * as styles from './FillInTheBlanks.styles';

const FillInTheBlanks = ({ currentQuestion }) => {
  // console.info(currentQuestion);
  const {
    instructionsText,
    questionText,
    solutionExplaination,
    wordList
    // answer
  } = currentQuestion.fillInTheBlanks;
  const { ttAnswers } = currentQuestion;

  const {
    // attemptFinished,
    skippingAllowed,
    showNextButton,
    answersEditableBeforeSubmission,
    updateAnswerPayload,
    isQuestionUsageFinal
  } = TestModuleInfoContainer.useContainer();

  const { attemptFinished, isPracticeQuestion } = QuestionsCommonWrapperContainer.useContainer();

  // Save checked answer options
  const [checkedAnswers, setCheckedAnswers] = useState([]);
  useEffect(
    // This effect simply clears the checkedAnswers on question change. It ensures on question switch the previous state is cleared
    () => () => {
      setCheckedAnswers([]);
    },
    [currentQuestion._id]
  );

  // Update `checkedAnswers` if already answered and/or attempt is completed
  useEffect(() => {
    if (ttAnswers && ttAnswers.length) {
      setCheckedAnswers(ttAnswers);
    }

    // Clear the checked answers array on unmount
    // Without this there's a bug which messes up the next question if it's of the same type
    return () => {
      setCheckedAnswers([]);
    };
  }, [ttAnswers]);

  // Handle disabling of answering
  const [answeringDisabled, handleAnsweringDisabled] = useState(false);
  useEffect(() => {
    // If editable and not answered - can answer
    // If not editable and not answered - can answer
    // If not editable and answered - cannot answer
    if (!attemptFinished) {
      if (!ttAnswers) {
        // This means it's not answered
        handleAnsweringDisabled(false);
      } else if (!answersEditableBeforeSubmission) {
        handleAnsweringDisabled(true);
      }
    } else {
      // If attempt is over answering is disabled
      handleAnsweringDisabled(true);
    }

    return () => {
      // Reset state
      handleAnsweringDisabled(!answersEditableBeforeSubmission);
    };
  }, [answersEditableBeforeSubmission, attemptFinished, ttAnswers]);

  // This simply updates `availableInputs` state which is purely used for local state management and UI rendering
  const [availableInputs, updateAvailableInputs] = useState([]);
  useEffect(() => {
    updateAvailableInputs(
      wordList.map((word, index) => ({
        fullWord: word.fullWord,
        answered: !!(checkedAnswers.length && checkedAnswers[index]),
        userInput: checkedAnswers.length && checkedAnswers[index] ? checkedAnswers[index] : ''
      }))
    );

    // On unload clear the availableInputs state
    return () => {
      updateAvailableInputs([]);
    };
  }, [checkedAnswers, wordList]);

  // Handle updating answer payload
  useEffect(() => {
    updateAnswerPayload({
      questionId: currentQuestion._id,
      answerArray: availableInputs.map((word) =>
        word.userInput.length > 0 &&
        word.userInput.trim().length > 0 &&
        word.fullWord.startsWith(word.userInput)
          ? word.fullWord
          : word.userInput
      )
    });
  }, [availableInputs, currentQuestion._id, updateAnswerPayload]);

  // Toggle next button visibility
  useEffect(() => {
    if (!skippingAllowed && availableInputs.length && isQuestionUsageFinal) {
      showNextButton(false);
    } else {
      showNextButton(true);
    }
  }, [availableInputs, isQuestionUsageFinal, showNextButton, skippingAllowed]);

  // Most of FillInTheBlanks question specific logic starts here
  const handleInputChange = (event) => {
    const wordIs = event.target.getAttribute('data-word');
    const firstTwoLetters = wordIs.substring(0, 2);

    const currentInputIndex = event.target.getAttribute('data-fitb-index');
    const nextInput = document.querySelector(
      `input[data-fitb-index="${parseInt(currentInputIndex) + 1}"]`
    );

    // Update input regardless if it's correct or wrong
    // This updates the input
    updateAvailableInputs((prevState) => {
      // eslint-disable-next-line no-unused-vars
      const updatedState = [...prevState];

      // Changing the approach here wherein instead of finding an index (above logic)
      // we find all instances of occuring word and update them. This ensures if there are duplicates, both of them get filled up
      updatedState.map((item) => {
        if (item.fullWord === wordIs) {
          // eslint-disable-next-line no-param-reassign
          item.userInput = event.target.value.toLowerCase();
        }
        return item;
      });

      return updatedState;
    });

    if (event.target.value?.toLowerCase() === firstTwoLetters?.toLowerCase()) {
      updateAvailableInputs((prevState) => {
        // eslint-disable-next-line no-unused-vars
        const updatedState = [...prevState];

        // const wordIndex = updatedState.findIndex((item) => item.fullWord === wordIs);
        // updatedState[wordIndex].answered = true;

        // Changing the approach here wherein instead of finding an index (above logic)
        // we find all instances of occuring word and update them. This ensures if there are duplicates, both of them get filled up
        updatedState
          // .filter((item) => item.fullWord === wordIs)
          .map((item) => {
            if (item.fullWord === wordIs) {
              // eslint-disable-next-line no-param-reassign
              item.answered = true;
            }
            return item;
          });

        return updatedState;
      });

      // Focus on next input
      if (nextInput) {
        nextInput.focus();
      }

      // eslint-disable-next-line no-param-reassign
      event.target.disabled = true;
      // eslint-disable-next-line no-param-reassign
      event.target.readonly = true;
    } else {
      // This will reset the answered key
      updateAvailableInputs((prevState) => {
        // eslint-disable-next-line no-unused-vars
        const updatedState = [...prevState];

        // Changing the approach here wherein instead of finding an index (above logic)
        // we find all instances of occuring word and update them. This ensures if there are duplicates, both of them get filled up
        updatedState.map((item) => {
          if (item.fullWord === wordIs) {
            // eslint-disable-next-line no-param-reassign
            item.answered = false;
          }
          return item;
        });

        return updatedState;
      });
    }
  };

  const handleInputKeydown = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 65) {
      event.preventDefault();
    }
  };

  // eslint-disable-next-line consistent-return
  function transform(node) {
    if (node.type === 'tag' && node.name === 'span' && node.attribs['data-response-plugin-value']) {
      const wordIndex = availableInputs.findIndex(
        (item) => item.fullWord === node.attribs['data-response-plugin-value']
      );

      const isUserInputEqualToAnswer =
        availableInputs[wordIndex] &&
        availableInputs[wordIndex]?.fullWord.substring(0, 2) ===
          availableInputs[wordIndex]?.userInput.substring(0, 2);

      const isWordCorrectlyAnswered =
        availableInputs[wordIndex]?.answered && isUserInputEqualToAnswer;

      // const isCorrect = isPracticeQuestion
      //   ? true
      //   : checkedAnswers.length
      //   ? checkedAnswers[wordIndex]
      //   : false;
      const isCorrect = (() => {
        if (isPracticeQuestion) {
          return true;
        }

        if (isWordCorrectlyAnswered) {
          return true;
        }

        if (checkedAnswers.length && isWordCorrectlyAnswered) {
          return checkedAnswers[wordIndex];
        }

        return false;
      })();

      return (
        <Fragment key={`${node.attribs['data-response-plugin-value']}-${wordIndex}`}>
          {!isWordCorrectlyAnswered && !answeringDisabled ? (
            <input
              key={`${node.attribs['data-response-plugin-value']}-${wordIndex}`}
              type="text"
              autoComplete="off"
              data-word={node.attribs['data-response-plugin-value']}
              onChange={handleInputChange}
              onKeyDown={handleInputKeydown}
              css={styles.input}
              data-fitb-index={wordIndex}
              value={availableInputs[wordIndex]?.userInput}
            />
          ) : (
            <span css={styles.answer({ isCorrect })}>
              {isWordCorrectlyAnswered
                ? node.attribs['data-response-plugin-value']
                : availableInputs[wordIndex]?.userInput}
            </span>
          )}
        </Fragment>
      );
    }
  }

  return (
    <div css={[spacer.mrT30]}>
      <Container>
        <Row>
          <Col>
            {instructionsText && <InstructionLine text={instructionsText} />}

            <div
              css={[
                spacer.mrB40,
                sharedStyles.types,
                sharedStyles.richTextQuestion,
                styles.questionWrapper
              ]}
              className="ck-content"
            >
              {ReactHtmlParser(DOMPurify.sanitize(questionText), { transform })}
            </div>

            {attemptFinished && !ttAnswers?.length && isQuestionUsageFinal && (
              <P color={colors.red} large>
                Question not attempted.
              </P>
            )}

            {attemptFinished && <SolutionExplainer text={solutionExplaination} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

FillInTheBlanks.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

const WithQuestionProvider = (props) => {
  const { questionSettings } = props.currentQuestion.fillInTheBlanks;

  return (
    <QuestionsCommonWrapperContainer.Provider
      initialState={{ currentQuestion: props.currentQuestion, questionSettings }}
    >
      <FillInTheBlanks {...props} />
    </QuestionsCommonWrapperContainer.Provider>
  );
};
WithQuestionProvider.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

export default WithQuestionProvider;
