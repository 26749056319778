/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, Fragment } from 'react';
import Countdown from 'react-countdown';
import PropTypes from 'prop-types';

import { useQueryClient } from 'react-query';
import { useInterval } from 'react-use';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';
import AssignedTestContainer from 'hooks/useTest';
import { useUpdateAttemptModuleTimer } from 'api/test';

import P from 'components/htmlElements/P';

import { spacer, colors } from 'styles/utilities';
import Icon from 'components/Icon/Icon';
import * as styles from './TestTimer.styles';

const renderer = ({ hours, minutes, seconds, completed, api }, color, isebLayout, hideSeconds) => (
  <Fragment>
    {!isebLayout && (
      <div>
        <P color={minutes > 0 ? color : colors.red} noMargin>
          Time {completed || api.isPaused() ? 'paused' : 'left'}
        </P>
        {!completed && !api.isPaused() && (
          <P color={minutes > 0 ? color : colors.red} noMargin gBold>
            {hours > 0 ? `${hours} ${hours > 1 ? 'hours' : 'hour'}` : ''}{' '}
            {minutes > 0 ? `${minutes} minutes` : ''} {minutes <= 0 && hideSeconds && '1 minute'}
            {!hideSeconds && `${seconds} seconds`}
          </P>
        )}
      </div>
    )}

    {isebLayout && (
      <div>
        {/* <P color={minutes > 0 ? color : colors.red} noMargin>
          Time {completed || api.isPaused() ? 'paused' : 'left'}
        </P> */}
        {!completed && !api.isPaused() && (
          <P color={minutes > 0 ? color : colors.red} instruction noMargin gBold>
            {hours > 0 ? `${'0'.concat(hours).slice(-2)}:` : ''}
            {minutes > 0 ? `${'0'.concat(minutes).slice(-2)}:` : ''}
            {'0'.concat(seconds).slice(-2)}
          </P>
        )}
      </div>
    )}
  </Fragment>
);

const TestTimer = ({
  time,
  pauseTimer,
  timerOnComplete,
  key,
  updatePageTitleTimer,
  color,
  isebLayout,
  hideSeconds
}) => {
  const [countdownApi, updateCountdownApi] = useState();
  const setRef = (countdown) => {
    if (countdown) {
      updateCountdownApi(countdown.getApi());
    }
  };

  // Handling timers
  const { attemptFinished, testModule, updateTimeLimit, timerPaused } =
    TestModuleInfoContainer.useContainer();
  const { testInfo, testAssignmentInfo, isTestAtSection, testHubAttemptToken } =
    AssignedTestContainer.useContainer();
  const { updateAttemptTimer } = useUpdateAttemptModuleTimer(
    testInfo._id,
    testAssignmentInfo.id,
    testHubAttemptToken
  );
  // When response from api call, update timeLimit state
  // When response is error or 0, set a module completion state and submit the test and open preview
  // Pause the timer when timerPaused received is true
  // If timer goes below 0
  useInterval(
    async () => {
      const payload = {};
      if (isTestAtSection) {
        payload.sectionId = testModule._id;
      } else {
        payload.subSectionId = testModule._id;
      }
      const { response } = await updateAttemptTimer(payload);
      const remainingTime = response?.data?.timeLeft;

      // if (remainingTime) {
      //   updateTimeLimit(remainingTime);
      // }

      if (remainingTime <= 0 || response?.error?.errorCode === 'TIME_ELAPSED') {
        // If timer runs to 0 or below set it to 0
        updateTimeLimit(0);

        // handleTimeOver();
        await timerOnComplete();
      }
    },
    !timerPaused && !attemptFinished ? 3000 : null
  );

  const queryClient = useQueryClient();
  useInterval(
    // This fetches the attempt info every minute to sync up the timer with server
    () => {
      queryClient.invalidateQueries('getTestAttempt', { testAttemptId: testInfo._id });
    },
    !timerPaused && !attemptFinished ? 60000 : null
  );
  useEffect(() => {
    // Extra step to refetch attempt when timer gets paused
    if (timerPaused) {
      queryClient.invalidateQueries('getTestAttempt', { testAttemptId: testInfo._id });
    }
  }, [queryClient, testInfo._id, timerPaused]);
  //

  useEffect(() => {
    if (countdownApi) {
      if (pauseTimer) {
        countdownApi.pause();
      } else {
        countdownApi.start();
      }
    }
  }, [countdownApi, pauseTimer]);

  const handleOnTick = ({ total }) => {
    // updatePageTitleTimer(total / 1000);
  };

  // @ref: https://github.com/ndresx/react-countdown/issues/64#issuecomment-559270873
  const [date] = useState(Date.now() + time * 1000);

  return (
    <div
      className={`d-flex text-uppercase ${isebLayout && 'isebLayout-timer'}`}
      css={styles.timerContainer}
    >
      <div>
        <Icon color={color} icon="timer" size={25} />
      </div>
      <div css={spacer.padL10}>
        <Countdown
          ref={setRef}
          key={key}
          date={date}
          onComplete={timerOnComplete}
          onTick={handleOnTick}
          renderer={(props) => renderer(props, color, isebLayout, hideSeconds)}
          autoStart={false}
          color={color}
        />
      </div>
    </div>
  );
};

TestTimer.propTypes = {
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pauseTimer: PropTypes.bool,
  timerOnComplete: PropTypes.func,
  updatePageTitleTimer: PropTypes.func,
  key: PropTypes.string,
  color: PropTypes.string,
  isebLayout: PropTypes.bool,
  hideSeconds: PropTypes.bool
};

TestTimer.defaultProps = {
  pauseTimer: true,
  timerOnComplete: () => {},
  updatePageTitleTimer: () => {},
  key: 'test-timer',
  color: colors.white,
  isebLayout: false,
  hideSeconds: false
};

export default React.memo(TestTimer);
