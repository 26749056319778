/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { breakpoints, colors, mixins } from 'styles/utilities';

export const candidatesTableSection = css`
  width: 100%;
  padding: 0px 15px;
`;

export const assignProductWrapper = css`
  flex: 1 0 490px;
  max-width: 570px;

  ${breakpoints({
    marginBottom: ['20px', '', '', '', '0px']
  })};
`;

export const schoolGroupItemWrapper = ({ selected }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 15px 10px 25px;
  border-bottom: 2px solid ${colors.grey2};
  ${selected && `background-color: ${colors.cream}`};

  &:hover {
    background-color: ${!selected && mixins.toRgba(colors.cream, 0.4)};
  }
`;

export const groupToggle = css`
  text-align: right;

  ${mixins.transition()};
  &:hover {
    color: ${colors.primaryLinkHover};
  }
`;

export const groupName = css`
  text-decoration: underline;
  cursor: pointer;
  ${mixins.transition()};

  &:hover {
    color: ${colors.primaryAnchorDefaultColor};
  }
`;

export const tooltipRow = css`
  margin-top: 0px;
  margin-bottom: 0px;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

export const editIcon = css`
  .edit-icon {
    cursor: pointer;
    path {
      ${mixins.transition()};
    }

    &:hover {
      path {
        fill: ${colors.green} !important;
      }
    }
  }
`;
