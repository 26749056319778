/** @jsxImportSource @emotion/react */

// import { useState } from 'react';
import PropTypes from 'prop-types';

import P from 'components/htmlElements/P';
import { spacer } from 'styles/utilities';
import greyImage from '../../static/images/school-group-grey.png';
import * as styles from './SchoolGroupItem.styles';

const SchoolGroupItem = ({ groupName, noOfStudents, handleClick }) => (
  <div
    css={[styles.container]}
    onClick={() => handleClick()}
    onKeyDown={() => handleClick()}
    role="button"
    tabIndex={0}
  >
    <img src={greyImage} alt="school-group" width={44} height={44} css={spacer.mrR10} />

    <div css={styles.content}>
      <P noMargin className="animate group" gBold>
        {groupName}
      </P>
      <P noMargin className="animate students" gBook tiny css={spacer.mrT5}>
        {noOfStudents} students
      </P>
    </div>
  </div>
);
SchoolGroupItem.propTypes = {
  groupName: PropTypes.string.isRequired,
  noOfStudents: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default SchoolGroupItem;
