/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors } from 'styles/utilities';

export const answerInputWrapper = css`
  display: flex;
  justify-content: center;

  .answerInput {
    border: 1px solid ${colors.blackTransparent25};
    max-width: 25%;
    min-width: 300px;
  }
`;
