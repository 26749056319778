import { useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@emotion/react';
import TestSkeleton from 'components/TestSkeleton';

import { useNavigate } from 'components/Router';
import useShowTestResult from 'hooks/useShowTestResult';
import { useStartTestAttempt } from 'api/test';
import AssignedTestContainer from 'hooks/useTest';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';
import MetaWrapper from 'components/MetaWrapper';
import { secondsToHmsNiceName } from 'utils/secondsToHms';

import { colors } from 'styles/utilities';
import { products } from 'globals/constants';
// import getProductColor from 'components/ProductCard/helpers';

const testTheme = {
  cat: {
    colors: {
      primary: products.CAT.color
    }
  },
  pretest: {
    colors: {
      primary: colors.teal
    }
  },
  ukiset: {
    colors: {
      primary: products.UKISET.color
    }
  },
  cem: {
    colors: {
      primary: products.CEM.color
    }
  },
  default: {
    colors: {
      primary: colors.green
    }
  }
};

const TestPage = ({ hasExtraTime }) => {
  const { testInfo, testAssignmentInfo, isTestAtSection } = AssignedTestContainer.useContainer();
  const { testModule, timeLimit, attemptFinished, timerPaused, clozeQuestionType } =
    TestModuleInfoContainer.useContainer();

  const { startAttempt } = useStartTestAttempt(testInfo._id, testAssignmentInfo.id);

  const [pageTitleTimer, updatePageTitleTimer] = useState(timeLimit);

  // Is used for resetting the navigation pointer when an attempt is started
  // A side effect of resetting a module (from admin acc.) is that the nav index remains as is
  // and the user ends up starting the subsequent attempt from their browser's localstorage's saved nav index
  // TODO: Yet to implement this
  const [isStartingAttempt, toggleIsStartingAttempt] = useState(false);
  const [showTestSkeleton, toggleShowTestSkeleton] = useState(false);

  // Start the attempt if it's not started yet
  useEffect(() => {
    async function attemptStartFn() {
      if (!testModule?.timerData?.startedAt) {
        const payload = {};
        if (isTestAtSection) {
          payload.sectionId = testModule._id;
        } else {
          payload.subSectionId = testModule._id;
        }

        if (hasExtraTime) {
          payload.startWithExtraTime = true;
        }

        toggleIsStartingAttempt(true);

        await startAttempt(payload);

        toggleShowTestSkeleton(true);
      } else {
        toggleShowTestSkeleton(true);
      }
    }

    if (testModule) {
      attemptStartFn();
    }
  }, [hasExtraTime, isTestAtSection, startAttempt, testModule]);

  // Go back to the previous page if user's results are deferred to be shown
  const navigate = useNavigate();
  const { showResult } = useShowTestResult({
    endedAt: testModule?.timerData?.endedAt
  });

  useEffect(() => {
    if (attemptFinished && !showResult) {
      navigate('./');
    }
  }, [attemptFinished, navigate, showResult]);

  return (
    <ThemeProvider
      theme={testTheme[testAssignmentInfo.wpMeta?.categories[0].slug] || testTheme.default}
    >
      {testModule && showTestSkeleton && (
        <Fragment>
          <MetaWrapper
            title={
              attemptFinished
                ? testAssignmentInfo.wpMeta.name
                : timerPaused && !clozeQuestionType.isCloze
                ? 'Paused'
                : !clozeQuestionType.isCloze
                ? secondsToHmsNiceName(pageTitleTimer)
                : 'Cloze'
            }
          />
          <TestSkeleton
            updatePageTitleTimer={updatePageTitleTimer}
            isStartingAttempt={isStartingAttempt}
          />
        </Fragment>
      )}
    </ThemeProvider>
  );
};

TestPage.propTypes = {
  hasExtraTime: PropTypes.bool.isRequired
};

export default TestPage;
