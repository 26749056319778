/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors, mixins } from 'styles/utilities';

const fixedBoxSize = '60px';

export const wrapper = ({ fixedSize, type }) => css`
  padding: 15px;
  margin: 0;
  background-color: #ccf3e1;
  border: 2px solid transparent;
  display: inline-block;
  cursor: grab;
  user-select: none;

  ${fixedSize &&
  css`
    position: relative;
    width: ${fixedBoxSize};
    height: ${fixedBoxSize};
    > * {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `}
  ${type === 'circle' &&
  css`
    border-radius: 50%;
  `}
`;

export const placeholder = css`
  ${
    '' /* border: 2px dashed ${colors.cream};
  display: inline-block;
  pointer-events: none; */
  }
  .wordbox {
    pointer-events: none;
    border: 2px dashed ${colors.cream};
    background-color: transparent;
    > * {
      opacity: 0;
    }
  }
`;

export const questionWordBox = ({ isOver }) => css`
  position: relative;
  display: inline-block;
  width: ${fixedBoxSize};
  height: ${fixedBoxSize};

  .wordbox {
    background-color: ${isOver ? colors.lightYellow : colors.grey2};
    cursor: default;
    ${mixins.transition()};

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .box-contents {
    opacity: ${isOver ? 0 : 1};
    ${
      '' /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    }
  }
`;
