/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Link from 'components/htmlElements/Link';
import Button from 'components/htmlElements/Button';
import { spacer, bgColor } from 'styles/utilities';
import StatsItem from 'components/StatsItem';
import { getProductColor, ProductCardTop } from '../helpers';

import * as styles from '../ProductCard.styles';

const ProductCardWithStats = (props) => {
  const { examType, productType, productPageLink, productName, stats } = props;

  return (
    <Fragment>
      <Link to={productPageLink}>
        <div css={styles.cardOuter(getProductColor(examType))} className="card-outer">
          <ProductCardTop productName={productName} productType={productType} examType={examType} />

          <div css={[styles.borderTop, styles.stats, bgColor.grey2]}>
            {stats.map(({ stat, value }) => (
              <div css={[styles.cardStatItem, spacer.padLR15, spacer.padBT10]}>
                <StatsItem stat={stat} value={value} />
              </div>
            ))}
          </div>
        </div>
      </Link>

      <Button wide bgHover={getProductColor(examType)} product>
        {productType === 'courses'
          ? 'Edit course'
          : productType === 'skill practice'
          ? 'Edit Skill Practice'
          : 'Edit Test'}
      </Button>
    </Fragment>
  );
};

ProductCardWithStats.propTypes = {
  examType: PropTypes.string.isRequired,
  productType: PropTypes.string,
  productPageLink: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  stats: PropTypes.arrayOf(PropTypes.object).isRequired
};

ProductCardWithStats.defaultProps = {
  productType: 'tests'
};

// dummy data format to pass into stats card
// const stats = [
//   { stat: 'Schools', value: '19' },
//   { stat: 'Parents', value: '1650' },
//   { stat: 'Copies', value: '7317' }
// ];

export default ProductCardWithStats;
