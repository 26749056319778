/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { breakpoints, colors } from 'styles/utilities';

export const appletContainer = css`
  width: 100%;
  max-width: 2000px;
  margin: auto;

  .wrapper {
    ${breakpoints({
      flexDirection: ['column', 'column', '', 'row', '']
    })};
  }
`;

export const questionContainer = css`
  flex-grow: 1;
  ${breakpoints({
    width: ['100%', '', '', '70%', '']
  })};
`;

export const appletWrapper = css`
  max-width: 100%;
`;

export const answerInputWrapper = css`
  ${breakpoints({
    width: ['100%', '', '', '30%', '']
  })};

  .geogebraQuestion-text {
    font-size: 21px;
  }

  .answerInput {
    max-width: 300px;
    padding: 12px;
    font-size: 16px;
    line-height: 1.5;
    border: 1px solid ${colors.mediumGrey2};

    &:focus {
      border-color: ${colors.primaryLinkActive};
    }
  }
`;
