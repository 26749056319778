import {
  Link as Link_,
  Router,
  Location,
  navigate,
  useNavigate,
  useLocation,
  Redirect,
  useParams,
  LocationProvider,
  createHistory,
  globalHistory
} from '@reach/router';
// import StyledNavLink from 'components/htmlElements/StyledNavLink';

// const Link = StyledNavLink.withComponent(Link_);
const UnstyledLink = Link_;

export {
  Router,
  Location,
  navigate,
  useNavigate,
  useLocation,
  Redirect,
  UnstyledLink,
  useParams,
  LocationProvider,
  createHistory,
  globalHistory
};
