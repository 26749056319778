import React from 'react';
import { render } from 'react-dom';

// import 'utils/wdyr';

// Sentry & Logrocket logging
import 'utils/logging';

import App from './App';

render(<App />, document.querySelector('#app'));
