/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import facepaint from 'facepaint';
import { typography, mixins, misc } from 'styles/utilities';

import 'styles/ck-content-styles.css';

const breakPoints = facepaint([mixins.respondTo(misc.viewport['d-vp'])]);

export const imagesSizeInQuestionText = 1110;

export const types = css`
  ${breakPoints({
    fontSize: ['14px', typography.baseSizes.epsilon]
  })};
  line-height: 120%;
`;

export const richTextQuestion = css`
  ${'' /* Reset styles coming from rich text editor */}
  ${
    '' /* * {
    background-color: transparent !important;
  } */
  }
  img {
    max-width: 100%;
  }
`;

export const verticalQuestionLayout = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;

  .section-answer-options {
    align-self: flex-end;
    align-items: flex-end;
    display: flex;
    justify-content: center;
    flex-grow: 2;
    flex-wrap: no-wrap;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    > * {
      flex: 1 1 100%;
    }
  }

  &.attempt-finished {
    height: auto;
    .section-answer-options {
      flex-grow: unset;
      margin: 40px 0;
    }
  }
`;
