/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
// import { useNavigate } from 'components/Router/Router';
import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import store from 'store';

const useId = () => {
  const [schoolId, setSchoolId] = useState(store.get('schoolId'));
  const [parentId, setParentId] = useState(store.get('parentId'));
  const [childId, setChildId] = useState(store.get('childId'));
  useEffect(() => {
    if (childId === null) {
      setChildId(store.get('childId'));
    }
  }, [childId]);
  useEffect(() => {
    if (schoolId === null) {
      setSchoolId(store.get('schoolId'));
    }
  }, [schoolId]);
  useEffect(() => {
    if (parentId === null) {
      setSchoolId(store.get('parentId'));
    }
  }, [parentId]);
  return {
    schoolId,
    setSchoolId,
    parentId,
    setParentId,
    childId,
    setChildId
  };
};

// eslint-disable-next-line import/prefer-default-export
export const IdContainer = createContainer(useId);
