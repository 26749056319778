// Base spacings: [20, 15, 10, 5, 0]
// Responsive spacings: [120, 80, 60, 40, 30]
const spacings = {
  base: {
    0: 0,
    1: 1,
    3: 3,
    5: 5,
    10: 10,
    15: 15,
    20: 20
  },
  responsive: {
    30: {
      'f-vp': 30,
      'e-vp': 30,
      'd-vp': 15,
      'c-vp': 15,
      'max-c-vp': 15
    },
    40: {
      'f-vp': 40,
      'e-vp': 40,
      'd-vp': 20,
      'c-vp': 20,
      'max-c-vp': 20
    },
    60: {
      'f-vp': 60,
      'e-vp': 60,
      'd-vp': 30,
      'c-vp': 30,
      'max-c-vp': 30
    },
    80: {
      'f-vp': 80,
      'e-vp': 80,
      'd-vp': 40,
      'c-vp': 40,
      'max-c-vp': 40
    }
  }
};

export default spacings;
