/**
 *
 * Asynchronously loads the component for CandidateSidebar
 *
 */

import loadable from 'utils/loadable';

export default loadable(() =>
  import('./CandidateSidebar' /* webpackChunkName: "CandidateSidebar" */)
);
