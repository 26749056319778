/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types';

import { colors } from 'styles/utilities';
import * as styles from './ProgressSvg.styles';

const ProgressSvg = ({ size, progress }) => {
  // refer : https://sparkbox.com/foundry/how_to_code_an_SVG_pie_chart
  const convertToPiePercentage = (val) => {
    if (progress > 0) {
      const percent = val * 100;
      return (percent * 31.4) / 100 - 0.1;
    }
    return 0;
  };

  // if complete progress
  if (progress === 1) {
    return (
      <div css={styles.relativeDiv}>
        <span css={styles.pieFull} className={size > 32 ? 'pie-full' : 'pie-full small'}>
          <svg height={size} width={size} viewBox="0 0 20 20">
            <circle r="10" cx="10" cy="10" fill={colors.green} />
          </svg>
        </span>
      </div>
    );
  }

  return (
    <svg height={size} width={size} viewBox="0 0 20 20">
      <circle r="10" cx="10" cy="10" fill={colors.grey4} />
      <circle
        r="5"
        cx="10"
        cy="10"
        fill="transparent"
        stroke={colors.green}
        strokeWidth="10"
        strokeDasharray={`${convertToPiePercentage(progress)} 31.4`}
        transform="rotate(-90) translate(-20)"
      />
    </svg>
  );
};

ProgressSvg.propTypes = {
  size: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired
};

export default ProgressSvg;
