/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';

import { Container, Row, Col } from 'components/Grid';
import P from 'components/htmlElements/P';
import SolutionExplainer from 'components/SolutionExplainer/SolutionExplainer';
import AnswerOption from 'components/QuestionTypes/shared-components/AnswerOption';

import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';

import { InstructionLine } from 'components/Instructions';
import { arrayPush, arrayPop } from 'utils/arrayPushPop';

import { spacer, colors } from 'styles/utilities';
import * as sharedStyles from 'components/QuestionTypes/shared-components/shared.styles';
import transformImgixUrls from 'components/QuestionTypes/shared-components/transformImgixUrls';

import QuestionsCommonWrapperContainer from 'components/QuestionTypes/shared-components/QuestionsCommonWrapper';
import Latex, { withLatex } from 'components/QuestionTypes/shared-components/Latex';

const Text = ({ currentQuestion }) => {
  const { instructionsText, questionText, answerOptions, solutionExplaination, answer } =
    currentQuestion.text;
  const { ttAnswers } = currentQuestion;

  const {
    // attemptFinished,
    skippingAllowed,
    showNextButton,
    answersEditableBeforeSubmission,
    updateAnswerPayload,
    isQuestionUsageFinal
  } = TestModuleInfoContainer.useContainer();

  const { attemptFinished, isNoteOrSampleQuestion } =
    QuestionsCommonWrapperContainer.useContainer();

  const [maximumSelections, updateMaximumSelections] = useState(0);
  useEffect(() => {
    updateMaximumSelections(answerOptions.filter((opt) => opt.isCorrect).length);
  }, [answerOptions]);

  // Save checked answer options
  const [checkedAnswers, setCheckedAnswers] = useState([]);
  useEffect(
    // This effect simply clears the checkedAnswers on question change. It ensures on question switch the previous state is cleared
    () => () => {
      setCheckedAnswers([]);
    },
    [currentQuestion._id]
  );

  const handleAnswerChange = (event, shortId) => {
    if (event.target.checked) {
      if (checkedAnswers.length < maximumSelections) {
        const updatedAnswersArray = arrayPush([...checkedAnswers], shortId); // Spread the array otherwise it will mutate it
        setCheckedAnswers(updatedAnswersArray);
      } else {
        setCheckedAnswers((prevState) => {
          // This replaces the first instance
          const updatedState = [...prevState];
          updatedState.pop();
          updatedState.unshift(shortId);
          return updatedState;
        });
      }
    } else {
      const updatedAnswersArray = arrayPop([...checkedAnswers], shortId); // Spread the array otherwise it will mutate it
      setCheckedAnswers(updatedAnswersArray);
    }
  };

  // Update `checkedAnswers` if already answered and/or attempt is completed
  useEffect(() => {
    if (ttAnswers && ttAnswers.length) {
      setCheckedAnswers(ttAnswers);
    }

    // Clear the checked answers array on unmount
    // Without this there's a bug which messes up the next question if it's of the same type
    return () => {
      setCheckedAnswers([]);
    };
  }, [ttAnswers]);

  // Handle disabling of answering
  const [answeringDisabled, handleAnsweringDisabled] = useState(false);
  useEffect(() => {
    // If editable and not answered - can answer
    // If not editable and not answered - can answer
    // If not editable and answered - cannot answer
    if (!attemptFinished) {
      if (!ttAnswers) {
        // This means it's not answered
        handleAnsweringDisabled(false);
      } else if (!answersEditableBeforeSubmission) {
        handleAnsweringDisabled(true);
      }
    } else {
      // If attempt is over answering is disabled
      handleAnsweringDisabled(true);
    }

    return () => {
      // Reset state
      handleAnsweringDisabled(!answersEditableBeforeSubmission);
    };
  }, [answersEditableBeforeSubmission, attemptFinished, ttAnswers]);

  // Toggle next button visibility
  useEffect(() => {
    if (
      !skippingAllowed &&
      checkedAnswers.length !== maximumSelections &&
      !isNoteOrSampleQuestion
    ) {
      showNextButton(false);
    } else {
      showNextButton(true);
    }
  }, [
    checkedAnswers.length,
    isNoteOrSampleQuestion,
    maximumSelections,
    showNextButton,
    skippingAllowed
  ]);

  // Handle updating answer payload
  useEffect(() => {
    updateAnswerPayload({
      questionId: currentQuestion._id,
      answerArray: checkedAnswers
    });
  }, [checkedAnswers, currentQuestion._id, updateAnswerPayload]);

  return (
    <div css={[spacer.padT30]} className={`${!attemptFinished ? 'h-100' : ''}`}>
      <Container className="h-100">
        <Row className="h-100">
          <Col className="h-100">
            <div
              css={sharedStyles.verticalQuestionLayout}
              className={`${attemptFinished ? 'attempt-finished' : ''}`}
            >
              {instructionsText && <InstructionLine text={instructionsText} />}

              <Latex
                css={[
                  spacer.mrT20,
                  spacer.mrB40,
                  sharedStyles.types,
                  sharedStyles.richTextQuestion
                ]}
                className="ck-content"
              >
                {ReactHtmlParser(DOMPurify.sanitize(withLatex(questionText)), {
                  transform: (node) =>
                    transformImgixUrls(node, { width: sharedStyles.imagesSizeInQuestionText })
                })}
              </Latex>

              <div className="section-answer-options">
                {answerOptions &&
                  answerOptions.map((answerOption, index) => (
                    <AnswerOption
                      key={answerOption.shortId}
                      html={answerOption.text}
                      shortId={answerOption.shortId}
                      type="box"
                      index={index}
                      disabled={answeringDisabled}
                      onChange={handleAnswerChange}
                      checked={checkedAnswers.includes(answerOption.shortId)}
                      isCorrectAnswer={attemptFinished && answer?.includes(answerOption.shortId)}
                      attemptFinished={attemptFinished}
                    />
                  ))}
              </div>
            </div>

            {attemptFinished && !ttAnswers?.length && isQuestionUsageFinal && (
              <P color={colors.red} large>
                Question not attempted.
              </P>
            )}

            {attemptFinished && <SolutionExplainer text={solutionExplaination} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Text.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

const WithQuestionProvider = (props) => {
  const { questionSettings } = props.currentQuestion.text;

  return (
    <QuestionsCommonWrapperContainer.Provider
      initialState={{ currentQuestion: props.currentQuestion, questionSettings }}
    >
      <Text {...props} />
    </QuestionsCommonWrapperContainer.Provider>
  );
};
WithQuestionProvider.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

export default WithQuestionProvider;
