/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors, misc, mixins } from 'styles/utilities';

const outlineColor = colors.blackTransparent10;
const innerColor = '#EBFAF1';
const arrowSize = 10;
const outlineWidth = 1;
const wrapperMarginSize = arrowSize / 2 + outlineWidth;

export const arrow = css`
  div[data-popper-placement^='top'] & {
    bottom: 0px;
  }

  div[data-popper-placement^='right'] & {
    left: 0px;
  }

  div[data-popper-placement^='bottom'] & {
    top: 0px;
  }

  div[data-popper-placement^='left'] & {
    right: 0px;
  }

  &:before {
    content: '';
    width: ${arrowSize}px;
    height: ${arrowSize}px;
    transform: translate(-50%, -50%) rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    border-style: solid;
    border-width: ${outlineWidth}px;
    background: ${innerColor};
  }

  div[data-popper-placement^='top'] &:before {
    border-color: transparent ${outlineColor} ${outlineColor} transparent;
  }

  div[data-popper-placement^='right'] &:before {
    border-color: transparent transparent ${outlineColor} ${outlineColor};
  }

  div[data-popper-placement^='bottom'] &:before {
    border-color: ${outlineColor} transparent transparent ${outlineColor};
  }

  div[data-popper-placement^='left'] &:before {
    border-color: ${outlineColor} ${outlineColor} transparent transparent;
  }
`;

export const tooltipWrapper = css`
  background: ${innerColor};
  max-width: 270px;
  border-radius: ${misc.mediumBorderRadius};

  &[data-popper-placement^='top'] {
    margin-bottom: ${wrapperMarginSize}px;
  }

  &[data-popper-placement^='right'] {
    margin-left: ${wrapperMarginSize}px;
  }

  &[data-popper-placement^='bottom'] {
    margin-top: ${wrapperMarginSize}px;
  }

  &[data-popper-placement^='left'] {
    margin-right: ${wrapperMarginSize}px;
  }

  box-shadow: 0px 0px 6px ${colors.blackTransparent10};
  border-style: solid;
  border-width: ${outlineWidth}px;
  border-color: ${outlineColor};
`;

export const tooltipState = ({ isVisible }) => css`
  padding: 15px;
  ${mixins.transition()};

  ${isVisible
    ? css`
        opacity: 1;
        /* pointer-events: all; */
        visibility: visible;
        z-index: ${misc.modalZindex};
      `
    : css`
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        z-index: -${misc.modalZindex};
      `}
`;
