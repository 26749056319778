import React from 'react';
import { Router } from 'components/Router';
import { ROUTE_PATHS } from 'globals/constants';
import ScrollToTop from 'utils/ScrollToTop';

// Pages
import LoginContainer from 'pages/Login/container';

import NotFound from 'pages/NotFound/page';

import Layout from 'layouts';
import { UserInfoContainer } from 'api/auth';
// import TesthubPreviewContainer from 'pages/TesthubPreviews';

import loadable from 'utils/loadable';
import ParentRoutes from './parentRoutes';
import CandidateRoutes from './candidateRoutes';
import SchoolRoutes from './schoolRoutes';

const AdminRoutes = loadable(() => import('./adminRoutes' /* webpackChunkName: "adminRoutes" */));
// const ParentRoutes = loadable(() =>
//   import('./parentRoutes' /* webpackChunkName: "parentRoutes" */)
// );
// const CandidateRoutes = loadable(() =>
//   import('./candidateRoutes' /* webpackChunkName: "candidateRoutes" */)
// );
// const SchoolRoutes = loadable(() =>
//   import('./schoolRoutes' /* webpackChunkName: "schoolRoutes" */)
// );

// To disable scroll position see -> https://github.com/reach/router/issues/242

const Routes = () => {
  const { userIs } = UserInfoContainer.useContainer();

  return (
    <Router className="main">
      <ScrollToTop path={ROUTE_PATHS.home}>
        {userIs === 'administrator' && <AdminRoutes path="/*" />}
        {userIs === 'parent' && <ParentRoutes path="/*" />}
        {userIs === 'school' && <SchoolRoutes path="/*" />}
        {userIs === 'candidate' && <CandidateRoutes path="/*" />}

        {/* <TesthubPreviewContainer path="/testhub-preview/*" /> */}

        <LoginContainer path={ROUTE_PATHS.login} />
        <Layout component={NotFound} default />
      </ScrollToTop>
    </Router>
  );
};
export default Routes;
