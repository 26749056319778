/** @jsxImportSource @emotion/react */

import { Fragment, useEffect, useState, useMemo } from 'react';

import PropTypes from 'prop-types';

import { bgColor, spacer } from 'styles/utilities';
import Button from 'components/htmlElements/Button';
import A from 'components/htmlElements/A';
import { useComponentVisible } from 'components/Navbar/Navbar';
import * as styles from './AssignProductDropdown.styles';

export const removeDuplicates = (duplicates) => {
  const flag = {};
  const unique = [];
  duplicates.forEach((elem) => {
    if (!flag[elem.product_id]) {
      flag[elem.product_id] = true;
      unique.push({
        ...elem,
        count: duplicates.filter((dup) => dup.product_id === elem.product_id).length
      });
    }
  });

  return unique;
};

const AssignProductDropdown = ({
  items,
  handleAssignProductClick,
  handleSelect,
  clearSelection
}) => {
  const { ref, isComponentVisible, toggleVisibility } = useComponentVisible(false);

  const productList = useMemo(() => removeDuplicates(items), [items]);

  const [trigger, setTrigger] = useState(
    items.length > 0 ? 'Select Product' : 'No Products available'
  );

  useEffect(() => {
    if (clearSelection) {
      setTrigger(items.length > 0 ? 'Select Product' : 'No Products available');
    }
  }, [clearSelection, items.length]);

  return (
    <Fragment>
      <div css={styles.dropdownContainer} className="dropdown-container">
        <div
          ref={ref}
          css={styles.inner}
          onClick={items.length > 0 ? toggleVisibility : null}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
        >
          <div css={[styles.container, bgColor.black3]} className="trigger">
            {trigger}
          </div>

          <ul
            className={`options ${isComponentVisible ? 'show' : ''}`}
            css={[bgColor.white, spacer.padL0, styles.dropdownItemlist]}
          >
            {productList.map((product) => (
              <A
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  setTrigger(`${product.name} (${product.count})`);
                  handleSelect(product);
                }}
                css={[spacer.padLR20, spacer.padBT15]}
                className="d-block item-link"
                key={product.name}
              >
                <li className="item" key={product.id}>
                  {product.name} ({product.count})
                </li>
              </A>
            ))}
          </ul>
        </div>

        <div css={styles.buttonWrapper}>
          <Button
            textCenter
            onClick={handleAssignProductClick}
            className="dropdown-button"
            type="button"
            disabled={!items.length}
          >
            Assign
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

AssignProductDropdown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleAssignProductClick: PropTypes.func,
  handleSelect: PropTypes.func,
  clearSelection: PropTypes.bool
};

AssignProductDropdown.defaultProps = {
  handleAssignProductClick: null,
  handleSelect: null,
  clearSelection: false
};

export default AssignProductDropdown;
