// @ref https://stackoverflow.com/a/59682467/2623613
export default function arrayPushPop(array, item) {
  const index = array.indexOf(item);
  if (index === -1) array.push(item);
  else array.splice(index, 1);
  return array;
}

export function arrayPush(array, item) {
  const index = array.indexOf(item);
  if (index === -1) array.push(item);
  return array;
}

export function arrayPop(array, item) {
  const index = array.indexOf(item);
  if (index !== -1) array.splice(index, 1);
  return array;
}
