/** @jsxImportSource @emotion/react */
import { useEffect, useState, Fragment, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';

import { Container, Row, Col } from 'components/Grid';
import P from 'components/htmlElements/P';
import SolutionExplainer from 'components/SolutionExplainer/SolutionExplainer';
import AnswerOption from 'components/QuestionTypes/shared-components/AnswerOption';

import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';

import { InstructionLine } from 'components/Instructions';
import { arrayPush, arrayPop } from 'utils/arrayPushPop';

import { spacer, colors } from 'styles/utilities';
import * as sharedStyles from 'components/QuestionTypes/shared-components/shared.styles';
import transformImgixUrls, {
  setSrcSet
} from 'components/QuestionTypes/shared-components/transformImgixUrls';
import QuestionsCommonWrapperContainer from 'components/QuestionTypes/shared-components/QuestionsCommonWrapper';
// import Latex from 'components/QuestionTypes/shared-components/Latex';

import Modal, { useModal } from 'components/Modal';
import Icon from 'components/Icon';
import * as styles from './TwoColComprehension.styles';

export const questionImagesWidth = 545;

const TwoColComprehension = ({ currentQuestion }) => {
  const {
    instructionsText,
    questionText,
    answerOptions,
    solutionExplaination,
    answer,
    questionImages,
    allowZoom
  } = currentQuestion.twoColComprehension;
  const { ttAnswers } = currentQuestion;

  const { isOpen: isZoomModalOpen, openModal: openZoomModal, toggle: toggleZoomModal } = useModal();
  const [zoomedImage, setZoomedImage] = useState(null);

  const {
    // attemptFinished,
    skippingAllowed,
    showNextButton,
    answersEditableBeforeSubmission,
    updateAnswerPayload,
    isQuestionUsageFinal
  } = TestModuleInfoContainer.useContainer();

  const { attemptFinished, isNoteOrSampleQuestion } =
    QuestionsCommonWrapperContainer.useContainer();

  const [maximumSelections, updateMaximumSelections] = useState(0);
  useEffect(() => {
    updateMaximumSelections(answerOptions.filter((opt) => opt.isCorrect).length);
  }, [answerOptions]);

  // Save checked answer options
  const [checkedAnswers, setCheckedAnswers] = useState([]);
  useEffect(
    // This effect simply clears the checkedAnswers on question change. It ensures on question switch the previous state is cleared
    () => () => {
      setCheckedAnswers([]);
    },
    [currentQuestion._id]
  );

  const handleAnswerChange = (event, shortId) => {
    if (event.target.checked) {
      if (checkedAnswers.length < maximumSelections) {
        const updatedAnswersArray = arrayPush([...checkedAnswers], shortId); // Spread the array otherwise it will mutate it
        setCheckedAnswers(updatedAnswersArray);
      } else {
        setCheckedAnswers((prevState) => {
          // This replaces the first instance
          const updatedState = [...prevState];
          updatedState.pop();
          updatedState.unshift(shortId);
          return updatedState;
        });
      }
    } else {
      const updatedAnswersArray = arrayPop([...checkedAnswers], shortId); // Spread the array otherwise it will mutate it
      setCheckedAnswers(updatedAnswersArray);
    }
  };

  // Update `checkedAnswers` if already answered and/or attempt is completed
  useEffect(() => {
    if (ttAnswers && ttAnswers.length) {
      setCheckedAnswers(ttAnswers);
    }

    // Clear the checked answers array on unmount
    // Without this there's a bug which messes up the next question if it's of the same type
    return () => {
      setCheckedAnswers([]);
    };
  }, [ttAnswers]);

  // Handle disabling of answering
  const [answeringDisabled, handleAnsweringDisabled] = useState(false);
  useEffect(() => {
    // If editable and not answered - can answer
    // If not editable and not answered - can answer
    // If not editable and answered - cannot answer
    if (!attemptFinished) {
      if (!ttAnswers) {
        // This means it's not answered
        handleAnsweringDisabled(false);
      } else if (!answersEditableBeforeSubmission) {
        handleAnsweringDisabled(true);
      }
    } else {
      // If attempt is over answering is disabled
      handleAnsweringDisabled(true);
    }

    return () => {
      // Reset state
      handleAnsweringDisabled(!answersEditableBeforeSubmission);
    };
  }, [answersEditableBeforeSubmission, attemptFinished, ttAnswers]);

  // Toggle next button visibility
  useEffect(() => {
    if (
      !skippingAllowed &&
      checkedAnswers.length !== maximumSelections &&
      !isNoteOrSampleQuestion
    ) {
      showNextButton(false);
    } else {
      showNextButton(true);
    }
  }, [
    checkedAnswers.length,
    isNoteOrSampleQuestion,
    maximumSelections,
    showNextButton,
    skippingAllowed
  ]);

  // Handle updating answer payload
  useEffect(() => {
    updateAnswerPayload({
      questionId: currentQuestion._id,
      answerArray: checkedAnswers
    });
  }, [checkedAnswers, currentQuestion._id, updateAnswerPayload]);

  // Handle image switching for multiple images
  const [currentCheckedImageIndex, updateCheckedImageIndex] = useState(0);
  const handleImageChange = (index) => {
    updateCheckedImageIndex(index);
  };

  const questionImageWrapperRef = useRef(null);
  const questionImageRef = useRef(null);
  const contentWrapperRef = useRef(null);

  const imageWrapperHeightOnLoad = useCallback(() => {
    if (questionImageRef.current.clientHeight > 400) {
      questionImageWrapperRef.current.style.height = `${
        contentWrapperRef.current.clientHeight - 100
      }px`;
    }
  }, []);

  useEffect(() => {
    const imageWrapperHeightHandler = () => {
      if (questionImageRef.current.clientHeight > 400 && window.innerWidth < 992) {
        questionImageWrapperRef.current.style.height = `${
          contentWrapperRef.current.clientHeight - 40
        }px`;
      } else if (questionImageRef.current.clientHeight > 400) {
        questionImageWrapperRef.current.style.height = `${
          contentWrapperRef.current.clientHeight - 100
        }px`;
      }
    };

    window.addEventListener('resize', imageWrapperHeightHandler);
    return () => window.removeEventListener('resize', imageWrapperHeightHandler);
  }, [questionImages]);

  return (
    <div css={[spacer.mrT30, styles.contentWrapper]} ref={contentWrapperRef}>
      <Container>
        <Row>
          <Col>
            {instructionsText && <InstructionLine text={instructionsText} />}

            <div css={[spacer.mrBT40, styles.questionContainer]}>
              <div className="question-column">
                {questionImages.length && (
                  <Fragment>
                    {questionImages.length > 1 ? (
                      <div css={styles.imagesWrapper({ allowZoom })}>
                        {questionImages.map((questionImage, index) => (
                          <label
                            htmlFor={`image-${questionImages.indexOf(questionImage)}`}
                            key={`${index.toString()}-item`}
                          >
                            <input
                              type="radio"
                              className="twoColComp-image-radio"
                              name="two-colComp-image-radio"
                              id={`image-${questionImages.indexOf(questionImage)}`}
                              checked={index === currentCheckedImageIndex}
                              onChange={() => handleImageChange(index)}
                            />
                            <div
                              onClick={() => {
                                if (allowZoom) {
                                  openZoomModal();
                                  setZoomedImage(questionImage.url);
                                }
                              }}
                              role="button"
                              tabIndex="0"
                              onKeyDown={() => {}}
                              className="twoColComp-image"
                            >
                              {allowZoom && <Icon icon="expand" size="24" className="zoom-icon" />}
                              <img
                                src={questionImage.url}
                                alt=""
                                srcSet={setSrcSet(questionImage.url, {
                                  width: questionImagesWidth
                                })}
                                className="w-100"
                              />
                            </div>
                            <div
                              className="twoColComp-image-index"
                              css={styles.twoColCompImageIndex}
                            >
                              <P as="span" gBold>
                                {index + 1}
                              </P>
                            </div>
                          </label>
                        ))}
                      </div>
                    ) : (
                      <div css={styles.singleImageWrapper({ allowZoom })}>
                        <div className="image-wrapper" ref={questionImageWrapperRef}>
                          <div
                            onClick={() => {
                              if (allowZoom) {
                                openZoomModal();
                                setZoomedImage(questionImages[0].url);
                              }
                            }}
                            role="button"
                            tabIndex="0"
                            onKeyDown={() => {}}
                          >
                            {allowZoom && <Icon icon="expand" size="24" className="zoom-icon" />}
                            <img
                              className="twoColComp-image"
                              src={questionImages[0].url}
                              alt=""
                              srcSet={setSrcSet(questionImages[0].url, {
                                width: questionImagesWidth
                              })}
                              ref={questionImageRef}
                              onLoad={imageWrapperHeightOnLoad}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </Fragment>
                )}
              </div>

              <div className="question-column" css={spacer.padL30}>
                {attemptFinished && !ttAnswers?.length && isQuestionUsageFinal && (
                  <P color={colors.red} css={spacer.mrB20} large>
                    Question not attempted.
                  </P>
                )}

                <div
                  css={[spacer.mrB20, sharedStyles.types, sharedStyles.richTextQuestion]}
                  className="ck-content"
                >
                  {ReactHtmlParser(DOMPurify.sanitize(questionText), {
                    transform: transformImgixUrls
                  })}
                </div>

                {answerOptions &&
                  answerOptions.map((answerOption, index) => (
                    <AnswerOption
                      key={answerOption.shortId}
                      html={answerOption.text}
                      shortId={answerOption.shortId}
                      type="circle"
                      index={index}
                      disabled={answeringDisabled}
                      onChange={handleAnswerChange}
                      checked={checkedAnswers.includes(answerOption.shortId)}
                      isCorrectAnswer={attemptFinished && answer?.includes(answerOption.shortId)}
                      attemptFinished={attemptFinished}
                    />
                  ))}
              </div>
            </div>

            {attemptFinished && <SolutionExplainer text={solutionExplaination} />}
          </Col>
        </Row>
      </Container>

      {allowZoom && (
        <Modal
          isOpen={isZoomModalOpen}
          toggle={toggleZoomModal}
          isAtTop
          emotionCss={styles.zoomModal}
          large
          largeCloseButton
        >
          <img src={zoomedImage} alt="" />
        </Modal>
      )}
    </div>
  );
};

TwoColComprehension.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

const WithQuestionProvider = (props) => {
  const { questionSettings } = props.currentQuestion.twoColComprehension;

  return (
    <QuestionsCommonWrapperContainer.Provider
      initialState={{ currentQuestion: props.currentQuestion, questionSettings }}
    >
      <TwoColComprehension {...props} />
    </QuestionsCommonWrapperContainer.Provider>
  );
};
WithQuestionProvider.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

export default WithQuestionProvider;
