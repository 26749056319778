import React, { useState, cloneElement, useRef } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import ClickawayListener from 'react-click-away-listener';
import Tooltip from './Tooltip';

const TooltipTrigger = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleIsVisible = () => setIsVisible((prevVisibility) => !prevVisibility);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
    placement: props.placement
  });

  const { trigger, closeOnClick } = props;
  const referenceElementConfig = { ref: setReferenceElement };
  const popperElementConfig = { ref: setPopperElement };

  const timeoutRef = useRef();

  if (trigger === 'click') {
    referenceElementConfig.onClick = () => toggleIsVisible();
  } else {
    referenceElementConfig.onMouseEnter = () => toggleIsVisible();
    referenceElementConfig.onMouseLeave = () => {
      timeoutRef.current = setTimeout(() => setIsVisible(false), 300);
    };
    popperElementConfig.onMouseEnter = () => clearTimeout(timeoutRef?.current);
    popperElementConfig.onMouseLeave = () => setIsVisible(false);
  }

  return (
    <React.Fragment>
      {closeOnClick ? (
        <ClickawayListener onClickAway={() => setIsVisible(false)}>
          {cloneElement(props.children, referenceElementConfig)}
        </ClickawayListener>
      ) : (
        <React.Fragment>{cloneElement(props.children, referenceElementConfig)}</React.Fragment>
      )}
      <Tooltip
        popperElementConfig={popperElementConfig}
        tooltipChildren={props.tooltipInner}
        setArrowElement={setArrowElement}
        isVisible={isVisible}
        styles={styles}
        attributes={attributes}
      />
    </React.Fragment>
  );
};

TooltipTrigger.defaultProps = {
  trigger: 'hover',
  closeOnClick: true
};

TooltipTrigger.propTypes = {
  children: PropTypes.element.isRequired,
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end'
  ]).isRequired,
  tooltipInner: PropTypes.element.isRequired,
  trigger: PropTypes.oneOf(['hover', 'click']),
  closeOnClick: PropTypes.bool
};

export default TooltipTrigger;
