/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { typography } from 'styles/utilities';

export const large = css`
  text-transform: uppercase;
  margin: 0;
`;
export const small = css`
  text-transform: uppercase;
  margin: 0;
  &.small {
    font-size: ${typography.baseSizes.iota};
  }
`;

export const Stat = css`
  &:last-child {
    margin-bottom: 0;
  }
`;
