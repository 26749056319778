/** @jsxImportSource @emotion/react */
import { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';

import { Container, Row, Col } from 'components/Grid';
import P from 'components/htmlElements/P';
import SolutionExplainer from 'components/SolutionExplainer/SolutionExplainer';
import AnswerOption from 'components/QuestionTypes/shared-components/AnswerOption';

import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';

import { InstructionLine } from 'components/Instructions';
import transformImgixUrls from 'components/QuestionTypes/shared-components/transformImgixUrls';

import { spacer, colors } from 'styles/utilities';
import * as sharedStyles from 'components/QuestionTypes/shared-components/shared.styles';
import QuestionsCommonWrapperContainer from 'components/QuestionTypes/shared-components/QuestionsCommonWrapper';
import Latex, { withLatex } from 'components/QuestionTypes/shared-components/Latex';

import * as styles from './SentenceCompletion.styles';

const SentenceCompletion = ({ currentQuestion }) => {
  const { instructionsText, questionText, answerOptions, solutionExplaination, answer } =
    currentQuestion.sentenceCompletion;
  const { ttAnswers } = currentQuestion;

  const {
    // attemptFinished,
    skippingAllowed,
    showNextButton,
    answersEditableBeforeSubmission,
    updateAnswerPayload,
    isQuestionUsageFinal
  } = TestModuleInfoContainer.useContainer();

  const { attemptFinished, isNoteOrSampleQuestion } =
    QuestionsCommonWrapperContainer.useContainer();

  // Save checked answer options
  const [checkedAnswers, setCheckedAnswers] = useState([]);
  useEffect(
    // This effect simply clears the checkedAnswers on question change. It ensures on question switch the previous state is cleared
    () => () => {
      setCheckedAnswers([]);
    },
    [currentQuestion._id]
  );

  const handleAnswerChange = (event, shortId) => {
    if (event.target.checked) {
      setCheckedAnswers([shortId]);
    }
  };

  // Update `checkedAnswers` if already answered and/or attempt is completed
  useEffect(() => {
    if (ttAnswers && ttAnswers.length) {
      setCheckedAnswers(ttAnswers);
    }

    // Clear the checked answers array on unmount
    // Without this there's a bug which messes up the next question if it's of the same type
    return () => {
      setCheckedAnswers([]);
    };
  }, [ttAnswers]);

  // Handle disabling of answering
  const [answeringDisabled, handleAnsweringDisabled] = useState(false);
  useEffect(() => {
    // If editable and not answered - can answer
    // If not editable and not answered - can answer
    // If not editable and answered - cannot answer
    if (!attemptFinished) {
      if (!ttAnswers) {
        // This means it's not answered
        handleAnsweringDisabled(false);
      } else if (!answersEditableBeforeSubmission) {
        handleAnsweringDisabled(true);
      }
    } else {
      // If attempt is over answering is disabled
      handleAnsweringDisabled(true);
    }

    return () => {
      // Reset state
      handleAnsweringDisabled(!answersEditableBeforeSubmission);
    };
  }, [answersEditableBeforeSubmission, attemptFinished, ttAnswers]);

  // Toggle next button visibility
  useEffect(() => {
    if (!skippingAllowed && !checkedAnswers.length && !isNoteOrSampleQuestion) {
      showNextButton(false);
    } else {
      showNextButton(true);
    }
  }, [checkedAnswers.length, isNoteOrSampleQuestion, showNextButton, skippingAllowed]);

  // Handle updating answer payload
  useEffect(() => {
    updateAnswerPayload({
      questionId: currentQuestion._id,
      answerArray: checkedAnswers
    });
  }, [checkedAnswers, currentQuestion._id, updateAnswerPayload]);

  return (
    <div css={[spacer.padT30]} className="h-100">
      <Container className="h-100">
        <Row className="h-100">
          <Col className="h-100">
            <div
              css={[sharedStyles.verticalQuestionLayout, styles.questionWrapper]}
              className={`${attemptFinished ? 'attempt-finished' : ''}`}
            >
              {instructionsText && (
                <div className="section-instructions">
                  <InstructionLine text={instructionsText} />
                </div>
              )}

              <Latex
                css={[sharedStyles.types, sharedStyles.richTextQuestion]}
                className="text-center question-text ck-content"
              >
                {ReactHtmlParser(DOMPurify.sanitize(withLatex(questionText)), {
                  transform: (node) =>
                    transformImgixUrls(node, { width: sharedStyles.imagesSizeInQuestionText })
                })}
              </Latex>

              <div className="section-answer-options">
                {answerOptions &&
                  answerOptions.map((answerOption, index) => (
                    <AnswerOption
                      key={answerOption.shortId}
                      html={answerOption.text}
                      shortId={answerOption.shortId}
                      type="box"
                      index={index}
                      disabled={answeringDisabled}
                      onChange={handleAnswerChange}
                      checked={checkedAnswers.includes(answerOption.shortId)}
                      isCorrectAnswer={attemptFinished && answer?.includes(answerOption.shortId)}
                      attemptFinished={attemptFinished}
                    />
                  ))}
              </div>
            </div>

            {attemptFinished && !ttAnswers?.length && isQuestionUsageFinal && (
              <P color={colors.red} large>
                Question not attempted.
              </P>
            )}

            {attemptFinished && (
              <Fragment>
                <P large css={spacer.mrT20}>
                  {!ttAnswers?.length || !checkedAnswers.includes(answer[0]) ? (
                    <Fragment>
                      <span>The correct answer is </span>
                      <strong className="d-inline-block">
                        {ReactHtmlParser(answerOptions.find((a) => a.shortId === answer[0]).text)}
                      </strong>
                    </Fragment>
                  ) : (
                    'Your answer is correct.'
                  )}
                </P>
                <SolutionExplainer text={solutionExplaination} />
              </Fragment>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

SentenceCompletion.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

const WithQuestionProvider = (props) => {
  const { questionSettings } = props.currentQuestion.sentenceCompletion;

  return (
    <QuestionsCommonWrapperContainer.Provider
      initialState={{ currentQuestion: props.currentQuestion, questionSettings }}
    >
      <SentenceCompletion {...props} />
    </QuestionsCommonWrapperContainer.Provider>
  );
};
WithQuestionProvider.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

export default WithQuestionProvider;
