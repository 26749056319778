import React from 'react';
import { useDroppable, useDraggable } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import PropTypes from 'prop-types';

export function Droppable(props) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
    data: {
      ...(props.accepts.length && { accepts: props.accepts })
    }
  });

  // @ref: https://stackoverflow.com/a/32371612/2623613
  const childrenWithProps = React.Children.map(props.children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { isOver });
    }
    return child;
  });

  return (
    <div ref={setNodeRef} className={`${isOver ? 'is-over' : ''}`}>
      {childrenWithProps}
    </div>
  );
}
Droppable.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  accepts: PropTypes.array
};
Droppable.defaultProps = {
  accepts: []
};

export function Draggable(props) {
  const Element = props.element;

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: props.id,
    disabled: props.disabled,
    data: {
      ...(props.type.length && { type: props.type })
    }
  });
  const style = {
    // transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    // opacity: isDragging ? 0.5 : undefined
    touchAction: 'manipulation'
  };

  return (
    <Element
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      style={style}
      className={`${isDragging ? 'is-dragging' : ''}`}
    >
      {props.children}
    </Element>
  );
}
Draggable.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  element: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string
};
Draggable.defaultProps = {
  element: 'div',
  disabled: false,
  type: ''
};

export function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id
  });

  // console.info(transform, props.id);

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {props.children}
    </div>
  );
}
SortableItem.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
