/** @jsxImportSource @emotion/react */

import { Fragment, useEffect, useMemo } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ModalHeader from 'components/ModalHeader';
import Modal from 'components/Modal';
import { Input, FieldError, Wrapper } from 'components/htmlElements/Fields';
import Button from 'components/htmlElements/Button';
import LoaderOverlay from 'components/LoaderOverlay';
import SuccessModal from 'components/SuccessModal';

import { UserInfoContainer } from 'api/auth';
import { useEditAccount } from 'api/candidate';
import { dateInYYYYMMDD, dateInMMDDYYYY } from 'utils/dateFormats';

import { useUserAccountContext } from 'components/EditAccount/userAccountContext';

import { spacer } from 'styles/utilities';

const currentDate = dateInMMDDYYYY(new Date()).replace(/-/g, '/');

const eidtCandidateValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  dob: Yup.date().max(currentDate, 'Date cannot be in future').required('Required'),
  password: Yup.string().min(6, 'Must contain atleast 6 characters'),
  confirmPassword: Yup.string()
    .when('password', {
      is: (value) => value && value.length > 0,
      then: Yup.string().required('Confirm your password'),
      otherwise: Yup.string()
    })
    .oneOf([Yup.ref('password')], 'Password does not match')
});

const EditCandidateAccount = () => {
  const { isEditCandidateOpen, toggleEditCandidateModal, closeEditCandidateModal } =
    useUserAccountContext();

  const { firstName, lastName, dob, id, userIs, currentCandidate } =
    UserInfoContainer.useContainer();

  const isDobEditable = useMemo(
    () => ['school', 'admin', 'parent', 'candidate'].includes(userIs),
    [userIs]
  );
  const loggedUserIsCandidate = useMemo(() => userIs === 'candidate', [userIs]);
  const candidateInfo = useMemo(() => {
    if (loggedUserIsCandidate) {
      return {
        firstName,
        lastName,
        dob,
        id
      };
    }
    return {
      firstName: currentCandidate?.firstName,
      lastName: currentCandidate?.lastName,
      dob: currentCandidate?.dob,
      id: currentCandidate?.id
    };
  }, [
    currentCandidate?.dob,
    currentCandidate?.firstName,
    currentCandidate?.id,
    currentCandidate?.lastName,
    dob,
    firstName,
    id,
    lastName,
    loggedUserIsCandidate
  ]);

  const { editAccount, isError, isSuccess, reset } = useEditAccount({
    candidateId: candidateInfo.id,
    loggedUserIsCandidate
  });

  useEffect(
    () =>
      // Clear mutation state when modal is opened

      () => {
        reset();
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEditCandidateOpen]
  );

  const handleFormSubmission = async (values, actions) => {
    actions.setSubmitting(true);

    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      ...(isDobEditable && { dob: new Date(values.dob).toISOString() }),
      ...(values.password && { password: values.password })
    };

    await editAccount(payload);

    actions.setSubmitting(false);
  };

  return (
    <div className="is-relative">
      <Modal isOpen={isEditCandidateOpen} toggle={toggleEditCandidateModal}>
        <Formik
          initialValues={{
            firstName: candidateInfo.firstName,
            lastName: candidateInfo.lastName,
            dob: dateInYYYYMMDD(candidateInfo.dob),
            password: '',
            confirmPassword: ''
          }}
          validationSchema={eidtCandidateValidationSchema}
          onSubmit={handleFormSubmission}
        >
          {({ isSubmitting }) => (
            <Fragment>
              {!isSuccess ? (
                <div css={[spacer.padBT30, spacer.padLR30]}>
                  <ModalHeader header="Edit Account" />
                  <Form>
                    <div>
                      <Wrapper>
                        <Field
                          type="text"
                          name="firstName"
                          id="firstName"
                          as={Input}
                          placeholder="First name"
                        />
                        <ErrorMessage name="firstName" component={FieldError} />
                      </Wrapper>
                      <Wrapper>
                        <Field
                          type="text"
                          name="lastName"
                          id="lastName"
                          as={Input}
                          placeholder="Last name"
                        />
                        <ErrorMessage name="lastName" component={FieldError} />
                      </Wrapper>
                      <Wrapper>
                        <Field
                          type="date"
                          name="dob"
                          id="dob"
                          as={Input}
                          placeholder="Birthdate"
                          disabled={!isDobEditable}
                          readonly={!isDobEditable}
                        />
                        <ErrorMessage name="dob" component={FieldError} />
                      </Wrapper>
                      <Wrapper>
                        <Field
                          type="password"
                          name="password"
                          id="password"
                          autoComplete="off"
                          as={Input}
                          placeholder="Password"
                        />
                        <ErrorMessage name="password" component={FieldError} />
                      </Wrapper>
                      <Wrapper>
                        <Field
                          type="password"
                          name="confirmPassword"
                          id="confirmPassword"
                          autoComplete="off"
                          as={Input}
                          placeholder="Confirm Password"
                        />
                        <ErrorMessage name="confirmPassword" component={FieldError} />
                      </Wrapper>

                      {isError && (
                        <Wrapper>
                          <FieldError>
                            There was a problem creating the account, please try again
                          </FieldError>
                        </Wrapper>
                      )}
                    </div>

                    <Button wide rounded textCenter type="submit">
                      Edit Account
                    </Button>
                  </Form>

                  {isSubmitting && <LoaderOverlay />}
                </div>
              ) : (
                <SuccessModal
                  onClick={closeEditCandidateModal}
                  message="Account details updated!"
                />
              )}
            </Fragment>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

EditCandidateAccount.propTypes = {};

export default EditCandidateAccount;
