/* eslint-disable jsx-a11y/media-has-caption */
/** @jsxImportSource @emotion/react */
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';

import { Container, Row, Col } from 'components/Grid';
import P from 'components/htmlElements/P';
import SolutionExplainer from 'components/SolutionExplainer/SolutionExplainer';
import AnswerOption from 'components/QuestionTypes/shared-components/AnswerOption';

import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';

import { InstructionLine } from 'components/Instructions';
import { arrayPush, arrayPop } from 'utils/arrayPushPop';

import { spacer, colors } from 'styles/utilities';
import * as sharedStyles from 'components/QuestionTypes/shared-components/shared.styles';
import transformImgixUrls from 'components/QuestionTypes/shared-components/transformImgixUrls';

import QuestionsCommonWrapperContainer from 'components/QuestionTypes/shared-components/QuestionsCommonWrapper';
import Latex, { withLatex } from 'components/QuestionTypes/shared-components/Latex';
import H5 from 'components/htmlElements/H5';
import Button from 'components/htmlElements/Button';
import Icon from 'components/Icon/Icon';
import * as styles from './AudioQuestion.styles';
import Keyboard from './Keyboard';

const AudioQuestion = ({ currentQuestion }) => {
  const {
    instructionsText,
    questionText,
    answerOptions,
    solutionExplaination,
    answer,
    questionAudio,
    showKeyboard
  } = currentQuestion.audioQuestion;
  const { ttAnswers } = currentQuestion;

  const {
    // attemptFinished,
    skippingAllowed,
    showNextButton,
    answersEditableBeforeSubmission,
    updateAnswerPayload,
    isQuestionUsageFinal
  } = TestModuleInfoContainer.useContainer();

  const { attemptFinished, isNoteOrSampleQuestion, isPracticeQuestion } =
    QuestionsCommonWrapperContainer.useContainer();

  const [maximumSelections, updateMaximumSelections] = useState(0);
  useEffect(() => {
    updateMaximumSelections(answerOptions.filter((opt) => opt.isCorrect).length);
  }, [answerOptions]);

  // Save checked answer options
  const [checkedAnswers, setCheckedAnswers] = useState([]);

  // save answer input
  const [answerInput, setAnswerInput] = useState('');

  useEffect(
    // This effect simply clears the checkedAnswers on question change. It ensures on question switch the previous state is cleared
    () => () => {
      setAnswerInput('');
      setCheckedAnswers([]);
    },
    [currentQuestion._id]
  );

  const handleAnswerChange = (event, shortId) => {
    if (event.target.checked) {
      if (checkedAnswers.length < maximumSelections) {
        const updatedAnswersArray = arrayPush([...checkedAnswers], shortId); // Spread the array otherwise it will mutate it
        setCheckedAnswers(updatedAnswersArray);
      } else {
        setCheckedAnswers((prevState) => {
          // This replaces the first instance
          const updatedState = [...prevState];
          updatedState.pop();
          updatedState.unshift(shortId);
          return updatedState;
        });
      }
    } else {
      const updatedAnswersArray = arrayPop([...checkedAnswers], shortId); // Spread the array otherwise it will mutate it
      setCheckedAnswers(updatedAnswersArray);
    }
  };

  // Update `checkedAnswers or answerInput` if already answered and/or attempt is completed
  useEffect(() => {
    if (showKeyboard && ttAnswers && ttAnswers.length) {
      setAnswerInput(ttAnswers[0]);
    } else if (ttAnswers && ttAnswers.length) {
      setCheckedAnswers(ttAnswers);
    }

    // Clear the checked answers array on unmount
    // Without this there's a bug which messes up the next question if it's of the same type
    return () => {
      if (showKeyboard) {
        setAnswerInput('');
      } else {
        setCheckedAnswers([]);
      }
    };
  }, [showKeyboard, ttAnswers]);

  // Handle disabling of answering
  const [answeringDisabled, handleAnsweringDisabled] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  useEffect(() => {
    // If editable and not answered - can answer
    // If not editable and not answered - can answer
    // If not editable and answered - cannot answer
    if (!attemptFinished) {
      if (!ttAnswers) {
        // This means it's not answered
        handleAnsweringDisabled(false);
      } else if (!answersEditableBeforeSubmission) {
        handleAnsweringDisabled(true);
      }
    } else {
      // If attempt is over answering is disabled
      handleAnsweringDisabled(true);
    }

    return () => {
      // Reset state
      handleAnsweringDisabled(!answersEditableBeforeSubmission);
    };
  }, [answersEditableBeforeSubmission, attemptFinished, ttAnswers]);

  // Toggle next button visibility
  useEffect(() => {
    if (
      !skippingAllowed &&
      checkedAnswers.length !== maximumSelections &&
      !isNoteOrSampleQuestion
    ) {
      showNextButton(false);
    } else {
      showNextButton(true);
    }
  }, [
    checkedAnswers.length,
    isNoteOrSampleQuestion,
    maximumSelections,
    showNextButton,
    skippingAllowed
  ]);

  // Handle updating answer payload
  useEffect(() => {
    if (showKeyboard) {
      updateAnswerPayload({
        questionId: currentQuestion._id,
        answerArray: [answerInput]
      });
    } else {
      updateAnswerPayload({
        questionId: currentQuestion._id,
        answerArray: checkedAnswers
      });
    }
  }, [answerInput, checkedAnswers, currentQuestion._id, showKeyboard, updateAnswerPayload]);

  const audioRef = useRef(null);
  const soundAnimationRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const audioElement = audioRef.current;

    try {
      if (questionAudio?.[0]?.autoplay && !attemptFinished) {
        audioElement.play().catch(() => console.info('play promise failed'));
      }
    } catch {
      console.info('playback did not succeed');
    }

    return () => {
      audioElement.currentTime = 0;
      audioElement?.pause();
    };
  }, [questionAudio, currentQuestion.id, attemptFinished]);

  const playAnimation = useCallback((state) => {
    if (state === 'playing') {
      setIsPlaying(true);
      soundAnimationRef.current.classList.add('animate');
    } else {
      setIsPlaying(false);
      soundAnimationRef.current.classList.remove('animate');
    }
  }, []);

  useEffect(() => {
    if (attemptFinished && ttAnswers?.length) {
      if (ttAnswers[0] === answer[0]) {
        setIsCorrect(true);
      }
    }

    if (isNoteOrSampleQuestion && showKeyboard) {
      setAnswerInput(answer[0]);
    }

    if (attemptFinished && isPracticeQuestion && answer) {
      if (answerInput[0] === answer[0]) {
        setIsCorrect(true);
      }
    }

    return () => setIsCorrect(false);
  }, [
    answer,
    answerInput,
    attemptFinished,
    isNoteOrSampleQuestion,
    isPracticeQuestion,
    showKeyboard,
    ttAnswers
  ]);

  const isImageQuestion = answerOptions.some(({ text }) => text.includes('<img'));

  return (
    <div css={[spacer.padT30]} className={`${!attemptFinished ? 'h-100' : ''}`}>
      <Container className="h-100">
        <Row className="h-100">
          <Col className="h-100">
            <div
              css={sharedStyles.verticalQuestionLayout}
              className={`${attemptFinished ? 'attempt-finished' : ''}`}
            >
              {instructionsText && <InstructionLine text={instructionsText} />}

              {showKeyboard ? (
                <div css={styles.inputContainer} className={attemptFinished && 'no-pointer'}>
                  <div
                    className="w-100 text-center"
                    css={[spacer.padBT15, styles.input, spacer.mrB20]}
                  >
                    <H5 noMargin>{attemptFinished ? ttAnswers?.[0] : answerInput} </H5>
                  </div>

                  {attemptFinished && !isNoteOrSampleQuestion && ttAnswers && (
                    <Fragment>
                      {isCorrect ? (
                        <P css={spacer.mrB15} color={colors.green} large className="text-center">
                          Your answer is correct
                        </P>
                      ) : (
                        <div css={spacer.mrB15} className="text-center">
                          <P large color={colors.red} className="d-inline">
                            Your answer is incorrect, The correct answer is:{' '}
                          </P>
                          <P large color={colors.green} className="d-inline">
                            {answer[0]}
                          </P>
                        </div>
                      )}
                    </Fragment>
                  )}

                  {isNoteOrSampleQuestion && (
                    <P css={spacer.mrB15} color={colors.green} large className="text-center">
                      Correct answer
                    </P>
                  )}

                  <Keyboard setAnswer={attemptFinished ? () => {} : setAnswerInput} />
                </div>
              ) : (
                <div
                  className="section-answer-options justify-content-between flex-grow-0 mx-auto"
                  css={[spacer.mrT30, styles.answerOptionsWrapper({ isImageQuestion })]}
                >
                  {answerOptions &&
                    answerOptions.map((answerOption, index) => (
                      <AnswerOption
                        key={answerOption.shortId}
                        html={answerOption.text}
                        shortId={answerOption.shortId}
                        type="box"
                        index={index}
                        disabled={answeringDisabled}
                        onChange={handleAnswerChange}
                        checked={checkedAnswers.includes(answerOption.shortId)}
                        isCorrectAnswer={attemptFinished && answer?.includes(answerOption.shortId)}
                        attemptFinished={attemptFinished}
                        imageWidth={styles.audioQuestionImageSize}
                      />
                    ))}
                </div>
              )}

              {/* question text and audio play button */}
              <div className="d-flex justify-content-between" css={spacer.mrB20}>
                <Latex
                  css={[
                    sharedStyles.types,
                    sharedStyles.richTextQuestion,
                    styles.questionContentWrapper
                  ]}
                  className="ck-content w-100"
                >
                  {ReactHtmlParser(DOMPurify.sanitize(withLatex(questionText)), {
                    transform: (node) =>
                      transformImgixUrls(node, { width: sharedStyles.imagesSizeInQuestionText })
                  })}
                </Latex>

                <div
                  className="d-flex justify-content-center align-items-start"
                  css={[styles.questionContentWrapper, spacer.mrL30]}
                >
                  <div className="d-flex justify-content-center">
                    <div className="m-auto" css={styles.AudioAnimation} ref={soundAnimationRef}>
                      <div className="bar" />
                      <div className="bar" />
                      <div className="bar" />
                    </div>

                    <div
                      className="d-flex justify-content-center align-items-center"
                      css={spacer.mrL20}
                    >
                      <audio
                        key={questionAudio?.[0]?.url}
                        hidden
                        ref={audioRef}
                        onEnded={() => playAnimation('stopped')}
                        onPlay={() => playAnimation('playing')}
                        onPause={() => playAnimation('stopped')}
                      >
                        <source src={questionAudio?.[0]?.url} />
                      </audio>

                      <Button
                        rounded
                        textCenter
                        onClick={() => {
                          audioRef.current.currentTime = 0;
                          audioRef.current.play();
                        }}
                      >
                        <Icon icon={isPlaying ? 'pause' : 'play'} size={20} css={spacer.mrR10} />
                        {isPlaying ? 'Playing...' : 'Play again'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {attemptFinished && !ttAnswers?.length && isQuestionUsageFinal && (
              <P color={colors.red} large>
                Question not attempted.
              </P>
            )}

            {attemptFinished && <SolutionExplainer text={solutionExplaination} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

AudioQuestion.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

const WithQuestionProvider = (props) => {
  const { questionSettings } = props.currentQuestion.audioQuestion;

  return (
    <QuestionsCommonWrapperContainer.Provider
      initialState={{ currentQuestion: props.currentQuestion, questionSettings }}
    >
      <AudioQuestion {...props} />
    </QuestionsCommonWrapperContainer.Provider>
  );
};
WithQuestionProvider.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

export default WithQuestionProvider;
