//* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors } from 'styles/utilities';

export const skeletonWrapper = css`
  display: flex;
  flex-direction: column;
  height: 100vh;

  .isebTestSkeleton-container {
    height: 100%;
    overflow-y: auto;
  }
`;

export const navContainer = css`
  width: 100%;
  background-color: ${colors.black2};
`;

export const testNav = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  .logo {
    width: 220px;
  }
`;

export const testArea = (theme) => css`
  height: 100%;
  border: 10px solid ${theme.colors.primary};
  overflow-y: auto;
`;

export const testButton = css`
  border: 2px solid ${colors.green4};
  padding: 6px 20px;
  border-radius: 10px;
  color: ${colors.black2};
  background-color: #dff9f2;
  text-transform: capitalize;
  transition: none;
  line-height: 1;
  font-weight: 400;

  &.faux-button {
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    background-color: #88d8c2;
    color: ${colors.black2};
  }

  &:focus,
  &:active {
    color: ${colors.black2};
  }
`;

export const progressBarWrapper = (props) => css`
  ${(() => {
    if (props.attemptFinished) {
      return css`
        flex-grow: 1;
        position: relative;
      `;
    }

    return css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `;
  })()}
`;

export const testNavWrapper = css`
  min-height: 54px;
`;

export const preloaderWrapper = css`
  position: absolute;
  opacity: 0;
  position: fixed;
  z-index: -9999;
  pointer-events: none;
  visibility: hidden;
`;
