import React, { Fragment } from 'react';
import { hot } from 'react-hot-loader/root';
import { Global } from '@emotion/react';
import { inject } from '@vercel/analytics';

import './styles/app.scss';

import { QueryClient, QueryClientProvider } from 'react-query';
import { globals } from 'styles';
import Routes from 'globals/routes';
import { UserInfoContainer } from 'api/auth';
import { IdContainer } from 'pages/Admin/IdContext';
import FooterNote from 'components/FooterNote';
import { globalHistory } from 'components/Router';
import NavsContainer from 'hooks/useNavs';
import ApiRequestHeadersContainer from 'hooks/useExtraApiRequestHeaders';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

globalHistory.listen(({ location }) => {
  // @ref: https://stackoverflow.com/a/58757929/2623613
  // the new gtag API
  if (window.gtag) {
    window.gtag('config', 'G-2T2RDJMG8H', { page_path: location.pathname });
  }
});

inject();

const App = () => {
  const { footerHidden } = NavsContainer.useContainer();

  return (
    <Fragment>
      <Global styles={globals} />

      <Routes />

      {!footerHidden && (
        <FooterNote
          note="Education is not preparation for life; education is life itself."
          author="John Dewey"
        />
      )}
    </Fragment>
  );
};

const WithUserContainer = () => (
  <UserInfoContainer.Provider>
    <ApiRequestHeadersContainer.Provider>
      <IdContainer.Provider>
        <NavsContainer.Provider>
          <App />
        </NavsContainer.Provider>
      </IdContainer.Provider>
    </ApiRequestHeadersContainer.Provider>
  </UserInfoContainer.Provider>
);

const WithQueryClient = () => (
  <QueryClientProvider client={queryClient} contextSharing>
    <WithUserContainer />
  </QueryClientProvider>
);

export default hot(WithQueryClient);
