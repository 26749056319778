const typography = {
  fonts: {
    gothamBlack: {
      fontFamily: `"Gotham SSm Black A", "Gotham SSm Black B", Arial Black, sans-serif`
    },
    gothamBold: {
      fontFamily: `"Gotham SSm Bold A", "Gotham SSm Bold B", Arial Black, sans-serif`
    },
    gothamBook: {
      fontFamily: `"Gotham SSm Book A", "Gotham SSm Book B", Arial, sans-serif`
    },
    mercuryDisplay: {
      fontFamily: `"Mercury Display BI A", "Mercury Display BI B", Georgia, serif`
    }
  },
  baseSizes: {
    alpha: '45px', // h1
    beta: '38px', // h2
    gamma: '26px', // h3
    delta: '24px', // h4
    epsilon: '18px', // h5
    zeta: '16px', // h6 | p large
    eta: '14px', // p
    theta: '12px', // p small
    iota: '10px' // top bottom margin for all typography
  }
};

export default typography;
