import React, { Fragment, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { ROUTE_PATHS } from 'globals/constants';
import NavsContainer from 'hooks/useNavs';
import AssignedTestContainer, { TestDataProvider } from 'hooks/useTest';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';
import { useGetAttemptQuestions } from 'api/test';

import { Redirect } from 'components/Router';
import PageLoader from 'components/PageLoader';

import TestPage from './page';

const TestContainer = ({ testAssignmentId, moduleId, location }) => {
  const { testInfo, isTestAtSection, testHubAttemptToken } = AssignedTestContainer.useContainer();

  const questionsPayload = isTestAtSection
    ? { atLevelSectionId: moduleId }
    : { atLevelSubsectionId: moduleId };

  const {
    data: questionsData,
    isError,
    isSuccess
  } = useGetAttemptQuestions(testInfo._id, testAssignmentId, questionsPayload, testHubAttemptToken);

  // Disables back button
  // @ref: https://codepen.io/dhavalt10/pen/rGLBzB
  useEffect(() => {
    if (window) {
      window.history.pushState(null, null, window.location.href);
      onpopstate = () => {
        window.history.go(1);
      };
    }
  }, []);

  if (isError) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (!isSuccess) {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  return (
    <Fragment>
      {testInfo && (
        <TestModuleInfoContainer.Provider
          initialState={{
            testInfo,
            moduleId,
            isTestAtSection,
            moduleQuestions: questionsData?.data?.questions
          }}
        >
          <TestPage hasExtraTime={location?.state?.hasExtraTime || false} />
        </TestModuleInfoContainer.Provider>
      )}
    </Fragment>
  );
};

TestContainer.propTypes = {
  testAssignmentId: PropTypes.string.isRequired,
  moduleId: PropTypes.string.isRequired,
  location: PropTypes.object
};

TestContainer.defaultProps = {
  location: {}
};

const WithProviders = (props) => {
  const { hideNav, hideFooter, showNav, showFooter } = NavsContainer.useContainer();

  useLayoutEffect(() => {
    hideNav();
    hideFooter();

    return () => {
      showNav();
      showFooter();
    };
  }, [hideFooter, hideNav, showFooter, showNav]);

  return (
    <TestDataProvider testAssignmentId={props.testAssignmentId}>
      <TestContainer {...props} />
    </TestDataProvider>
  );
};
WithProviders.propTypes = {
  testAssignmentId: PropTypes.string.isRequired
};

export default WithProviders;
