/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Input, FieldError, Wrapper } from 'components/htmlElements/Fields';

import ModalHeader from 'components/ModalHeader/ModalHeader';
import Button from 'components/htmlElements/Button';
import { spacer } from 'styles/utilities';
import Modal from 'components/Modal/Modal';

import { useForgottenPassword } from 'api/auth';
import LoaderOverlay from 'components/LoaderOverlay';
// import * as styles from './ForgotPasswordForm.styles';

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().required('Required')
});

const ForgotPasswordForm = ({ isOpen, toggleModal }) => {
  const { forgotPasswordMutation, data, isError } = useForgottenPassword();

  const handleFormSubmission = async (values, actions) => {
    actions.setSubmitting(true);

    await forgotPasswordMutation(values);

    actions.setSubmitting(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={forgotPasswordSchema}
        onSubmit={handleFormSubmission}
      >
        {({ isSubmitting }) => (
          <Form>
            <div css={[spacer.padLR30, spacer.padBT30]}>
              <ModalHeader header="Forgotten Password?" />
              <div>
                <Wrapper>
                  <Field
                    type="text"
                    name="email"
                    id="email"
                    as={Input}
                    placeholder="Enter your username or email id"
                  />
                  <ErrorMessage name="email" component={FieldError} />
                </Wrapper>

                {data && data.error ? (
                  <Wrapper>
                    <FieldError>{data.error}</FieldError>
                  </Wrapper>
                ) : data?.message ? (
                  <Wrapper>
                    <FieldError isSuccess>{data.message}</FieldError>
                  </Wrapper>
                ) : (
                  ''
                )}

                {isError && (
                  <Wrapper>
                    <FieldError>
                      There was a problem resetting password, please try again
                    </FieldError>
                  </Wrapper>
                )}
              </div>
              <Button rounded textCenter type="submit" wide>
                Reset Password
              </Button>

              {isSubmitting && <LoaderOverlay />}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

ForgotPasswordForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default ForgotPasswordForm;
