/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors } from 'styles/utilities';

export const divider = css`
  height: 100%;
  width: 2px;
  background-color: ${colors.mediumGrey};
`;

export const scoreBandWrapper = ({ border }) => css`
  ${border &&
  css`
    border-top: 1px solid ${colors.grey2};
    border-bottom: 1px solid ${colors.grey2};
  `}
`;
