/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'components/QuestionTypes/shared-components/DndkitComponents';
import P from 'components/htmlElements/P';

import * as styles from './Wordbin.styles';

const Wordbin = ({ isOver, children }) => (
  <div css={styles.bin({ isOpen: isOver })}>
    <div className="bin-contents">{children}</div>
  </div>
);
Wordbin.propTypes = {
  isOver: PropTypes.bool,
  children: PropTypes.node
};
Wordbin.defaultProps = {
  children: null,
  isOver: false
};

const WithDroppable = ({ id, ...props }) => (
  <div css={styles.binWrapper}>
    <P large>Word Bin</P>
    <Droppable id={id}>
      <Wordbin {...props} />
    </Droppable>
  </div>
);
WithDroppable.propTypes = {
  id: PropTypes.string.isRequired
};

export default WithDroppable;
