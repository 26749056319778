import { TEST_STRUCTURE, QUESTION_USAGE_TYPE } from 'globals/constants';
import { prev, next } from 'utils/getPrevNextLesson';

export const getModulesCount = (testInfo) => {
  if (testInfo.questionStructure === TEST_STRUCTURE.subsections) {
    let totalSubsections = 0;
    testInfo.section.forEach((section) => {
      totalSubsections += section.subSection.length;
    });
    return totalSubsections;
  }
  return testInfo.section.length;
};

export const getCompletedModules = (testInfo) => {
  if (testInfo.questionStructure === TEST_STRUCTURE.subsections) {
    let totalCompletedSubsections = 0;
    testInfo.section.forEach((sectionItem) => {
      const totalEndedAt = sectionItem.subSection.filter(
        (subSectionItem) => subSectionItem.timerData.endedAt
      );
      totalCompletedSubsections += totalEndedAt.length;
    });
    return totalCompletedSubsections;
  }
  return testInfo.section.filter((sectionItem) => sectionItem.timerData.endedAt).length;
};

export const nextQuestion = (
  current,
  questionsMap,
  backForthNavigationEnabled,
  attemptFinished
) => {
  let pages = questionsMap.map((q) => q._id);
  if (backForthNavigationEnabled && !attemptFinished) {
    pages = questionsMap
      .filter((q) => q.usage === QUESTION_USAGE_TYPE.final || !q.questionWatched)
      .map((q) => q._id);
  }

  const question = next(current, pages, backForthNavigationEnabled);
  return question;
};
export const prevQuestion = (
  current,
  questionsMap,
  backForthNavigationEnabled,
  attemptFinished
) => {
  let pages = questionsMap.map((q) => q._id);
  if (backForthNavigationEnabled && !attemptFinished) {
    pages = questionsMap
      .filter((q) => q.usage === QUESTION_USAGE_TYPE.final || !q.questionWatched)
      .map((q) => q._id);
  }

  const question = prev(current, pages, backForthNavigationEnabled);
  return question;
};

export const disableSpaceBarClick = (e) => {
  if (e.keyCode === 32) {
    e.preventDefault();
  }
};
