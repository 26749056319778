/** @jsxImportSource @emotion/react */

import { Fragment, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import ModalHeader from 'components/ModalHeader';
import Modal from 'components/Modal';
import { Input, FieldError, Wrapper } from 'components/htmlElements/Fields';
import Button from 'components/htmlElements/Button';
import LoaderOverlay from 'components/LoaderOverlay';
import SuccessModal from 'components/SuccessModal';

import { useAddChild } from 'api/parents';
import { dateInMMDDYYYY } from 'utils/dateFormats';

import { useUserAccountContext } from 'components/EditAccount/userAccountContext';
import { spacer } from 'styles/utilities';

const currentDate = dateInMMDDYYYY(new Date()).replace(/-/g, '/');

const addCandidateValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  dob: Yup.date().max(currentDate, 'Date cannot be in future').required('Required'),
  username: Yup.string()
    .matches(/^.[a-zA-Z0-9_]+$/, {
      message: 'Only letters, numbers or underscores allowed',
      excludeEmptyString: true
    })
    .required('Required'),
  password: Yup.string().required('Required').min(6, 'Must contain atleast 6 characters'),
  // .matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //   'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
  // ),
  confirmPassword: Yup.string()
    .required('Confirm your password')
    .oneOf([Yup.ref('password')], 'Password does not match')
});

const AddCandidateAccount = () => {
  const { isAddCandidateOpen, toggleAddCandidateModal, closeAddCandidateModal } =
    useUserAccountContext();

  const { addChild, data, isError, isSuccess, reset } = useAddChild();

  useEffect(
    () =>
      // Clear mutation state when modal is opened

      () => {
        reset();
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAddCandidateOpen]
  );

  const handleFormSubmission = async (values, actions) => {
    actions.setSubmitting(true);

    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      dob: new Date(values.dob).toISOString(),
      username: values.username,
      password: values.password
    };

    const resp = await addChild(payload);

    actions.setSubmitting(false);

    if (resp && resp.response.errorCode === 'username_exists') {
      actions.setErrors({ username: 'Username exists. Please use something else' });
    }
  };

  return (
    <div className="is-relative">
      <Modal isOpen={isAddCandidateOpen} toggle={toggleAddCandidateModal}>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            dob: '',
            username: '',
            password: '',
            confirmPassword: ''
          }}
          validationSchema={addCandidateValidationSchema}
          onSubmit={handleFormSubmission}
        >
          {({ isSubmitting, values, resetForm }) => (
            <Fragment>
              {!isSuccess || (data && data.response.errorCode === 'username_exists') ? (
                <div css={[spacer.padBT30, spacer.padLR30]}>
                  <ModalHeader header="Add child account" />
                  <Form>
                    <div>
                      <Wrapper>
                        <Field
                          type="text"
                          name="firstName"
                          id="firstName"
                          as={Input}
                          placeholder="First name"
                        />
                        <ErrorMessage name="firstName" component={FieldError} />
                      </Wrapper>
                      <Wrapper>
                        <Field
                          type="text"
                          name="lastName"
                          id="lastName"
                          as={Input}
                          placeholder="Last name"
                        />
                        <ErrorMessage name="lastName" component={FieldError} />
                      </Wrapper>
                      <Wrapper>
                        <Field type="date" name="dob" id="dob" as={Input} placeholder="Birthdate" />
                        <ErrorMessage name="dob" component={FieldError} />
                      </Wrapper>
                      <Wrapper>
                        <Field
                          type="text"
                          name="username"
                          id="username"
                          autoComplete="off"
                          as={Input}
                          placeholder="Child's username"
                        />
                        <ErrorMessage name="username" component={FieldError} />
                      </Wrapper>
                      <Wrapper>
                        <Field
                          type="password"
                          name="password"
                          id="password"
                          autoComplete="off"
                          as={Input}
                          placeholder="Child's password "
                        />
                        <ErrorMessage name="password" component={FieldError} />
                      </Wrapper>
                      <Wrapper>
                        <Field
                          type="password"
                          name="confirmPassword"
                          id="confirmPassword"
                          autoComplete="off"
                          as={Input}
                          placeholder="Confirm Password"
                        />
                        <ErrorMessage name="confirmPassword" component={FieldError} />
                      </Wrapper>

                      {isError && (
                        <Wrapper>
                          <FieldError>
                            There was a problem creating the account, please try again
                          </FieldError>
                        </Wrapper>
                      )}
                    </div>

                    <Button wide textCenter rounded type="submit">
                      Create Account
                    </Button>
                  </Form>

                  {isSubmitting && <LoaderOverlay />}
                </div>
              ) : (
                <SuccessModal
                  onClick={closeAddCandidateModal}
                  message="Account successfully created"
                  formReset={reset}
                  clearFields={resetForm}
                  name={{ firstName: values.firstName, lastName: values.lastName }}
                  isCandidateForm
                />
              )}
            </Fragment>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

AddCandidateAccount.propTypes = {};

export default AddCandidateAccount;
