/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TabNav from 'components/StyledTabs/StyledTabs';
import H1 from 'components/htmlElements/H1';
import { colors, spacer } from 'styles/utilities';
import ProductBand from 'components/ProductBand/ProductBand';
import H3 from 'components/htmlElements/H3';
import { PRODUCT_CARD_TYPES } from 'globals/constants';
import { getProductCategory } from 'components/ProductCard/helpers';
import { useUserAccountContext } from 'components/EditAccount/userAccountContext';
import Button from 'components/htmlElements/Button';
import P from 'components/htmlElements/P';
import Icon from 'components/Icon/Icon';
import * as styles from './PurchasedProducts.styles';

const PlaceHolderText = () => (
  <div css={[spacer.padBT20, spacer.padL10, spacer.padR40]}>
    <H3 gBold color={colors.mediumGrey}>
      Nothing to assign
    </H3>
  </div>
);

const PurchasedProducts = ({ productsData, handleAssignProduct, items }) => {
  const { openAddCandidateModal } = useUserAccountContext();

  const tests = useMemo(
    () => productsData.filter((productData) => productData.product_collection[0].slug === 'tests'),
    [productsData]
  );
  const courses = useMemo(
    () =>
      productsData.filter((productData) => productData.product_collection[0].slug === 'courses'),
    [productsData]
  );
  const skillPractices = useMemo(
    () =>
      productsData.filter(
        (productData) => productData.product_collection[0].slug === 'skill-practice'
      ),
    [productsData]
  );

  const mockExams = useMemo(
    () =>
      productsData
        .filter((productData) => productData.product_collection[0].slug === 'live-mocks')
        .filter((item) => new Date(`${item?.product_acf?.livemocks_date}`) >= Date.now()),
    [productsData]
  );

  // filter live mocks out of all products array then concat it with filtered mockExams
  const allProducts = useMemo(
    () =>
      productsData
        .filter((productData) => productData.product_collection[0].slug !== 'live-mocks')
        .concat(mockExams),
    [mockExams, productsData]
  );

  const tabs = useMemo(
    () => [
      `All (${tests.length + courses.length + skillPractices.length + mockExams.length})`,
      `Tests  (${tests.length})`,
      `Courses (${courses.length})`,
      `Skill Practice (${skillPractices.length})`,
      `Mock Exams (${mockExams.length})`
    ],
    [tests.length, courses.length, skillPractices.length, mockExams.length]
  );

  return (
    <div css={spacer.padBT40}>
      <H1 gBook css={[spacer.mrT0, spacer.mrB40]}>
        Unassigned Products
      </H1>

      <div css={styles.ProductBandsContainer} className={items.length === 0 ? 'locked' : ''}>
        <TabNav tabs={tabs}>
          <div>
            {allProducts.length > 0 ? (
              allProducts.map((productData) => (
                <ProductBand
                  key={productData.id}
                  examType={getProductCategory(productData.product_cat)}
                  productBandType={
                    productData.product_collection[0].slug === 'live-mocks'
                      ? PRODUCT_CARD_TYPES.mockExam
                      : PRODUCT_CARD_TYPES.assignTest
                  }
                  productName={productData.name}
                  handleClick={(id) => handleAssignProduct(id, productData.product_id)}
                  items={items}
                  quantity={productData.quantity}
                  assigned={productData.assigned}
                  mockExamData={
                    productData.product_collection[0].slug === 'live-mocks'
                      ? { product: productData?.meta_data, details: productData?.product_acf }
                      : {}
                  }
                />
              ))
            ) : (
              <PlaceHolderText />
            )}
          </div>

          <div>
            {tests.length > 0 ? (
              tests.map((productData) => (
                <ProductBand
                  key={productData.id}
                  examType={getProductCategory(productData.product_cat)}
                  productBandType={PRODUCT_CARD_TYPES.assignTest}
                  productName={productData.name}
                  handleClick={(id) => handleAssignProduct(id, productData.product_id)}
                  items={items}
                  quantity={productData.quantity}
                  assigned={productData.assigned}
                />
              ))
            ) : (
              <PlaceHolderText />
            )}
          </div>

          <div>
            {courses.length > 0 ? (
              courses.map((productData) => (
                <ProductBand
                  key={productData.id}
                  examType={getProductCategory(productData.product_cat)}
                  productBandType={PRODUCT_CARD_TYPES.assignTest}
                  productName={productData.name}
                  handleClick={(id) => handleAssignProduct(id, productData.product_id)}
                  items={items}
                  quantity={productData.quantity}
                  assigned={productData.assigned}
                />
              ))
            ) : (
              <PlaceHolderText />
            )}
          </div>

          <div>
            {skillPractices.length > 0 ? (
              skillPractices.map((productData) => (
                <ProductBand
                  key={productData.id}
                  examType={getProductCategory(productData.product_cat)}
                  productBandType={PRODUCT_CARD_TYPES.assignTest}
                  productName={productData.name}
                  handleClick={(id) => handleAssignProduct(id, productData.product_id)}
                  items={items}
                  quantity={productData.quantity}
                  assigned={productData.assigned}
                />
              ))
            ) : (
              <PlaceHolderText />
            )}
          </div>

          <div>
            {mockExams.length > 0 ? (
              mockExams.map((productData) => (
                <ProductBand
                  key={productData.id}
                  examType={getProductCategory(productData.product_cat)}
                  productBandType={PRODUCT_CARD_TYPES.mockExam}
                  productName={productData.name}
                  handleClick={(id) => handleAssignProduct(id, productData.product_id)}
                  items={items}
                  quantity={productData.quantity}
                  assigned={productData.assigned}
                  mockExamData={{
                    product: productData?.meta_data,
                    details: productData?.product_acf
                  }}
                />
              ))
            ) : (
              <PlaceHolderText />
            )}
          </div>
        </TabNav>

        {items.length === 0 && (
          <div className="locked-cta-container text-center">
            <div className="locked-cta-wrapper">
              <div className="lock-svg">
                <Icon icon="lock" color={colors.black} size={212} />
              </div>
              <H1 as={P} gBold css={spacer.mrB20}>
                Add a child account first to access your purchases
              </H1>
              <Button rounded textCenter onClick={openAddCandidateModal}>
                Add Child Account
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

PurchasedProducts.propTypes = {
  productsData: PropTypes.array.isRequired,
  handleAssignProduct: PropTypes.func.isRequired,
  items: PropTypes.array
};

PurchasedProducts.defaultProps = {
  items: []
};

export default PurchasedProducts;
