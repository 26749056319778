/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors, mixins } from 'styles/utilities';

export const questionWrapper = css`
  .section-instructions,
  .section-answer-options {
    flex-grow: 1.5;
  }

  .section-answer-options {
    align-self: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-grow: 2;
    flex-wrap: wrap;
    > * {
      flex: 0 1 auto;
    }
  }

  &.attempt-finished {
    .section-instructions {
      flex-grow: unset;
    }
  }
`;

export const answerBlock = ({ isCorrectAnswer, checked, attemptFinished }) => css`
  margin: 10px 0;

  .answer-text {
    padding: 8px 2px;
    background-color: transparent;
    margin: 0;
    white-space: nowrap;
    background-color: transparent;
    ${mixins.transition()};
    &:hover {
      cursor: pointer;
      background-color: ${colors.orange2};
      color: ${colors.white};
    }
  }
  &:last-of-type {
    .answer-text {
      margin-left: 40px;
    }
  }
  input {
    display: none;
    &:checked + .answer-text {
      background-color: ${colors.orange2};
      color: ${colors.white};
    }
  }

  ${attemptFinished &&
  css`
    .answer-text:hover {
      cursor: default;
      background-color: transparent;
      color: ${colors.black};
    }
  `}

  ${checked && attemptFinished
    ? css`
        .answer-text {
          background-color: ${isCorrectAnswer ? colors.green : colors.red};
          color: ${colors.white};
        }
      `
    : isCorrectAnswer &&
      css`
        .answer-text {
          background-color: ${colors.green};
          color: ${colors.white};
          &:hover {
            background-color: ${colors.green};
            color: ${colors.white};
          }
        }
      `}
`;
