// This file should contain all global styles
import { css } from '@emotion/react';
import { colors, misc, mixins, typography } from 'styles/utilities';
import { respondToMax } from 'styles/helpers/mixins';

// import fonts from 'styles/helpers/fonts';
import reboot from './bootstrap-reboot';
import FormFieldResets from './FormFieldResets';

// Import additional global styles

const globalStyles = css`
  ${reboot}
  ${'' /* ${fonts} */}

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: ${colors.grey2};
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.mediumGrey};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.black2};
  }

  :focus-visible {
    outline: none;
  }

  html,
  body {
    width: 100%;
    margin: 0;
    padding: 0;
    height: 100%;
  }
  body,
  body .default-font-wrapper {
    color: ${colors.black};
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: ${typography.fonts.gothamBook.fontFamily};
    line-height: 120%;
    font-style: normal;
    letter-spacing: normal;
    font-size: ${typography.baseSizes.eta};
  }

  math {
    font-family: 'Gotham SSm Book A', 'Gotham SSm Book B', Arial, sans-serif;
    line-height: 120%;
    font-style: normal;
    letter-spacing: normal;
    math-style: normal;
  }

  mstyle {
    math-style: normal;
  }

  #app {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    > .main {
      flex-grow: 1;
    }
  }

  img {
    vertical-align: bottom;
    height: auto;
  }

  video {
    vertical-align: bottom;
  }

  svg {
    vertical-align: bottom;
  }
  ul,
  ol {
    padding: 0;
    margin: 0;
    .ck-content &,
    &.styled {
      padding-left: 1.5em;
    }
    &.unstyled {
      list-style: none;
    }
  }
  sup {
    font-size: 0.8em;
    top: 0;
    vertical-align: super;
  }

  b,
  strong {
    font-family: ${typography.fonts.gothamBold.fontFamily};
  }

  .is-relative {
    position: relative;
  }

  ${respondToMax(misc.viewport['c-vp'])} {
    .container:not(.no-push-container) {
      padding-left: ${misc.gutterSpacings['max-c-vp']};
      padding-right: ${misc.gutterSpacings['max-c-vp']};
    }
    .no-push-container {
      padding-left: ${parseInt(misc.rowColumnSpacing) * 2}px;
      padding-right: ${parseInt(misc.rowColumnSpacing) * 2}px;
    }
    .row {
      margin-left: ${parseInt(misc.rowColumnSpacing) * -1}px;
      margin-right: ${parseInt(misc.rowColumnSpacing) * -1}px;
    }
    [class*='col-'] {
      padding-left: ${misc.rowColumnSpacing};
      padding-right: ${misc.rowColumnSpacing};
    }
    .container.edge-container {
      padding-left: 0;
      padding-right: 0;
      > .row {
        margin-left: 0;
        margin-right: 0;
        padding-left: ${misc.rowColumnSpacing};
        padding-right: ${misc.rowColumnSpacing};
      }
    }
  }

  ${FormFieldResets}

  p {
    margin: ${misc.defaultTextMargin};
  }

  a,
  .a {
    &:hover {
      color: ${colors.primaryLinkHover};
      text-decoration: none;
      fill: ${colors.primaryLinkHover};
    }
    &[isactive='true'],
    &:active {
      color: ${colors.primaryLinkActive};
      fill: ${colors.primaryLinkActive};
    }
    ${mixins.transition()}
    ${mixins.linkActiveStyles} {
      outline: none;
    }
  }

  .border-default {
    border: ${misc.border.default};
  }
  .border-radius-default {
    border-radius: ${misc.largeBorderRadius};
  }
  .br-t {
    border-top: ${misc.border.default};
  }
  .br-r {
    border-right: ${misc.border.default};
  }
  .br-b {
    border-bottom: ${misc.border.default};
  }
  .br-l {
    border-left: ${misc.border.default};
  }

  .card-default {
    background-color: ${colors.white};
    border-radius: ${misc.largeBorderRadius};
    overflow: hidden;
    border: ${misc.border.default};
    &.no-border {
      border: none;
    }
  }
  .card-spacing-default {
    padding: ${misc.cardSpacing.default};
  }
  .card-spacing-small {
    padding: ${misc.cardSpacing.small};
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const globals = {
  ...globalStyles
};
