/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { products } from 'globals/constants';
import { bgColor, spacer, colors } from 'styles/utilities';
import H3 from '../htmlElements/H3';

import * as styles from './ProductCard.styles';

export const getProductCategory = (categories) => {
  const productCategories = Object.values(products).map((value) => value.name);

  const productCategory = categories.filter((item) => productCategories.includes(item.slug))?.[0]
    ?.slug;

  return productCategory;
};

export const getProductColor = (productName) => {
  // If no product name is passed, return green
  if (!productName) {
    return colors.green;
  }

  const name = productName.toLowerCase();
  if (name === products.CAT.name) {
    return products.CAT.color;
  }
  if (name === products.CEM.name) {
    return products.CEM.color;
  }
  if (name === products.UKISET.name) {
    return products.UKISET.color;
  }
  if (name === products.PRETEST.name) {
    return products.PRETEST.color;
  }
  if (name === products.PROGRESS.name) {
    return products.PROGRESS.color;
  }
  if (name === products.LONDON_CONSORTIUM.name) {
    return products.LONDON_CONSORTIUM.color;
  }
  if (name === products.SEVEN_PLUS.name) {
    return products.SEVEN_PLUS.color;
  }
  if (name === products.EIGHT_PLUS.name) {
    return products.EIGHT_PLUS.color;
  }
  if (name === products.TEN_PLUS.name) {
    return products.TEN_PLUS.color;
  }
  if (name === products.ELEVEN_PLUS.name) {
    return products.ELEVEN_PLUS.color;
  }
  if (name === products.THIRTEEN_PLUS.name) {
    return products.THIRTEEN_PLUS.color;
  }
  if (name === products.INCAS.name) {
    return products.INCAS.color;
  }
  if (name === products.ADAPTIVE_ADMISSIONS_TEST.name) {
    return products.ADAPTIVE_ADMISSIONS_TEST.color;
  }
  if (name === products.PRE_11_PLUS.name) {
    return products.PRE_11_PLUS.color;
  }
  return '';
};

const TopBand = ({ examType }) => <div css={styles.topBand(getProductColor(examType))} />;

TopBand.propTypes = {
  examType: PropTypes.string.isRequired
};

export const ProductCardTop = ({ examType, productType, productName }) => (
  <Fragment>
    <TopBand examType={examType} />

    <div
      css={[styles.productName, bgColor.grey2, spacer.padLR15]}
      className={`product-name ${productType === 'courses' ? 'course' : ''}`}
    >
      <H3 gBlack semiBold css={spacer.padR40} className="product">
        {productName}
      </H3>
    </div>
  </Fragment>
);

ProductCardTop.propTypes = {
  examType: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  productType: PropTypes.string
};

ProductCardTop.defaultProps = {
  productType: 'test'
};
