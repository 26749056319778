/** @jsxImportSource @emotion/react */
import { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';

import { Container, Row, Col } from 'components/Grid';
import P from 'components/htmlElements/P';
import SolutionExplainer from 'components/SolutionExplainer/SolutionExplainer';
import { InstructionLine } from 'components/Instructions';

import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';

import { spacer, colors } from 'styles/utilities';
import * as sharedStyles from 'components/QuestionTypes/shared-components/shared.styles';
import QuestionsCommonWrapperContainer from 'components/QuestionTypes/shared-components/QuestionsCommonWrapper';
import { cloneDeep, shuffle } from 'lodash-es';

import Select from 'components/htmlElements/Fields/Select';
import * as styles from './IncasClozeQuestion.styles';

const IncasClozeQuestion = ({ currentQuestion }) => {
  const { instructionsText, questionText, solutionExplaination, answerOptions, answer } =
    currentQuestion.incasClozeQuestion;
  const { ttAnswers } = currentQuestion;

  const {
    skippingAllowed,
    showNextButton,
    answersEditableBeforeSubmission,
    updateAnswerPayload,
    setSubmitLastQuestionPayload,
    isQuestionUsageFinal
  } = TestModuleInfoContainer.useContainer();

  const { attemptFinished, isPracticeQuestion, isNoteOrSampleQuestion } =
    QuestionsCommonWrapperContainer.useContainer();

  // Save selected answer options
  const [selectedAnswers, setSelectedAnswers] = useState(new Array(answerOptions.length).fill(''));

  useEffect(
    // This effect simply clears the selectedAnswers on question change. It ensures on question switch the previous state is cleared
    () => () => {
      setSelectedAnswers([]);
    },
    [currentQuestion._id]
  );

  // Update `selectedAnswers` if already answered and/or attempt is completed
  useEffect(() => {
    if (ttAnswers && ttAnswers.length) {
      setSelectedAnswers(ttAnswers);
    }

    // Clear the checked answers array on unmount
    // Without this there's a bug which messes up the next question if it's of the same type
    return () => {
      setSelectedAnswers([]);
    };
  }, [ttAnswers]);

  // Handle disabling of answering
  const [answeringDisabled, handleAnsweringDisabled] = useState(false);
  useEffect(() => {
    // If editable and not answered - can answer
    // If not editable and not answered - can answer
    // If not editable and answered - cannot answer
    if (!attemptFinished) {
      if (!ttAnswers) {
        // This means it's not answered
        handleAnsweringDisabled(false);
      } else if (!answersEditableBeforeSubmission) {
        handleAnsweringDisabled(true);
      }
    } else {
      // If attempt is over answering is disabled
      handleAnsweringDisabled(true);
    }

    return () => {
      // Reset state
      handleAnsweringDisabled(!answersEditableBeforeSubmission);
    };
  }, [answersEditableBeforeSubmission, attemptFinished, ttAnswers]);

  // Toggle next button visibility
  useEffect(() => {
    // Check if total answers is equal to available answerOptions
    const allAnswersFilled = selectedAnswers.length === answerOptions.length;

    if (!skippingAllowed && !isNoteOrSampleQuestion && !allAnswersFilled) {
      showNextButton(false);
    } else {
      showNextButton(true);
    }
  }, [
    answerOptions.length,
    isNoteOrSampleQuestion,
    selectedAnswers,
    showNextButton,
    skippingAllowed
  ]);

  // Handle updating answer payload
  useEffect(() => {
    updateAnswerPayload({
      questionId: currentQuestion._id,
      answerArray: selectedAnswers
    });
  }, [selectedAnswers, currentQuestion._id, updateAnswerPayload]);

  // Submit last/current question the user is on when time is up
  useEffect(() => {
    setSubmitLastQuestionPayload(true);
  }, [setSubmitLastQuestionPayload]);

  // adding chosen option as an array with it's respective index in a state
  const handleInputChange = (value, index) => {
    const chosenOptions = cloneDeep(selectedAnswers);

    chosenOptions[index] = value;

    setSelectedAnswers(chosenOptions);
  };

  // keeping track of indices
  let lastIndexFound = -1;

  // eslint-disable-next-line consistent-return
  function transform(node, index) {
    if (node.type === 'tag' && node.name === 'span' && node.attribs['data-response-plugin-value']) {
      const currentIndex = answerOptions.findIndex(
        (item, i) => i > lastIndexFound && item.includes(node.attribs['data-response-plugin-value'])
      );

      lastIndexFound = currentIndex;

      const isCorrect = (() => {
        if (isPracticeQuestion || isNoteOrSampleQuestion) {
          return true;
        }

        if (selectedAnswers.length) {
          return selectedAnswers?.[currentIndex] === answer?.[currentIndex];
        }

        return false;
      })();

      return (
        <Fragment>
          {!answeringDisabled ? (
            <div
              key={`${node.attribs['data-response-plugin-value']}-${index}`}
              css={styles.clozeInput}
            >
              <Select
                placeholder={selectedAnswers[currentIndex] || `Select answer`}
                options={shuffle(
                  answerOptions[currentIndex].map((option) => ({
                    option,
                    value: option
                  }))
                )}
                setValue={(value) => handleInputChange(value, currentIndex)}
              />
            </div>
          ) : (
            <span
              css={styles.clozeAnswer({ isCorrect, answer: ttAnswers?.[currentIndex]?.length })}
              key={`${node.attribs['data-response-plugin-value']}-${index}`}
            >
              {ttAnswers?.[currentIndex]?.length ? ttAnswers?.[currentIndex] : `          `}
            </span>
          )}
        </Fragment>
      );
    }
  }

  return (
    <div css={[spacer.mrT30]}>
      <Container>
        <Row>
          <Col>
            {instructionsText && <InstructionLine text={instructionsText} />}

            <div
              css={[
                spacer.mrB40,
                sharedStyles.types,
                sharedStyles.richTextQuestion,
                styles.questionWrapper
              ]}
              className="ck-content"
            >
              {ReactHtmlParser(DOMPurify.sanitize(questionText), { transform })}
            </div>

            {attemptFinished && !ttAnswers?.length && isQuestionUsageFinal && (
              <P color={colors.red} large>
                Question not attempted.
              </P>
            )}

            {attemptFinished && <SolutionExplainer text={solutionExplaination} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

IncasClozeQuestion.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

const WithQuestionProvider = (props) => {
  const { questionSettings } = props.currentQuestion.incasClozeQuestion;

  return (
    <QuestionsCommonWrapperContainer.Provider
      initialState={{ currentQuestion: props.currentQuestion, questionSettings }}
    >
      <IncasClozeQuestion {...props} />
    </QuestionsCommonWrapperContainer.Provider>
  );
};
WithQuestionProvider.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

export default WithQuestionProvider;
