import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import styledMap from 'styled-map';
import { UnstyledLink } from 'components/Router/Router';
import { typography, colors, mixins } from 'styles/utilities';

const Link = styled(UnstyledLink, {
  shouldForwardProp: (prop) => ['getProps'].includes(prop) || isPropValid(prop)
})`
  font-family: ${typography.fonts.gothamBook.fontFamily};
  line-height: inherit;
  outline: none;
  cursor: pointer;
  font-size: ${styledMap`
    small: ${typography.baseSizes.iota};
    default: 14px;
  `};
  text-decoration: none;
  background-color: ${styledMap`
      noBg: transparent;
      default: unset;
    `};
  // border: ${styledMap`
  //   noBg: none;
  //   default: unset;
  // `};
  ${mixins.transition('0.2s', 'all', 'ease-in-out')}
  ${mixins.linkActiveStyles} {
    outline: none;
  }
  text-transform: ${(props) => (props.transform ? props.transform : 'none')};
  color: ${(props) => (props.color ? props.color : colors.primaryAnchorDefaultColor)};
  fill: ${styledMap`
      highlightSvg: ${(props) => (props.color ? props.color : colors.primaryAnchorDefaultColor)};
      default: '';
    `};
  &:hover {
    color: ${colors.primaryLinkHover};
    text-decoration: none;
    fill: ${styledMap`
        highlightSvg: ${colors.primaryLinkHover};
        default: '';
      `};
  }
  &[isactive='true'],
  &:active {
    color: ${colors.primaryLinkActive};
    fill: ${styledMap`
        highlightSvg: ${colors.primaryLinkActive};
        default: '';
      `};
  }
`;

export default Link;
