import React, { useLayoutEffect, Fragment } from 'react';
import NavsContainer from 'hooks/useNavs';

import MetaWrapper from 'components/MetaWrapper';
import Login from './page';

const LoginContainer = () => {
  const { hideNav, hideFooter, showNav, showFooter } = NavsContainer.useContainer();

  useLayoutEffect(() => {
    hideNav();
    hideFooter();

    return () => {
      showNav();
      showFooter();
    };
  }, [hideFooter, hideNav, showFooter, showNav]);

  return (
    <Fragment>
      <MetaWrapper title="Login" />
      <Login />
    </Fragment>
  );
};

export default LoginContainer;
