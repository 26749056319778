/** @jsxImportSource @emotion/react */
import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';

import * as stylesheet from './Tooltip.styles';

const Tooltip = React.forwardRef(
  ({ tooltipChildren, setArrowElement, isVisible, styles, attributes, popperElementConfig }) => (
    <div
      {...popperElementConfig}
      css={[stylesheet.tooltipWrapper, stylesheet.tooltipState({ isVisible })]}
      style={styles.popper}
      {...attributes.popper}
      className="tooltip-content"
    >
      {cloneElement(tooltipChildren)}
      <div
        ref={setArrowElement}
        css={stylesheet.arrow}
        style={styles.arrow}
        className="tooltip-arrow"
      />
    </div>
  )
);

Tooltip.propTypes = {
  tooltipChildren: PropTypes.element.isRequired,
  setArrowElement: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  styles: PropTypes.shape({
    popper: PropTypes.any,
    arrow: PropTypes.any
  }).isRequired,
  attributes: PropTypes.shape({
    popper: PropTypes.any,
    arrow: PropTypes.any
  }).isRequired,
  popperElementConfig: PropTypes.shape({
    ref: PropTypes.func
  }).isRequired
};

export default Tooltip;
