/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
// import { colors } from 'styles/utilities';

export const draggrableItemsWrapper = css`
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  > * {
    margin-right: 10px;
  }
  &.answer-parts-wrapper > * {
    margin-right: 5px;
    &.answer-parts-text {
      margin-left: 0;
    }
  }
`;
