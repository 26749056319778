/** @jsxImportSource @emotion/react */
import React, { useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

// styling
import P from 'components/htmlElements/P';
import { spacer } from 'styles/utilities';
import * as styles from './CheckBox.styles';

const Checkbox = React.forwardRef(
  ({ id, label, indeterminate, hideCheckbox, disabled, ...inputProps }, ref) => {
    const checkRef = useRef(null);
    const checkboxEl = ref || checkRef;

    useEffect(() => {
      checkboxEl.current.indeterminate = indeterminate;
    }, [checkboxEl, indeterminate]);

    return (
      <label
        htmlFor={id}
        className={
          checkboxEl.current?.checked
            ? 'd-flex align-items-center inputSelected'
            : 'd-flex align-items-center'
        }
        css={[spacer.padBT15, spacer.padLR15, styles.label({ disabled })]}
      >
        <input type="checkbox" ref={checkboxEl} id={id} {...inputProps} disabled={disabled} />
        {!hideCheckbox && (
          <span className="checkbox-box" css={styles.checkboxBox}>
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xlinkHref="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="32px"
              height="32px"
              viewBox="199.524 41.5 32 32"
              enableBackground="new 199.524 41.5 32 32"
              xmlSpace="preserve"
              className="checkmark"
              css={styles.checkmark}
            >
              <path
                className="checkmark_check"
                fill="none"
                stroke="#000000"
                strokeWidth="1.0752"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M200.633,60.353l7.981,8.113l21.802-21.932"
              />
            </svg>
          </span>
        )}
        {typeof label === 'object' ? (
          <Fragment>{label}</Fragment>
        ) : (
          <P className="label-text">{label}</P>
        )}
      </label>
    );
  }
);

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  indeterminate: PropTypes.bool,
  hideCheckbox: PropTypes.bool,
  disabled: PropTypes.bool
};

Checkbox.defaultProps = {
  id: null,
  label: '',
  indeterminate: undefined,
  hideCheckbox: false,
  disabled: false
};

export default Checkbox;
