//* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors, misc, mixins } from 'styles/utilities';

export const wrapper = (props) => css`
  ${(() => {
    if (props.attemptFinished) {
      return css`
        display: flex;
        justify-content: center;
        padding: 0 20px;
      `;
    }

    return css``;
  })()}
`;

export const container = (props) => css`
  display: flex;
  align-items: center;
  flex-wrap: ${props.attemptFinished ? 'wrap' : 'nowrap'};
`;

export const progressPill = (props) => css`
  width: ${props.attemptFinished ? '20px' : '12px'};
  height: 24px;
  border: 2px solid ${props.isCurrent ? colors.black : 'transparent'};
  margin: ${props.attemptFinished ? '2px 0' : '0'};
  border-radius: ${misc.smallBorderRadius};

  &:not(:last-of-type) {
    margin-right: 8px;
  }
  ${
    '' /* background-color: ${props.answered
    ? !props.isCorrect && props.showWrongIndicator
      ? colors.red
      : colors.green
    : colors.mediumGrey}; */
  }
  ${(() => {
    if (!props.isFinalQuestion) {
      return css`
        background-color: ${colors.lightBlue};
        display: none;
      `;
    }
    if (props.answered) {
      if (!props.isCorrect && props.showWrongIndicator) {
        return css`
          background: ${colors.red};
        `;
      }
      return css`
        background: ${colors.green};
      `;
    }

    return css`
      background: ${colors.mediumGrey};
    `;
  })()}

  ${mixins.transition()};
  &:hover {
    background: ${colors.primaryLinkHover};
  }

  .number-wrapper {
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    margin-left: -2px;
    margin-top: -2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.white};
    font-size: 0.85em;
    cursor: pointer;
  }
`;
