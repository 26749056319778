/** @jsxImportSource @emotion/react */

import { useState, useRef, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { UserInfoContainer } from 'api/auth';
import { useEditAccount } from 'api/parents';

import { bgColor } from 'styles/utilities';
import Modal from 'components/Modal';
import Carousel from 'components/Carousel';
import * as styles from './WelcomeTour.styles';
import WelcomeTourProvider, { useWelcomeTourContext } from './WelcomeTourContext';

import 'slick-carousel/slick/slick.css';
import Slide from './Slide';
import TourControls from './TourControls';
import { NextArrow, PrevArrow } from './SliderArrows';
import { testStaticSlides } from './slideData/test';
import { courseStaticSlides } from './slideData/course';

const CAROUSEL_SETTINGS = {
  initialSlide: 0,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  swipeToSlide: false,
  swipe: false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
};

const WelcomeTour = ({ staticSlides }) => {
  const { firstName, userIs } = UserInfoContainer.useContainer();

  const { isOpen, closeWelcomeTourModal } = useWelcomeTourContext();
  const [slideNumber, setSlideNumber] = useState(1);
  const carouselRef = useRef(null);

  const slides = [...staticSlides];
  const isLastSlide = slideNumber === slides.length - 1;

  const { editAccount } = useEditAccount();

  const gotoNextSlide = () => {
    if (isLastSlide) {
      setSlideNumber(1);
      closeWelcomeTourModal();

      // Trigger API call to set welcome tour as watched
      if (userIs === 'parent') {
        editAccount({
          meta: {
            user_wellcome_popup: 1
          }
        });
      }
    } else if (carouselRef.current) carouselRef.current.slickNext();
  };

  useEffect(() => {
    if (!isOpen) {
      setSlideNumber(1);
    }
  }, [setSlideNumber, isOpen]);

  return (
    <Fragment>
      <Modal
        hideClose
        tour
        isOpen={isOpen}
        toggle={closeWelcomeTourModal}
        css={styles.modalContainerStyles({ slideNumber, isLastSlide })}
      >
        <div css={[bgColor.blue, styles.bgPtn, slideNumber === 0 ? styles.firstSlide : null]}>
          <Carousel
            ref={carouselRef}
            beforeChange={(_, next) => setSlideNumber(next)}
            settings={CAROUSEL_SETTINGS}
          >
            {slides.map(({ gifURL, title, subtitle, firstSlide }) => (
              <Slide
                key={title}
                gifURL={gifURL}
                title={firstSlide ? title(firstName) : title}
                subtitle={subtitle}
                isFirstSlide={firstSlide}
              />
            ))}
          </Carousel>
          <TourControls
            slideNumber={slideNumber}
            isLastSlide={slideNumber === slides.length - 1}
            gotoNextSlide={gotoNextSlide}
            closeModal={closeWelcomeTourModal}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

WelcomeTour.propTypes = {
  staticSlides: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default WelcomeTour;

// temporary components
export const TestWelcomeTour = () => (
  <WelcomeTourProvider>
    <TestTour />
  </WelcomeTourProvider>
);

export const CourseWelcomeTour = () => (
  <WelcomeTourProvider>
    <CourseTour />
  </WelcomeTourProvider>
);

const TestTour = () => {
  const { showWelcomeTourModal } = useWelcomeTourContext();
  return (
    <div>
      <button onClick={showWelcomeTourModal} type="button">
        Open test tour
      </button>
      <WelcomeTour staticSlides={testStaticSlides} />
    </div>
  );
};

const CourseTour = () => {
  const { showWelcomeTourModal } = useWelcomeTourContext();
  return (
    <div>
      <button onClick={showWelcomeTourModal} type="button">
        Open course tour
      </button>
      <WelcomeTour staticSlides={courseStaticSlides} />
    </div>
  );
};
