/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import * as Sentry from '@sentry/react';

import PretestLogo from 'images/logopretest.svg';
import { Input, FieldError, Wrapper } from 'components/htmlElements/Fields';
import Button from 'components/htmlElements/Button';
import A from 'components/htmlElements/A';
import { useLogin } from 'api/auth';
import LoaderOverlay from 'components/LoaderOverlay';

import { bgColor, spacer } from 'styles/utilities';

import * as styles from './LoginCard.styles';

const signInSchema = Yup.object().shape({
  email: Yup.string().required('Required'),
  password: Yup.string().required('Required')
});

const LoginCard = ({ openForgotPasswordModal }) => {
  const { login, data, isError } = useLogin();
  function toggleForgotPasswordModalOpen(e) {
    e.preventDefault();
    openForgotPasswordModal();
  }

  const handleFormSubmission = async (values, actions) => {
    actions.setSubmitting(true);

    const loginResponse = await login(values);

    actions.setSubmitting(false);

    if (isError) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          loginEmail: values.email,
          apiResponse: JSON.stringify(loginResponse)
        });
        Sentry.setTag('loginAttemptError', 'failed_login');
        Sentry.captureMessage(`Login failed for ${values.email}`);
      });
    }
  };

  return (
    <div css={[bgColor.black, styles.loginCard]} className="is-relative">
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={signInSchema}
        onSubmit={handleFormSubmission}
      >
        {({ isSubmitting }) => (
          <Form>
            <div css={[spacer.padLR30, spacer.padBT30]}>
              <div css={[styles.logoContainer, spacer.mrB30]}>
                <img src={PretestLogo} alt="Pretest Plus" />
              </div>
              <div>
                <Wrapper>
                  <Field
                    type="text"
                    name="email"
                    id="email"
                    as={Input}
                    placeholder="Enter your username or email id"
                  />

                  <ErrorMessage name="email" component={FieldError} />
                </Wrapper>
                <Wrapper>
                  <Field
                    type="password"
                    name="password"
                    placeholder="Enter Password"
                    id="password"
                    as={Input}
                  />
                  <ErrorMessage name="password" component={FieldError} />
                </Wrapper>

                {isError && (
                  <Wrapper>
                    <FieldError>There was a problem logging in, please try again</FieldError>
                  </Wrapper>
                )}

                {data && data.status !== 201 && (
                  <Wrapper>
                    <FieldError>LOGIN CREDENTIALS ARE INCORRECT</FieldError>
                  </Wrapper>
                )}

                <Wrapper>
                  <A onClick={toggleForgotPasswordModalOpen} href="#">
                    Forgotten Password?
                  </A>
                </Wrapper>
              </div>

              <Button rounded textCenter wide type="submit">
                Login
              </Button>

              {isSubmitting && <LoaderOverlay />}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

LoginCard.propTypes = {
  openForgotPasswordModal: PropTypes.func.isRequired
};

export default LoginCard;
