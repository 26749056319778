/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Button from 'components/htmlElements/Button';
import { spacer } from 'styles/utilities';
import H3 from 'components/htmlElements/H3';
import A from 'components/htmlElements/A';
import { Fragment } from 'react';
import H5 from 'components/htmlElements/H5';
import successful from '../../static/images/successful.svg';
import * as styles from './SuccessModal.styles';

const SuccessModal = ({ message, onClick, formReset, name, clearFields, isCandidateForm }) => {
  const handleAddChildAccount = (event) => {
    event.preventDefault();
    formReset();
    clearFields({
      firstName: '',
      lastName: '',
      dob: '',
      username: '',
      password: '',
      confirmPassword: ''
    });
  };

  return (
    <Fragment>
      <div css={[styles.container, spacer.padT40]}>
        <img src={successful} alt="success" css={styles.image} />
      </div>

      <div css={[spacer.padT20, spacer.padB30, spacer.padLR30]}>
        <div css={[spacer.mrB40]} className="text-center">
          <H3 gbold className="no-margin">
            {message}
          </H3>

          {isCandidateForm && (
            <Fragment>
              <H5 gBook css={spacer.mrT10}>
                Account for &ldquo;{name.firstName} {name.lastName}&rdquo; was successfully created.
              </H5>

              <div css={[spacer.padT15, spacer.padB10]}>
                <A large href="#" onClick={handleAddChildAccount}>
                  + Add another account
                </A>
              </div>
            </Fragment>
          )}
        </div>
        <Button wide textCenter rounded onClick={onClick}>
          Close
        </Button>
      </div>
    </Fragment>
  );
};
SuccessModal.propTypes = {
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  formReset: PropTypes.func,
  clearFields: PropTypes.func,
  name: PropTypes.object,
  isCandidateForm: PropTypes.bool
};

SuccessModal.defaultProps = {
  formReset: () => null,
  clearFields: () => null,
  name: {},
  isCandidateForm: false
};

export default SuccessModal;
