/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/htmlElements/Button';
import { spacer } from 'styles/utilities';
import * as styles from './AudioQuestion.styles';

const alphabets = [
  'q',
  'w',
  'e',
  'r',
  't',
  'y',
  'u',
  'i',
  'o',
  'p',
  'a',
  's',
  'd',
  'f',
  'g',
  'h',
  'j',
  'k',
  'l',
  'z',
  'x',
  'c',
  'v',
  'b',
  'n',
  'm'
];

const Keyboard = ({ setAnswer }) => (
  <div css={[spacer.padBT30, spacer.padLR30, styles.KeyboardContainer]}>
    <div css={styles.KeyboardWrapper}>
      {alphabets.map((item) => (
        <styles.KeyboardButton
          onClick={() => setAnswer((prev) => prev.concat(item))}
          key={item}
          className="keyboard-buttons"
          tabIndex={-1}
        >
          {item}
        </styles.KeyboardButton>
      ))}
    </div>

    <div className="d-flex justify-content-between align-items-center w-100" css={spacer.padT20}>
      <Button
        rounded
        textCenter
        css={styles.BottomButton}
        onClick={() => setAnswer((prev) => prev.substring(0, prev.length - 1))}
        className="keyboard-buttons"
        tabIndex={-1}
      >
        Back
      </Button>
      <Button
        rounded
        textCenter
        css={styles.BottomButton}
        onClick={() => setAnswer((prev) => prev.concat(' '))}
        className="keyboard-buttons"
        tabIndex={-1}
      >
        Space
      </Button>
    </div>
  </div>
);

Keyboard.propTypes = {
  setAnswer: PropTypes.func.isRequired
};

export default Keyboard;
