import React, { lazy, Suspense } from 'react';
import Loader from 'components/Loader';
import ErrorBoundary from './ErrorBoundries';

const loadable = (importFunc, { fallback = <Loader /> } = { fallback: <Loader /> }) => {
  // importFunc(); // Preloading
  const LazyComponent = lazy(importFunc);

  return (props) => (
    <ErrorBoundary>
      <Suspense fallback={fallback}>
        <LazyComponent {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};

export default loadable;
