import { useQuery, useMutation, useQueryClient } from 'react-query';
import request from 'utils/request';
import { apiEndpoints } from 'globals/constants';
import ApiRequestHeadersContainer from 'hooks/useExtraApiRequestHeaders';
import { useValidateToken } from 'api/auth';

export const useEditAccount = ({ candidateId, loggedUserIsCandidate }) => {
  const queryClient = useQueryClient();
  const { extraHeaders } = ApiRequestHeadersContainer.useContainer();
  const { validateToken } = useValidateToken();

  const { mutateAsync, isSuccess, isError, reset } = useMutation(
    async (payload) => {
      const { response } = await request({
        method: 'PATCH',
        endpoint: apiEndpoints.candidate.editAccount,
        payload,
        headers: {
          ...extraHeaders
        }
      });
      return response;
    },
    {
      onSettled() {
        // Refetch user here
        if (loggedUserIsCandidate) {
          validateToken();
        } else {
          queryClient.refetchQueries('getCandidate', { candidateId });
        }
      }
    }
  );

  return { editAccount: mutateAsync, isSuccess, isError, reset };
};

export const useCandidateProductRecommendations = ({ productId, candidateId }) => {
  const { extraHeaders } = ApiRequestHeadersContainer.useContainer();

  const { data, isSuccess, isError } = useQuery(
    ['candidateProductRecommendations', { productId, candidateId }],
    async () => {
      const { response } = await request({
        method: 'GET',
        endpoint: apiEndpoints.candidate.getProductRecommendations(productId),
        headers: {
          ...extraHeaders
        }
      });

      return response;
    }
  );

  return { data, isSuccess, isError };
};
