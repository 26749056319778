import styled from '@emotion/styled';
import styledMap from 'styled-map';
import facepaint from 'facepaint';
import { typography, colors, mixins, misc } from 'styles/utilities';

const breakPoints = facepaint([
  mixins.respondTo(misc.viewport['c-vp']),
  mixins.respondTo(misc.viewport['d-vp']),
  mixins.respondTo(misc.viewport['e-vp'])
]);

const H2 = styled.h2`
  font-family: ${styledMap`
    gBlack: ${typography.fonts.gothamBlack.fontFamily};
    gBold: ${typography.fonts.gothamBold.fontFamily};
    gBook: ${typography.fonts.gothamBook.fontFamily};
    mercury: ${typography.fonts.mercuryDisplay.fontFamily};
    default: ${typography.fonts.gothamBold.fontFamily};
  `};
  line-height: 120%;
  font-style: normal;
  margin: ${misc.defaultTextMargin};
  letter-spacing: -0.38px;
  color: ${(props) => (props.color ? props.color : colors.black)};
  ${breakPoints({
    fontSize: ['24px', '', '26px', typography.baseSizes.beta]
  })};
`;

export default H2;

// ${'' /* font-weight: ${props => {
//   if (props.gBlack) {
//     if (props.semiBold) {
//       return typography.fonts.gothamBlack.fontWeight.semiBold;
//     }
//     return typography.fonts.gothamBlack.fontWeight.regular;
//   }
//   if (props.gBold) {
//     if (props.bold) {
//       return typography.fonts.gothamBold.fontWeight.bold;
//     }
//     if (props.medium) {
//       return typography.fonts.gothamBold.fontWeight.medium;
//     }
//     return typography.fonts.gothamBold.fontWeight.light;
//   }
//   return typography.fonts.gothamBook.fontWeight.light;
// }}; */}
