const secondsToHms = (seconds) => {
  const d = Number(seconds);

  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  return `${h > 0 ? `${`0${h}`.slice(-2)}:` : '00:'}${`0${m}`.slice(-2)}:${`0${s}`.slice(-2)}`;
};

export const secondsToHmsNiceName = (seconds) => {
  const d = Number(seconds);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? ' hour' : ' hours') : '';
  const mDisplay = m > 0 ? `${h ? ', ' : ''}${m}${m === 1 ? ' minute' : ' minutes'}` : '';
  const sDisplay = s > 0 ? `${m || h ? ', ' : ''}${s}${s === 1 ? ' second' : ' seconds'}` : '';
  return hDisplay + mDisplay + sDisplay;
};

export default secondsToHms;
