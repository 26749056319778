/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

export const questionWrapper = css`
  .section-instructions,
  .section-answer-options {
    flex-grow: 1.5;
  }

  &.attempt-finished {
    .question-text {
      margin: 80px 0 40px;
    }
  }
`;
