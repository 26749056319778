/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors, misc, mixins } from 'styles/utilities';

export const wrapper = (props) => css`
  position: absolute;
  left: 50%;
  bottom: 60px;
  transform: translate(-50%, -50%);
  padding: 10px 10px;
  border: 1px solid ${colors.mediumGrey};
  background: ${colors.cream};
  border-radius: ${misc.smallBorderRadius};
  ${mixins.transition()};
  opacity: ${props.hide ? 0 : 1};
  visibility: ${props.hide ? 'hidden' : 'visible'};
`;
