/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types';

import P from 'components/htmlElements/P';

import { colors, spacer } from 'styles/utilities';
import * as styles from './SectionBand.styles';

const SectionBand = ({ sectionName, children }) => (
  <div css={[styles.container, spacer.padB10, spacer.mrB20]}>
    <P css={styles.sectionName} color={colors.mediumGrey2} noMargin>
      {sectionName}
    </P>
    <div>{children}</div>
  </div>
);

SectionBand.propTypes = {
  sectionName: PropTypes.string.isRequired,
  children: PropTypes.node
};

SectionBand.defaultProps = {
  children: null
};

export default SectionBand;
