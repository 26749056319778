/** @jsxImportSource @emotion/react */

import React, { useEffect, useState, Fragment, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEqual, cloneDeep } from 'lodash-es';
import * as Sentry from '@sentry/react';

import Button from 'components/htmlElements/Button';
import P from 'components/htmlElements/P';
import { Container, Row, Col } from 'components/Grid';
import PretestLogo from 'images/logopretest.svg';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import TestTimer from 'components/TestTimer';
import ScoreBand from 'components/ScoreBand';
import LoaderOverlay from 'components/LoaderOverlay';
import ScrolldownIndicator from 'components/ScrolldownIndicator';
import TouchscreenNotice from 'components/TouchscreenNotice';

import TestModuleInfoContainer from 'hooks/useTestModuleInfo';
import AssignedTestContainer from 'hooks/useTest';
import {
  prevQuestion as prev,
  nextQuestion as next,
  disableSpaceBarClick
} from 'utils/testsHelpers';

import Text from 'components/QuestionTypes/Text';
import TextISEB from 'components/QuestionTypes/Text/Text-iseb';
import Image from 'components/QuestionTypes/Image';
import Summary from 'components/QuestionTypes/Summary';
import TwoColComprehension from 'components/QuestionTypes/TwoColComprehension';
import SentenceCompletion from 'components/QuestionTypes/SentenceCompletion';
import SpellingPunctuation from 'components/QuestionTypes/SpellingPunctuation';
import ShuffuledSentences from 'components/QuestionTypes/ShuffuledSentences';
import PictureImage from 'components/QuestionTypes/PictureImage';
import Anagram from 'components/QuestionTypes/Anagram';
import SelectedLetters from 'components/QuestionTypes/SelectedLetters';
import Cloze from 'components/QuestionTypes/Cloze';
import IncasClozeQuestion from 'components/QuestionTypes/IncasClozeQuestion';
import MathAnagram from 'components/QuestionTypes/MathAnagram';
import FillInTheBlanks from 'components/QuestionTypes/FillInTheBlanks';
import Matrices from 'components/QuestionTypes/Matrices';
import Geogebra from 'components/QuestionTypes/Geogebra';
import GeogebraOptions from 'components/QuestionTypes/GeogebraOptions';
import AudioQuestion from 'components/QuestionTypes/AudioQuestion';
import FreeTextInput from 'components/QuestionTypes/FreeTextInput';

import { DISABLE_RIGHT_CLICK, QUESTION_USAGE_TYPE, layoutTypes } from 'globals/constants';

import store from 'store';

import { useSubmitAnswer, useEndTestAttempt, useAddMetaToAttempt } from 'api/test';

import EndModulePreview from 'components/EndModulePreview/EndModulePreview';
import NetDisconnectedOverlay from 'components/NetDisconnectedOverlay';

import { colors, spacer, txtColor } from 'styles/utilities';
import { useNetworkState } from 'react-use';
import IsebTestSkeleton from 'components/IsebTestSkeleton/IsebTestSkeleton';
import { getProductCategory } from 'components/ProductCard/helpers';
import QuestionPreloader from './QuestionPreloader';
import * as styles from './TestSkeleton.styles';

export const QuestionTypeComponent = ({ questionTypeSelected, layoutType, ...props }) => {
  switch (questionTypeSelected) {
    case 'text':
      if (layoutType === layoutTypes.ISEB_LAYOUT) {
        return <TextISEB {...props} />;
      }
      return <Text {...props} />;

    case 'image':
      return <Image {...props} />;

    case 'summary':
      return <Summary {...props} />;

    case 'twoColComprehension':
      return <TwoColComprehension {...props} />;

    case 'anagrams':
      return <Anagram {...props} />;

    case 'cloze':
      return <Cloze {...props} />;

    case 'incasClozeQuestion':
      return <IncasClozeQuestion {...props} />;

    case 'shuffledSentences':
      return <ShuffuledSentences {...props} />;

    case 'anagramsMathEq':
      return <MathAnagram {...props} />;

    case 'spellingPunctuation':
      return <SpellingPunctuation {...props} />;

    case 'sentenceCompletion':
      return <SentenceCompletion {...props} />;

    case 'pictureImage':
      return <PictureImage {...props} />;

    case 'selectedLetters':
      return <SelectedLetters {...props} />;

    case 'fillInTheBlanks':
      return <FillInTheBlanks {...props} />;

    case 'matrices':
      return <Matrices {...props} />;

    case 'geogebra':
      return <Geogebra {...props} />;

    case 'geogebraOptions':
      return <GeogebraOptions {...props} />;

    case 'audioQuestion':
      return <AudioQuestion {...props} />;

    case 'freeTextInput':
      return <FreeTextInput {...props} />;

    default:
      return null;
  }
};
QuestionTypeComponent.propTypes = {
  questionTypeSelected: PropTypes.string,
  layoutType: PropTypes.string
};
QuestionTypeComponent.defaultProps = {
  questionTypeSelected: '',
  layoutType: layoutTypes.DEFAULT
};

const TestSkeleton = ({ updatePageTitleTimer, isStartingAttempt }) => {
  const {
    questions,
    backForthNavigationEnabled,

    prevButtonVisible,
    nextButtonVisible,
    attemptFinished,
    answerPayload,
    updateAnswerPayload,
    answerPayloadInitialState,
    testModule,
    timeLimit,
    timerPaused,
    toggleTimerPaused,
    practiceQuestionCallback,
    practiceQuestionAnswered,
    isPracticeQuestion,
    isQuestionUsageFinal,
    clozeQuestionType,
    layoutType,
    submitLastQuestionPayload
  } = TestModuleInfoContainer.useContainer();
  const {
    testInfo,
    testAssignmentInfo,
    isTestAtSection,
    isLondonConsortiumTest,
    testHubAttemptToken
  } = AssignedTestContainer.useContainer();

  const testArea = useRef(null);

  const { submitAnswer, isError: errorOnSubmitAnswer } = useSubmitAnswer(
    testInfo._id,
    testAssignmentInfo.id,
    testModule._id, // Only for optimistically updating data
    testHubAttemptToken
  );

  const { endAttempt, isLoading: endAttemptIsLoading } = useEndTestAttempt(
    testInfo._id,
    testAssignmentInfo.id
  );

  const { addMetaToAttempt } = useAddMetaToAttempt(testInfo._id, testAssignmentInfo.id);

  const generateQindexString = useCallback(
    () => `${testAssignmentInfo.id}_${testModule._id}_qindex`,
    [testAssignmentInfo.id, testModule._id]
  );

  const handleEndAttempt = useCallback(
    async (goBackToTest = false) => {
      const payload = {};
      if (isTestAtSection) {
        payload.sectionId = testModule._id;
      } else {
        payload.subSectionId = testModule._id;
      }
      const endAttemptResponse = await endAttempt(payload);

      // Delete the navigation index
      store.remove(generateQindexString());

      // Delete nav index from meta
      const updatedMetadata = cloneDeep(testInfo.meta);
      if (endAttemptResponse.status === 200) {
        delete updatedMetadata[generateQindexString()];
        await addMetaToAttempt({
          meta: {
            ...updatedMetadata
          }
        });
      }

      if (goBackToTest) {
        window.location.href = `../../test/${testAssignmentInfo.id}`;
      }
    },
    [
      addMetaToAttempt,
      endAttempt,
      generateQindexString,
      isTestAtSection,
      testAssignmentInfo.id,
      testInfo.meta,
      testModule._id
    ]
  );

  // Internet disconnected modal
  const { online } = useNetworkState();

  // Module end preview modal state
  const [showEndModuleModal, toggleShowEndModuleModal] = useState(false);

  // Loader. Can be used by multiple functions to wait for api calls
  const [loaderVisible, toggleLoader] = useState(false);

  // Disable right click
  useEffect(() => {
    if (DISABLE_RIGHT_CLICK) {
      document.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });
    }
  }, []);

  // Questions navigation
  // - Navigation stores the current question index into localstorage
  // - Upon returning to the test module or on refresh, the question to be shown to user
  // is fetched from the localstorage
  // - If at any given point, the index is invalid, it will fallback to first question
  const storeQid = useMemo(() => {
    const qid = store.get(generateQindexString());

    // Accessing endedAt directly since the state update from `useTestModuleInfo` takes time
    if (testModule?.timerData?.endedAt || isStartingAttempt) {
      return 0;
    }

    // This ensures if qindex is not present in localstorage it will get it from the attempt meta
    if (
      typeof qid === 'undefined' &&
      typeof testInfo.meta[generateQindexString()] !== 'undefined' &&
      !attemptFinished
    ) {
      return testInfo.meta[generateQindexString()];
    }

    if (typeof questions[qid] !== 'undefined' && !attemptFinished) {
      return qid;
    }
    return 0;
  }, [
    attemptFinished,
    generateQindexString,
    isStartingAttempt,
    questions,
    testInfo.meta,
    testModule?.timerData?.endedAt
  ]);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(storeQid);

  // Update question index in localstorage on currentQuestionIndex change
  useEffect(() => {
    store.set(generateQindexString(), currentQuestionIndex, new Date().getTime() + 3000);
  }, [currentQuestionIndex, generateQindexString]);

  // Update the current question object
  const [currentQuestion, setCurrentQuestion] = useState({});
  useEffect(() => {
    if (currentQuestionIndex >= 0) {
      setCurrentQuestion(questions[currentQuestionIndex]);
    }
  }, [currentQuestionIndex, questions]);

  // // Update current question index to first unanswered question first time
  // useEffect(() => {
  //   if (!attemptFinished) {
  //     // Find question which isn't answered
  //     const firstUnansweredQuestion = questions.find((q) => !q.ttAnswers);
  //     if (firstUnansweredQuestion && Object.keys(firstUnansweredQuestion).length) {
  //       setCurrentQuestionIndex(questions.indexOf(firstUnansweredQuestion));
  //     } else {
  //       // This is a fallback if all questions are answered and the user exists. They will go to the last question from where they can submit the answers
  //       setCurrentQuestionIndex(questions.length - 1);
  //     }
  //   }
  // }, []);

  // Set a boolean to check if a question is last question or not
  const isLastQuestion = useMemo(
    () => currentQuestionIndex === questions.length - 1,
    [currentQuestionIndex, questions.length]
  );

  const nextQuestionId = useMemo(
    () =>
      next(
        currentQuestion._id,
        questions.map((q) => ({ _id: q._id, usage: q.usage, questionWatched: q.questionWatched })),
        backForthNavigationEnabled,
        attemptFinished
      ),
    [attemptFinished, backForthNavigationEnabled, currentQuestion._id, questions]
  );
  const prevQuestionId = useMemo(
    () =>
      prev(
        currentQuestion._id,
        questions.map((q) => ({ _id: q._id, usage: q.usage, questionWatched: q.questionWatched })),
        backForthNavigationEnabled,
        attemptFinished
      ),
    [attemptFinished, backForthNavigationEnabled, currentQuestion._id, questions]
  );

  // Update currentQuestion to next question
  const goToNextQuestion = () => {
    const nextQuestion = questions.find((q) => q._id === nextQuestionId);
    setCurrentQuestionIndex(questions.indexOf(nextQuestion));

    if (!attemptFinished) {
      addMetaToAttempt({
        meta: {
          ...(testInfo.meta && { ...testInfo.meta }),
          [generateQindexString()]: questions.indexOf(nextQuestion)
        }
      });
    }
  };

  // Handling answer submission
  const handleAnswerSubmission = useCallback(async () => {
    let submissionResponse = null;

    if (!attemptFinished && answerPayload.questionId && isQuestionUsageFinal) {
      // This `isEqual` check allows us to avoid unnecessary api call if answer hasn't changed
      if (!isEqual(answerPayload.answerArray, currentQuestion.ttAnswers)) {
        // Clone and clear out the `answerPayload` after successful api call
        // Ideally updateAnswerPayload should be called after api call but there's an edge case
        // wherein if the user selects an answer option before the previous api call is completed,
        // updateAnswerPayload runs after await which would cause the current question's selection getting cleared
        const clonedAnswerPayload = answerPayload;
        updateAnswerPayload(answerPayloadInitialState);

        const response = await submitAnswer({
          questionId: clonedAnswerPayload.questionId,
          payload: {
            ttAnswers: clonedAnswerPayload.answerArray
          }
        });

        submissionResponse = response;

        if (errorOnSubmitAnswer) {
          // Show proper notification here
          // eslint-disable-next-line no-alert
          alert('There was an issue submitting your answer, please try again or contact support');
          throw new Error('Error submitting answer', response);
        }

        // Log errors in sentry if answering fails
        if (response.status !== 200 || errorOnSubmitAnswer) {
          Sentry.withScope((scope) => {
            scope.setExtras({
              testAttempt: {
                testAttemptId: testInfo._id,
                testModuleId: testModule._id,
                questionId: clonedAnswerPayload.questionId
              },
              testAssignmentId: testAssignmentInfo.id,
              apiResponse: JSON.stringify(response)
            });
            Sentry.setTag('testAttemptError', 'answering_failed');
            Sentry.captureMessage(`Answering question ${clonedAnswerPayload.questionId} failed`);
          });
        }
      }
    }

    return submissionResponse;
  }, [
    answerPayload,
    answerPayloadInitialState,
    attemptFinished,
    currentQuestion.ttAnswers,
    errorOnSubmitAnswer,
    isQuestionUsageFinal,
    submitAnswer,
    testAssignmentInfo.id,
    testInfo._id,
    testModule._id,
    updateAnswerPayload
  ]);

  // Handle prev/next clicks
  const handlePrevClick = () => {
    // This is an async call
    handleAnswerSubmission();

    const previousQuestion = questions.find((q) => q._id === prevQuestionId);
    setCurrentQuestionIndex(questions.indexOf(previousQuestion));

    if (!attemptFinished) {
      addMetaToAttempt({
        meta: {
          ...(testInfo.meta && { ...testInfo.meta }),
          [generateQindexString()]: questions.indexOf(previousQuestion)
        }
      });
    }
  };

  const handleNextClick = async (event, navigateToFirstQuestion = false) => {
    if (!isLastQuestion || navigateToFirstQuestion) {
      if (isPracticeQuestion && !practiceQuestionAnswered) {
        // This handles the first time click for practice questions
        if (typeof practiceQuestionCallback?.callbackFn === 'function') {
          practiceQuestionCallback.callbackFn();
        }
      } else {
        goToNextQuestion();

        // This is an async call
        handleAnswerSubmission();
      }
    } else {
      toggleLoader(true);

      // Pause the timer
      toggleTimerPaused(true);

      // Submit answer to last question and call end attempt
      // This assumes that last question will always be of usage=final which is why
      // we are skipping calling practice question checks and callback here
      await handleAnswerSubmission();

      // Call attempt end here
      await handleEndAttempt(true);

      // Trigger the compeltion modal
      // toggleShowEndModuleModal(true);

      toggleLoader(false);
    }
  };

  // Handling timers
  const handleTimeOver = useCallback(async () => {
    // Pause the timer
    toggleTimerPaused(true);

    // Submit answer if submitLastQuestionPayload is true (applies to InCas cloze questions only)
    if (submitLastQuestionPayload) {
      const submissionResponse = await handleAnswerSubmission();

      const interval = setInterval(() => {
        if (submissionResponse?.status === 200) {
          // Call attempt end here
          handleEndAttempt();

          // Trigger the compeltion modal
          toggleShowEndModuleModal(true);

          clearInterval(interval);
        } else {
          console.info('tried to submit attempt but failed');
        }
      }, 100);

      return;
    }

    if (!endAttemptIsLoading) {
      // Call attempt end here
      handleEndAttempt();

      // Trigger the compeltion modal
      toggleShowEndModuleModal(true);
    }
  }, [
    endAttemptIsLoading,
    handleAnswerSubmission,
    handleEndAttempt,
    submitLastQuestionPayload,
    toggleTimerPaused
  ]);

  // Special case of handling cloze timers
  const handleClozeTimeOver = async () => {
    // Submit answer
    await handleAnswerSubmission();

    // Go to next question if exists otherwise submit answer
    if (nextQuestionId && !isLastQuestion) {
      goToNextQuestion();
    } else {
      // Trigger completion modal and End attempt here

      // Call attempt end here
      handleEndAttempt();

      // Trigger the compeltion modal
      toggleShowEndModuleModal(true);
    }
  };

  const Score = useCallback(
    (props) => (
      <Fragment>
        {testModule.resultData ? (
          <ScoreBand
            score={`${testModule.resultData.scored}/${testModule.resultData.total}`}
            statistics={{
              average: `${testModule?.statistics?.avg}/${testModule.resultData.total}`,
              lowest: `${testModule?.statistics?.min}/${testModule.resultData.total}`,
              highest: `${testModule?.statistics?.max}/${testModule.resultData.total}`
            }}
            border
            {...props}
          />
        ) : null}
      </Fragment>
    ),
    [
      testModule.resultData,
      testModule?.statistics?.avg,
      testModule?.statistics?.max,
      testModule?.statistics?.min
    ]
  );

  return (
    <div css={styles.skeletonWrapper}>
      <Fragment>
        {layoutType === layoutTypes.DEFAULT && (
          <Fragment>
            <div css={[styles.navContainer]} className="flex-shrink-1">
              <TouchscreenNotice
                testCategory={getProductCategory(testAssignmentInfo.wpMeta?.categories)}
              />
              <Container>
                <Row>
                  <Col>
                    <div css={styles.testNav}>
                      <div className="nav-left">
                        <img src={PretestLogo} alt="pretest-logo" className="logo" />
                      </div>
                      <P color={colors.green}>
                        {testAssignmentInfo.wpMeta.name} &gt; {testModule.name}
                      </P>
                      {!attemptFinished ? (
                        <div className="nav-right">
                          {clozeQuestionType.isCloze ? (
                            <TestTimer
                              time={clozeQuestionType.timeLimit || 0}
                              timerOnComplete={handleClozeTimeOver}
                              pauseTimer={
                                !!(isPracticeQuestion && clozeQuestionType.isClozeTimerPaused)
                              }
                              key={clozeQuestionType.id}
                              hideSeconds={isLondonConsortiumTest}
                            />
                          ) : (
                            <Fragment>
                              {timeLimit && (
                                <TestTimer
                                  time={timeLimit}
                                  pauseTimer={timerPaused}
                                  timerOnComplete={handleTimeOver}
                                  updatePageTitleTimer={updatePageTitleTimer}
                                  hideSeconds={isLondonConsortiumTest}
                                />
                              )}
                            </Fragment>
                          )}
                        </div>
                      ) : (
                        <Button
                          as="a"
                          href={`../../test/${testAssignmentInfo.id}`}
                          css={txtColor.white}
                          small
                          rounded
                        >
                          Go Back
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            <div css={styles.testArea} className="flex-grow-1" ref={testArea}>
              {attemptFinished && (
                <Container>
                  <Row>
                    <Col>
                      <div css={spacer.mrT30}>
                        <Score />
                      </div>
                    </Col>
                  </Row>
                </Container>
              )}

              <QuestionTypeComponent
                questionTypeSelected={currentQuestion.type}
                currentQuestion={currentQuestion}
                isLastQuestion={isLastQuestion}
                layoutType={layoutType}
                allowedExtraTime={testInfo?.settings?.allowedExtraTime}
              />
            </div>

            <div
              css={[styles.testNavWrapper, spacer.padBT10, spacer.padLR10]}
              className="d-flex align-items-center justify-content-between is-relative"
            >
              {prevQuestionId && (prevButtonVisible || attemptFinished) ? (
                <Button
                  css={styles.testButton}
                  onClick={handlePrevClick}
                  onKeyUp={disableSpaceBarClick}
                >
                  Back
                </Button>
              ) : (
                <Button
                  type="button"
                  css={styles.testButton}
                  className="faux-button"
                  onKeyUp={disableSpaceBarClick}
                >
                  {/* Faux button as placeholder */}
                  Back
                </Button>
              )}
              <div css={styles.progressBarWrapper({ attemptFinished })}>
                <ProgressBar
                  index={currentQuestionIndex}
                  questions={questions}
                  attemptFinished={attemptFinished}
                  setCurrentQuestionIndex={setCurrentQuestionIndex}
                />
              </div>
              {nextButtonVisible || attemptFinished ? (
                <Fragment>
                  {!attemptFinished ? (
                    <div>
                      <Button
                        css={styles.testButton}
                        onClick={handleNextClick}
                        onKeyUp={disableSpaceBarClick}
                      >
                        {isLastQuestion ? 'Finish' : 'Next'}
                      </Button>

                      {backForthNavigationEnabled && isLastQuestion ? (
                        <Button
                          css={[styles.testButton, spacer.mrL10]}
                          onClick={(e) => handleNextClick(e, true)}
                          onKeyUp={disableSpaceBarClick}
                        >
                          Next
                        </Button>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : !isLastQuestion || backForthNavigationEnabled ? (
                    <Button
                      css={styles.testButton}
                      onClick={(e) => handleNextClick(e, true)}
                      onKeyUp={disableSpaceBarClick}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      css={styles.testButton}
                      className="faux-button"
                      onKeyUp={disableSpaceBarClick}
                    >
                      {/* Faux button as placeholder */}
                      Next
                    </Button>
                  )}
                </Fragment>
              ) : (
                <div />
              )}
            </div>
          </Fragment>
        )}

        {layoutType === layoutTypes.ISEB_LAYOUT && (
          <div ref={testArea} className="isebTestSkeleton-container">
            <IsebTestSkeleton
              testAssignmentId={testAssignmentInfo?.id}
              testName={testModule.name}
              attemptFinished={attemptFinished}
              timeLimit={timeLimit}
              timerPaused={timerPaused}
              timerOnComplete={handleTimeOver}
              updatePageTitleTimer={updatePageTitleTimer}
              practiceQuestionAnswered={practiceQuestionAnswered}
              isLastQuestion={isLastQuestion}
              isFirstQuestion={currentQuestionIndex === 0}
              handleNextClick={handleNextClick}
              handlePrevClick={handlePrevClick}
              prevQuestionId={prevQuestionId}
              answerPayload={answerPayload}
              currentQuestion={currentQuestion}
              index={currentQuestionIndex}
              questions={questions}
              setCurrentQuestionIndex={setCurrentQuestionIndex}
              Score={Score}
            >
              <QuestionTypeComponent
                questionTypeSelected={currentQuestion.type}
                currentQuestion={currentQuestion}
                isLastQuestion={isLastQuestion}
                layoutType={layoutType}
                allowedExtraTime={testInfo?.settings?.allowedExtraTime}
              />
            </IsebTestSkeleton>
          </div>
        )}

        <EndModulePreview
          isOpen={showEndModuleModal && !attemptFinished}
          testId={testAssignmentInfo.id}
        />

        {/* <QuestionPreloader question={questions.find((q) => q._id === nextQuestionId)} /> */}
        {questions.map((q) => (
          <QuestionPreloader question={q} key={q._id} />
        ))}

        {attemptFinished ||
        (currentQuestion.usage === QUESTION_USAGE_TYPE.practice && practiceQuestionAnswered) ||
        currentQuestion.usage === QUESTION_USAGE_TYPE.sample ? (
          <ScrolldownIndicator
            ancestor={testArea?.current}
            currentQuestionIndex={currentQuestionIndex}
          />
        ) : null}

        {!online && <NetDisconnectedOverlay />}

        {loaderVisible && <LoaderOverlay />}
      </Fragment>
    </div>
  );
};

TestSkeleton.propTypes = {
  updatePageTitleTimer: PropTypes.func,
  isStartingAttempt: PropTypes.bool.isRequired
};
TestSkeleton.defaultProps = {
  updatePageTitleTimer: () => {}
};

export default TestSkeleton;
