/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TabNav from 'components/StyledTabs/StyledTabs';
import { colors, spacer } from 'styles/utilities';
import ProductBand from 'components/ProductBand/ProductBand';
import H3 from 'components/htmlElements/H3';
import { products } from 'globals/constants';
import { getProductCategory } from 'components/ProductCard/helpers';

const PlaceHolderText = () => (
  <div css={[spacer.padBT20, spacer.padL10, spacer.padR40]}>
    <H3 gBold color={colors.white}>
      No purchasable products yet
    </H3>
  </div>
);

const PurchasableProductsBlock = ({ productsData }) => {
  const catProducts = useMemo(
    () =>
      productsData.filter((productData) =>
        productData.categories.some((category) => category.slug === products.CAT.name)
      ),
    [productsData]
  );
  const isebProducts = useMemo(
    () =>
      productsData.filter((productData) =>
        productData.categories.some((category) => category.slug === products.PRETEST.name)
      ),
    [productsData]
  );
  const cemProducts = useMemo(
    () =>
      productsData.filter((productData) =>
        productData.categories.some((category) => category.slug === products.CEM.name)
      ),
    [productsData]
  );
  const ukisetProducts = useMemo(
    () =>
      productsData.filter((productData) =>
        productData.categories.some((category) => category.slug === products.UKISET.name)
      ),
    [productsData]
  );
  const progressProducts = useMemo(
    () =>
      productsData.filter((productData) =>
        productData.categories.some((category) => category.slug === products.PROGRESS.name)
      ),
    [productsData]
  );
  const londonConsortium = useMemo(
    () =>
      productsData.filter((productData) =>
        productData.categories.some((category) => category.slug === products.LONDON_CONSORTIUM.name)
      ),
    [productsData]
  );
  const sevenPlusProducts = useMemo(
    () =>
      productsData.filter((productData) =>
        productData.categories.some((category) => category.slug === products.SEVEN_PLUS.name)
      ),
    [productsData]
  );
  const eightPlusProducts = useMemo(
    () =>
      productsData.filter((productData) =>
        productData.categories.some((category) => category.slug === products.EIGHT_PLUS.name)
      ),
    [productsData]
  );
  // const tenPlusProducts = useMemo(
  //   () =>
  //     productsData.filter((productData) =>
  //       productData.categories.some((category) => category.slug === products.TEN_PLUS.name)
  //     ),
  //   [productsData]
  // );
  // const elevenPlusProducts = useMemo(
  //   () =>
  //     productsData.filter((productData) =>
  //       productData.categories.some((category) => category.slug === products.ELEVEN_PLUS.name)
  //     ),
  //   [productsData]
  // );
  // const incasProducts = useMemo(
  //   () =>
  //     productsData.filter((productData) =>
  //       productData.categories.some((category) => category.slug === products.INCAS.name)
  //     ),
  //   [productsData]
  // );
  // const thirteenPlusProducts = useMemo(
  //   () =>
  //     productsData.filter((productData) =>
  //       productData.categories.some((category) => category.slug === products.THIRTEEN_PLUS.name)
  //     ),
  //   [productsData]
  // );

  const tabs = useMemo(
    () => [
      'All',
      'CAT4',
      'ISEB',
      'CEM',
      'UKiset',
      'Progress',
      'London 11+ Consortium',
      '7+ Exam',
      '8+ Exam'
      // 'INCAS'
    ],
    []
  );

  return (
    <div>
      <TabNav tabs={tabs} color={colors.white}>
        <div>
          {productsData.length > 0 ? (
            productsData.map((productData) => (
              <ProductBand
                key={productData.id}
                examType={getProductCategory(productData.categories)}
                productName={productData.name}
                price={productData.price}
                productPageLink={productData.permalink}
              />
            ))
          ) : (
            <PlaceHolderText />
          )}
        </div>

        <div>
          {catProducts.length > 0 ? (
            catProducts.map((productData) => (
              <ProductBand
                key={productData.id}
                examType={getProductCategory(productData.categories)}
                productName={productData.name}
                price={productData.price}
                productPageLink={productData.permalink}
              />
            ))
          ) : (
            <PlaceHolderText />
          )}
        </div>

        <div>
          {isebProducts.length > 0 ? (
            isebProducts.map((productData) => (
              <ProductBand
                key={productData.id}
                examType={getProductCategory(productData.categories)}
                productName={productData.name}
                price={productData.price}
                productPageLink={productData.permalink}
              />
            ))
          ) : (
            <PlaceHolderText />
          )}
        </div>

        <div>
          {cemProducts.length > 0 ? (
            cemProducts.map((productData) => (
              <ProductBand
                key={productData.id}
                examType={getProductCategory(productData.categories)}
                productName={productData.name}
                price={productData.price}
                productPageLink={productData.permalink}
              />
            ))
          ) : (
            <PlaceHolderText />
          )}
        </div>

        <div>
          {ukisetProducts.length > 0 ? (
            ukisetProducts.map((productData) => (
              <ProductBand
                key={productData.id}
                examType={getProductCategory(productData.categories)}
                productName={productData.name}
                price={productData.price}
                productPageLink={productData.permalink}
              />
            ))
          ) : (
            <PlaceHolderText />
          )}
        </div>

        <div>
          {progressProducts.length > 0 ? (
            progressProducts.map((productData) => (
              <ProductBand
                key={productData.id}
                examType={getProductCategory(productData.categories)}
                productName={productData.name}
                price={productData.price}
                productPageLink={productData.permalink}
              />
            ))
          ) : (
            <PlaceHolderText />
          )}
        </div>

        <div>
          {londonConsortium.length > 0 ? (
            londonConsortium.map((productData) => (
              <ProductBand
                key={productData.id}
                examType={getProductCategory(productData.categories)}
                productName={productData.name}
                price={productData.price}
                productPageLink={productData.permalink}
              />
            ))
          ) : (
            <PlaceHolderText />
          )}
        </div>

        <div>
          {sevenPlusProducts.length > 0 ? (
            sevenPlusProducts.map((productData) => (
              <ProductBand
                key={productData.id}
                examType={getProductCategory(productData.categories)}
                productName={productData.name}
                price={productData.price}
                productPageLink={productData.permalink}
              />
            ))
          ) : (
            <PlaceHolderText />
          )}
        </div>

        <div>
          {eightPlusProducts.length > 0 ? (
            eightPlusProducts.map((productData) => (
              <ProductBand
                key={productData.id}
                examType={getProductCategory(productData.categories)}
                productName={productData.name}
                price={productData.price}
                productPageLink={productData.permalink}
              />
            ))
          ) : (
            <PlaceHolderText />
          )}
        </div>

        {/* <div>
          {incasProducts.length > 0 ? (
            incasProducts.map((productData) => (
              <ProductBand
                key={productData.id}
                examType={getProductCategory(productData.categories)}
                productName={productData.name}
                price={productData.price}
                productPageLink={productData.permalink}
              />
            ))
          ) : (
            <PlaceHolderText />
          )}
        </div> */}

        {/* <div>
          {tenPlusProducts.length > 0 ? (
            tenPlusProducts.map((productData) => (
              <ProductBand
                key={productData.id}
                examType={getProductCategory(productData.categories)}
                productName={productData.name}
                price={productData.price}
                productPageLink={productData.permalink}
              />
            ))
          ) : (
            <PlaceHolderText />
          )}
        </div> */}

        {/* <div>
          {elevenPlusProducts.length > 0 ? (
            elevenPlusProducts.map((productData) => (
              <ProductBand
                key={productData.id}
                examType={getProductCategory(productData.categories)}
                productName={productData.name}
                price={productData.price}
                productPageLink={productData.permalink}
              />
            ))
          ) : (
            <PlaceHolderText />
          )}
        </div> */}

        {/* <div>
          {thirteenProducts.length > 0 ? (
            thirteenProducts.map((productData) => (
              <ProductBand
                key={productData.id}
                examType={getProductCategory(productData.categories)}
                productName={productData.name}
                price={productData.price}
                productPageLink={productData.permalink}
              />
            ))
          ) : (
            <PlaceHolderText />
          )}
        </div> */}
      </TabNav>
    </div>
  );
};
PurchasableProductsBlock.propTypes = {
  productsData: PropTypes.array.isRequired
};

export default PurchasableProductsBlock;
