/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as styles from './ScrolldownIndicator.styles';

const ScrolldownIndicator = (props) => {
  const [hideNotice, toggleHideNotice] = useState(false);

  useEffect(() => {
    const scrollableEl = props.ancestor;
    const handleScroll = () => {
      if (scrollableEl.offsetHeight + scrollableEl.scrollTop + 50 >= scrollableEl.scrollHeight) {
        toggleHideNotice(true);
      } else {
        if (scrollableEl.clientHeight < scrollableEl.scrollHeight) {
          toggleHideNotice(true);
        }
        toggleHideNotice(false);
      }
    };

    scrollableEl?.addEventListener('scroll', handleScroll);
    if (scrollableEl) {
      setTimeout(() => {
        handleScroll();
      }, 100);
    }
    return () => {
      scrollableEl?.removeEventListener('scroll', handleScroll);
    };
  }, [props.ancestor, props.currentQuestionIndex]);

  return (
    <Fragment>
      <div css={styles.wrapper({ hide: hideNotice })}>
        You may need to scroll down to view the explanation.
      </div>
    </Fragment>
  );
};

ScrolldownIndicator.propTypes = {
  ancestor: PropTypes.instanceOf(Element),
  currentQuestionIndex: PropTypes.number.isRequired
};

ScrolldownIndicator.defaultProps = {
  ancestor: ''
};

export default ScrolldownIndicator;
