/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */

import React, { Fragment, useEffect, useMemo } from 'react';
import { useTable, useSortBy, useGlobalFilter, useRowSelect, useFilters } from 'react-table';
import PropTypes from 'prop-types';
import { colors, spacer } from 'styles/utilities';
import P from 'components/htmlElements/P';
import * as styles from '../DataTable.styles';
import { GlobalTableFilter } from '../DataTable';
import Checkbox from '../Checkbox';

const SchoolDataTable = ({
  columnConfig,
  rowData,
  setSelectedRows,
  clearRowSelection,
  children
}) => {
  const columns = useMemo(() => columnConfig, [columnConfig]);
  const data = useMemo(() => rowData, [rowData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { globalFilter },
    setGlobalFilter,
    selectedFlatRows,
    toggleAllRowsSelected
  } = useTable(
    {
      columns,
      data
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((restOfTheColumns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div css={styles.width}>
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div css={styles.width}>
              <Checkbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          canFilter: false,
          Filter: <div />,
          fixed: 'left'
        },
        ...restOfTheColumns
      ]);
    }
  );

  useEffect(() => {
    setSelectedRows(selectedFlatRows);
  }, [selectedFlatRows, setSelectedRows]);

  useEffect(() => {
    if (clearRowSelection) {
      toggleAllRowsSelected(false);
    }
  }, [clearRowSelection, toggleAllRowsSelected]);

  const handleSearchRows = (e) => setGlobalFilter(e.target.value);

  return (
    <Fragment>
      <div css={[styles.tableForm(true), spacer.mrB40]} className="flex-wrap">
        {children}

        <GlobalTableFilter
          handleChange={handleSearchRows}
          filterValue={globalFilter}
          placeholder="Search Students"
        />
      </div>

      <div css={styles.tableWrapper}>
        <div css={styles.table}>
          <table {...getTableProps()} className="alter">
            <thead className="alter">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, i) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                      css={styles.SchoolTableHeader}
                      width={`${
                        column.id === 'selection' ? '30px' : column.id === 'name' ? '200px' : ''
                      }`}
                    >
                      <div className="d-flex align-items-center">
                        {column.id !== 'selection' ? (
                          <P color={colors.mediumGrey2} noMargin>
                            {column.render('Header')}
                          </P>
                        ) : (
                          <P>{column.render('Header')}</P>
                        )}
                        <span
                          css={styles.sortIcon(
                            column.isSorted,
                            column.isSortedDesc,
                            column.canSort
                          )}
                          className={`sort-icon ${
                            headerGroup.headers.length - 1 === i ? 'hide' : ''
                          }`}
                        />
                        {column.canFilter ? <div>{column.render('Filter')}</div> : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={`alter ${row.isSelected ? 'selected' : ''}`}
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        css={[spacer.padBT20, spacer.padLR10]}
                        width={`${cell.column.id === 'selection' ? '30px' : ''}`}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {rows.length === 0 && (
            <P css={spacer.mrBT40} className="text-center" large>
              No students
            </P>
          )}
        </div>
      </div>
    </Fragment>
  );
};

SchoolDataTable.propTypes = {
  columnConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowData: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  clearRowSelection: PropTypes.bool,
  children: PropTypes.node.isRequired
};

SchoolDataTable.defaultProps = {
  clearRowSelection: false
};

export default SchoolDataTable;
