import {
  // useCallback,
  useEffect,
  useMemo
} from 'react';

import { createContainer } from 'unstated-next';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';
import { QUESTION_USAGE_TYPE } from 'globals/constants';
import AssignedTestContainer from 'hooks/useTest';
import { useSubmitAnswer } from 'api/test';

const useQuestionCommonWrapper = (initialState) => {
  const { questionSettings } = initialState;
  const currentQuestion = useMemo(
    () => initialState.currentQuestion,
    [initialState.currentQuestion]
  );
  const { usage, _id: questionId } = currentQuestion;

  const {
    testModule,
    toggleTimerPaused,
    setPracticeQuestionCallback,
    practiceQuestionAnswered,
    togglePracticeQuestionAnswered,
    toggleIsQuestionUsageFinal,
    attemptFinished: attemptFinishedFromAPI,
    isPracticeQuestion,
    toggleIsPracticeQuestion
  } = TestModuleInfoContainer.useContainer();

  // TODO: Ensure this doesn't interfere with testhub previews
  const { testInfo, testAssignmentInfo, testHubAttemptToken } =
    AssignedTestContainer.useContainer();

  const { submitAnswer: questionApiCall, isLoading: markQuestionWatchingLoading } = useSubmitAnswer(
    testInfo._id,
    testAssignmentInfo.id,
    testModule._id, // Only for optimistically updating data
    testHubAttemptToken
  );

  useEffect(() => {
    async function triggerQuestionWatched() {
      if (
        !currentQuestion.questionWatched &&
        !markQuestionWatchingLoading &&
        !attemptFinishedFromAPI
      ) {
        await questionApiCall({
          questionId: currentQuestion._id,
          payload: {
            questionWatched: true
          }
        });
      }
    }
    triggerQuestionWatched();
  }, [attemptFinishedFromAPI, currentQuestion, questionApiCall, markQuestionWatchingLoading]);

  // const timeElapsed = useCallback(
  //   async (start, end) => {
  //     let timeCalc;

  //     if (!attemptFinishedFromAPI) {
  //       if (currentQuestion.timeTaken) {
  //         // time elapsed updated and converted to seconds
  //         timeCalc = currentQuestion.timeTaken + (end - start) / 1000;
  //         // call api here
  //         setTimeout(async () => {
  //           await questionApiCall({
  //             questionId: currentQuestion._id,
  //             payload: {
  //               timeTaken: timeCalc
  //             }
  //           });
  //         }, 300);
  //       } else {
  //         // time elapsed converted to seconds
  //         timeCalc = (end - start) / 1000;
  //         // call api here
  //         setTimeout(async () => {
  //           await questionApiCall({
  //             questionId: currentQuestion._id,
  //             payload: {
  //               timeTaken: timeCalc
  //             }
  //           });
  //         }, 300);
  //       }
  //     }
  //   },
  //   [attemptFinishedFromAPI, currentQuestion._id, questionApiCall, currentQuestion.timeTaken]
  // );

  // useEffect(() => {
  //   // start time on question load
  //   const timeStart = Date.now();

  //   return () => {
  //     // store time at question id change
  //     const timeStop = Date.now();
  //     // time elapsed calculator function call
  //     timeElapsed(timeStart, timeStop);
  //   };
  // }, [questionId, timeElapsed]);

  const isSampleQuestion = useMemo(() => usage === QUESTION_USAGE_TYPE.sample, [usage]);
  const isNoteQuestion = useMemo(() => usage === QUESTION_USAGE_TYPE.note, [usage]);
  useEffect(() => {
    toggleIsPracticeQuestion(usage === QUESTION_USAGE_TYPE.practice);
  }, [toggleIsPracticeQuestion, usage]);

  // Checks the question type. This boolean is used across the app (mainly in TestSkeleton.js)
  useEffect(() => {
    if (usage === QUESTION_USAGE_TYPE.final) {
      toggleIsQuestionUsageFinal(true);
    } else {
      toggleIsQuestionUsageFinal(false);
    }
  }, [toggleIsQuestionUsageFinal, usage]);

  // Handle showing of answers for final questions and practice/sample/note questions
  const attemptFinished = useMemo(
    () =>
      attemptFinishedFromAPI ||
      (isPracticeQuestion && practiceQuestionAnswered) ||
      isSampleQuestion ||
      isNoteQuestion,
    [
      attemptFinishedFromAPI,
      isNoteQuestion,
      isPracticeQuestion,
      isSampleQuestion,
      practiceQuestionAnswered
    ]
  );
  // Old code. Delete later
  // const [attemptFinished, toggleAttemptFinished] = useState(
  //   attemptFinishedFromAPI ||
  //     (isPracticeQuestion && practiceQuestionAnswered) ||
  //     isSampleQuestion ||
  //     isNoteQuestion
  // );
  // useEffect(() => {
  //   toggleAttemptFinished(
  //     attemptFinishedFromAPI ||
  //       (isPracticeQuestion && practiceQuestionAnswered) ||
  //       isSampleQuestion ||
  //       isNoteQuestion
  //   );

  //   return () => {
  //     // Revert back to original attemptFinished state from server
  //     toggleAttemptFinished((s) => !s);
  //   };
  // }, [
  //   attemptFinishedFromAPI,
  //   isNoteQuestion,
  //   isPracticeQuestion,
  //   isSampleQuestion,
  //   practiceQuestionAnswered
  // ]);

  // Pause timer if question type is not final or pauseTimer is set to true
  useEffect(() => {
    // if (usage !== QUESTION_USAGE_TYPE.final || questionSettings.pauseTimer) {
    if (questionSettings.pauseTimer) {
      toggleTimerPaused(true);
    }

    return () => {
      toggleTimerPaused(false);
    };
  }, [questionSettings.pauseTimer, toggleTimerPaused, usage]);

  // Set practice question
  useEffect(() => {
    // Fake next click
    // - If a question is `practice` set a boolean, practiceQuestionAnswered, to false in TestModuleInfoContainer
    // Set a callback to be called on click of "next" button. Set practiceQuestionAnswered to true here so on the next click, the currentQuestion pointer goes to next question
    // - Revert the togglePracticeQuestionAnswered boolean and callback function on unmount
    if (isPracticeQuestion) {
      if (attemptFinishedFromAPI) {
        // This ensures the user doesn't have to click "Next" twice if the attempt is completed
        togglePracticeQuestionAnswered(true);
      } else {
        togglePracticeQuestionAnswered(false);
      }

      // This is called in `handleNextClick` in TestSkeleton
      // This ensures the practice question is answered and the user can go to next question if clicked on next again
      setPracticeQuestionCallback(() => {
        const updatedState = {
          callbackFn: () => {
            // Set the variable state to manage practice question here
            togglePracticeQuestionAnswered(true);
          }
        };
        return updatedState;
      });
    }

    // Cleanup
    return () => {
      if (isPracticeQuestion) {
        togglePracticeQuestionAnswered(false); // Revert to original state
        setPracticeQuestionCallback({
          callbackFn: () => {}
        });
      }
    };
  }, [
    attemptFinishedFromAPI,
    isPracticeQuestion,
    setPracticeQuestionCallback,
    togglePracticeQuestionAnswered,
    questionId // This ensures if subsequent questions are of same type (ie; Text, Image,...) the effect is re-run and togglePracticeQuestionAnswered is set back to `false`
  ]);

  const memodItems = useMemo(
    () => ({
      attemptFinished,
      isNoteOrSampleQuestion: isSampleQuestion || isNoteQuestion,
      isPracticeQuestion
    }),
    [attemptFinished, isNoteQuestion, isSampleQuestion, isPracticeQuestion]
  );

  return memodItems;
};

const QuestionsCommonWrapperContainer = createContainer(useQuestionCommonWrapper);

export default QuestionsCommonWrapperContainer;
