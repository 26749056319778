/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors } from 'styles/utilities';

export const contentWrapper = css`
  height: calc(100% - 30px);
`;

export const questionContainer = css`
  display: flex;
  justify-content: center;
  .question-column {
    width: 50%;
  }
`;

const zoomCss = css`
  .zoom-icon {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  &:hover {
    cursor: zoom-in;
    .zoom-icon {
      fill: ${colors.primaryLinkHover};
    }
  }
`;

// Multi image layout
export const imagesWrapper = (props) => css`
  position: relative;
  display: block;
  width: 450px;
  height: 470px;
  margin: 0 auto;
  border: 4px solid ${colors.grey2};
  border-radius: 4px;
  background: ${colors.grey2};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  input[type='radio'] {
    display: none;
  }

  label {
    margin: 10px 5px;
  }

  .twoColComp-image {
    display: none;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    ${'' /* Rendering only if zoom is enabled */}
    ${(() => {
      if (props.allowZoom) {
        return zoomCss;
      }

      return css``;
    })()}
  }

  .twoColComp-image-radio:checked + .twoColComp-image {
    display: block;
  }

  .twoColComp-image-index:hover,
  .twoColComp-image-radio:checked ~ .twoColComp-image-index {
    border-color: #436fff;
    background: #ccc;
  }
`;

export const twoColCompImageIndex = css`
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0 2px;
  background-color: #ddd;
  background-image: linear-gradient(#fff, #ccc, #fff);
  border: 2px solid #999;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

// Single image layout
export const singleImageWrapper = (props) => css`
  img {
    width: 100%;
  }
  .image-wrapper {
    height: 400px;
    overflow: auto;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    border: 1px solid ${colors.grey2};
    position: relative;
  }

  ${'' /* Rendering only if zoom is enabled */}
  ${(() => {
    if (props.allowZoom) {
      return zoomCss;
    }

    return css``;
  })()}
`;

export const zoomModal = css`
  img {
    width: 100%;
    padding: 20px;
  }
`;
