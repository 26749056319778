/** @jsxImportSource @emotion/react */
import { Fragment, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import ModalHeader from 'components/ModalHeader';
import Modal from 'components/Modal';
import { Input, FieldError, Wrapper } from 'components/htmlElements/Fields';
import Button from 'components/htmlElements/Button';
import LoaderOverlay from 'components/LoaderOverlay';
import SuccessModal from 'components/SuccessModal';

import { UserInfoContainer } from 'api/auth';
import { useChangePassword as useChangeParentPassword } from 'api/parents';
import { useChangePassword as useChangeSchoolPassword } from 'api/schools';

import { useUserAccountContext } from 'components/EditAccount/userAccountContext';

import { spacer } from 'styles/utilities';

const changePasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('THIS FIELD IS REQUIRED')
    .min(6, 'Must contain atleast 6 characters'),
  // .matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //   'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
  // ),
  confirmPassword: Yup.string()
    .required('Confirm your password')
    .oneOf([Yup.ref('password')], 'Password does not match')
});

const ChangePassword = () => {
  const { isChangePasswordOpen, toggleChangePasswordModal, closeChangePasswordModal } =
    useUserAccountContext();

  const { userIs } = UserInfoContainer.useContainer();

  const {
    changePassword: changeParentPassword,
    isSuccess: isSuccessChangeParentPassword,
    isError: isErrorChangeParentPassword,
    reset: resetChangeParentPasswordMutation
  } = useChangeParentPassword();

  const {
    changePassword: changeSchoolPassword,
    isSuccess: isSuccessChangeSchoolPassword,
    isError: isErrorChangeSchoolPassword,
    reset: resetChangeSchoolPasswordMutation
  } = useChangeSchoolPassword();

  useEffect(
    () =>
      // Clear mutation state when modal is opened

      () => {
        resetChangeParentPasswordMutation();
        resetChangeSchoolPasswordMutation();
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isChangePasswordOpen]
  );

  const handleFormSubmission = async (values) => {
    if (userIs === 'parent') {
      await changeParentPassword({ password: values.password });
    } else if (userIs === 'school') {
      await changeSchoolPassword({ password: values.password });
    }
  };

  return (
    <div className="is-relative">
      <Modal isOpen={isChangePasswordOpen} toggle={toggleChangePasswordModal}>
        <Formik
          initialValues={{
            password: '',
            confirmPassword: ''
          }}
          validationSchema={changePasswordValidationSchema}
          onSubmit={handleFormSubmission}
        >
          {({ isSubmitting }) => (
            <Fragment>
              {!isSuccessChangeParentPassword && !isSuccessChangeSchoolPassword ? (
                <div css={[spacer.padBT30, spacer.padLR30]}>
                  <ModalHeader header="Change Password" />
                  <Form>
                    <div>
                      <Wrapper>
                        <Field
                          type="password"
                          name="password"
                          id="password"
                          as={Input}
                          autoComplete="off"
                          placeholder="New Password"
                        />
                        <ErrorMessage name="password" component={FieldError} />
                      </Wrapper>
                      <Wrapper>
                        <Field
                          type="password"
                          name="confirmPassword"
                          id="confirmPassword"
                          as={Input}
                          placeholder="Confirm Password"
                        />
                        <ErrorMessage name="confirmPassword" component={FieldError} />
                      </Wrapper>

                      {isErrorChangeParentPassword && isErrorChangeSchoolPassword && (
                        <Wrapper>
                          <FieldError>
                            There was a problem with updating your password, please try again
                          </FieldError>
                        </Wrapper>
                      )}
                    </div>

                    <Button textCenter rounded wide type="submit">
                      Change Password
                    </Button>
                  </Form>

                  {isSubmitting && <LoaderOverlay />}
                </div>
              ) : (
                <SuccessModal onClick={closeChangePasswordModal} message="Password updated" />
              )}
            </Fragment>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

ChangePassword.propTypes = {};

export default ChangePassword;
