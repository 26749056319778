export const dateInMMDDYYYY = (date) => {
  let formattedDate = new Date(date);
  const dd = String(formattedDate.getDate()).padStart(2, '0');
  const mm = String(formattedDate.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = formattedDate.getFullYear();

  formattedDate = `${mm}-${dd}-${yyyy}`;
  return formattedDate;
};

export const dateInYYYYMMDD = (date) => {
  let formattedDate = new Date(date);
  const dd = String(formattedDate.getDate()).padStart(2, '0');
  const mm = String(formattedDate.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = formattedDate.getFullYear();

  formattedDate = `${yyyy}-${mm}-${dd}`;
  return formattedDate;
};

export const dateInFMY = (date) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  let formattedDate = new Date(date);

  const month = monthNames[formattedDate.getMonth()];
  const day = String(formattedDate.getDate()).padStart(2, '0');
  const year = formattedDate.getFullYear();

  formattedDate = `${day} ${month} ${year}`;
  return formattedDate;
};
