/** @jsxImportSource @emotion/react */

import 'slick-carousel/slick/slick.css';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

export function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
    >
      <Icon
        icon="chevron-right"
        className="carousel-icon"
        style={{ height: '1.6em', width: '1.6em' }}
      />
    </div>
  );
}
NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
};
NextArrow.defaultProps = {
  className: '',
  style: {},
  onClick: () => {}
};

export function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
    >
      <Icon
        icon="chevron-left"
        className="carousel-icon"
        style={{ height: '1.6em', width: '1.6em' }}
      />
    </div>
  );
}
PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
};
PrevArrow.defaultProps = {
  className: '',
  style: {},
  onClick: () => {}
};
