/** @jsxImportSource @emotion/react */
import { useMemo, Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'components/Grid';
import { colors, spacer } from 'styles/utilities';
import removeDuplicates from 'utils/removeDuplicates';
// import SectionBand from 'components/SectionBand/';

// import ProductCard from 'components/ProductCard/ProductCard';
// import { PRODUCT_CARD_TYPES } from 'globals/constants';

import SuccessModal from 'components/SuccessModal';
import Modal, { useModal } from 'components/Modal/Modal';
// import LoaderOverlay from 'components/LoaderOverlay';

import { useAssignProduct } from 'api/parents';
import { useUserAccountContext } from 'components/EditAccount/userAccountContext';

// import PageLoader from 'components/PageLoader';
// import { Waypoint } from 'react-waypoint';

import PurchasedProducts from 'components/PurchasedProducts/PurchasedProducts';
import H3 from 'components/htmlElements/H3';
import A from 'components/htmlElements/A';
import CandidateCard from 'components/CandidateCard/Loadable';
import PurchasableProducts from './PurchasableProducts';

const ParentDashboard = ({
  unassignedProducts,
  candidates
  // candidatesPaginated
  // fetchNextPage,
  // hasNextPage,
  // isFetchingNextPage
}) => {
  // Product assignment
  const candidateList = useMemo(
    () =>
      candidates && candidates.length
        ? candidates?.map(({ id, firstName, lastName }) => ({
            id,
            firstName,
            lastName
          }))
        : [],
    [candidates]
  );

  const assignableProducts = useMemo(
    () => removeDuplicates(unassignedProducts, 'product_id'),
    [unassignedProducts]
  );

  // const [productAssignment, updateProductAssignment] = useState({
  //   isAssigning: false,
  //   productIndex: ''
  // });

  const { isOpen: assignmentModalOpen, openModal, closeModal } = useModal();
  const { assignProduct, isSuccess } = useAssignProduct();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { openAddCandidateModal } = useUserAccountContext();

  const handleAssignProduct = async (id, productId) => {
    // updateProductAssignment({
    //   isAssigning: true,
    //   productIndex: index
    // });

    setIsModalOpen(true);

    // Assign product api call
    await assignProduct({
      candidateId: id,
      wpProductId: productId
    });

    // Clear state to remove loader
    // updateProductAssignment({
    //   isAssigning: false,
    //   productIndex: ''
    // });
  };

  const closeModalHandler = () => {
    closeModal();
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isSuccess && isModalOpen) {
      openModal();
    }

    return () => closeModal();
  }, [isSuccess, openModal, closeModal, isModalOpen]);

  return (
    <Fragment>
      <Col>
        <PurchasedProducts
          productsData={assignableProducts}
          handleAssignProduct={handleAssignProduct}
          items={candidateList.length ? candidateList : []}
        />

        <div css={spacer.mrT40}>
          {/* <SectionBand sectionName={`Unassigned Products (${unassignedProducts.length})`} />

          {unassignedProducts.length ? (
            <div className="d-flex flex-wrap">
              {unassignedProducts.map((unassignedProduct, index) => (
                <div
                  css={[spacer.mrB20, spacer.mrR20]}
                  key={`${unassignedProduct.product_id}-${index.toString()}`}
                  className="is-relative"
                >
                  {productAssignment.productIndex === index && productAssignment.isAssigning ? (
                    <LoaderOverlay />
                  ) : null}

                  <ProductCard
                    css={spacer.mrT100}
                    productCardType={
                      candidateList.length
                        ? PRODUCT_CARD_TYPES.assignTest
                        : PRODUCT_CARD_TYPES.assignmentDetails
                    }
                    examType={unassignedProduct.product_cat[0].slug}
                    productType={unassignedProduct.product_collection[0].slug}
                    productName={unassignedProduct.name}
                    handleClick={(item) => {
                      if (candidateList.length) {
                        setTimeout(() => {
                          handleAssignProduct(item, unassignedProduct.product_id, index);
                        }, 100);
                      } else {
                        openAddCandidateModal();
                      }
                    }}
                    ctaText={!candidateList.length ? 'Add Child Account' : ''}
                    items={candidateList.length ? candidateList : []}
                  />
                </div>
              ))}
            </div>
          ) : null} */}

          {/* {candidatesPaginated.length > 0 &&
            candidatesPaginated.map((candidate) => (
              <CandidateDetailsBlock candidate={candidate} key={candidate.id} />
            ))} */}

          <div>
            <div className="d-flex align-items-center" css={spacer.mrB30}>
              <H3 css={spacer.mrR30}>Child Accounts</H3>
              <A large underline href="#" onClick={openAddCandidateModal}>
                Add a child account
              </A>
            </div>

            <Row>
              {candidateList.length > 0 ? (
                <Fragment>
                  {candidateList.map((candidate) => (
                    <CandidateCard candidate={candidate} key={candidate.id} />
                  ))}
                </Fragment>
              ) : (
                <H3 gBold color={colors.mediumGrey} css={spacer.padL15} className="m-0">
                  No child account added yet
                </H3>
              )}
            </Row>
          </div>

          {/* <Waypoint
            bottomOffset="-40%"
            onEnter={() => {
              if (hasNextPage) {
                fetchNextPage();
              }
            }}
          />
          {isFetchingNextPage && (
            <PageLoader isFetching isRelative height="150px" bgColor="transparent" />
          )} */}

          <Modal isOpen={assignmentModalOpen} toggle={closeModalHandler}>
            <SuccessModal onClick={closeModalHandler} message="Product assigned" />
          </Modal>
        </div>
      </Col>
      <PurchasableProducts />
    </Fragment>
  );
};

ParentDashboard.propTypes = {
  unassignedProducts: PropTypes.array.isRequired,
  candidates: PropTypes.array.isRequired
  // candidatesPaginated: PropTypes.array.isRequired
  // fetchNextPage: PropTypes.func.isRequired,
  // hasNextPage: PropTypes.bool.isRequired,
  // isFetchingNextPage: PropTypes.bool.isRequired
};

export default ParentDashboard;
