/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { colors, mixins } from 'styles/utilities';

export const ProductIcon = styled.div`
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export const ProductWrapper = (props) => css`
  border-bottom: 2px solid ${colors.grey2};
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: ${mixins.toRgba(props.hoverColor, props.alpha)};
  }

  ${props.hideCtaButton && `pointer-events: none`}

  .divider {
    width: 4px;
    height: 4px;
    min-height: 4px;
    min-width: 4px;
    border-radius: 50%;
    background: ${colors.mediumGrey2};
    margin: 0 8px;
  }
`;
