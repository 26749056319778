/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { toRgba } from 'styles/helpers/mixins';
import { breakpoints, colors, misc, typography } from 'styles/utilities';

const toolTipBG = '#ebfaf1';

export const selectWrapper = css`
  max-width: fit-content;
  min-width: 250px;

  .trigger,
  .dropdown {
    background-color: ${toolTipBG};
    border-radius: ${misc.mediumBorderRadius};
    text-transform: none;
    max-height: 300px;
    border: 1px solid ${colors.primaryLinkActive};
  }

  .trigger {
    padding: 16px 20px;

    &:after {
      right: 19px;
    }
  }

  .dropdown,
  .dropdown.show {
    top: 60px;
  }

  .option {
    text-transform: none;

    &:hover li {
      background-color: ${colors.blackTransparent10};
    }
  }
`;

export const column = ({ showTiming }) => css`
  ${breakpoints({
    marginBottom: ['15px', '30px', '', '', '']
  })}

  ${breakpoints({
    maxWidth: showTiming ? ['50%', '33.33%', '', '', '20%'] : ['50%', '', '', '25%', '']
  })}
`;

export const cardStyles = css`
  border-radius: ${misc.mediumBorderRadius};
  overflow: hidden;
  border: 1px solid ${colors.grey};
  background: ${toRgba(colors.cream, 0.5)};
`;

export const chartWrapper = css`
  margin: auto;

  ${breakpoints({
    width: ['220px', '', '', '250px', '300px'],
    height: ['220px', '', '', '250px', '300px']
  })}
`;

export const chartTooltipStyles = css`
  div.google-visualization-tooltip {
    pointer-events: none;
    border-radius: ${misc.mediumBorderRadius} !important;
    overflow: hidden;
    background: ${toolTipBG};
    border-color: ${colors.green};
  }

  .tooltip-styles {
    padding: 12px;
    white-space: nowrap;
    font-size: 14px;
    font-family: ${typography.fonts.gothamBold};
    font-weight: bold;
    color: ${colors.black3};
  }
`;

export const chartLegend = css`
  .legend-color {
    min-width: 24px;
    min-height: 24px;
    border-radius: ${misc.mediumBorderRadius};
  }

  .legend-label {
    white-space: nowrap;
  }
`;

export const tableWrapper = css`
  max-width: 360px;

  .card-default {
    border: 1px solid ${colors.grey4};
  }

  .table-row:not(:last-child, :first-of-type) {
    border-bottom: 1px solid ${colors.grey4};
  }

  .table-row:first-of-type {
    border-bottom: 1px solid ${colors.mediumGrey};
    background: ${toRgba(colors.green, 0.06)};
  }

  .cell-title,
  .cell-score {
    padding: 16px;
    margin: 0;
  }

  .cell-title {
    width: 60%;
    border-right: 1px solid ${colors.grey4};
  }

  .cell-score {
    width: 40%;
  }
`;

export const scoreBandWrapper = ({ markerPosition }) => css`
  position: relative;

  .score-bands-wrapper {
    border-radius: ${misc.mediumBorderRadius};
    overflow-x: hidden;
  }

  .score-band-pin {
    position: absolute;
    top: -24px;
    right: calc(${markerPosition}% - 32px);
  }

  .score-band-text {
    width: 20%;
  }
`;

export const bandColor = ({ bg }) => css`
  background: ${bg};
  height: 40px;
  width: 20%;
`;

export const topicPerformanceChartWrapper = css`
  border-radius: ${misc.mediumBorderRadius};
  border: 1px solid ${colors.grey4};
`;

export const resultsSummaryBand = css`
  border-radius: ${misc.mediumBorderRadius};
  overflow: hidden;
  border: 1px solid ${colors.grey};
  background: ${toRgba(colors.cream, 0.4)};

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }

  .summary-band-cta {
    border-top: 1px solid ${colors.grey};
  }

  .summary-band-wrapper {
    ${breakpoints({
      maxWidth: ['33.33%', '', '', '20%', '']
    })}
  }
`;

export const timeAnalysisTable = css`
  .card-default {
    border: 1px solid ${colors.grey4};
  }

  .table-head-row {
    background: ${toRgba(colors.cream, 0.8)};

    .cell-time {
      border-left: 1px solid ${colors.grey4};
    }
  }

  .table-row:not(:last-of-type) {
    border-bottom: 1px solid ${colors.grey4};
  }

  .cell-title,
  .cell-time {
    padding: 16px;
    margin: 0;
  }

  .cell-title {
    width: 60%;
  }

  .cell-time {
    width: 40%;
    border-left: 1px solid ${colors.grey4};
  }
`;

export const timeAnalysisSection = css`
  ${breakpoints({
    marginTop: ['60px', '', '', '40px', '']
  })}
`;

export const timeAnalysisChart = ({ time }) => css`
  position: relative;

  .time-analysis-chart {
    border-radius: ${misc.mediumBorderRadius};
    overflow: hidden;
    width: 100%;
    height: 40px;
    background: linear-gradient(270deg, ${colors.slowestTime} 0%, ${colors.fastestTime} 100%);
  }

  .time-band-pin {
    position: absolute;
    max-width: 80px;
    bottom: 0px;
    left: calc(${(time / 50) * 100}% - 32px);
  }
`;

export const timeAxis = css`
  .time-stamp {
    position: relative;
    padding-top: 3px;
  }

  .time-stamp::before {
    content: '';
    position: absolute;
    display: block;
    height: 4px;
    width: 1px;
    top: 0;
    left: 50%;
    background: ${colors.black3};
  }

  .time-stamp:first-of-type::before {
    left: 5px;
  }

  .time-stamp:last-of-type::before {
    left: unset;
    right: 5px;
  }
`;

export const optionStyles = ({ isSubSection }) => css`
  ${isSubSection &&
  `display: inline-block;
   padding-left: 25px;`}

  &.active::before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: ${colors.blackTransparent10};
    z-index: -1;
  }
`;

export const divider = css`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: ${colors.blackTransparent60};
`;

export const warningTooltip = css`
  .tooltip-content {
    background: #faebeb;
    border-color: ${colors.red};
  }

  .tooltip-arrow::before {
    background: #faebeb;
    border-color: transparent transparent ${colors.red} ${colors.red} !important;
  }

  .tooltip-content {
    max-width: 400px;
  }
`;
