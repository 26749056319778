/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors, mixins } from 'styles/utilities';

export const questionLayout = css`
  .card {
    padding: 32px;
    background-color: ${colors.white};
    border-radius: 12px;
    border: 1px solid ${colors.grey};
  }

  .ck-content {
    p:not(:last-child) {
      margin: 0 0 18px 0;
    }
  }

  .answer-row {
    margin: 0px -10px;
  }

  &.attempt-finished .answer-row {
    pointer-events: none;
  }

  .section-answer-options,
  &.attempt-finished .section-answer-options {
    margin-top: 25px;
    margin-bottom: 0px;
    align-items: stretch;
    flex-wrap: wrap;

    & > * {
      flex: 1 1 10%;
    }
  }

  .vertical-options {
    flex-direction: column;
    padding-bottom: 15px;

    & > * {
      margin-bottom: 0px;
    }

    .option-box-wrapper {
      padding-bottom: 0px;
    }

    .option-box-wrapper .answer-contents {
      justify-content: flex-start;
    }
  }

  .answer-wrapper {
    align-items: stretch;

    &:hover {
      .answer-contents {
        transform: translateY(-1px);
        box-shadow: 0px 2px 3px rgba(50, 5, 139, 0.15);
        border-color: ${colors.violet};
      }
    }

    .correct-answer .answer-contents {
      background-color: ${mixins.toRgba(colors.green, 0.65)};
    }

    input {
      &:checked + .answer-contents {
        transform: translateY(-1px);
        box-shadow: 0px 2px 3px rgba(50, 5, 139, 0.15);
        border-color: ${colors.violet};
        background-color: ${mixins.toRgba(colors.violet, 0.15)};
      }
    }

    .correct-answer input {
      &:checked + .answer-contents {
        transform: translateY(-1px);
        box-shadow: 0px 2px 3px rgba(50, 5, 139, 0.15);
        border-color: ${colors.blue};
        background-color: ${mixins.toRgba(colors.green, 0.65)};
      }
    }
  }

  .option-box-inner {
    height: 100%;
  }

  .option-box-inner .answer-contents {
    padding: 10px 18px;
    min-height: 55px;
    border-radius: 12px;
    border: 2px solid ${colors.grey};
    height: 100%;
  }

  .answer-contents .image {
    margin-bottom: 0px;
    mix-blend-mode: darken;

    & > img {
      max-width: 100%;
    }
  }
`;
