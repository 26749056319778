/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors, mixins, misc } from 'styles/utilities';

export const productCardWrapper = css`
  max-width: 370px;
`;

export const topBand = (color) => css`
  height: 10px;
  width: 100%;
  background-color: ${color};
`;

export const cardOuter = (color) => css`
  .product {
    ${mixins.transition('0.1s', 'all', 'ease')};
  }
  &.card-outer:hover {
    .product {
      color: ${color};
    }
  }
`;

export const productName = css`
  display: flex;
  position: relative;
  ${mixins.respondTo(misc.viewport['b-vp'])} {
    min-height: 200px;
  }
  &.product-name.course::after {
    content: '';
    line-height: 1;
    position: absolute;
    right: 15px;
    top: 12px;
    width: 35px;
    height: 35px;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath d='M16 32c-8.836 0-16-7.164-16-16s7.164-16 16-16 16 7.163 16 16c0 8.836-7.164 16-16 16zM11.429 9.143v13.714l11.429-6.857-11.429-6.857z'%3E%3C/path%3E%3C/svg%3E%0A");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

export const borderTop = css`
  border-top: 1px solid ${colors.white};
`;

export const progress = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const stats = css`
  display: flex;
`;

export const cardStatItem = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-right: 1px solid ${colors.white};
  &:last-child {
    border: none;
  }
`;
