import React, { useState, useEffect, useMemo } from 'react';
import { createContainer } from 'unstated-next';
import PropTypes from 'prop-types';
import { TEST_STRUCTURE, ROUTE_PATHS, products } from 'globals/constants';

import { Redirect } from 'components/Router';
import PageLoader from 'components/PageLoader';
import { useGetAssignedTest, useGetTestAttempt } from 'api/test';

const useTest = ({ initialTestAssigmentData, initialTestData }) => {
  const [testAssignmentInfo, updateTestAssignmentInfo] = useState(initialTestAssigmentData);
  useEffect(() => {
    updateTestAssignmentInfo(initialTestAssigmentData);
  }, [initialTestAssigmentData]);

  const [testInfo, updateTestInfo] = useState(initialTestData);

  useEffect(() => {
    updateTestInfo(initialTestData);
  }, [initialTestData]);

  // Helper vars
  const isTestAtSection = useMemo(
    () => testInfo?.questionStructure === TEST_STRUCTURE.sections,
    [testInfo?.questionStructure]
  );

  const isTestAtSubSection = useMemo(
    () => testInfo?.questionStructure === TEST_STRUCTURE.subsections,
    [testInfo?.questionStructure]
  );

  const testCompleted = useMemo(() => testInfo.isCompleted, [testInfo.isCompleted]);

  const hasExtraTime = useMemo(() => {
    if (isTestAtSubSection) {
      return testInfo?.section.some((sectionItem) =>
        sectionItem.subSection.some(
          (subsectionItem) => subsectionItem.settings.allowedExtraTime > 0
        )
      );
    }
    return testInfo?.section.some((sectionItem) => sectionItem.settings.allowedExtraTime > 0);
  }, [isTestAtSubSection, testInfo?.section]);

  const isLondonConsortiumTest = useMemo(
    () => testAssignmentInfo?.wpMeta?.categories[0].slug === products.LONDON_CONSORTIUM.name,
    [testAssignmentInfo?.wpMeta?.categories]
  );

  const testHubAttemptToken = useMemo(
    () => testAssignmentInfo?.testHubMeta?.token,
    [testAssignmentInfo?.testHubMeta?.token]
  );

  return {
    testAssignmentInfo,
    testInfo,
    isTestAtSection,
    isTestAtSubSection,
    testCompleted,
    hasExtraTime,
    isLondonConsortiumTest,
    testHubAttemptToken
  };
};

const AssignedTestContainer = createContainer(useTest);
export default AssignedTestContainer;

export const TestDataProvider = ({ testAssignmentId, children }) => {
  const { data: testAssignmentData, isError, isSuccess } = useGetAssignedTest(testAssignmentId);
  const {
    data: testAttemptData,
    isError: testAttemptError,
    isSuccess: testAttemptSuccess
  } = useGetTestAttempt(testAssignmentData?.testHubAttemptId, testAssignmentData?.id);

  if (
    isError ||
    testAttemptError ||
    (testAssignmentData?.statusCode && testAssignmentData?.statusCode === 404)
  ) {
    return <Redirect to={ROUTE_PATHS.notFound} noThrow />;
  }

  if (!isSuccess || !testAttemptSuccess) {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  return (
    <AssignedTestContainer.Provider
      initialState={{
        initialTestAssigmentData: testAssignmentData,
        initialTestData: testAttemptData?.data
      }}
    >
      {children}
    </AssignedTestContainer.Provider>
  );
};

TestDataProvider.propTypes = {
  testAssignmentId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
