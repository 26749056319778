import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import styledMap from 'styled-map';
import { typography, colors, mixins } from 'styles/utilities';

const A = styled('a', {
  shouldForwardProp: (prop) => isPropValid(prop)
})`
  font-family: ${styledMap`
  gBold: ${typography.fonts.gothamBold.fontFamily};
  default: ${typography.fonts.gothamBook.fontFamily};
  `};
  line-height: inherit;
  outline: none;
  cursor: pointer;
  font-size: ${styledMap`
    large: ${typography.baseSizes.epsilon};
    medium: ${typography.baseSizes.zeta};
    small: ${typography.baseSizes.theta};
    default: 14px;
  `};
  text-decoration: ${styledMap`
  underline: underline;
  default: none;
  `};
  background-color: ${styledMap`
    noBg: transparent;
    default: unset;
  `};
  border: ${styledMap`
    noBg: none;
    default: unset;
  `};
  ${mixins.transition()}
  ${mixins.linkActiveStyles} {
    outline: none;
  }
  text-transform: ${(props) => (props.transform ? props.transform : 'none')};
  color: ${(props) => (props.color ? props.color : colors.primaryAnchorDefaultColor)};
  fill: ${styledMap`
    highlightSvg: ${(props) => (props.color ? props.color : colors.primaryAnchorDefaultColor)};
    default: '';
  `};
  &:hover {
    color: ${colors.primaryLinkHover};
    text-decoration: ${styledMap`
  underline: underline;
  default: none;
`};
    fill: ${styledMap`
      highlightSvg: ${colors.primaryLinkHover};
      default: '';
    `};
  }
  &[isactive='true'],
  &:active {
    color: ${colors.primaryLinkActive};
    fill: ${styledMap`
      highlightSvg: ${colors.primaryLinkActive};
      default: '';
    `};
  }
`;

export default A;
