/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import StatsItem from 'components/StatsItem';

import { colors, spacer } from 'styles/utilities';
import P from 'components/htmlElements/P';
import H3 from 'components/htmlElements/H3';
import * as styles from './ScoreBand.styles';

const ScoreBand = ({ score, statistics, border }) => (
  <div
    className="d-flex justify-content-between align-items-start"
    css={styles.scoreBandWrapper({ border })}
  >
    <div className="score" css={[spacer.padR20, spacer.padBT10]}>
      <P instruction color={colors.mediumGrey} css={[spacer.mrT0, spacer.mrB10]}>
        Your Score:
      </P>
      <H3 gBold color={colors.mediumGrey} className="m-0">
        {score}
      </H3>
    </div>

    <div css={styles.divider} />

    {Object.keys(statistics) && (
      <div css={[spacer.padBT10, spacer.padL20]}>
        {Object.keys(statistics).map((statistic) => (
          <StatsItem key={statistic} stat={statistic} value={statistics[statistic]} />
        ))}
      </div>
    )}
  </div>
);

ScoreBand.propTypes = {
  score: PropTypes.string.isRequired,
  statistics: PropTypes.object,
  border: PropTypes.bool
};

ScoreBand.defaultProps = {
  statistics: {},
  border: false
};

export default ScoreBand;
