/** @jsxImportSource @emotion/react */

import { colors, spacer } from 'styles/utilities';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import P from 'components/htmlElements/P';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';
import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';
import { withLatex } from 'components/QuestionTypes/shared-components/Latex';
import * as sharedStyles from 'components/QuestionTypes/shared-components/shared.styles';
import transformImgixUrls from 'components/QuestionTypes/shared-components/transformImgixUrls';
import * as styles from './IsebTestSkeleton.styles';

const tintColors = [
  { color: colors.black, alpha: 0 },
  { color: colors.lightBlue, alpha: 0.3 },
  { color: '#adff2f', alpha: 0.2 },
  { color: '#00ff00', alpha: 0.22 },
  { color: '#00ffaa', alpha: 0.3 },
  { color: '#ff0088', alpha: 0.15 },
  { color: colors.orange, alpha: 0.25 },
  { color: '#ff0050', alpha: 0.18 },
  { color: colors.violet, alpha: 0.3 },
  { color: '#00bfff', alpha: 0.2 },
  { color: colors.yellow, alpha: 0.2 }
];

const Popup = ({
  closePopup,
  active,
  setFontSize,
  selectedTint,
  setSelectedTint,
  timerVisible,
  timerToggle,
  progressBarVisible,
  progressBarToggle
}) => {
  const { helpText } = TestModuleInfoContainer.useContainer();

  return (
    <div
      className="popup-backdrop"
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
      onClick={(e) => closePopup(e)}
      css={styles.popupBackdrop}
    >
      {active === 'settings' && (
        <div css={styles.popupWrapper({ right: 125 })}>
          <div>
            <P gBold color={colors.mediumGrey} css={spacer.mrBT0}>
              Font size
            </P>

            <div className="d-flex" css={spacer.mrT10}>
              <styles.HeaderButton
                className="fontBtn"
                css={spacer.mrR5}
                onClick={() => setFontSize('default')}
              >
                <Icon icon="font" color={colors.checkboxGrey} size="20" />
              </styles.HeaderButton>

              <styles.HeaderButton
                className="fontBtn"
                css={spacer.mrR5}
                onClick={() => setFontSize('medium')}
              >
                <Icon icon="font" color={colors.checkboxGrey} size="26" />
              </styles.HeaderButton>

              <styles.HeaderButton className="fontBtn" onClick={() => setFontSize('large')}>
                <Icon icon="font" color={colors.checkboxGrey} size="32" />
              </styles.HeaderButton>
            </div>
          </div>

          <div className="d-flex align-items-center" css={spacer.mrT15}>
            <styles.HeaderButton
              onClick={() => timerToggle((prev) => !prev)}
              className="toggle-btn"
              css={spacer.mrR15}
            >
              {timerVisible ? 'Hide Timer' : 'Show Timer'}
            </styles.HeaderButton>

            <styles.HeaderButton
              onClick={() => progressBarToggle((prev) => !prev)}
              className="toggle-btn"
            >
              {progressBarVisible ? 'Hide Progress Bar' : 'Show Progress Bar'}
            </styles.HeaderButton>
          </div>
        </div>
      )}

      {active === 'tint' && (
        <div css={styles.popupWrapper({ right: 70 })}>
          <P gBold color={colors.mediumGrey} css={spacer.mrBT0}>
            Apply tint on content
          </P>

          <div className="d-flex align-items-center" css={spacer.mrT10}>
            {tintColors.map((tint) => (
              <styles.HeaderButton
                key={tint.color}
                className={selectedTint.color === tint.color ? 'tintBtn selected' : 'tintBtn'}
                onClick={() => setSelectedTint(tint)}
              >
                <styles.TintCircle tint={tint} />
              </styles.HeaderButton>
            ))}
          </div>
        </div>
      )}

      {active === 'info' && (
        <div css={styles.popupWrapper({ right: 15 })} className="help-text">
          <div css={[sharedStyles.types, sharedStyles.richTextQuestion]} className="ck-content">
            {ReactHtmlParser(DOMPurify.sanitize(withLatex(helpText)), {
              transform: (node) =>
                transformImgixUrls(node, { width: sharedStyles.imagesSizeInQuestionText })
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Popup;

Popup.propTypes = {
  closePopup: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired,
  setFontSize: PropTypes.func.isRequired,
  selectedTint: PropTypes.object.isRequired,
  setSelectedTint: PropTypes.func.isRequired,
  timerVisible: PropTypes.bool.isRequired,
  timerToggle: PropTypes.func.isRequired,
  progressBarVisible: PropTypes.bool.isRequired,
  progressBarToggle: PropTypes.func.isRequired
};
