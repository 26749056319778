import styled from '@emotion/styled';
import styledMap from 'styled-map';
import facepaint from 'facepaint';
import { typography, colors, mixins, misc } from 'styles/utilities';

const breakPoints = facepaint([mixins.respondTo(misc.viewport['d-vp'])]);

const H5 = styled.h5`
  font-family: ${styledMap`
  gBlack: ${typography.fonts.gothamBlack.fontFamily};
  gBold: ${typography.fonts.gothamBold.fontFamily};
  gBook: ${typography.fonts.gothamBook.fontFamily};
    default: ${typography.fonts.gothamBold.fontFamily};
  `};

  line-height: 120%;
  font-style: normal;
  letter-spacing: normal;
  color: ${(props) => (props.color ? props.color : colors.black)};
  ${breakPoints({
    fontSize: ['14px', typography.baseSizes.epsilon]
  })};
  margin: ${(props) => (props.noMargin ? 0 : misc.defaultTextMargin)};
`;

export default H5;
