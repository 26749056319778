import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Redirect } from 'components/Router';
import { ROUTE_PATHS } from 'globals/constants';

import ApiRequestHeadersContainer from 'hooks/useExtraApiRequestHeaders';
import { UserInfoContainer } from 'api/auth';
import { useCandidate } from 'api/schools';
import { useUserAccountContext } from 'components/EditAccount/userAccountContext';

const EditStudentDetails = ({ candidateId }) => {
  const { addHeaders, removeHeaders } = ApiRequestHeadersContainer.useContainer();
  const { updateUserInfo } = UserInfoContainer.useContainer();
  const { openEditCandidateModal } = useUserAccountContext();
  const { data: candidateData, isSuccess, isError } = useCandidate(candidateId);

  useEffect(() => {
    addHeaders({
      candidateId
    });

    return () => {
      removeHeaders();
    };
  }, [addHeaders, candidateId, removeHeaders]);

  // Fetch user and update user info container
  useEffect(() => {
    if (isSuccess) {
      updateUserInfo((prevState) => ({ ...prevState, currentCandidate: candidateData }));
    }

    return () => {
      updateUserInfo((prevState) => {
        const { currentCandidate, ...rest } = prevState;
        return { ...rest };
      });
    };
  }, [candidateData, isSuccess, updateUserInfo]);

  if (isError) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (isSuccess && candidateData) {
    openEditCandidateModal();
  }

  return null;
};
EditStudentDetails.propTypes = {
  candidateId: PropTypes.string.isRequired
};

export default EditStudentDetails;
