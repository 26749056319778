/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';

// Components
import { Container, Row, Col } from 'components/Grid';
import ForgotPasswordForm from 'components/ForgotPasswordForm/ForgotPasswordForm';
import ResetPasswordForm from 'components/ResetPasswordForm/ResetPasswordForm';

import LoginCard from 'components/LoginCard/LoginCard';

// Styling
import { spacer, colors } from 'styles/utilities';
import H1 from 'components/htmlElements/H1';
import P from 'components/htmlElements/P';
import A from 'components/htmlElements/A';

// utilities
import { UserInfoContainer, useLogout } from 'api/auth';
import { useLocation, navigate } from 'components/Router';
import { ROUTE_PATHS, site } from 'globals/constants';

import * as styles from './page.styles';

function Login() {
  const [isForgotPasswordOpen, setForgotPasswordIsOpen] = useState(false);
  const [isResetPasswordOpen, setIsResetPasswordOpen] = useState(false);
  // const [requestError, setRequestError] = useState(null);

  const openForgotPasswordModal = () => setForgotPasswordIsOpen(true);
  const closeForgotPasswordModal = () => setForgotPasswordIsOpen(false);
  const toggleForgotPasswordModal = () => setForgotPasswordIsOpen((s) => !s);

  const openResetModal = () => setIsResetPasswordOpen(true);
  const closeResetModal = () => setIsResetPasswordOpen(false);
  const toggleResetModal = () => setIsResetPasswordOpen((s) => !s);

  const { token } = UserInfoContainer.useContainer();
  const location = useLocation();
  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const key = search.get('key');
    if (key) openResetModal();
  }, [location]);

  // Handle force forgot password popup
  const logout = useLogout();
  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const forgotPassword = search.get('forgotPassword');
    const key = search.get('key');
    const forceLogout = search.get('forceLogout');
    if (forgotPassword === 'true' && !token && !key) {
      openForgotPasswordModal();
      logout({ updateUrl: false });
    } else if (forceLogout === 'true') {
      logout();
    }
  }, [location.search, logout, token]);

  // If no errors after login or if navigated to login page,
  // go to home page
  useEffect(() => {
    if (token) {
      navigate(ROUTE_PATHS.home);
    }
  }, [token]);

  return (
    <div css={[styles.loginPage, styles.bgPtn]} className="d-flex align-items-center">
      <Container>
        <Row className="d-flex justify-content-center align-items-center">
          <Col className="col-md-6">
            <H1 gBlack className="text-center" color={colors.white} css={spacer.mrB60}>
              Hello
            </H1>
            <LoginCard openForgotPasswordModal={openForgotPasswordModal} />
            <P color={colors.white} css={spacer.mrT60} className="text-center">
              Don&apos;t have an account?&nbsp;
              <A href={`${site.url}/products`} target="_blank">
                Buy Products First
              </A>
            </P>
          </Col>
        </Row>
      </Container>

      {isForgotPasswordOpen && (
        <ForgotPasswordForm
          isOpen={isForgotPasswordOpen}
          openModal={openForgotPasswordModal}
          closeModal={closeForgotPasswordModal}
          toggleModal={toggleForgotPasswordModal}
        />
      )}
      {isResetPasswordOpen && (
        <ResetPasswordForm
          isOpen={isResetPasswordOpen}
          openModal={openResetModal}
          closeModal={closeResetModal}
          toggleModal={toggleResetModal}
        />
      )}
    </div>
  );
}

export default Login;
