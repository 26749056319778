/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors } from 'styles/utilities';

export const questionWrapper = css`
  > * {
    margin: 5px 0;
  }
  .no-wrap-content {
    display: inline-flex;
    align-items: center;
  }
`;

export const clozeInput = css`
  min-width: 125px;
  width: auto;
  border: 1px solid ${colors.mediumGrey2};
  display: inline-flex;
  outline: none;
  margin: 3px 0px;

  &:focus {
    border-color: ${colors.primaryLinkActive};
  }

  & * {
    text-transform: none;
  }
`;

export const clozeAnswer = ({ isCorrect, answer }) => css`
  color: ${isCorrect ? colors.green : colors.red};
  white-space: pre;

  ${answer ? null : `text-decoration: underline`}
`;
