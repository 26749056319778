import { useState } from 'react';
import { createContainer } from 'unstated-next';

const useNavs = () => {
  const [navHidden, toggleHideNav] = useState(false);
  const hideNav = () => toggleHideNav(true);
  const showNav = () => toggleHideNav(false);

  const [footerHidden, toggleHideFooter] = useState(false);
  const hideFooter = () => toggleHideFooter(true);
  const showFooter = () => toggleHideFooter(false);

  const [navHeight, updateNavHeight] = useState(0);

  return {
    navHidden,
    hideNav,
    showNav,
    footerHidden,
    hideFooter,
    showFooter,
    navHeight,
    updateNavHeight
  };
};

const NavsContainer = createContainer(useNavs);
export default NavsContainer;
