/** @jsxImportSource @emotion/react */
import { Fragment, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import ModalHeader from 'components/ModalHeader';
import Modal from 'components/Modal';
import { Input, FieldError, Wrapper } from 'components/htmlElements/Fields';
import Button from 'components/htmlElements/Button';
import LoaderOverlay from 'components/LoaderOverlay';
import SuccessModal from 'components/SuccessModal';

import { UserInfoContainer } from 'api/auth';
import { useEditAccount as useEditParentAccount } from 'api/parents';
import { useEditAccount as useEditSchoolAccount } from 'api/schools';

import { spacer } from 'styles/utilities';

import { useUserAccountContext } from './userAccountContext';

const editAccountValidationSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required')
});

const EditAccount = () => {
  const { isEditAccountOpen, toggleEditAccountModal, closeEditAccountModal } =
    useUserAccountContext();

  const { firstName, lastName, email, userIs } = UserInfoContainer.useContainer();

  const {
    editAccount: editParentAccount,
    isSuccess: isSuccessEditParentAccount,
    isError: isErrorEditParentAccount,
    reset: resetEditParentAccountMutation
  } = useEditParentAccount();

  const {
    editAccount: editSchoolAccount,
    isSuccess: isSuccessEditSchoolAccount,
    isError: isErrorEditSchoolAccount,
    reset: resetEditSchoolAccountMutation
  } = useEditSchoolAccount();

  useEffect(
    () =>
      // Clear mutation state when modal is opened

      () => {
        resetEditParentAccountMutation();
        resetEditSchoolAccountMutation();
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEditAccountOpen]
  );

  const handleFormSubmission = async (values) => {
    if (userIs === 'parent') {
      await editParentAccount({
        ...values,
        name: `${values.first_name} ${values.last_name}` // Since this is a WP query, we should update the displayName/name too
      });
    } else if (userIs === 'school') {
      await editSchoolAccount({
        ...values,
        name: `${values.first_name} ${values.last_name}` // Since this is a WP query, we should update the displayName/name too
      });
    }
  };

  return (
    <div className="is-relative">
      <Modal isOpen={isEditAccountOpen} toggle={toggleEditAccountModal}>
        <Formik
          initialValues={{
            first_name: firstName,
            last_name: lastName
          }}
          validationSchema={editAccountValidationSchema}
          onSubmit={handleFormSubmission}
        >
          {({ isSubmitting }) => (
            <Fragment>
              {!isSuccessEditParentAccount && !isSuccessEditSchoolAccount ? (
                <div css={[spacer.padBT30, spacer.padLR30]}>
                  <ModalHeader header="Edit Account" />
                  <Form>
                    <div>
                      <Wrapper>
                        <Field
                          type="text"
                          name="first_name"
                          id="first_name"
                          as={Input}
                          placeholder="First name"
                        />
                        <ErrorMessage name="first_name" component={FieldError} />
                      </Wrapper>
                      <Wrapper>
                        <Field
                          type="text"
                          name="last_name"
                          id="last_name"
                          as={Input}
                          placeholder="Last name"
                        />
                        <ErrorMessage name="last_name" component={FieldError} />
                      </Wrapper>
                      <Wrapper>
                        <Input
                          type="text"
                          name="email"
                          id="email"
                          value={email}
                          readonly
                          disabled
                          placeholder="Email"
                        />
                      </Wrapper>

                      {isErrorEditParentAccount && isErrorEditSchoolAccount && (
                        <Wrapper>
                          <FieldError>
                            There was a problem with updating your account, please try again
                          </FieldError>
                        </Wrapper>
                      )}
                    </div>

                    <Button wide textCenter rounded type="submit">
                      Update
                    </Button>
                  </Form>

                  {isSubmitting && <LoaderOverlay />}
                </div>
              ) : (
                <SuccessModal onClick={closeEditAccountModal} message="Account details updated" />
              )}
            </Fragment>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

EditAccount.propTypes = {};

export default EditAccount;
