/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';

import { Container, Row, Col } from 'components/Grid';
import P from 'components/htmlElements/P';
import SolutionExplainer from 'components/SolutionExplainer/SolutionExplainer';

import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';

import { InstructionLine } from 'components/Instructions';

import { spacer, colors } from 'styles/utilities';
import * as sharedStyles from 'components/QuestionTypes/shared-components/shared.styles';
import transformImgixUrls from 'components/QuestionTypes/shared-components/transformImgixUrls';

import QuestionsCommonWrapperContainer from 'components/QuestionTypes/shared-components/QuestionsCommonWrapper';
import Latex, { withLatex } from 'components/QuestionTypes/shared-components/Latex';
import Input from 'components/htmlElements/Fields/Input';
import * as styles from './FreeTextInput.styles';

const FreeTextInput = ({ currentQuestion }) => {
  const { instructionsText, questionText, solutionExplaination, answer } =
    currentQuestion.freeTextInput;
  const { ttAnswers } = currentQuestion;

  const {
    skippingAllowed,
    showNextButton,
    answersEditableBeforeSubmission,
    updateAnswerPayload,
    isQuestionUsageFinal
  } = TestModuleInfoContainer.useContainer();

  const { attemptFinished, isNoteOrSampleQuestion, isPracticeQuestion } =
    QuestionsCommonWrapperContainer.useContainer();

  // Save input answer
  const [answerInput, setAnswerInput] = useState([]);
  useEffect(
    // This effect simply clears the answerInput on question change. It ensures on question switch the previous state is cleared
    () => () => {
      setAnswerInput([]);
    },
    [currentQuestion._id]
  );

  // Update `answerInput` if already answered and/or attempt is completed
  useEffect(() => {
    if (ttAnswers && ttAnswers.length) {
      setAnswerInput(ttAnswers);
    }

    // Clear the answerInput answers array on unmount
    // Without this there's a bug which messes up the next question if it's of the same type
    return () => {
      setAnswerInput([]);
    };
  }, [ttAnswers]);

  // Handle disabling of answering
  const [answeringDisabled, handleAnsweringDisabled] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  useEffect(() => {
    // If editable and not answered - can answer
    // If not editable and not answered - can answer
    // If not editable and answered - cannot answer
    if (!attemptFinished) {
      if (!ttAnswers) {
        // This means it's not answered
        handleAnsweringDisabled(false);
      } else if (!answersEditableBeforeSubmission) {
        handleAnsweringDisabled(true);
      }
    } else {
      // If attempt is over answering is disabled
      handleAnsweringDisabled(true);
    }

    return () => {
      // Reset state
      handleAnsweringDisabled(!answersEditableBeforeSubmission);
    };
  }, [answersEditableBeforeSubmission, attemptFinished, ttAnswers]);

  // Toggle next button visibility
  useEffect(() => {
    if (!skippingAllowed && !isNoteOrSampleQuestion) {
      showNextButton(false);
    } else {
      showNextButton(true);
    }
  }, [isNoteOrSampleQuestion, showNextButton, skippingAllowed]);

  // Handle updating answer payload
  useEffect(() => {
    updateAnswerPayload({
      questionId: currentQuestion._id,
      answerArray: answerInput
    });
  }, [answerInput, currentQuestion._id, updateAnswerPayload]);

  useEffect(() => {
    if (attemptFinished && ttAnswers) {
      if (ttAnswers[0] === answer[0]) {
        setIsCorrect(true);
      }
    }

    if (isNoteOrSampleQuestion) {
      setAnswerInput(answer);
    }

    if (attemptFinished && isPracticeQuestion && answer) {
      setAnswerInput(answer);
      if (answerInput[0] === answer[0]) {
        setIsCorrect(true);
      }
    }

    return () => setIsCorrect(false);
  }, [
    answer,
    attemptFinished,
    isNoteOrSampleQuestion,
    ttAnswers,
    currentQuestion._id,
    answerInput,
    isPracticeQuestion
  ]);

  const handleAnswerChange = (e) => {
    const answerFieldValue = e.target.value;

    setAnswerInput([answerFieldValue]);

    // answer input validation
    if (answerFieldValue.length > 0 && answerFieldValue.trim() !== '') {
      showNextButton(true);
    } else {
      showNextButton(false);
    }
  };

  return (
    <div css={[spacer.padT30]} className={`${!attemptFinished ? 'h-100' : ''}`}>
      <Container className="h-100">
        <Row className="h-100">
          <Col className="h-100">
            <div
              css={sharedStyles.verticalQuestionLayout}
              className={`${attemptFinished ? 'attempt-finished' : ''}`}
            >
              {instructionsText && <InstructionLine text={instructionsText} />}

              <Latex
                css={[
                  spacer.mrT20,
                  spacer.mrB40,
                  sharedStyles.types,
                  sharedStyles.richTextQuestion
                ]}
                className="ck-content"
              >
                {ReactHtmlParser(DOMPurify.sanitize(withLatex(questionText)), {
                  transform: (node) =>
                    transformImgixUrls(node, { width: sharedStyles.imagesSizeInQuestionText })
                })}
              </Latex>

              <div css={styles.answerInputWrapper}>
                <Input
                  placeholder="Freetext answer..."
                  name="answer"
                  className="answerInput"
                  disabled={answeringDisabled}
                  onChange={(e) => handleAnswerChange(e)}
                  autoComplete="off"
                  value={answerInput[0] ?? ''}
                  css={[spacer.padBT15]}
                />
              </div>

              {attemptFinished && !isNoteOrSampleQuestion && (ttAnswers || isPracticeQuestion) && (
                <P
                  css={spacer.mrT15}
                  color={isCorrect ? colors.green : colors.red}
                  large
                  className="text-center"
                >
                  {isCorrect ? 'Your answer is correct' : 'Your answer is incorrect'}
                </P>
              )}
            </div>

            {attemptFinished && !ttAnswers?.length && isQuestionUsageFinal && (
              <P color={colors.red} large>
                Question not attempted.
              </P>
            )}

            {attemptFinished && <SolutionExplainer text={solutionExplaination} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

FreeTextInput.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

const WithQuestionProvider = (props) => {
  const { questionSettings } = props.currentQuestion.freeTextInput;

  return (
    <QuestionsCommonWrapperContainer.Provider
      initialState={{ currentQuestion: props.currentQuestion, questionSettings }}
    >
      <FreeTextInput {...props} />
    </QuestionsCommonWrapperContainer.Provider>
  );
};
WithQuestionProvider.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

export default WithQuestionProvider;
