/* eslint-disable jsx-a11y/no-static-element-interactions */
/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { misc, colors, spacer, typography } from 'styles/utilities';
import { useEffect, useState } from 'react';
import { useComponentVisible } from 'components/Navbar/Navbar';
import StyledInput from './StyledInput';
import A from '../A';
import P from '../P';

const StyledSelect = StyledInput.withComponent('select');

const SelectBase = styled(StyledSelect, {
  shouldForwardProp: (prop) => isPropValid(prop)
})`
  -webkit-appearance: none;
  width: 1px;
  height: 1px;
  display: block;
  padding: ${misc.input.paddingVertical} ${misc.input.paddingHorizontal};
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 1;
  border: 1px solid ${colors.grey2};
  color: ${colors.mediumGrey2};
`;

const fancySelectDiv = css`
  width: 100%;
  position: relative;
  text-transform: uppercase;
`;

const triggerStyles = css`
  background-color: ${colors.white};
  border: 1px solid ${colors.grey2};
  border-radius: 0;
  box-shadow: none;
  padding: ${misc.input.paddingVertical} ${misc.input.paddingHorizontal};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  color: ${colors.selectBlack};
  text-transform: uppercase;
  font-family: ${typography.fonts.gothamBook.fontFamily};
  &.trigger:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-top-color: ${colors.mediumGrey};
    position: absolute;
    right: 9px;
    top: 50%;
    margin-top: -3.5px;
  }
`;

const optionsContainer = css`
  margin: 0;
  max-height: 150px;
  background-color: ${colors.white};
  border: 1px solid ${colors.grey2};
  box-shadow: ${misc.dropDownShadow};
  border-radius: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  list-style: none;
  visibility: hidden;
  opacity: 0;
  z-index: ${misc.dropdownZindex};
  overflow: auto;
  min-width: 200px;
  transition: opacity 300ms cubic-bezier(1, 0, 0.36, 1), top 300ms cubic-bezier(1, 0, 0.36, 1),
    visibility 300ms cubic-bezier(1, 0, 0.36, 1);
  line-height: 120%;
  font-size: 14px;
  padding: 0;
  &.show {
    top: 50px;
    visibility: visible;
    opacity: 1;
  }
`;

const Select = ({ placeholder, options, setValue, triggerProps }) => {
  const { ref, isComponentVisible, toggleVisibility } = useComponentVisible(false);
  const [trigger, setTrigger] = useState(placeholder);
  // const [value, setValue] = useState('');

  const handleSelect = (val) => {
    setTrigger(val.option);
    setValue(val.value);
  };

  // keeping state up to date
  useEffect(() => {
    setTrigger(placeholder);
  }, [placeholder]);

  return (
    <div css={fancySelectDiv} onClick={toggleVisibility} ref={ref} onKeyDown={toggleVisibility}>
      <SelectBase />
      <div css={triggerStyles} className="trigger">
        <P css={[spacer.mrR30, spacer.mrBT0]} color={colors.selectBlack} {...triggerProps}>
          {trigger}
        </P>
      </div>
      <ul
        css={[optionsContainer, spacer.padLR0, spacer.padBT0]}
        className={isComponentVisible ? 'show dropdown' : 'dropdown'}
      >
        {options.map((val, index) => (
          <A
            onClick={() => handleSelect(val)}
            transform="uppercase"
            color={colors.mediumGrey}
            key={`${index.toString()}-${val}`}
            className="option is-relative"
          >
            <li css={[spacer.padLR15, spacer.padBT15]}>{val.option}</li>
          </A>
        ))}
      </ul>
    </div>
  );
};

Select.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array,
  setValue: PropTypes.func,
  triggerProps: PropTypes.object
};

Select.defaultProps = {
  options: [],
  setValue: null,
  triggerProps: { large: true }
};

export default Select;
