/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';
import transformImgixUrls from 'components/QuestionTypes/shared-components/transformImgixUrls';

import Latex, { withLatex } from 'components/QuestionTypes/shared-components/Latex';

import * as sharedStyles from 'components/QuestionTypes/shared-components/shared.styles';
import * as styles from './SolutionExplainer.styles';

/**
 * Primary UI component for user interaction
 */
const SolutionExplainer = ({ text }) => (
  <Fragment>
    {text && text.length ? (
      <div css={styles.container}>
        <Latex css={[sharedStyles.types, styles.solution]} className="ck-content">
          {ReactHtmlParser(
            DOMPurify.sanitize(withLatex(text), {
              ADD_TAGS: ['iframe'],
              ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling']
            }),
            {
              transform: (node) =>
                transformImgixUrls(node, { width: sharedStyles.imagesSizeInQuestionText })
            }
          )}
        </Latex>
      </div>
    ) : (
      ''
    )}
  </Fragment>
);

SolutionExplainer.propTypes = {
  text: PropTypes.string.isRequired
};

export default SolutionExplainer;
