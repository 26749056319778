/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTable, useSortBy, useGlobalFilter, usePagination, useRowSelect } from 'react-table';
import P from 'components/htmlElements/P';
import Input from 'components/htmlElements/Fields/Input';
import { colors, spacer, bgColor } from 'styles/utilities';
import Checkbox from './Checkbox';
import PaginationButtons from './PaginationButtons';

import * as styles from './DataTable.styles';

const DataTable = ({
  columnConfig,
  rowData,
  placeholder,
  dataPage,
  setDataPage,
  setSearch,
  search,
  pageCount
}) => {
  const columns = useMemo(() => columnConfig, [columnConfig]);
  const data = useMemo(() => rowData, [rowData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    prepareRow,
    setPageSize,
    state: { pageSize, pageIndex }
  } = useTable(
    {
      columns,
      data,
      initialState: { dataPage: 1 },
      manualPagination: true,
      pageCount
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((restOfTheColumns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div css={styles.width}>
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div css={styles.width}>
              <Checkbox {...row.getToggleRowSelectedProps()} />
            </div>
          )
        },
        ...restOfTheColumns
      ]);
    }
  );

  // const handleMinRows = (e) => setPageSize(Number(e.target.value));
  const handleSearchRows = (e) => {
    setSearch(e.target.value);
  };

  const getStartRowNumber = () => pageIndex * pageSize + 1;

  const getEndRowNumber = (entries) => {
    const result = pageIndex * pageSize + Number(pageSize);
    if (entries < result) return entries;
    return result;
  };

  return (
    <div css={styles.tableWrapper}>
      <div css={styles.tableForm(true)}>
        {/* <SelectRowLimit defaultRowSize={pageSize} handleRowSize={10} /> */}
        <div />
        <GlobalTableFilter
          handleChange={handleSearchRows}
          filterValue={search}
          showLabel
          placeholder={placeholder}
        />
      </div>

      <div css={styles.table}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                    css={styles.tableHeader}
                  >
                    {column.id !== 'selection' ? (
                      <P color={colors.mediumGrey2} noMargin>
                        {column.render('Header')}
                      </P>
                    ) : (
                      <div>{column.render('Header')}</div>
                    )}

                    <span
                      css={styles.sortIcon(column.isSorted, column.isSortedDesc)}
                      className={`sort-icon ${headerGroup.headers.length - 1 === i ? 'hide' : ''}`}
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} css={styles.tableData}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div css={[styles.tableBottom, spacer.padBT10]}>
        <P noMargin>
          Showing {getStartRowNumber()} to {getEndRowNumber(rows.length)} entries
        </P>

        <PaginationButtons
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          previousPage={previousPage}
          gotoPage={gotoPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          dataPage={dataPage}
          setDataPage={setDataPage}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  );
};

export const GlobalTableFilter = ({ handleChange, filterValue, placeholder, showLabel }) => (
  <div css={[styles.tableSearchInput]} className="d-flex align-items-stretch">
    <label css={styles.label} className="w-100 d-flex align-items-stretch">
      {showLabel ? 'Search:' : ''}
      <Input onChange={handleChange} value={filterValue || ''} placeholder={placeholder || ''} />
    </label>
  </div>
);

export const SelectRowLimit = ({ defaultRowSize, handleRowSize }) => (
  <div>
    <label css={styles.label}>
      Show&nbsp;
      <select
        defaultValue={defaultRowSize}
        onChange={handleRowSize}
        css={[styles.formRowSelect, bgColor.grey4]}
      >
        {[10, 25, 50, 100].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </select>
      &nbsp;Entries
    </label>
  </div>
);

DataTable.propTypes = {
  columnConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowData: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholder: PropTypes.string.isRequired,
  pageCount: PropTypes.number.isRequired
};

export default DataTable;
