/** @jsxImportSource @emotion/react */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import QuestionsCommonWrapperContainer from 'components/QuestionTypes/shared-components/QuestionsCommonWrapper';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';
import { Container, Row, Col } from 'components/Grid';
import { colors, spacer } from 'styles/utilities';
import { InstructionLine } from 'components/Instructions/Instructions';
import * as sharedStyles from 'components/QuestionTypes/shared-components/shared.styles';
import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';
import SolutionExplainer from 'components/SolutionExplainer/SolutionExplainer';
import P from 'components/htmlElements/P';
import * as styles from './Matrices.styles';
import Latex, { withLatex } from '../shared-components/Latex';

const Matrices = ({ currentQuestion }) => {
  const {
    instructionsText,
    questionText,
    selectedItems,
    shownItems,
    solutionExplaination,
    answer
  } = currentQuestion.matrices;
  const { ttAnswers } = currentQuestion;

  const {
    // attemptFinished,
    skippingAllowed,
    showNextButton,
    answersEditableBeforeSubmission,
    updateAnswerPayload,
    isQuestionUsageFinal
  } = TestModuleInfoContainer.useContainer();

  const { attemptFinished, isNoteOrSampleQuestion } =
    QuestionsCommonWrapperContainer.useContainer();

  // Save selected answers
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  // Update `selectedAnswers` if already answered and/or attempt is completed
  useEffect(() => {
    if (ttAnswers && ttAnswers.length) {
      setSelectedAnswers(ttAnswers);
    }

    // Clear the selected answers array on unmount
    // Without this there's a bug which messes up the next question if it's of the same type
    return () => {
      setSelectedAnswers([]);
    };
  }, [ttAnswers]);

  // Handle disabling of answering
  const [answeringDisabled, handleAnsweringDisabled] = useState(false);

  useEffect(() => {
    // If editable and not answered - can answer
    // If not editable and not answered - can answer
    // If not editable and answered - cannot answer
    if (!attemptFinished) {
      if (!ttAnswers) {
        // This means it's not answered
        handleAnsweringDisabled(false);
      } else if (!answersEditableBeforeSubmission) {
        handleAnsweringDisabled(true);
      }
    } else {
      // If attempt is over answering is disabled
      handleAnsweringDisabled(true);
    }

    return () => {
      // Reset state
      handleAnsweringDisabled(!answersEditableBeforeSubmission);
    };
  }, [answersEditableBeforeSubmission, attemptFinished, ttAnswers]);

  // Toggle next button visibility
  useEffect(() => {
    if (!skippingAllowed && !isNoteOrSampleQuestion && !selectedAnswers.length) {
      showNextButton(false);
    } else {
      showNextButton(true);
    }
  }, [isNoteOrSampleQuestion, selectedAnswers.length, showNextButton, skippingAllowed]);

  // Handle updating answer payload
  useEffect(() => {
    updateAnswerPayload({
      questionId: currentQuestion._id,
      answerArray: selectedAnswers
    });
  }, [selectedAnswers, currentQuestion._id, updateAnswerPayload]);

  // Handling updating selectedAnswer
  const setAnswersHandler = (item) => {
    if (shownItems.includes(item)) {
      if (selectedAnswers.includes(item)) {
        setSelectedAnswers(selectedAnswers.filter((a) => a !== item));
      } else {
        setSelectedAnswers((prev) => [...prev, item]);
      }
    }
  };

  // First Matrix
  const [patternSelected, setPatternSelected] = useState([]);
  const [selectionComplete, setSelectionComplete] = useState(false);

  const setPattern = (item) => {
    if (patternSelected.includes(item)) {
      setPatternSelected(patternSelected.filter((p) => p !== item));
    } else {
      setPatternSelected((prev) => [...prev, item]);
    }
  };

  useEffect(() => {
    if (selectedAnswers.length > 0) {
      setSelectionComplete(true);
    }
  }, [selectedAnswers]);

  useEffect(() => {
    if (selectedItems.length === patternSelected.length) {
      setSelectionComplete(true);
    }
  }, [patternSelected.length, selectedItems.length]);

  // returns className to show selected pattern in first matrix
  const showSelected = useCallback(
    (item) => {
      if (selectedAnswers.length > 0 && selectedItems.includes(item)) {
        return 'selected';
      }
      if (attemptFinished && selectedItems.includes(item)) {
        return 'selected';
      }
      if (selectedItems.includes(item) && patternSelected.includes(item)) {
        return 'selected';
      }
      if (selectionComplete && selectedItems.includes(item)) {
        return 'selected';
      }
      if (selectedItems.includes(item)) {
        return 'selectable';
      }
      return null;
    },
    [attemptFinished, patternSelected, selectedAnswers.length, selectedItems, selectionComplete]
  );

  // returns className based on item index
  const showAnswer = useCallback(
    (item) => {
      if (shownItems.includes(item) && selectedAnswers.includes(item)) return 'selected';
      if (shownItems.includes(item)) {
        return 'selectable';
      }
      return null;
    },
    [selectedAnswers, shownItems]
  );

  // answer validation state
  const [isCorrect, setIsCorrect] = useState();
  const [sortedAnswers, setSortedAnswers] = useState([]);
  const [sortedSelectedAnswers, setSortedSelectedAnswers] = useState([]);

  // sorting both answer array and selectedAnswer array in ascending order to check if answer is correct

  useEffect(() => {
    if (attemptFinished) {
      setSortedAnswers(answer?.sort((a, b) => a - b));
      setSortedSelectedAnswers(selectedAnswers.sort((a, b) => a - b));
      setIsCorrect(
        sortedAnswers.every((item, i) => item === sortedSelectedAnswers[i]) &&
          sortedAnswers.length === sortedSelectedAnswers.length
      );
    }
  }, [answer, selectedAnswers, attemptFinished, sortedAnswers, sortedSelectedAnswers]);

  useEffect(
    // This effect simply clears the older states on question change. It ensures on question switch the previous state is cleared
    () => () => {
      setSelectedAnswers([]);
      setPatternSelected([]);
      setSortedAnswers([]);
      setSortedSelectedAnswers([]);
      setSelectionComplete(false);
      setIsCorrect();
    },
    [currentQuestion._id]
  );

  // showing the result in third matrix
  const showResults = useCallback(
    (item) => {
      if (selectedAnswers.includes(item) && isCorrect) {
        return 'correct';
      }
      if (
        selectedAnswers.includes(item) &&
        shownItems.includes(item) &&
        !isCorrect &&
        isCorrect !== undefined
      ) {
        return 'inCorrect';
      }

      if (shownItems.includes(item)) {
        return 'selectable';
      }
      return null;
    },
    [isCorrect, selectedAnswers, shownItems]
  );

  const showSample = useCallback(
    (item) => {
      if (answer?.includes(item)) {
        return 'correct';
      }
      if (shownItems.includes(item)) {
        return 'selectable';
      }

      return null;
    },
    [answer, shownItems]
  );

  const renderList = useMemo(() => {
    const generatedArray = [];
    for (let i = 0; i < 100; i += 1) {
      generatedArray.push(i);
    }
    return generatedArray;
  }, []);

  return (
    <div css={spacer.padT30}>
      <Container>
        <Row className="h-100">
          <Col className="h-100">
            {instructionsText && <InstructionLine text={instructionsText} />}

            <Latex
              css={[spacer.mrT60, spacer.mrB40, sharedStyles.types, sharedStyles.richTextQuestion]}
              className="ck-content"
            >
              {ReactHtmlParser(DOMPurify.sanitize(withLatex(questionText)))}
            </Latex>

            <div css={[styles.matricesWrapper, spacer.padB30]}>
              <div css={styles.matrixWrapper}>
                {renderList.map((i) => (
                  <styles.Dots
                    css={[spacer.mrBT5, spacer.mrLR5]}
                    key={`item-${i.toString()}`}
                    noHover={!selectedItems.includes(i) || answeringDisabled}
                    className={showSelected(i)}
                    onClick={() => !selectionComplete && setPattern(i)}
                  />
                ))}
              </div>

              {!attemptFinished && !isNoteOrSampleQuestion && (
                <div css={styles.matrixWrapper} className={selectionComplete ? 'show' : 'hide'}>
                  {renderList.map((i) => (
                    <styles.Dots
                      css={[spacer.mrBT5, spacer.mrLR5]}
                      key={`item-${i.toString()}`}
                      noHover={!shownItems.includes(i) || answeringDisabled}
                      className={showAnswer(i)}
                      onClick={() => setAnswersHandler(i)}
                    />
                  ))}
                </div>
              )}

              {attemptFinished && !isNoteOrSampleQuestion && (
                <React.Fragment>
                  <div>
                    <div css={styles.matrixWrapper}>
                      {renderList.map((i) => (
                        <styles.Dots
                          css={[spacer.mrBT5, spacer.mrLR5]}
                          key={`item-${i.toString()}`}
                          noHover
                          className={showResults(i)}
                          onClick={() => setAnswersHandler(i)}
                        />
                      ))}
                    </div>

                    <P
                      css={spacer.mrT10}
                      color={isCorrect ? colors.green : colors.red}
                      className="text-center"
                      large
                    >
                      {isCorrect ? 'Your answer is correct' : 'Your answer is wrong'}
                    </P>
                  </div>

                  <div>
                    <div css={styles.matrixWrapper}>
                      {renderList.map((i) => (
                        <styles.Dots
                          css={[spacer.mrBT5, spacer.mrLR5]}
                          key={`item-${i.toString()}`}
                          noHover
                          className={showSample(i)}
                        />
                      ))}
                    </div>

                    <P css={spacer.mrT10} color={colors.green} className="text-center" large>
                      Correct answer
                    </P>
                  </div>
                </React.Fragment>
              )}

              {isNoteOrSampleQuestion && (
                <div>
                  <div css={styles.matrixWrapper}>
                    {renderList.map((i) => (
                      <styles.Dots
                        css={[spacer.mrBT5, spacer.mrLR5]}
                        key={`item-${i.toString()}`}
                        noHover
                        className={showSample(i)}
                      />
                    ))}
                  </div>

                  <P css={spacer.mrT10} color={colors.green} className="text-center" large>
                    Correct answer
                  </P>
                </div>
              )}
            </div>

            {attemptFinished && !ttAnswers?.length && isQuestionUsageFinal && (
              <P color={colors.red} large>
                Question not attempted.
              </P>
            )}

            {attemptFinished && <SolutionExplainer text={solutionExplaination} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Matrices.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

const WithQuestionProvider = (props) => {
  const { questionSettings } = props.currentQuestion.matrices;

  return (
    <QuestionsCommonWrapperContainer.Provider
      initialState={{ currentQuestion: props.currentQuestion, questionSettings }}
    >
      <Matrices {...props} />
    </QuestionsCommonWrapperContainer.Provider>
  );
};
WithQuestionProvider.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

export default WithQuestionProvider;
