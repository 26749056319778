/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';

import P from 'components/htmlElements/P';
import SolutionExplainer from 'components/SolutionExplainer/SolutionExplainer';

import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';

import { InstructionLine } from 'components/Instructions';
import { spacer, colors } from 'styles/utilities';
import * as sharedStyles from 'components/QuestionTypes/shared-components/shared.styles';
import transformImgixUrls from 'components/QuestionTypes/shared-components/transformImgixUrls';

import QuestionsCommonWrapperContainer from 'components/QuestionTypes/shared-components/QuestionsCommonWrapper';
import Latex, { withLatex } from 'components/QuestionTypes/shared-components/Latex';
import { arrayPop, arrayPush } from 'utils/arrayPushPop';
import * as styles from './GeogebraOptions.styles';
import AnswerOption from '../shared-components/AnswerOption';

const GeogebraOptions = ({ currentQuestion }) => {
  const {
    instructionsText,
    questionText,
    answerOptions,
    solutionExplaination,
    geogebraApplet,
    answer
  } = currentQuestion.geogebraOptions;
  const { ttAnswers } = currentQuestion;

  const {
    // attemptFinished,
    skippingAllowed,
    showNextButton,
    answersEditableBeforeSubmission,
    updateAnswerPayload,
    isQuestionUsageFinal
  } = TestModuleInfoContainer.useContainer();

  const { attemptFinished, isNoteOrSampleQuestion } =
    QuestionsCommonWrapperContainer.useContainer();

  const [maximumSelections, updateMaximumSelections] = useState(0);
  useEffect(() => {
    updateMaximumSelections(answerOptions.filter((opt) => opt.isCorrect).length);
  }, [answerOptions]);

  // Save checked answer options
  const [checkedAnswers, setCheckedAnswers] = useState([]);
  useEffect(
    // This effect simply clears the checkedAnswers on question change. It ensures on question switch the previous state is cleared
    () => () => {
      setCheckedAnswers([]);
    },
    [currentQuestion._id]
  );

  const handleAnswerChange = (event, shortId) => {
    if (event.target.checked) {
      if (checkedAnswers.length < maximumSelections) {
        const updatedAnswersArray = arrayPush([...checkedAnswers], shortId); // Spread the array otherwise it will mutate it
        setCheckedAnswers(updatedAnswersArray);
      } else {
        setCheckedAnswers((prevState) => {
          // This replaces the first instance
          const updatedState = [...prevState];
          updatedState.pop();
          updatedState.unshift(shortId);
          return updatedState;
        });
      }
    } else {
      const updatedAnswersArray = arrayPop([...checkedAnswers], shortId); // Spread the array otherwise it will mutate it
      setCheckedAnswers(updatedAnswersArray);
    }
  };
  // Update `checkedAnswers` if already answered and/or attempt is completed
  useEffect(() => {
    if (ttAnswers && ttAnswers.length) {
      setCheckedAnswers(ttAnswers);
    }

    // Clear the checked answers array on unmount
    // Without this there's a bug which messes up the next question if it's of the same type
    return () => {
      setCheckedAnswers([]);
    };
  }, [ttAnswers]);

  // Handle disabling of answering
  const [answeringDisabled, handleAnsweringDisabled] = useState(false);
  useEffect(() => {
    // If editable and not answered - can answer
    // If not editable and not answered - can answer
    // If not editable and answered - cannot answer
    if (!attemptFinished) {
      if (!ttAnswers) {
        // This means it's not answered
        handleAnsweringDisabled(false);
      } else if (!answersEditableBeforeSubmission) {
        handleAnsweringDisabled(true);
      }
    } else {
      // If attempt is over answering is disabled
      handleAnsweringDisabled(true);
    }

    return () => {
      // Reset state
      handleAnsweringDisabled(!answersEditableBeforeSubmission);
    };
  }, [answersEditableBeforeSubmission, attemptFinished, ttAnswers]);

  // Toggle next button visibility
  useEffect(() => {
    if (
      !skippingAllowed &&
      checkedAnswers.length !== maximumSelections &&
      !isNoteOrSampleQuestion
    ) {
      showNextButton(false);
    } else {
      showNextButton(true);
    }
  }, [
    checkedAnswers.length,
    isNoteOrSampleQuestion,
    maximumSelections,
    showNextButton,
    skippingAllowed
  ]);

  // Handle updating answer payload
  useEffect(() => {
    updateAnswerPayload({
      questionId: currentQuestion._id,
      answerArray: checkedAnswers
    });
  }, [checkedAnswers, currentQuestion._id, updateAnswerPayload]);

  useEffect(() => {
    const ggbApp = new window.GGBApplet(
      {
        showMenuBar: false,
        showToolBar: false,
        showAlgebraInput: false,
        showFullscreenButton: true,
        material_id: geogebraApplet || '',
        scaleContainerClass: 'geogebraContainer',
        borderColor: colors.blackTransparent50
      },
      true
    );
    ggbApp.inject('ggb-element');
  }, [geogebraApplet, currentQuestion._id]);

  const contentWrapperRef = useRef(null);
  const instructionTextRef = useRef(null);
  const appletWrapperRef = useRef(null);

  const appletHeightHandler = useCallback(() => {
    if (window.innerWidth > 992 && !attemptFinished) {
      appletWrapperRef.current.style.height = `${
        contentWrapperRef.current.clientHeight - instructionTextRef.current.clientHeight - 80
      }px`;
    } else {
      appletWrapperRef.current.style.height = 'auto';
    }
  }, [attemptFinished]);

  useEffect(() => {
    appletHeightHandler();
    window.addEventListener('resize', appletHeightHandler);

    return () => window.removeEventListener('resize', appletHeightHandler);
  }, [appletHeightHandler]);

  return (
    <div
      css={[spacer.padLR30, styles.appletContainer]}
      className={`${!attemptFinished ? 'h-100' : ''}`}
    >
      <div className="d-flex wrapper h-100" css={[spacer.padBT30]} ref={contentWrapperRef}>
        <div css={[styles.questionContainer, spacer.mrR30, spacer.mrB10]}>
          <div css={instructionsText && spacer.mrB10} ref={instructionTextRef}>
            {instructionsText && <InstructionLine text={instructionsText} />}
          </div>

          <div css={[styles.appletWrapper]} className="geogebraContainer" ref={appletWrapperRef}>
            <div id="ggb-element" key={geogebraApplet || 'applet'} />
          </div>
        </div>

        <div css={[sharedStyles.verticalQuestionLayout, styles.answerOptionsWrapper]}>
          <Latex
            css={[spacer.mrB10, sharedStyles.types, sharedStyles.richTextQuestion]}
            className="ck-content geogebraQuestion-text"
          >
            {ReactHtmlParser(DOMPurify.sanitize(withLatex(questionText)), {
              transform: (node) =>
                transformImgixUrls(node, {
                  width: sharedStyles.imagesSizeInQuestionText
                })
            })}
          </Latex>

          <div className="answer-options">
            {answerOptions &&
              answerOptions.map((answerOption, index) => (
                <AnswerOption
                  key={answerOption.shortId}
                  html={answerOption.text}
                  shortId={answerOption.shortId}
                  type="box"
                  index={index}
                  disabled={answeringDisabled}
                  onChange={handleAnswerChange}
                  checked={checkedAnswers.includes(answerOption.shortId)}
                  isCorrectAnswer={attemptFinished && answer?.includes(answerOption.shortId)}
                  attemptFinished={attemptFinished}
                />
              ))}
          </div>

          {attemptFinished && !ttAnswers?.length && isQuestionUsageFinal && (
            <P css={spacer.mrT15} color={colors.red} large className="text-center">
              Question not attempted.
            </P>
          )}
        </div>
      </div>

      {attemptFinished && <SolutionExplainer text={solutionExplaination} />}
    </div>
  );
};

GeogebraOptions.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

const WithQuestionProvider = (props) => {
  const { questionSettings } = props.currentQuestion.geogebraOptions;

  return (
    <QuestionsCommonWrapperContainer.Provider
      initialState={{ currentQuestion: props.currentQuestion, questionSettings }}
    >
      <GeogebraOptions {...props} />
    </QuestionsCommonWrapperContainer.Provider>
  );
};
WithQuestionProvider.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

export default WithQuestionProvider;
