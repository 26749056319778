import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as LogRocket from 'logrocket';
import { UserInfoContainer, useValidateToken, useLogout } from 'api/auth';

import { Redirect } from 'components/Router';
import { ROUTE_PATHS } from 'globals/constants';

const Layout = ({ component: Component, ...rest }) => {
  const { validateToken, data } = useValidateToken();
  const { token, id, firstName, lastName, userIs, username, email } =
    UserInfoContainer.useContainer();

  const logout = useLogout();
  useEffect(() => {
    validateToken();

    if (id) {
      // Set Logrocket info
      LogRocket.identify(id, {
        name: `${firstName} ${lastName}`,
        userIs,
        ...(username && { username }),
        ...(email && { email })
      });
    }
  }, [validateToken, token, id, firstName, lastName, userIs, username, email]);

  const [didLogout, updateDidLogout] = useState(false);

  useEffect(() => {
    const logoutAction = () => {
      if (data && data.statusCode === 401) {
        logout();
        updateDidLogout(true);
        // window.location.reload();
      }
    };

    if (!didLogout) {
      logoutAction();
    }
  }, [data, didLogout, logout]);

  return (
    <Fragment>
      {token ? <Component {...rest} /> : <Redirect to={ROUTE_PATHS.login} noThrow />}
    </Fragment>
  );
};

Layout.propTypes = {
  component: PropTypes.func.isRequired
};
export default Layout;
