export function prev(current, pages, getLast = true) {
  const index = pages.indexOf(current);
  if (index === 0) {
    if (getLast) {
      return pages[pages.length - 1];
    }
    return null;
  }
  return pages[index - 1];
}

export function next(current, pages, getFirst = true) {
  const index = pages.indexOf(current);
  if (index === pages.length - 1) {
    if (getFirst) {
      return pages[0];
    }
    return null;
  }
  return pages[index + 1];
}

export const getPrevLesson = ({ data, lessonId }) => {
  const filteredSection = data.filter(({ lessons }) =>
    lessons?.some((lesson) => lesson._id === lessonId)
  );

  // If there are no results bail out
  if (!filteredSection.length) {
    return null;
  }

  const flattenLessons = data
    ?.map(({ section, lessons }) =>
      lessons.map((lesson) => ({
        ...lesson,
        sectionId: section._id,
        sectionName: section.name
      }))
    )
    .flat();

  const lessonIds = flattenLessons.map((lesson) => lesson._id);

  const prevId = prev(lessonId, lessonIds);

  const prevLessonObj = flattenLessons.filter((lesson) => lesson._id === prevId);

  return prevLessonObj[0];
};

export const getNextLesson = ({ data, lessonId }) => {
  const filteredSection = data.filter(({ lessons }) =>
    lessons?.some((lesson) => lesson._id === lessonId)
  );

  // If there are no results bail out
  if (!filteredSection.length) {
    return null;
  }

  const flattenLessons = data
    ?.map(({ section, lessons }) =>
      lessons.map((lesson) => ({
        ...lesson,
        sectionId: section._id,
        sectionName: section.name
      }))
    )
    .flat();

  const lessonIds = flattenLessons.map((lesson) => lesson._id);

  const nextId = next(lessonId, lessonIds);

  const nextLessonObj = flattenLessons.filter((lesson) => lesson._id === nextId);

  return nextLessonObj[0];
};
