/* eslint-disable import/prefer-default-export */

import { css } from '@emotion/react';
// import { typography } from 'styles/utilities';

export const footerDiv = css`
  width: 100%;
  position: relative;
  z-index: 1;
`;
