/** @jsxImportSource @emotion/react */

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { colors, spacer } from 'styles/utilities';
import H3 from 'components/htmlElements/H3';
import P from 'components/htmlElements/P';
import { Row } from 'components/Grid/Grid';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { dateInYYYYMMDD } from 'utils/dateFormats';
import * as styles from './MockExamModal.styles';

const MockExamModal = ({ isOpen, toggleModal, color, header, quantity, examData }) => {
  const { product, details } = examData;

  const slots = useMemo(
    () =>
      product
        .find((slot) => slot.key === '_tmdata')
        ?.value?.[0]?.tmcartepo_data.slice(1)
        ?.map((field, i, arr) => i % 2 === 0 && { name: field?.key, phone: arr[i + 1]?.key })
        ?.filter((val) => val),
    [product]
  );

  const tableHeadings = useMemo(() => ['Name', 'Phone No.'], []);

  return (
    <div className="is-relative">
      <Modal isOpen={isOpen} toggle={toggleModal}>
        <div css={styles.ModalHeader({ color })}>
          <styles.TopBand color={color} />

          <div css={[spacer.padBT30, spacer.padLR30]}>
            <H3 gBook css={spacer.mrBT0}>
              {header}
            </H3>

            <P large gBook color={colors.mediumGrey} css={spacer.mrT15}>
              Slots booked: {quantity}
            </P>
          </div>
        </div>

        <div css={[spacer.padT20, spacer.padLR30]}>
          <div css={spacer.mrB40}>
            <div css={spacer.mrB10}>
              <P>
                <strong>Date:</strong> {details.livemocks_date}
              </P>
            </div>
            <div css={spacer.mrB10}>
              <P>
                <strong>Time:</strong> {details.livemocks_time}
              </P>
            </div>
            <div css={spacer.mrB10}>
              <P>
                <strong>Venue:</strong> {details.livemocks_venue}
              </P>
            </div>
          </div>

          <div>
            <P as={H3} gBook css={spacer.mrB30}>
              Booking details
            </P>

            <div css={[styles.slotsTable, spacer.padLR15]}>
              <Row className="table-head table-row">
                {tableHeadings.map((heading) => (
                  <div
                    className="col-6 table-cell"
                    css={[spacer.padBT20, spacer.padLR20]}
                    key={heading}
                  >
                    <P instruction noMargin>
                      {heading}
                    </P>
                  </div>
                ))}
              </Row>

              <div className="table-body">
                {slots &&
                  slots.map((slot, i) => (
                    <Row className="table-head table-row" key={`${i.toString()}-item`}>
                      {Object.values(slot).map((val) => (
                        <div
                          className="col-6 table-cell"
                          css={[spacer.padBT20, spacer.padLR20]}
                          key={val}
                        >
                          <P instruction noMargin color={colors.mediumGrey}>
                            {val}
                          </P>
                        </div>
                      ))}
                    </Row>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div css={[spacer.padLR30, spacer.padBT30]}>
          <AddToCalendarButton
            id="atcb-button"
            name={`${header} mock exam`}
            startDate={dateInYYYYMMDD(details?.livemocks_date)}
            options={['Apple', 'Google', 'Outlook.com']}
            startTime={details?.livemocks_time}
            endTime={details?.livemocks_time}
            location={details?.livemocks_venue}
            timeZone="currentBrowser"
            trigger="click"
            buttonStyle="custom"
            customCss="./addToCalendar.css"
            hideIconButton
            hideBackground
            hideCheckmark
            listStyle="modal"
          />
        </div>
      </Modal>
    </div>
  );
};

MockExamModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  examData: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired
};

export default MockExamModal;
