/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors, mixins } from 'styles/utilities';

export const binWrapper = css`
  text-align: center;
  display: inline-block;
`;

export const bin = ({ isOpen }) => css`
  width: 140px;
  height: 150px;
  position: relative;
  background-color: ${isOpen ? colors.yellow : colors.grey2};
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  margin-top: 40px;
  &:before {
    content: '';
    position: absolute;
    width: 160px;
    height: 12px;
    left: -10px;
    top: -15px;
    background-color: ${isOpen ? colors.yellow : colors.grey2};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    ${mixins.transition({ properties: 'transform', duration: '250ms' })};
    transform: ${isOpen ? 'rotate(30deg)' : 'rotate(0deg)'};
    transform-origin: 90% 100%;
  }
  &:after {
    content: '';
    position: absolute;
    width: 40px;
    height: 10px;
    left: 50%;
    margin-left: -20px;
    top: -28px;
    background-color: ${isOpen ? colors.lightYellow : colors.grey2};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    ${mixins.transition({ properties: 'transform', duration: '250ms' })};
    transform: ${isOpen ? 'rotate(30deg)  translate(5px, -30px)' : 'rotate(0deg)'};
    transform-origin: 90% 100%;
  }

  .bin-contents {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
