/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors, mixins } from 'styles/utilities';

export const ProductBandsContainer = css`
  &.locked {
    position: relative;
    min-height: 450px;

    .locked-cta-container {
      position: absolute;
      padding: 30px 0px;
      width: 100%;
      z-index: 50;
      inset: 0;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      background: ${mixins.toRgba(colors.cream, 0.3)};
      border: 2px dashed ${colors.black};
      backdrop-filter: blur(10px);

      .locked-cta-wrapper {
        max-width: 700px;
      }

      .lock-svg {
        margin-bottom: -30px;
      }
    }
  }
`;
