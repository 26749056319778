import { useQuery, useMutation, useQueryClient } from 'react-query';
import request from 'utils/request';
import { apiEndpoints } from 'globals/constants';
import { useValidateToken } from 'api/auth';

export const useUnassignedProducts = () => {
  const { data, isSuccess, isError } = useQuery('unassignedProducts', async () => {
    const { response } = await request({
      method: 'GET',
      endpoint: apiEndpoints.school.unassignedProducts
    });
    return response;
  });

  return { data, isSuccess, isError };
};

export const useCandidates = () => {
  const { data, isSuccess, isError } = useQuery('schoolCandidates', async () => {
    const { response } = await request({
      method: 'GET',
      endpoint: apiEndpoints.school.candidates,
      payload: {
        candidateAssignedTests: true,
        candidateAssignedCourses: true,
        groups: true
      }
    });
    return response;
  });
  return { data, isSuccess, isError };
};

export const useCandidate = (candidateId) => {
  const { data, isError, isSuccess } = useQuery(['getCandidate', { candidateId }], async () => {
    const { response } = await request({
      method: 'GET',
      endpoint: apiEndpoints.school.getCandidate(candidateId)
    });
    return response;
  });
  return { data, isError, isSuccess };
};

// Group APIs
export const useGroups = () => {
  const { data, isSuccess, isError } = useQuery('schoolGroups', async () => {
    const { response } = await request({
      method: 'GET',
      endpoint: apiEndpoints.school.groups,
      payload: {
        candidateAssignedTests: true,
        candidateAssignedCourses: true,
        groups: true
      }
    });
    return response;
  });
  return { data, isSuccess, isError };
};

export const useAddGroup = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ name }) => {
      const { response, status } = await request({
        method: 'POST',
        endpoint: apiEndpoints.school.groups,
        payload: { name }
      });

      return { response, status };
    },
    {
      onSettled() {
        // Refetch groups
        queryClient.refetchQueries('schoolGroups');
      }
    }
  );

  const { mutateAsync, data, isError, isSuccess } = mutation;
  return { addGroup: mutateAsync, data, isError, isSuccess };
};

export const useAddCandidateToGroup = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ candidateId, groupId }) => {
      const { response, status } = await request({
        method: 'POST',
        endpoint: apiEndpoints.school.addCandidateToGroup(groupId),
        payload: { candidateId }
      });

      return { response, status };
    },
    {
      onSettled() {
        // Refetch groups
        queryClient.refetchQueries('schoolGroups');

        // Refetch candidates
        queryClient.refetchQueries('schoolCandidates');
      }
    }
  );

  const { mutateAsync, data, isError, isSuccess, isLoading } = mutation;
  return { addCandidateToGroup: mutateAsync, data, isError, isSuccess, isLoading };
};

export const useRemoveCandidateFromGroup = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ candidateId, groupId }) => {
      const { response, status } = await request({
        method: 'POST',
        endpoint: apiEndpoints.school.removeCandidateFromGroup(groupId),
        payload: { candidateId }
      });

      return { response, status };
    },
    {
      onMutate(variables) {
        // Optimistic update on groups
        const groupsData = queryClient.getQueryData('schoolGroups');
        const currentGroupIndex = groupsData.findIndex((group) => group.id === variables.groupId);
        const currentGroup = groupsData[currentGroupIndex];

        const itemToRemove = currentGroup.candidates.findIndex(
          (candidate) => candidate.id === variables.candidateId
        );
        currentGroup.candidates.splice(itemToRemove, 1);
        groupsData[currentGroupIndex] = currentGroup;

        queryClient.setQueryData('schoolGroups', groupsData);
      },
      onSettled() {
        // Refetch groups
        queryClient.refetchQueries('schoolGroups');

        // Refetch candidates
        queryClient.refetchQueries('schoolCandidates');
      }
    }
  );

  const { mutateAsync, data, isError, isSuccess, isLoading } = mutation;
  return { removeCandidateFromGroup: mutateAsync, data, isError, isSuccess, isLoading };
};

export const useAssignProduct = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isSuccess, isError, isLoading, reset } = useMutation(
    async ({ candidateId, wpProductId }) => {
      const { response, status } = await request({
        method: 'POST',
        endpoint: apiEndpoints.school.assignProduct,
        payload: {
          candidateId,
          wpProductId
        }
      });

      return { response, status };
    },
    {
      onSettled() {
        // Refetch unassinged products
        queryClient.refetchQueries('unassignedProducts');

        // Refetch that specific user's tests and courses too
        queryClient.refetchQueries('schoolCandidates');
      },

      onError(err) {
        console.info(err);
        // throw new Error(err);
      }
    }
  );

  return { assignProduct: mutateAsync, isSuccess, isError, isLoading, reset };
};

// Account apis
export const useEditAccount = () => {
  // const queryClient = useQueryClient();
  const { validateToken } = useValidateToken();

  const { mutateAsync, isSuccess, isError, reset } = useMutation(
    async (payload) => {
      const { response } = await request({
        method: 'PATCH',
        endpoint: apiEndpoints.school.editAccount,
        payload
      });
      return response;
    },
    {
      onSettled() {
        // Refetch user here
        validateToken();
      }
    }
  );

  return { editAccount: mutateAsync, isSuccess, isError, reset };
};

export const useChangePassword = () => {
  const { mutateAsync, isSuccess, isError, reset } = useMutation(async (payload) => {
    const { response } = await request({
      method: 'PATCH',
      endpoint: apiEndpoints.school.changePassword,
      payload
    });
    return response;
  });

  return { changePassword: mutateAsync, isSuccess, isError, reset };
};

export const useGetPurchasedProducts = () => {
  const { data, isSuccess, isError } = useQuery('purchasedProducts', async () => {
    const { response } = await request({
      method: 'GET',
      endpoint: apiEndpoints.school.getPurchasedProducts
    });
    return response;
  });
  return { data, isSuccess, isError };
};

export const useGetAllAnalyticsCohort = () => {
  const { data, isSuccess, isError } = useQuery('allAnalyticsCohort', async () => {
    const { response } = await request({
      method: 'GET',
      endpoint: apiEndpoints.school.getAllAnalyticsCohort
    });
    return response;
  });
  return { data, isSuccess, isError };
};

export const useGetAnalyticsCohort = (cohortId) => {
  const { data, isSuccess, isError } = useQuery(['analyticsCohort', { cohortId }], async () => {
    const { response } = await request({
      method: 'GET',
      endpoint: apiEndpoints.school.getAnalyticsCohort(cohortId)
    });
    return response;
  });
  return { data, isSuccess, isError };
};

export const useSetAnalyticsCohort = () => {
  const { mutateAsync, isSuccess, isError, reset } = useMutation(async (payload) => {
    const { response } = await request({
      method: 'POST',
      endpoint: apiEndpoints.school.setAnalyticsCohort,
      payload
    });
    return response;
  });

  return { setAnalyticsCohort: mutateAsync, isSuccess, isError, reset };
};

export const useUpdateAnalyticsCohort = (cohortId) => {
  const { mutateAsync, isSuccess, isError, reset } = useMutation(async (payload) => {
    const { response } = await request({
      method: 'PATCH',
      endpoint: apiEndpoints.school.updateAnalyticsCohort(cohortId),
      payload
    });
    return response;
  });

  return { updateAnalyticsCohort: mutateAsync, isSuccess, isError, reset };
};

export const useDeleteAnalyticsCohort = (cohortId) => {
  const { mutateAsync, isSuccess, isError, reset } = useMutation(async () => {
    const { response } = await request({
      method: 'DELETE',
      endpoint: apiEndpoints.school.deleteAnalyticsCohort(cohortId)
    });
    return response;
  });

  return { deleteAnalyticsCohort: mutateAsync, isSuccess, isError, reset };
};
