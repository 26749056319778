/** @jsxImportSource @emotion/react */
import { useMemo, useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import MetaWrapper from 'components/MetaWrapper';
import H1 from 'components/htmlElements/H1';
import P from 'components/htmlElements/P';
import { UserInfoContainer } from 'api/auth';
import { useUserAccountContext } from 'components/EditAccount/userAccountContext';
import { spacer, colors, bgColor } from 'styles/utilities';
import TabNav from 'components/StyledTabs/StyledTabs';
import ProductBand from 'components/ProductBand/ProductBand';
import H3 from 'components/htmlElements/H3';
// import ProductCard from 'components/ProductCard/ProductCard';
import { PRODUCT_CARD_TYPES } from 'globals/constants';
import Breadcrumb from 'components/Breadcrumb';
// import Button from 'components/htmlElements/Button';
import FilterButton from 'components/FilterButton/FilterButton';
import A from 'components/htmlElements/A';
import LoaderOverlay from 'components/LoaderOverlay';
import { getProductCategory } from 'components/ProductCard/helpers';
// import * as styles from './page.styles';

const ProductsPanel = ({ assignedProducts, panelType }) => {
  const [filteredProducts, setFilteredProducts] = useState(assignedProducts);
  const [active, setActive] = useState('all');

  useEffect(() => {
    setFilteredProducts(assignedProducts);
    setActive('all');

    return () => setFilteredProducts([]);
  }, [assignedProducts]);

  const completed = useMemo(
    () =>
      assignedProducts.filter(
        ({ totalCompletedModules, totalModules }) => totalCompletedModules === totalModules
      ),
    [assignedProducts]
  );

  const inProgress = useMemo(
    () =>
      assignedProducts.filter(
        ({ totalCompletedModules, totalModules }) =>
          totalCompletedModules !== 0 && totalCompletedModules !== totalModules
      ),
    [assignedProducts]
  );

  const unattempted = useMemo(
    () => assignedProducts.filter(({ totalCompletedModules }) => totalCompletedModules === 0),
    [assignedProducts]
  );

  const getplaceHolderText = (type, state) => {
    if (type === 'all') {
      switch (state) {
        case 'completed':
          return 'No completed tests or courses';
        case 'inProgress':
          return 'No tests or courses in progress';
        case 'unattempted':
          return 'No unattempted tests or courses';
        default:
          return 'No test/courses assigned yet';
      }
    } else {
      switch (state) {
        case 'completed':
          return `No completed ${type}`;
        case 'inProgress':
          return `No ${type} in progress`;
        case 'unattempted':
          return `No unattempted ${type}`;
        default:
          return `No ${type} assigned yet `;
      }
    }
  };

  return (
    <div css={[spacer.padLR30, spacer.mrT40]}>
      <div className="d-flex align-items-center">
        <FilterButton
          onClick={() => {
            setFilteredProducts(assignedProducts);
            setActive('all');
          }}
          className={active === 'all' ? 'active' : ''}
          css={spacer.mrR10}
        >
          All
        </FilterButton>
        <FilterButton
          onClick={() => {
            setFilteredProducts(completed);
            setActive('completed');
          }}
          className={active === 'completed' ? 'active' : ''}
          css={spacer.mrR10}
        >
          Completed ({completed.length})
        </FilterButton>
        <FilterButton
          onClick={() => {
            setFilteredProducts(inProgress);
            setActive('inProgress');
          }}
          className={active === 'inProgress' ? 'active' : ''}
          css={spacer.mrR10}
        >
          In Progress ({inProgress.length})
        </FilterButton>
        <FilterButton
          onClick={() => {
            setFilteredProducts(unattempted);
            setActive('unattempted');
          }}
          className={active === 'unattempted' ? 'active' : ''}
        >
          Unattempted ({unattempted.length})
        </FilterButton>
      </div>

      <div css={[spacer.mrT40]}>
        {filteredProducts.length > 0 ? (
          filteredProducts.map(
            ({ assignedTest, assignedCourse, totalCompletedModules, totalModules }) => (
              <ProductBand
                key={assignedTest?.id || assignedCourse?.id}
                examType={getProductCategory(
                  assignedTest?.wpMeta?.categories || assignedCourse?.wpMeta?.categories
                )}
                productBandType={PRODUCT_CARD_TYPES.assignmentDetails}
                productName={assignedTest?.wpMeta.name || assignedCourse?.wpMeta.name}
                totalCompletedModules={totalCompletedModules}
                totalModules={totalModules}
                progress={totalCompletedModules / totalModules}
                productPageLink={
                  assignedTest ? `test/${assignedTest?.id}` : `course/${assignedCourse?.id}`
                }
              />
            )
          )
        ) : (
          <H3 gBold color={colors.mediumGrey}>
            {getplaceHolderText(panelType, active)}
          </H3>
        )}
      </div>
    </div>
  );
};

ProductsPanel.propTypes = {
  assignedProducts: PropTypes.array.isRequired,
  panelType: PropTypes.string.isRequired
};

const CandidateDashboard = ({ tests, skillPractices, courses }) => {
  const { displayName, userIs, currentCandidate, username } = UserInfoContainer.useContainer();
  const { openEditCandidateModal } = useUserAccountContext();
  const [loading, toggleLoading] = useState(false);

  const loggedUserIsCandidate = useMemo(() => userIs === 'candidate', [userIs]);
  const name = useMemo(
    () =>
      loggedUserIsCandidate
        ? displayName
        : `${currentCandidate?.firstName || ''} ${currentCandidate?.lastName || ''}`,
    [currentCandidate?.firstName, currentCandidate?.lastName, displayName, loggedUserIsCandidate]
  );

  const handleEditAccountClick = (event) => {
    event.preventDefault();
    if (currentCandidate || loggedUserIsCandidate) {
      // Ensure the modal opens only when currentCandidate info is present or the logged in user is a candidate
      openEditCandidateModal();
    }
  };

  useEffect(() => {
    if (
      name === 'null null' ||
      (!name.trim().length && (currentCandidate || loggedUserIsCandidate))
    ) {
      openEditCandidateModal();
    }
  }, [currentCandidate, loggedUserIsCandidate, name, openEditCandidateModal]);

  const allAssignedProducts = useMemo(
    () => [...tests, ...skillPractices, ...courses],
    [tests, courses, skillPractices]
  );

  const tabs = useMemo(
    () => [
      `All (${allAssignedProducts.length})`,
      `Tests  (${tests.length})`,
      `Courses (${courses.length})`,
      `Skill Practice (${skillPractices.length})`
    ],
    [allAssignedProducts.length, tests.length, courses.length, skillPractices.length]
  );

  const crumbs = [
    {
      name: 'home',
      link: '/'
    },
    {
      name
    }
  ];

  useEffect(() => {
    toggleLoading(true);

    const timeOut = setTimeout(() => {
      toggleLoading(false);
    }, 1000);

    return () => clearTimeout(timeOut);
  }, [allAssignedProducts]);

  return (
    <Fragment>
      <MetaWrapper title={`${name ? `${name}'s` : ''} Account`} />

      <div
        className="d-flex justify-content-between align-items-start flex-wrap"
        css={[bgColor.cream, spacer.padBT40, spacer.padLR20]}
      >
        <div>
          {!loggedUserIsCandidate && (
            <div css={spacer.mrB20}>
              <Breadcrumb links={crumbs} />
            </div>
          )}

          <H1 color={colors.blue}>{name}</H1>
          <div className="d-flex align-items-center">
            <P large gBook>
              Username: {currentCandidate?.username || username}
            </P>
            {!loggedUserIsCandidate && userIs !== 'administrator' && (
              <Fragment>
                <P large css={spacer.mrLR5}>
                  |
                </P>
                <A large href="#" onClick={handleEditAccountClick}>
                  Edit Account
                </A>
              </Fragment>
            )}
          </div>
        </div>

        {/* <Button rounded href="#" as="a">
          View Analytics
        </Button> */}
      </div>

      <div>
        {loading ? (
          <LoaderOverlay />
        ) : (
          <TabNav tabs={tabs} background="cream">
            <Fragment>
              {allAssignedProducts.length > 0 ? (
                <ProductsPanel assignedProducts={allAssignedProducts} panelType="all" />
              ) : (
                <div css={[spacer.padBT20, spacer.padL10, spacer.padR40]}>
                  <H3 gBold color={colors.mediumGrey} css={spacer.mrL30}>
                    No products assigned yet
                  </H3>
                </div>
              )}
            </Fragment>

            <Fragment>
              {tests.length > 0 ? (
                <ProductsPanel assignedProducts={tests} panelType="tests" />
              ) : (
                <div css={[spacer.padBT20, spacer.padL10, spacer.padR40]}>
                  <H3 gBold color={colors.mediumGrey} css={spacer.mrL30}>
                    No tests assigned yet
                  </H3>
                </div>
              )}
            </Fragment>

            <Fragment>
              {courses.length > 0 ? (
                <ProductsPanel assignedProducts={courses} panelType="courses" />
              ) : (
                <div css={[spacer.padBT20, spacer.padL10, spacer.padR40]}>
                  <H3 gBold color={colors.mediumGrey} css={spacer.mrL30}>
                    No courses assigned yet
                  </H3>
                </div>
              )}
            </Fragment>

            <Fragment>
              {skillPractices.length > 0 ? (
                <ProductsPanel assignedProducts={skillPractices} panelType="skill practice" />
              ) : (
                <div css={[spacer.padBT20, spacer.padL10, spacer.padR40]}>
                  <H3 gBold color={colors.mediumGrey} css={spacer.mrL30}>
                    No Skill Practice assigned yet
                  </H3>
                </div>
              )}
            </Fragment>
          </TabNav>
        )}
      </div>
    </Fragment>
  );
};

CandidateDashboard.propTypes = {
  tests: PropTypes.array.isRequired,
  skillPractices: PropTypes.array.isRequired,
  courses: PropTypes.array.isRequired
};

export default CandidateDashboard;

//  Old Code

// export const IncompleteTests = ({ incompleteTests, productPageLinkPrefix }) => (
//   <div css={spacer.mrT60}>
//     <SectionBand sectionName="Incomplete Test" />
//     <div className="d-flex flex-wrap">
//       {incompleteTests.length ? (
//         incompleteTests.map(({ assignedTest, totalModules, totalCompletedModules }) => (
//           <div key={assignedTest.id} css={[spacer.mrR20, spacer.mrB20]}>
//             <ProductCard
//               productCardType={PRODUCT_CARD_TYPES.assignmentDetails}
//               examType={assignedTest.wpMeta.categories[0].slug}
//               productType={assignedTest.wpMeta.product_collection[0].slug}
//               productName={assignedTest.wpMeta.name}
//               progress={totalCompletedModules / totalModules}
//               totalCompletedModules={totalCompletedModules}
//               totalModules={totalModules}
//               productPageLink={`${productPageLinkPrefix}test/${assignedTest.id}`}
//             />
//           </div>
//         ))
//       ) : (
//         <H1 gBlack css={spacer.mrB30} color={colors.grey}>
//           No incomplete tests yet.
//         </H1>
//       )}
//     </div>
//   </div>
// );
// IncompleteTests.propTypes = {
//   incompleteTests: PropTypes.array.isRequired,
//   productPageLinkPrefix: PropTypes.string
// };
// IncompleteTests.defaultProps = {
//   productPageLinkPrefix: ''
// };

// export const CompletedTests = ({ completedTests, productPageLinkPrefix }) => (
//   <div css={spacer.mrT60}>
//     <SectionBand sectionName="Completed Tests" />
//     <div className="d-flex flex-wrap">
//       {completedTests.length ? (
//         completedTests.map(({ assignedTest, totalModules, totalCompletedModules }) => (
//           <div key={assignedTest.id} css={[spacer.mrR20, spacer.mrB20]}>
//             <ProductCard
//               productCardType={PRODUCT_CARD_TYPES.assignmentDetails}
//               examType={assignedTest.wpMeta.categories[0].slug}
//               productType={assignedTest.wpMeta.product_collection[0].slug}
//               productName={assignedTest.wpMeta.name}
//               progress={totalCompletedModules / totalModules}
//               totalCompletedModules={totalCompletedModules}
//               totalModules={totalModules}
//               productPageLink={`${productPageLinkPrefix}test/${assignedTest.id}`}
//             />
//           </div>
//         ))
//       ) : (
//         <H1 gBlack css={spacer.mrB30} color={colors.grey}>
//           No complete tests yet.
//         </H1>
//       )}
//     </div>
//   </div>
// );
// CompletedTests.propTypes = {
//   completedTests: PropTypes.array.isRequired,
//   productPageLinkPrefix: PropTypes.string
// };
// CompletedTests.defaultProps = {
//   productPageLinkPrefix: ''
// };

// export const IncompleteSkillPractices = ({ incompleteSkillPractices, productPageLinkPrefix }) => (
//   <div css={spacer.mrT60}>
//     <SectionBand sectionName="Incomplete Skill Practice" />
//     <div className="d-flex flex-wrap">
//       {incompleteSkillPractices.length ? (
//         incompleteSkillPractices.map(({ assignedTest, totalModules, totalCompletedModules }) => (
//           <div key={assignedTest.id} css={[spacer.mrR20, spacer.mrB20]}>
//             <ProductCard
//               productCardType={PRODUCT_CARD_TYPES.assignmentDetails}
//               examType={assignedTest.wpMeta.categories[0].slug}
//               productType={assignedTest.wpMeta.product_collection[0].slug}
//               productName={assignedTest.wpMeta.name}
//               progress={totalCompletedModules / totalModules}
//               totalCompletedModules={totalCompletedModules}
//               totalModules={totalModules}
//               productPageLink={`${productPageLinkPrefix}test/${assignedTest.id}`}
//             />
//           </div>
//         ))
//       ) : (
//         <H1 gBlack css={spacer.mrB30} color={colors.grey}>
//           No incomplete skill practice yet.
//         </H1>
//       )}
//     </div>
//   </div>
// );
// IncompleteSkillPractices.propTypes = {
//   incompleteSkillPractices: PropTypes.array.isRequired,
//   productPageLinkPrefix: PropTypes.string
// };
// IncompleteSkillPractices.defaultProps = {
//   productPageLinkPrefix: ''
// };

// export const CompletedSkillPractices = ({ completedSkillPractices, productPageLinkPrefix }) => (
//   <div css={spacer.mrT60}>
//     <SectionBand sectionName="Completed Skill Practice" />
//     <div className="d-flex flex-wrap">
//       {completedSkillPractices.length ? (
//         completedSkillPractices.map(({ assignedTest, totalModules, totalCompletedModules }) => (
//           <div key={assignedTest.id} css={[spacer.mrR20, spacer.mrB20]}>
//             <ProductCard
//               productCardType={PRODUCT_CARD_TYPES.assignmentDetails}
//               examType={assignedTest.wpMeta.categories[0].slug}
//               productType={assignedTest.wpMeta.product_collection[0].slug}
//               productName={assignedTest.wpMeta.name}
//               progress={totalCompletedModules / totalModules}
//               totalCompletedModules={totalCompletedModules}
//               totalModules={totalModules}
//               productPageLink={`${productPageLinkPrefix}test/${assignedTest.id}`}
//             />
//           </div>
//         ))
//       ) : (
//         <H1 gBlack css={spacer.mrB30} color={colors.grey}>
//           No completed skill practice yet.
//         </H1>
//       )}
//     </div>
//   </div>
// );
// CompletedSkillPractices.propTypes = {
//   completedSkillPractices: PropTypes.array.isRequired,
//   productPageLinkPrefix: PropTypes.string
// };
// CompletedSkillPractices.defaultProps = {
//   productPageLinkPrefix: ''
// };

// export const IncompleteCourses = ({ incompleteCourses, productPageLinkPrefix }) => (
//   <div css={spacer.mrT60}>
//     <SectionBand sectionName="Incomplete Courses" />
//     <div className="d-flex flex-wrap">
//       {incompleteCourses.length ? (
//         incompleteCourses.map(({ assignedCourse, totalModules, totalCompletedModules }) => (
//           <div key={assignedCourse.id} css={[spacer.mrR20, spacer.mrB20]}>
//             <ProductCard
//               productCardType={PRODUCT_CARD_TYPES.assignmentDetails}
//               examType={assignedCourse.wpMeta.categories[0].slug}
//               productType={assignedCourse.wpMeta.product_collection[0].slug}
//               productName={assignedCourse.wpMeta.name}
//               progress={totalCompletedModules / totalModules}
//               totalCompletedModules={totalCompletedModules}
//               totalModules={totalModules}
//               productPageLink={`${productPageLinkPrefix}course/${assignedCourse.id}`}
//             />
//           </div>
//         ))
//       ) : (
//         <H1 gBlack css={spacer.mrB30} color={colors.grey}>
//           No incomplete courses yet.
//         </H1>
//       )}
//     </div>
//   </div>
// );
// IncompleteCourses.propTypes = {
//   incompleteCourses: PropTypes.array.isRequired,
//   productPageLinkPrefix: PropTypes.string
// };
// IncompleteCourses.defaultProps = {
//   productPageLinkPrefix: ''
// };

// export const CompletedCourses = ({ completedCourses, productPageLinkPrefix }) => (
//   <div css={spacer.mrT60}>
//     <SectionBand sectionName="Completed Courses" />
//     <div className="d-flex flex-wrap">
//       {completedCourses.length ? (
//         completedCourses.map(({ assignedCourse, totalModules, totalCompletedModules }) => (
//           <div key={assignedCourse.id} css={[spacer.mrR20, spacer.mrB20]}>
//             <ProductCard
//               productCardType={PRODUCT_CARD_TYPES.assignmentDetails}
//               examType={assignedCourse.wpMeta.categories[0].slug}
//               productType={assignedCourse.wpMeta.product_collection[0].slug}
//               productName={assignedCourse.wpMeta.name}
//               progress={totalCompletedModules / totalModules}
//               totalCompletedModules={totalCompletedModules}
//               totalModules={totalModules}
//               productPageLink={`${productPageLinkPrefix}course/${assignedCourse.id}`}
//             />
//           </div>
//         ))
//       ) : (
//         <H1 gBlack css={spacer.mrB30} color={colors.grey}>
//           No completed courses yet.
//         </H1>
//       )}
//     </div>
//   </div>
// );
// CompletedCourses.propTypes = {
//   completedCourses: PropTypes.array.isRequired,
//   productPageLinkPrefix: PropTypes.string
// };
// CompletedCourses.defaultProps = {
//   productPageLinkPrefix: ''
// };

// const CandidateDashboard = ({ tests, skillPractices, courses }) => {
//   const { displayName, userIs, currentCandidate } = UserInfoContainer.useContainer();
//   const { openEditCandidateModal } = useUserAccountContext();

//   const loggedUserIsCandidate = useMemo(() => userIs === 'candidate', [userIs]);
//   const name = useMemo(
//     () =>
//       loggedUserIsCandidate
//         ? displayName
//         : `${currentCandidate?.firstName || ''} ${currentCandidate?.lastName || ''}`,
//     [currentCandidate?.firstName, currentCandidate?.lastName, displayName, loggedUserIsCandidate]
//   );

//   const handleEditAccountClick = (event) => {
//     event.preventDefault();
//     if (currentCandidate || loggedUserIsCandidate) {
//       // Ensure the modal opens only when currentCandidate info is present or the logged in user is a candidate
//       openEditCandidateModal();
//     }
//   };

//   useEffect(() => {
//     if (
//       name === 'null null' ||
//       (!name.trim().length && (currentCandidate || loggedUserIsCandidate))
//     ) {
//       openEditCandidateModal();
//     }
//   }, [currentCandidate, loggedUserIsCandidate, name, openEditCandidateModal]);

//   return (
//     <Col>
//       <MetaWrapper title={`${name ? `${name}'s` : ''} Account`} />

//       <H1 color={colors.blue} css={spacer.mrT40}>
//         {name}
//       </H1>
//       {!loggedUserIsCandidate && (
//         <Fragment>
//           <H4 gBook color={colors.mediumGrey}>
//             {currentCandidate?.username} | {getAge(currentCandidate?.dob)} years old
//           </H4>
//           <P
//             large
//             as="a"
//             href="#"
//             onClick={handleEditAccountClick}
//             color={colors.green}
//             css={spacer.mrT10}
//             className="d-inline-block"
//           >
//             Edit Account
//           </P>
//         </Fragment>
//       )}

//       {/* Tests */}
//       <IncompleteTests incompleteTests={tests.incompleteTests} />
//       <CompletedTests completedTests={tests.completedTests} />

//       {/* Skill Practice */}
//       <IncompleteSkillPractices
//         incompleteSkillPractices={skillPractices.incompleteSkillPractices}
//       />
//       <CompletedSkillPractices completedSkillPractices={skillPractices.completedSkillPractices} />

//       {/* Courses */}
//       <IncompleteCourses incompleteCourses={courses.incompleteCourses} />
//       <CompletedCourses completedCourses={courses.completedCourses} />
//     </Col>
//   );
// };
