/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Fragment } from 'react';

// import A from 'components/htmlElements/A';
import H6 from 'components/htmlElements/H6';
import Button from 'components/htmlElements/Button';
import { spacer, bgColor, colors } from 'styles/utilities';
import ProgressSvg from 'components/ProgressSvg/ProgressSvg';
import { UnstyledLink } from 'components/Router';
import { getProductColor, ProductCardTop } from '../helpers';

import * as styles from '../ProductCard.styles';

const ProductCardAssignmetDetails = (props) => {
  const {
    examType,
    productType,
    productPageLink,
    productName,
    progress,
    totalModules,
    totalCompletedModules,
    ctaText,
    handleClick,
    progressText,
    hideCtaButton
  } = props;

  const LinkEl = productPageLink ? UnstyledLink : 'div';

  return (
    // <div css={styles.productCardWrapper}>
    <Fragment>
      <LinkEl to={productPageLink}>
        <div css={styles.cardOuter(getProductColor(examType))} className="card-outer">
          <ProductCardTop productName={productName} productType={productType} examType={examType} />

          {typeof progress !== 'undefined' && progress !== null ? (
            <div
              css={[
                styles.progress,
                styles.borderTop,
                spacer.padLR15,
                spacer.padBT10,
                bgColor.grey2
              ]}
            >
              <H6
                gBold
                color={colors.mediumGrey2}
                noMargin
                style={{ fontSize: '18px' }}
                className="text-uppercase"
              >
                {totalCompletedModules}/{totalModules} {progressText || 'MODULES ANSWERED'}
              </H6>
              <ProgressSvg size={34} progress={progress} />
            </div>
          ) : null}
        </div>
      </LinkEl>

      {!hideCtaButton ? (
        <Fragment>
          {typeof handleClick === 'function' ? (
            <Button wide bgHover={getProductColor(examType)} product onClick={handleClick}>
              {ctaText || (progress === 1 ? 'Show report' : 'See details')}
            </Button>
          ) : (
            <Button
              as={UnstyledLink}
              wide
              bgHover={getProductColor(examType)}
              product
              to={productPageLink}
            >
              {ctaText || (progress === 1 ? 'Show report' : 'See details')}
            </Button>
          )}
        </Fragment>
      ) : null}
    </Fragment>
    // </div>
  );
};

ProductCardAssignmetDetails.propTypes = {
  examType: PropTypes.string.isRequired,
  productType: PropTypes.string,
  productPageLink: PropTypes.string,
  productName: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  totalModules: PropTypes.number.isRequired,
  totalCompletedModules: PropTypes.number.isRequired,
  ctaText: PropTypes.string,
  handleClick: PropTypes.func,
  progressText: PropTypes.string,
  hideCtaButton: PropTypes.bool
};

ProductCardAssignmetDetails.defaultProps = {
  productPageLink: '',
  productType: '',
  ctaText: '',
  handleClick: '',
  progressText: '',
  hideCtaButton: false
};
export default ProductCardAssignmetDetails;
