import { useEffect, useMemo, useState } from 'react';
import { UserInfoContainer } from 'api/auth';

const useShowTestResult = ({ endedAt }) => {
  const { userIs, currentCandidate, delayResultsDisplay, delayResultsDuration } =
    UserInfoContainer.useContainer();

  // Add delayResultsDuration to the end time to get the extra time
  const endedAtWithExtraTime = useMemo(() => {
    const extraTime = new Date(endedAt);
    extraTime.setHours(extraTime.getHours() + (delayResultsDuration || 3));
    return extraTime;
  }, [delayResultsDuration, endedAt]);

  // Get time in 12 hours format
  const endedAtTime12Hours = useMemo(
    () =>
      new Date(endedAtWithExtraTime).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }),
    [endedAtWithExtraTime]
  );

  const [showResult, updateShowResult] = useState(
    currentCandidate === undefined ? userIs === 'candidate' && delayResultsDisplay === false : true
  );

  useEffect(() => {
    // Check if current time is greater than endedAtWithExtraTime
    const isTimeOver = new Date() >= new Date(endedAtWithExtraTime);
    updateShowResult(currentCandidate === undefined && delayResultsDisplay ? isTimeOver : true);
  }, [currentCandidate, delayResultsDisplay, endedAtWithExtraTime]);

  return {
    showResult,
    endedAtTime12Hours
  };
};

export default useShowTestResult;
