/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors } from 'styles/utilities';

export const relativeDiv = css`
  position: relative;
`;

export const pieFull = css`
  &.pie-full::after {
    content: '';
    position: absolute;
    border: 4px solid ${colors.white};
    border-top: 0;
    border-left: 0;
    width: 10px;
    height: 18px;
    -webkit-transform: rotate(45deg) translate(-50%, -50%);
    -ms-transform: rotate(45deg) translate(-50%, -50%);
    transform: rotate(45deg) translate(-50%, -50%);
    left: 50%;
    top: 50%;
    transform-origin: 10% -10%;
  }

  &.small::after {
    border: 3px solid ${colors.white};
    border-top: 0;
    border-left: 0;
    width: 7px;
    height: 14px;
  }
`;
