/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from 'styles/utilities';

export const ProductIcon = styled.div`
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export const bandWrapper = css`
  &:not(:last-of-type) {
    border-bottom: 2px solid ${colors.grey2};
  }

  .delete-button:hover {
    color: ${colors.red};
    opacity: 0.8;
  }
`;

export const nameStringClamp = css`
  max-width: 650px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
