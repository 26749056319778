/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors } from 'styles/utilities';

import StationaryBg from 'images/bg-stationary.svg';

export const loginPage = css`
  min-height: 100vh;
  background: url(${StationaryBg}) 50% 155% no-repeat ${colors.blue};
  background-size: contain;
`;

export const bgPtn = css`
  position: relative;
  z-index: 0;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAJElEQVQIW2P8//+/DyMj4xYGJMAIYqNLgAXRJeCCyBIogjAJAA+zE0+2WHGzAAAAAElFTkSuQmCC);
    background-repeat: repeat;
    opacity: 0.3;
    z-index: -1;
  }
`;
