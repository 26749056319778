/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
// import { colors } from 'styles/utilities';

export const mixedWordOrderWrapper = css`
  display: flex;
  margin-bottom: 20px;
  > * {
    margin-right: 10px;
  }
`;
