/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors } from 'styles/utilities';
import { answerOptionImageSize } from '../AudioQuestion/AudioQuestion.styles';

export const questionContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  .question-column {
    &:nth-of-type(1) {
      width: 60%;
    }
    &:nth-of-type(2) {
      width: 40%;
    }
  }
`;

export const mainImageSize = 490;
export const questionContents = css`
  figure.image {
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
  }
  img {
    width: 100%;
    width: ${`${mainImageSize}px`};
  }
`;

export const answerOptionsWrapper = css`
  width: 100%;
  width: ${`${mainImageSize / 2 + 40}px`};
  overflow: hidden;
`;

export const answerBlock = ({ isCorrectAnswer, checked, attemptFinished }) => css`
  padding: 10px;
  width: 50%;
  float: left;
  &:nth-of-type(odd) {
    ${'' /* padding-right: 15px; */}
  }
  &:nth-of-type(even) {
    ${'' /* padding-left: 15px; */}
  }
  .answer-image {
    padding: 2px;
    border: 3px solid transparent;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      border-color: ${colors.grey2};
    }
    img {
      width: ${`${answerOptionImageSize}px`};
      height: ${`${answerOptionImageSize}px`};
      object-fit: contain;
    }
  }
  input {
    display: none;
    &:checked + .answer-image {
      border-color: ${colors.green};
    }
  }

  ${attemptFinished &&
  css`
    .answer-image:hover {
      cursor: default;
      border-color: transparent;
    }
  `}

  ${checked && attemptFinished
    ? css`
        input:checked + .answer-image {
          border-color: ${isCorrectAnswer ? colors.green : colors.red};
        }
      `
    : isCorrectAnswer &&
      css`
        .answer-image {
          border-color: ${colors.green};
          &:hover {
            border-color: ${colors.green};
          }
        }
      `}
`;
