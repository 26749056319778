//* eslint-disable import/prefer-default-export */
import { css, keyframes } from '@emotion/react';
import { spacer, colors, typography, mixins, misc } from 'styles/utilities';

import Checkmark from 'images/checkmark.png';

export const tableWrapper = css`
  height: 600px;
  overflow-y: auto;
  overflow-x: auto;
`;

export const table = css`
  table {
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    border-top: 1px solid ${colors.grey2};
    border-bottom: 1px solid ${colors.black};
    &.alter {
      border-bottom: none;
    }
  }

  table {
    thead {
      border-bottom: 1px solid ${colors.black};
      &.alter {
        border-bottom: 1px solid ${colors.grey2};
      }
    }
  }

  table {
    th,
    td {
      text-align: left;
      font-weight: normal;
    }
    tbody {
      tr {
        border-bottom: 2px solid ${colors.grey2};
        &.alter.selected {
          background-color: ${mixins.toRgba(colors.green, 0.1)};
        }
      }
    }
  }
`;

export const tableHeader = css`
  padding: 0 18px;
  width: 2px;
  ${spacer.padBT10};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`;
export const SchoolTableHeader = css`
  padding: 10px;
  ${'' /* width: 2px; */}
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
`;
export const alterTableHeader = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`;

export const sortIcon = (isSorted, isDescending, canSort) => css`
  margin-left: 10px;
  width: 15px;
  height: 15px;
  &.hide {
    background: none;
  }

  ${canSort &&
  `background-image: url(${
    isSorted
      ? `${
          isDescending
            ? require('images/sort_desc.svg').default
            : require('images/sort_asc.svg').default
        }`
      : require('images/sort_both.svg').default
  });
    background-repeat: no-repeat;
    background-size: contain;
  `}
`;

export const tableData = css`
  padding: 8px 0;
  ${spacer.padLR10};
  &.table-header {
    color: red;
  }
`;

export const assignProductWrapper = css`
  max-width: 570px;
`;

export const tableForm = (selected) => css`
  display: flex;
  justify-content: ${selected ? 'space-between' : 'flex-end'};
`;

export const width = css`
  width: 0px;
  margin: 0;
  padding: 0;
`;

export const formRowSelect = css`
  outline: none;
  border: none;
  &.drop-down {
    color: ${colors.mediumGrey2};
  }
`;

export const label = css`
  font-size: ${typography.baseSizes.eta};
  font-family: ${typography.fonts.gothamBook.fontFamily};
`;

export const right = css`
  label {
    font-family: ${typography.fonts.gothamBook.fontFamily};
    display: flex;
    align-items: center;
  }
`;

const checkmarkCheckAnimation = keyframes`
  0% {
    stroke-dashoffset: 48;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

export const container = css`
  .check {
    opacity: 0;
    position: absolute;
  }
  .checkbox-label {
    cursor: pointer;
    /* .checkmark-svg {
      opacity: 0;
      z-index: 10000;
      position: absolute;
      left: -1px;
      top: -1px;
      height: 15px;
      width: 15px;
      background-image: url(${Checkmark});
      background-repeat: no-repeat;
      background-position: cover;
    } */
    .custom-label {
      position: absolute;
      left: 40px;
      top: -5px;
    }
  }

  .check:checked + .checkbox-box .checkmark_check {
    opacity: 1;
    stroke: ${colors.white};
    animation: ${checkmarkCheckAnimation} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0s forwards;
  }

  input {
    display: none;
    &:checked,
    &:indeterminate {
      + .checkbox-box {
        border-color: transparent;
        background-color: ${colors.primaryLinkActive};
      }
    }
    &:checked {
      &:hover {
        + .checkbox-box {
          background-color: ${colors.checkBoxHover};
          border-color: transparent;
        }
      }
      + .checkbox-box .checkmark_check {
        opacity: 1;
        stroke: ${colors.white};
        animation: ${checkmarkCheckAnimation} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0s forwards;
      }
    }
    &:indeterminate {
      + .checkbox-box {
        &:after {
          content: '';
          width: 10px;
          height: 2px;
          background-color: ${colors.white};
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .checkmark_check {
          display: none;
        }
      }
    }
  }
`;

export const checkboxBox = css`
  width: 18px;
  height: 18px;
  border: 2px solid ${colors.blackTransparent50};
  border-radius: 3px;
  display: inline-block;
  position: relative;
  ${mixins.transition()}

  &:hover {
    border-color: ${colors.blackTransparent20};
  }
`;

export const checkmark = css`
  overflow: visible;
  position: absolute;
  top: 50%;
  left: 2px;
  width: 10px;
  height: 10px;
  transform: translateY(-50%);
  .checkmark_check {
    stroke-dasharray: 48;
    opacity: 0;
    stroke-width: 5px;
    transform-origin: center;
    fill: transparent;
    ${mixins.transition()}
  }
`;

export const tableBottom = css`
  display: flex;
  justify-content: space-between;
`;

export const placeEnd = css`
  justify-content: flex-end;
`;

export const tableFooter = css`
  color: ${colors.mediumGrey2};
  text-align: center;
  font-family: 'gotham ssm book a';
  text-transform: none;
`;

/// ///////

export const flexTableStyles = css`
  ${
    '' /* These styles are suggested for the table fill all available space in its containing element */
  }
  display: block;
  ${'' /* These styles are required for a horizontaly scrollable table overflow */}
  overflow: auto;

  .table {
    .thead {
      ${'' /* These styles are required for a scrollable body to align with the header properly */}
      overflow-y: auto;
      overflow-x: hidden;

      border-top: 1px solid ${colors.grey2};
      border-bottom: 1px solid ${colors.black8};
    }

    .tbody {
      ${'' /* These styles are required for a scrollable table body */}
      overflow-y: scroll;
      overflow-x: hidden;
      height: 400px;
    }

    .tr {
      align-items: center;
      padding: 5px 0;
      &:nth-child(odd):not(.thead-row) {
        background-color: ${colors.grey3};
      }

      :last-child {
        .td {
          border-bottom: 0;
        }
      }

      body &.selected:not(.thead-row) {
        background-color: ${mixins.toRgba(colors.primaryAnchorDefaultColor, 0.1)};

        & > :nth-of-type(1) {
          label {
            background-color: transparent;
          }
        }
      }
    }

    .th {
      display: flex;
      align-items: center;
    }

    .th,
    .td {
      ${
        '' /* In this example we use an absolutely position resizer,
       so this is required. */
      }
      position: relative;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

// Pagination
export const paginationButton = css`
  cursor: pointer;
  color: ${colors.black};
  margin-left: 2px;
  border-radius: ${misc.smallBorderRadius};
  padding: 7px 14px;
  background-color: white;
  border: 1px solid transparent;
  outline: none;
  ${mixins.transition()};
  &:focus {
    outline: none;
  }

  &:hover {
    color: white;
    background-color: #585858;
    background: linear-gradient(to bottom, #585858 0%, #111 100%);
  }

  &.active {
    color: ${colors.white};
    border-color: ${colors.grey2};
    background-color: ${colors.primaryLinkActive};
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    color: ${colors.blackTransparent20};
    background-color: transparent;
    background: transparent;
  }
`;

// Filtering
export const tableSearchInput = css`
  width: 300px;
  max-height: 50px;
`;
