/** @jsxImportSource @emotion/react */
import { useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';

import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';
import transformImgixUrls, {
  setSrcSet
} from 'components/QuestionTypes/shared-components/transformImgixUrls';
import { imagesSizeInQuestionText } from 'components/QuestionTypes/shared-components/shared.styles';

import isNotUndefined from 'utils/isNotUndefined';
import questionTypes from 'components/QuestionTypes/shared-components/questionTypes';

import { questionImagesWidth } from 'components/QuestionTypes/TwoColComprehension';

import GracefulImage from 'components/GracefulImage';

import { mainImageSize } from 'components/QuestionTypes/PictureImage/PictureImage.styles';

import { answerOptionImageSize } from 'components/QuestionTypes/AudioQuestion/AudioQuestion.styles';
import * as styles from './TestSkeleton.styles';

const questionTypesToPreload = {
  [questionTypes.TWO_COL_COMPREHENSION]: {
    questionText: 'auto',
    questionImages: questionImagesWidth
  },
  [questionTypes.TEXT]: {
    questionText: imagesSizeInQuestionText
  },
  [questionTypes.IMAGE]: {
    questionText: imagesSizeInQuestionText
  },
  [questionTypes.SUMMARY]: {
    questionText: imagesSizeInQuestionText
  },
  [questionTypes.SENTENCE_COMPLETION]: {
    questionText: imagesSizeInQuestionText
  },
  [questionTypes.PICTURE_IMAGE]: {
    questionText: mainImageSize,
    answerOptions: answerOptionImageSize
  },
  [questionTypes.AUDIO]: {
    answerOptions: answerOptionImageSize
  }
};

const QuestionPreloader = ({ question }) => {
  const shouldPreload = useMemo(() => {
    if (isNotUndefined(question) && Object.keys(questionTypesToPreload).includes(question.type)) {
      return true;
    }
    return false;
  }, [question]);

  if (!isNotUndefined(question) || !shouldPreload) {
    return null;
  }

  // console.info(question[question.type]);
  // console.info(questionTypesToPreload[question.type]);

  return (
    <div css={styles.preloaderWrapper}>
      {Object.keys(questionTypesToPreload[question.type]).map((preloadKey) => {
        if (preloadKey === 'questionImages') {
          return question[question.type].questionImages.map((questionImage, index) => (
            <GracefulImage
              key={`${index.toString()}-image`}
              src={questionImage.url}
              alt=""
              srcSet={
                questionImage.url &&
                setSrcSet(questionImage.url, {
                  width: questionTypesToPreload[question.type][preloadKey]
                })
              }
            />
          ));
        }

        if (preloadKey === 'answerOptions') {
          return question[question.type].answerOptions.map((answerOption) => (
            <GracefulImage
              key={answerOption.shortId}
              src={answerOption.url}
              alt=""
              srcSet={
                answerOption.url &&
                setSrcSet(answerOption.url, {
                  width: questionTypesToPreload[question.type][preloadKey]
                })
              }
            />
          ));
        }

        return (
          <Fragment key={preloadKey}>
            {ReactHtmlParser(DOMPurify.sanitize(question[question.type][preloadKey]), {
              transform: (node) =>
                transformImgixUrls(node, {
                  width: questionTypesToPreload[question.type][preloadKey]
                })
            })}
          </Fragment>
        );
      })}
      {/* {ReactHtmlParser(DOMPurify.sanitize(question.questionText), {
        transform: (node) => transformImgixUrls(node, { width: styles.mainImageSize })
      })} */}
    </div>
  );
};

QuestionPreloader.propTypes = {
  question: PropTypes.object.isRequired
};

export default QuestionPreloader;
