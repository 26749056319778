/** @jsxImportSource @emotion/react */
import { useEffect, Fragment } from 'react';
import { Router } from 'components/Router';
import { ROUTE_PATHS } from 'globals/constants';

import Navbar, { useComponentVisible, NavMenuItem, DropdownMenu } from 'components/Navbar';
import NavsContainer from 'hooks/useNavs';

import { useLogout, UserInfoContainer } from 'api/auth';
import Layout from 'layouts';
import A from 'components/htmlElements/A';

import UserAccountContextsProvider, {
  useUserAccountContext
} from 'components/EditAccount/userAccountContext';

import WelcomeTourProvider, {
  useWelcomeTourContext
} from 'components/WelcomeTour/WelcomeTourContext';
import WelcomeTour from 'components/WelcomeTour';
import { testStaticSlides } from 'components/WelcomeTour/slideData/test';

// Load up all modals used for parent accounts
import EditAccountModal from 'components/EditAccount';
import ChangePassword from 'components/ChangePassword';
import AddCandidateAccount from 'components/AddCandidateAccount';
import EditCandidateAccount from 'components/EditCandidateAccount';

import ParentDashboardContainer from 'pages/Parent/Dashboard/container';
import NotFound from 'pages/NotFound/page';
import CandidatePageContainer from 'pages/Parent/Candidate/container';

// Candidate screens
import TestDetailsContainer from 'pages/Candidate/TestDetails/container';
import TestContainer from 'pages/Test/container';
import CandidateCourse from 'pages/Candidate/Course';
import CandidateDashboardContainer from 'pages/Candidate/Dashboard/container';

import { spacer, colors } from 'styles/utilities';

const ParentNav = () => {
  const { ref, isComponentVisible, toggleVisibility } = useComponentVisible(false);
  const logout = useLogout();
  const { openEditAccountModal, openChangePasswordModal, openAddCandidateModal } =
    useUserAccountContext();

  const { showWelcomeTourModal } = useWelcomeTourContext();

  const handleEditAccountClick = (event) => {
    event.preventDefault();
    openEditAccountModal();
  };

  const handleAddChildAccount = (event) => {
    event.preventDefault();
    openAddCandidateModal();
  };

  const handleHelpLinkClick = (event) => {
    event.preventDefault();
    showWelcomeTourModal();
  };

  const handleChangePasswordClick = (event) => {
    event.preventDefault();
    openChangePasswordModal();
  };

  const handleLogout = (event) => {
    event.preventDefault();
    logout();
  };

  return (
    <Fragment>
      <div className="d-flex align-items-center">
        <NavMenuItem text="Home" link="/" />
        <NavMenuItem text="Add Child" onClick={handleAddChildAccount} link="#" />
        <NavMenuItem text="Help" onClick={handleHelpLinkClick} link="#" />
        <NavMenuItem account onClick={toggleVisibility} ref={ref} />
      </div>
      <DropdownMenu show={isComponentVisible}>
        <A
          href="#"
          onClick={handleEditAccountClick}
          color={colors.mediumGrey}
          transform="uppercase"
          css={[spacer.padBT10, spacer.padLR10]}
        >
          Edit Account
        </A>
        <A
          href="#"
          onClick={handleChangePasswordClick}
          color={colors.mediumGrey}
          transform="uppercase"
          css={[spacer.padBT10, spacer.padLR10]}
        >
          Change Password
        </A>
        <A
          href="#"
          onClick={handleLogout}
          color={colors.mediumGrey}
          transform="uppercase"
          css={[spacer.padBT10, spacer.padLR10]}
        >
          Logout
        </A>
      </DropdownMenu>
    </Fragment>
  );
};

const ParentRoutes = () => {
  const { userIs, userWelcomePopup: showWelcomeTour } = UserInfoContainer.useContainer();
  const logout = useLogout();

  const { navHidden } = NavsContainer.useContainer();

  useEffect(() => {
    if (userIs !== 'parent') {
      logout();
    }
  }, [logout, userIs]);

  return (
    <UserAccountContextsProvider>
      <WelcomeTourProvider showTour={parseInt(showWelcomeTour) !== 1}>
        {!navHidden && (
          <Navbar>
            <ParentNav />
          </Navbar>
        )}

        <Router>
          <Layout component={ParentDashboardContainer} path="/" />

          <Layout component={CandidatePageContainer} path={ROUTE_PATHS.parentCandidate}>
            <CandidateDashboardContainer path="/" />
            <TestDetailsContainer path={ROUTE_PATHS.candidate.testDetails} />
            <TestContainer path={ROUTE_PATHS.candidate.testFlow} />
            <CandidateCourse path={ROUTE_PATHS.candidate.coursePage} />
          </Layout>

          <Layout component={NotFound} default />
        </Router>

        {/* Modal forms */}
        <EditAccountModal />
        <ChangePassword />
        <AddCandidateAccount />
        <EditCandidateAccount />

        <WelcomeTour staticSlides={testStaticSlides} />
      </WelcomeTourProvider>
    </UserAccountContextsProvider>
  );
};

export default ParentRoutes;
