/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';
import { useQuery, useQueries, useMutation, useQueryClient } from 'react-query';
import { apiEndpoints } from 'globals/constants';
import request from 'utils/request';
import ApiRequestHeadersContainer from 'hooks/useExtraApiRequestHeaders';

const fetchCourseAttempt = async (courseAttemptId, assignmentId) => {
  const { response } = await request({
    method: 'GET',
    endpoint: apiEndpoints.course.getCourseAttempt(courseAttemptId),
    headers: {
      assignmentId,
      type: 'COURSE'
    }

    // // This is temporary until fetching courses issue is fixed
    // // https://trello.com/c/RFOTDmBy
    // endpoint: `https://staging-api.testtools.co/courseAttempt/${courseAttemptId}`,
    // apiToken: tempToken
  });

  return response;
};

const useGetAllAssignedCourses = ({ candidateId } = {}) => {
  const { extraHeaders } = ApiRequestHeadersContainer.useContainer();

  const { data, isSuccess, isError } = useQuery(
    ['getAllAssignedCourses', { candidateId }],
    async () => {
      const { response } = await request({
        method: 'GET',
        endpoint: apiEndpoints.candidate.getAllAssignedCourses,
        headers: {
          ...extraHeaders,
          ...(candidateId && { candidateId })
        }
      });
      return response;
    }
  );

  const assignedCoursesData = useMemo(() => {
    if (data) {
      return data;
    }
    return [];
  }, [data]);

  // Get courses data from testshub and merge it with assignment data before sending further
  const courseAttemptQueries = useQueries(
    assignedCoursesData.length
      ? assignedCoursesData?.map((assignedCourse) => {
          const courseAttemptId = assignedCourse.testHubAttemptId;
          const assignmentId = assignedCourse.id;

          return {
            queryKey: ['getCourseAttempt', { courseAttemptId }], // Key is same as used in `useGetTestAttempt` to avoid multiple fetching of same query
            queryFn: () =>
              fetchCourseAttempt(courseAttemptId, assignmentId, assignedCourse.testHubMeta.token),
            enabled: !!assignedCoursesData.length
          };
        })
      : []
  );

  // Memo the data from api instead of storing in state
  // @ref: https://github.com/trojanowski/react-apollo-hooks/issues/158#issuecomment-490763073
  const courseAttemptsData = useMemo(() => {
    const isTestAttemptsLoading = courseAttemptQueries.some((query) => query.isLoading);
    if (!isTestAttemptsLoading) {
      return courseAttemptQueries.map((courseAttemptData) => courseAttemptData.data.data);
    }
    return [];
  }, [courseAttemptQueries]);

  const combinedData = useMemo(() => {
    if (assignedCoursesData.length && courseAttemptsData.length) {
      const mergedData = assignedCoursesData.map((assignedCourse) => {
        const testHubAttempt = courseAttemptsData.find(
          (attempt) => attempt._id === assignedCourse.testHubAttemptId
        );

        return {
          assignedCourse,
          totalModules: testHubAttempt.totalLessons,
          totalCompletedModules: testHubAttempt.watched
        };
      });
      return mergedData;
    }
    return [];
  }, [assignedCoursesData, courseAttemptsData]);

  return { data: combinedData, isSuccess, isError };
};

const useGetCourseAssigned = (courseAssignmentId) => {
  const { extraHeaders } = ApiRequestHeadersContainer.useContainer();

  const { data, isSuccess, isError } = useQuery(
    ['getCourseAssigned', { courseAssignmentId }],
    async () => {
      const { response } = await request({
        method: 'GET',
        endpoint: apiEndpoints.candidate.getAssignedCourse(courseAssignmentId),
        headers: {
          ...extraHeaders
        }
      });
      return response;
    }
  );

  return { data, isSuccess, isError };

  // const { data, isSuccess, isError } = useQuery(
  //   [
  //     'getCourseAttempt',
  //     {
  //       courseAttemptId: courseAssignmentId
  //     }
  //   ],
  //   async () => {
  //     if (!courseAssignmentId) return null;

  //     const { response } = await request({
  //       method: 'GET',
  //       endpoint: apiEndpoints.course.getCourseAttempt(courseAssignmentId),
  //     });

  //     return response;
  //   }
  // );

  // return { data, isSuccess, isError };
};

// Api calls to Tests Hub
const useGetCourseAttempt = (courseAttemptId, assignmentId) => {
  const { data, isSuccess, isError } = useQuery(
    ['getCourseAttempt', { courseAttemptId }],
    () => fetchCourseAttempt(courseAttemptId, assignmentId),
    {
      enabled: !!courseAttemptId
    }
  );
  return { data, isSuccess, isError };
};

const useGetLessons = (sections, courseAttemptId, assignmentId) => {
  const lessonQueries = useQueries(
    sections
      ? sections?.map((section) => ({
          queryKey: ['courseLessons', section._id],
          queryFn: async () => {
            const { response } = await request({
              method: 'GET',
              endpoint: apiEndpoints.course.getLessons(courseAttemptId),
              payload: {
                atLevelSectionId: section._id
              },
              headers: {
                assignmentId
              }
            });
            return response;
          },
          enabled: !!sections.length
        }))
      : []
  );

  return lessonQueries.map((lessonQuery, index) => ({
    section: sections[index],
    lessons: lessonQuery.data?.data?.lessons
  }));
};

const useUpdateLessonWatch = (assignmentId) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ courseAttemptId, lessonId, payload }) => {
      const response = await request({
        method: 'POST',
        endpoint: apiEndpoints.course.updateLessonWatch(courseAttemptId, lessonId),
        payload,
        headers: {
          assignmentId
        }
      });
      return response;
    },
    {
      onSettled(data, error, variables) {
        if (variables.payload.watched) {
          queryClient.refetchQueries('courseLessons');
          queryClient.refetchQueries([
            'getCourseAttempt',
            {
              courseAttemptId: variables.courseAttemptId
            }
          ]);
        }
      }
    }
  );

  const { mutateAsync, data, error, isSuccess } = mutation;
  return { updateLessonWatch: mutateAsync, data, error, isSuccess };
};

const useRestartCourse = (assignmentId) => {
  const mutation = useMutation(async ({ courseAttemptId }) => {
    const response = await request({
      method: 'POST',
      endpoint: apiEndpoints.course.restartCourse(courseAttemptId),
      headers: {
        assignmentId
      }
    });
    return response;
  });

  const { mutateAsync, data, error, isSuccess, isLoading } = mutation;
  return { restartCourse: mutateAsync, data, error, isSuccess, isLoading };
};

// Bookmarks api hooks
const useGetCourseBookmarks = (courseAttemptId, assignmentId) => {
  const { data, isSuccess, isError } = useQuery(
    [
      'courseBookmarks',
      {
        courseAttemptId
      }
    ],
    async () => {
      if (!courseAttemptId) return null;

      const { response } = await request({
        method: 'GET',
        endpoint: apiEndpoints.course.bookmarks.getCourseBookmarks,
        payload: {
          courseAttemptId
        },
        headers: {
          assignmentId
        }
      });

      return response;
    },
    {
      enabled: !!courseAttemptId
    }
  );

  return { data, isSuccess, isError };
};

const useDeleteBookmark = (assignmentId) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ bookmarkId }) => {
      const response = await request({
        method: 'DELETE',
        endpoint: apiEndpoints.course.bookmarks.deleteBookmark(bookmarkId),
        headers: {
          assignmentId
        }
      });
      return response;
    },
    {
      onSettled(data, error, variables) {
        queryClient.invalidateQueries('courseBookmarks');

        // Remove the bookmarks from array
        const bookmarksData = queryClient.getQueryData([
          'courseBookmarks',
          { courseAttemptId: data.response.data.courseAttemptId }
        ]);

        bookmarksData.data = bookmarksData.data.filter(
          (bookmark) => bookmark._id !== variables.bookmarkId
        );

        queryClient.setQueryData(
          [
            'courseBookmarks',
            {
              courseAttemptId: data.courseAttemptId
            }
          ],
          bookmarksData
        );
      }
    }
  );

  const { mutateAsync, data, error, isSuccess } = mutation;
  return { deleteBookmark: mutateAsync, data, error, isSuccess };
};

const useUpdateBookmark = (assignmentId) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ bookmarkId, payload }) => {
      const response = await request({
        method: 'PATCH',
        endpoint: apiEndpoints.course.bookmarks.updateBookmark(bookmarkId),
        payload,
        headers: {
          assignmentId
        }
      });
      return response;
    },
    {
      onSettled(data) {
        queryClient.invalidateQueries('courseBookmarks');

        // Update all bookmarks array
        const bookmarksData = queryClient.getQueryData([
          'courseBookmarks',
          { courseAttemptId: data.response.data.courseAttemptId }
        ]);

        bookmarksData.data = bookmarksData.data.map((bookmark) => {
          if (bookmark._id === data.response.data._id) {
            return data.response.data;
          }
          return bookmark;
        });

        queryClient.setQueryData(
          [
            'courseBookmarks',
            {
              courseAttemptId: data.courseAttemptId
            }
          ],
          bookmarksData
        );
      }
    }
  );

  const { mutateAsync, data, error, isSuccess } = mutation;
  return { updateBookmark: mutateAsync, data, error, isSuccess };
};

const useCreateBookmark = (assignmentId) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ courseAttemptId, lessonId, bookmarkText, bookmarkTime }) => {
      const response = await request({
        method: 'POST',
        endpoint: apiEndpoints.course.bookmarks.createBookmark,
        payload: {
          courseAttemptId,
          lessonId,
          bookmarkText,
          bookmarkTime
        },
        headers: {
          assignmentId
        }
      });
      return response;
    },
    {
      onSettled(data) {
        queryClient.invalidateQueries('courseBookmarks');

        // Update all bookmarks array
        const bookmarksData = queryClient.getQueryData([
          'courseBookmarks',
          { courseAttemptId: data.response.data.courseAttemptId }
        ]);

        bookmarksData.data.push(data.response.data);

        queryClient.setQueryData(
          [
            'courseBookmarks',
            {
              courseAttemptId: data.courseAttemptId
            }
          ],
          bookmarksData
        );
      }
    }
  );

  const { mutateAsync, data, error, isSuccess } = mutation;
  return { createBookmark: mutateAsync, data, error, isSuccess };
};

export {
  useGetAllAssignedCourses,
  useGetCourseAssigned,
  useGetCourseAttempt,
  useGetLessons,
  useGetCourseBookmarks,
  useDeleteBookmark,
  useUpdateBookmark,
  useUpdateLessonWatch,
  useRestartCourse,
  useCreateBookmark
};
