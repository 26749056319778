import React from 'react';

import { Redirect } from 'components/Router';
import PageLoader from 'components/PageLoader';
import { ROUTE_PATHS } from 'globals/constants';

import CandidateProductsContainer from 'hooks/useCandidateAssignedProducts';
import CandidateDashboard from './page';

const CandidateDashboardContainer = () => {
  const {
    isError,
    isSuccess,
    completedTests,
    incompleteTests,
    completedSkillPractices,
    incompleteSkillPractices,
    coursesIsError,
    coursesIsSuccess,
    completedCourses,
    incompleteCourses
  } = CandidateProductsContainer.useContainer();

  if (isError || coursesIsError) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (!isSuccess || !coursesIsSuccess) {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  return (
    <CandidateDashboard
      tests={[...completedTests, ...incompleteTests]}
      skillPractices={[...completedSkillPractices, ...incompleteSkillPractices]}
      courses={[...completedCourses, ...incompleteCourses]}
    />
  );
};

const WithProviders = (props) => (
  <CandidateProductsContainer.Provider>
    <CandidateDashboardContainer {...props} />
  </CandidateProductsContainer.Provider>
);

export default WithProviders;
