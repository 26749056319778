//* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors, misc } from 'styles/utilities';

export const container = css`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: ${misc.navZindex};
`;

export const innerNav = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const right = css`
  display: flex;
  align-items: center;
  position: relative;
`;

export const menuItem = css`
  color: ${colors.navMenuItem};
  &: active {
    color: ${colors.green};
  }
`;

export const userItem = css`
  color: ${colors.green};
  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 7px solid;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
`;

export const dropDownContainer = css`
  position: absolute;
  width: auto;
  right: 20px;
  top: 50px;
  text-transform: uppercase;
  visibility: hidden;
  opacity: 0;
  display: flex;
  flex-direction: column;
  box-shadow: ${misc.dropDownShadow};
  ${'' /* ${mixins.transition('0.2s', 'all', 'ease-in-out')} */}
  &.animate {
    opacity: 1;
    visibility: visible;
  }
`;
