/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { isEqual, cloneDeep } from 'lodash-es';
import { QUESTION_USAGE_TYPE } from 'globals/constants';
import questionTypes from 'components/QuestionTypes/shared-components/questionTypes';

import * as styles from './ProgressBar.styles';

const ProgressBar = ({ index, questions, attemptFinished, setCurrentQuestionIndex }) => {
  const items = [];
  let finalQuestionCount = 0;

  // Directly doing .sort() on `questions` mutates the state object which causes unintended behaviour and submits wrong answers
  const clonedQuestions = cloneDeep(questions);

  for (let i = 0; i < clonedQuestions.length; i += 1) {
    let shouldSort = false;

    // Showing number in result screen
    if (questions[i].usage === QUESTION_USAGE_TYPE.final) {
      finalQuestionCount += 1;
    }

    if (
      [
        questionTypes.ANAGRAMS_MATH_EQ,
        questionTypes.TEXT,
        questionTypes.SUMMARY,
        questionTypes.IMAGE,
        questionTypes.SPELLING_PUNCTUATION,
        questionTypes.SENTENCE_COMPLETION,
        questionTypes.TWO_COL_COMPREHENSION,
        questionTypes.PICTURE_IMAGE,
        questionTypes.MATRICES
      ].includes(clonedQuestions[i].type)
    ) {
      shouldSort = true;
    }

    const isCorrect = (() => {
      if (clonedQuestions[i].type === questionTypes.CLOZE) {
        return !!clonedQuestions[i].ttAnswers?.length;
      }
      return isEqual(
        shouldSort ? clonedQuestions[i].ttAnswers?.sort() : clonedQuestions[i].ttAnswers,
        shouldSort
          ? clonedQuestions[i][clonedQuestions[i].type].answer?.sort()
          : clonedQuestions[i][clonedQuestions[i].type].answer
      );
    })();

    const handleProgressBarClick = (event) => {
      event.preventDefault();
      setCurrentQuestionIndex(i);
    };

    items.push(
      <div
        css={[
          styles.progressPill({
            answered: clonedQuestions[i].ttAnswers?.length,
            isCurrent: index === i,
            isCorrect,
            showWrongIndicator: attemptFinished,
            isFinalQuestion: clonedQuestions[i].usage === QUESTION_USAGE_TYPE.final,
            attemptFinished
          })
        ]}
        key={i}
      >
        {attemptFinished ? (
          <a href="#" className="number-wrapper" onClick={handleProgressBarClick}>
            {finalQuestionCount}
          </a>
        ) : (
          ''
        )}
      </div>
    );
  }

  return (
    <div css={styles.wrapper({ attemptFinished })}>
      <div css={styles.container({ attemptFinished })}>{items.map((barItem) => barItem)}</div>
    </div>
  );
};

ProgressBar.propTypes = {
  index: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
  attemptFinished: PropTypes.bool.isRequired,
  setCurrentQuestionIndex: PropTypes.func.isRequired
};

export default ProgressBar;
