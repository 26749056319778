/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { misc, mixins } from 'styles/utilities';

export const ModalHeader = (props) => css`
  background-color: ${mixins.toRgba(props.color, 0.1)};
`;

export const TopBand = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${({ color }) => color};
  border-radius: ${misc.mediumBorderRadius} ${misc.mediumBorderRadius} 0px 0px;
`;

export const slotsTable = css`
  border-radius: ${misc.mediumBorderRadius};
  overflow: hidden;
  border: ${misc.border.default};

  .table-row:not(:last-of-type) {
    border-bottom: ${misc.border.default};
  }

  .table-row > div:not(:last-of-type) {
    border-right: ${misc.border.default};
  }
`;
