import React, { Fragment } from 'react';

import { Redirect } from 'components/Router';
import PageLoader from 'components/PageLoader';
import { ROUTE_PATHS } from 'globals/constants';
import MetaWrapper from 'components/MetaWrapper';

import { useGetPurchasedProducts } from 'api/schools';

import SchoolProducts from './page';

const SchoolProductContainer = () => {
  const { data, isSuccess, isError } = useGetPurchasedProducts();

  if (isError) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (!isSuccess) {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  return (
    <Fragment>
      <MetaWrapper title="Products" />
      <SchoolProducts products={data || []} />
    </Fragment>
  );
};

export default SchoolProductContainer;
