import React from 'react';
import PropTypes from 'prop-types';

import { TestDataProvider } from 'hooks/useTest';

import TestDetails from './page';

const TestDetailsContainer = ({ testAssignmentId }) => (
  <TestDataProvider testAssignmentId={testAssignmentId}>
    <TestDetails />
  </TestDataProvider>
);

TestDetailsContainer.propTypes = {
  testAssignmentId: PropTypes.string.isRequired
};

export default TestDetailsContainer;
