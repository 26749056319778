/* eslint-disable camelcase */
/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
// import { navigate } from 'components/Router';

import { useMemo } from 'react';
import { Col } from 'components/Grid';
import H1 from 'components/htmlElements/H1';

import { spacer, colors } from 'styles/utilities';
import Button from 'components/htmlElements/Button';
import H2 from 'components/htmlElements/H2';
import CohortBand from 'components/CohortBand/CohortBand';
import { useModal } from 'components/Modal/Modal';
import CohortModal from 'components/CohortModal/CohortModal';
import { useSetAnalyticsCohort } from 'api/schools';
// import * as styles from './page.styles';

const Cohort = ({ products, candidates, analyticsCohort }) => {
  const { isOpen, openModal, toggle, closeModal } = useModal();
  const { setAnalyticsCohort } = useSetAnalyticsCohort();

  const availableProducts = useMemo(() => {
    if (analyticsCohort?.length > 0) {
      return products.filter(
        (product) => !analyticsCohort?.some((cohort) => cohort?.wpProductId === product?.product_id)
      );
    }

    return products;
  }, [analyticsCohort, products]);

  return (
    <Col css={spacer.mrBT30}>
      <div>
        <div className="d-flex justify-content-between align-items-start" css={spacer.mrB40}>
          <H1>Analytics Cohort</H1>

          <Button rounded product onClick={openModal}>
            Create Cohort
          </Button>
        </div>

        <div>
          {analyticsCohort?.length ? (
            analyticsCohort.map((cohort) => (
              <CohortBand
                cohort={cohort}
                key={cohort.id}
                allCandidates={candidates}
                cohortProduct={
                  products.find(({ product_id }) => product_id === cohort.wpProductId) || {}
                }
              />
            ))
          ) : (
            <H1 as={H2} gBlack css={spacer.mrB30} color={colors.grey}>
              No Cohort has been set yet
            </H1>
          )}
        </div>
      </div>

      {isOpen && (
        <CohortModal
          header="Create Cohort"
          isOpen={isOpen}
          toggleModal={toggle}
          closeModal={closeModal}
          products={availableProducts}
          candidates={candidates}
          handleCohortRequest={setAnalyticsCohort}
        />
      )}
    </Col>
  );
};

Cohort.propTypes = {
  products: PropTypes.array.isRequired,
  candidates: PropTypes.array.isRequired,
  analyticsCohort: PropTypes.array.isRequired
};

export default Cohort;
