import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient, useQueries } from 'react-query';
import request from 'utils/request';
import { apiEndpoints } from 'globals/constants';
import ApiRequestHeadersContainer from 'hooks/useExtraApiRequestHeaders';
import { UserInfoContainer } from 'api/auth';

import { getModulesCount, getCompletedModules } from 'utils/testsHelpers';

export const useGetAssignedTest = (testAssignmentId) => {
  const { extraHeaders } = ApiRequestHeadersContainer.useContainer();

  const { data, isSuccess, isError } = useQuery(
    ['getTestAssigned', { testAssignmentId }],
    async () => {
      const { response } = await request({
        method: 'GET',
        endpoint: apiEndpoints.candidate.getAssignedTest(testAssignmentId),
        headers: {
          ...extraHeaders
        }
      });
      return response;
    }
  );
  return { data, isSuccess, isError };
};

const fetchTestAttempt = async (testAttemptId, assignmentId) => {
  const { response } = await request({
    method: 'GET',
    endpoint: apiEndpoints.test.getAttempt(testAttemptId),
    headers: {
      assignmentId,
      type: 'TEST'
    }
  });
  return response;
};

// API calls to Tests Hub
export const useGetTestAttempt = (testAttemptId, assignmentId) => {
  const { data, isSuccess, isError } = useQuery(
    ['getTestAttempt', { testAttemptId }],
    () => fetchTestAttempt(testAttemptId, assignmentId),
    {
      enabled: !!assignmentId
    }
  );
  return { data, isSuccess, isError };
};

export const useStartTestAttempt = (attemptId, assignmentId) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (payload) => {
      const { response, status } = await request({
        method: 'POST',
        endpoint: apiEndpoints.test.startAttemptModule(attemptId),
        payload,
        headers: {
          assignmentId,
          type: 'TEST'
        }
      });

      return { response, status };
    },
    {
      onSettled(data, errors, variables) {
        // While we are refetching test attempt data, update the cached data
        // This is important because the TestTimer component takes in the timeLimit and sets a state which doesn't update on subsequent re-renders
        // This causes the UI to go out of sync when a test with extra timer is started
        const testAttemptData = queryClient.getQueryData([
          'getTestAttempt',
          { testAttemptId: attemptId }
        ]);

        testAttemptData.data.section.map((sectionItem) => {
          if (variables.sectionId) {
            if (sectionItem.sectionId === variables.sectionId) {
              // eslint-disable-next-line no-param-reassign
              sectionItem.timerData.timeLimit = data.response.data.timeLeft;
            }
          } else {
            sectionItem.subSection.map((subsectionItem) => {
              if (subsectionItem.subSectionId === variables.subSectionId) {
                console.info('inside subSectionId');
                // eslint-disable-next-line no-param-reassign
                subsectionItem.timerData.timeLimit = data.response.data.timeLeft;
              }
              return subsectionItem;
            });
          }

          return sectionItem;
        });

        queryClient.setQueryData(['getTestAttempt', { testAttemptId: attemptId }], testAttemptData);
        queryClient.refetchQueries(['getTestAttempt', { testAttemptId: attemptId }]);
      }
    }
  );

  const { mutateAsync, data, isError, isSuccess } = mutation;
  return { startAttempt: mutateAsync, data, isError, isSuccess };
};

export const useGetAttemptQuestions = (attemptId, assignmentId, payload, testHubAttemptToken) => {
  const { data, isSuccess, isError } = useQuery(
    ['getAttemptQuestions', { moduleId: payload.atLevelSectionId || payload.atLevelSubsectionId }],
    async () => {
      const { response } = await request({
        method: 'GET',
        endpoint: apiEndpoints.test.getQuestions(attemptId),
        payload,
        apiToken: testHubAttemptToken,
        headers: {
          assignmentId,
          type: 'TEST'
        }
      });
      return response;
    }
  );
  return { data, isSuccess, isError };
};

export const useSubmitAnswer = (attemptId, assignmentId, moduleId, testHubAttemptToken) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ questionId, payload }) => {
      const { response, status } = await request({
        method: 'POST',
        endpoint: apiEndpoints.test.answerQuestion(attemptId, questionId),
        payload,
        apiToken: testHubAttemptToken,
        headers: {
          assignmentId,
          type: 'TEST'
        }
      });

      return { response, status };
    },
    {
      onMutate(mutationData) {
        // Optimistic update questions data & refetch from server once api call is complete
        const attemptQuestionsData = queryClient.getQueryData([
          'getAttemptQuestions',
          { moduleId }
        ]);

        attemptQuestionsData.data.questions.map((question) => {
          const cachedQuestion = { ...question };
          if (question._id === mutationData.questionId) {
            cachedQuestion.ttAnswers = mutationData.payload.ttAnswers;

            const filteredAnswerArray = mutationData.payload.ttAnswers?.filter((i) => i);
            if (!filteredAnswerArray?.length) {
              delete cachedQuestion.ttAnswers;
            }

            // Also update `questionWatched` if it's available in payload
            if (mutationData.payload.questionWatched) {
              cachedQuestion.questionWatched = mutationData.payload.questionWatched;
            }
          }
          return cachedQuestion;
        });

        queryClient.setQueryData(['getAttemptQuestions', { moduleId }], attemptQuestionsData);
      },
      onSettled() {
        queryClient.refetchQueries(['getAttemptQuestions', { moduleId }]);
      }
    }
  );

  const { mutateAsync, data, isSuccess, isError, isLoading } = mutation;
  return { submitAnswer: mutateAsync, data, isSuccess, isError, isLoading };
};

export const useUpdateAttemptModuleTimer = (attemptId, assignmentId, testHubAttemptToken) => {
  const mutation = useMutation(async (payload) => {
    const { response, status } = await request({
      method: 'POST',
      endpoint: apiEndpoints.test.attemptModuleIsAlive(attemptId),
      payload,
      apiToken: testHubAttemptToken,
      headers: {
        assignmentId,
        type: 'TEST'
      }
    });

    return { response, status };
  });

  const { mutateAsync, data, error, status } = mutation;
  return { updateAttemptTimer: mutateAsync, data, error, status };
};

export const useEndTestAttempt = (attemptId, assignmentId) => {
  // const queryClient = useQueryClient();

  const mutation = useMutation(
    async (payload) => {
      const { response, status } = await request({
        method: 'POST',
        endpoint: apiEndpoints.test.endAttemptModule(attemptId),
        payload,
        headers: {
          assignmentId,
          type: 'TEST'
        }
      });

      return { response, status };
    },
    {
      onSettled() {
        // Fetching latest test attempt data and questions
        // queryClient.invalidateQueries('getTestAttempt', { testAttemptId: attemptId });
        // queryClient.invalidateQueries('getAttemptQuestions');
      }
    }
  );

  const { mutateAsync, data, isError, isSuccess, isLoading } = mutation;
  return { endAttempt: mutateAsync, data, isError, isSuccess, isLoading };
};

export const useGetAllAssignedTests = ({ candidateId } = {}) => {
  const { extraHeaders } = ApiRequestHeadersContainer.useContainer();

  const { data, isSuccess, isError } = useQuery(
    ['getAllAssignedTests', { candidateId }],
    async () => {
      const { response } = await request({
        method: 'GET',
        endpoint: apiEndpoints.candidate.getAllAssignedTests,
        headers: {
          ...extraHeaders,
          ...(candidateId && { candidateId })
        }
      });
      return response;
    }
  );

  const assignedTestsData = useMemo(() => {
    if (data) {
      return data;
    }
    return [];
  }, [data]);

  // Get tests data from testshub and merge it with assignment data before sending further
  const testAttemptQueries = useQueries(
    assignedTestsData.length
      ? assignedTestsData?.map((assignedTest) => {
          const testAttemptId = assignedTest.testHubAttemptId;
          const assignmentId = assignedTest.id;

          return {
            queryKey: ['getTestAttempt', { testAttemptId }], // Key is same as used in `useGetTestAttempt` to avoid multiple fetching of same query
            queryFn: () => fetchTestAttempt(testAttemptId, assignmentId),
            enabled: !!assignedTestsData.length
          };
        })
      : []
  );

  // Memo the data from api instead of storing in state
  // @ref: https://github.com/trojanowski/react-apollo-hooks/issues/158#issuecomment-490763073
  const isTestAttemptsLoading = useMemo(
    () => testAttemptQueries.some((query) => query.isLoading),
    [testAttemptQueries]
  );
  const testAttemptsData = useMemo(() => {
    if (!isTestAttemptsLoading) {
      return testAttemptQueries.map((testAttemptData) => testAttemptData.data.data);
    }
    return [];
  }, [isTestAttemptsLoading, testAttemptQueries]);

  const combinedData = useMemo(() => {
    if (assignedTestsData.length && testAttemptsData.length) {
      const mergedData = assignedTestsData.map((assignedTest) => {
        const testHubAttempt = testAttemptsData.find(
          (attempt) => attempt._id === assignedTest.testHubAttemptId
        );

        return {
          assignedTest,
          totalModules: getModulesCount(testHubAttempt),
          totalCompletedModules: getCompletedModules(testHubAttempt)
        };
      });
      return mergedData;
    }
    return [];
  }, [assignedTestsData, testAttemptsData]);

  return { data: combinedData, isSuccess: isSuccess && !isTestAttemptsLoading, isError };
};

export const useResetAttemptModule = (attemptId, assignmentId) => {
  const queryClient = useQueryClient();

  const { userCanResetAttemptModules } = UserInfoContainer.useContainer();
  const { extraHeaders } = ApiRequestHeadersContainer.useContainer();

  const mutation = useMutation(
    async (payload) => {
      // Ensure only account with reset privilages can call this api
      if (!userCanResetAttemptModules) {
        return false;
      }

      const { response, status } = await request({
        method: 'POST',
        endpoint: apiEndpoints.test.resetAttemptModule(attemptId),
        payload,
        headers: {
          ...extraHeaders,
          assignmentId,
          type: 'TEST'
        }
      });

      return { response, status };
    },
    {
      onSettled() {
        // Fetching latest test attempt data and questions
        queryClient.invalidateQueries('getTestAttempt', { testAttemptId: attemptId });
        // queryClient.invalidateQueries('getAttemptQuestions');
      }
    }
  );

  const { mutateAsync, data, isError, isSuccess, isLoading, reset } = mutation;
  return { resetAttemptModule: mutateAsync, data, isError, isSuccess, isLoading, reset };
};

export const useAddMetaToAttempt = (attemptId, assignmentId, refetchTestAttempt = false) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ meta }) => {
      const { response, status } = await request({
        method: 'PATCH',
        endpoint: apiEndpoints.test.patchAttempt(attemptId),
        payload: {
          meta
        },
        headers: {
          assignmentId,
          type: 'TEST'
        }
      });

      return { response, status };
    },
    {
      onSettled() {
        if (refetchTestAttempt) {
          queryClient.refetchQueries(['getTestAttempt', { testAttemptId: attemptId }]);
        }
      }
    }
  );

  const { mutateAsync, data, isSuccess, isError, isLoading } = mutation;
  return { addMetaToAttempt: mutateAsync, data, isSuccess, isError, isLoading };
};

export const useGetTestAnalytics = ({ assignmentId, fetchAnalytics }) => {
  const { extraHeaders } = ApiRequestHeadersContainer.useContainer();

  const { data, isSuccess, isLoading } = useQuery(
    ['getTestAnalytics', { assignmentId }],
    async () => {
      if (!fetchAnalytics) {
        return {
          data: { reportType: 'testAggregate', currentCandidateAnalytics: null }
        };
      }

      const { response } = await request({
        method: 'GET',
        endpoint: apiEndpoints.test.getAnalytics(assignmentId),
        headers: {
          ...extraHeaders
        }
      });
      return response;
    }
  );

  const isIndividualTestReport = data?.data?.reportType === 'testAggregate';

  if (isIndividualTestReport) {
    return {
      isSuccess,
      isLoading,
      analyticsType: 'individual',
      candidateAnalytics: data?.currentCandidateAnalytics, // candidate analytics
      aggregatedAnalytics: data?.data?.aggregatedAnalytics, // aggregated analytics of all test attempts
      cohortAttempts: [],
      percentileBands: data?.data?.aggregatedAnalytics?.percentileBands || {}
    };
  }

  const cohortAttempts =
    data?.data?.attemptAnalytics?.filter(
      (attempt) => !attempt?.notice && attempt?.attemptAnalytics
    ) || [];

  return {
    isSuccess,
    isLoading,
    analyticsType: 'cohort',
    candidateAnalytics: data?.currentCandidateAnalytics, // candidate analytics
    cohortAttempts,
    aggregatedAnalytics: data?.data?.aggregatedAnalytics, // aggregated analytics of all cohort attempts
    showCohortAnalytics: cohortAttempts?.length > 1,
    percentileBands: {}
  };
};
