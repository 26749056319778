import { useMemo } from 'react';
import { createContainer } from 'unstated-next';

import { useGetAllAssignedTests } from 'api/test';
import { useGetAllAssignedCourses } from 'api/course';
import { PRODUCT_COLLECTION } from 'globals/constants';

const useCandidateAssignedProducts = (initialstate = {}) => {
  const { candidateId } = initialstate;

  const { data: testsData, isError, isSuccess } = useGetAllAssignedTests({ candidateId });
  const {
    data: coursesData,
    isError: coursesIsError,
    isSuccess: coursesIsSuccess
  } = useGetAllAssignedCourses({ candidateId });

  const tests = useMemo(() => {
    if (testsData.length) {
      return testsData.filter(
        (test) => test.assignedTest.wpMeta.product_collection[0].slug === PRODUCT_COLLECTION.tests
      );
    }
    return [];
  }, [testsData]);
  const completedTests = useMemo(() => {
    if (tests.length) {
      return tests.filter((test) => test.totalModules === test.totalCompletedModules);
    }
    return [];
  }, [tests]);
  const incompleteTests = useMemo(() => {
    if (tests.length) {
      return tests.filter((test) => test.totalModules !== test.totalCompletedModules);
    }
    return [];
  }, [tests]);

  const skillPractices = useMemo(() => {
    if (testsData.length) {
      return testsData.filter(
        (test) =>
          test.assignedTest.wpMeta.product_collection[0].slug === PRODUCT_COLLECTION.skillPractices
      );
    }
    return [];
  }, [testsData]);
  const completedSkillPractices = useMemo(() => {
    if (skillPractices.length) {
      return skillPractices.filter((test) => test.totalModules === test.totalCompletedModules);
    }
    return [];
  }, [skillPractices]);
  const incompleteSkillPractices = useMemo(() => {
    if (skillPractices.length) {
      return skillPractices.filter((test) => test.totalModules !== test.totalCompletedModules);
    }
    return [];
  }, [skillPractices]);

  const courses = useMemo(() => {
    if (coursesData?.length) {
      return coursesData;
    }
    return [];
  }, [coursesData]);
  const completedCourses = useMemo(() => {
    if (courses.length) {
      return courses.filter((course) => course.totalModules === course.totalCompletedModules);
    }
    return [];
  }, [courses]);
  const incompleteCourses = useMemo(() => {
    if (courses.length) {
      return courses.filter((course) => course.totalModules !== course.totalCompletedModules);
    }
    return [];
  }, [courses]);

  return {
    testsData,
    isError,
    isSuccess,
    completedTests,
    incompleteTests,
    completedSkillPractices,
    incompleteSkillPractices,
    courses,
    coursesIsError,
    coursesIsSuccess,
    completedCourses,
    incompleteCourses
  };
};

const CandidateProductsContainer = createContainer(useCandidateAssignedProducts);
export default CandidateProductsContainer;
