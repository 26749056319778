/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors, misc, mixins } from 'styles/utilities';

export const timerContainer = css`
  &.isebLayout-timer {
    width: auto;
    min-height: 36px;
    padding: 2px 10px;
    align-items: center;
    border-radius: ${misc.largeBorderRadius};
    background-color: ${mixins.toRgba(colors.violet, 0.15)};
  }
`;
