import { useEffect, useMemo } from 'react';
import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from 'react-query';
import request from 'utils/request';
import { apiEndpoints } from 'globals/constants';
import removeDuplicates from 'utils/removeDuplicates';

export const useAdminSchools = (payload) => {
  const { data, isSuccess, isError, isLoading, isFetching } = useQuery(
    ['schoolsList', { payload }],
    async () => {
      const resp = await request({
        method: 'GET',
        // endpoint: `${apiEndpoints.admin.schools}?per_page=${perPage}&page=${page}`,
        endpoint: apiEndpoints.admin.schools,
        payload
      });
      return resp;
    },
    {
      enabled: Boolean(payload.search) || Boolean(payload.enabled)
    }
  );
  return {
    data: data?.response || [],
    header: data?.headers,
    totalPages: data?.headers?.get('Totalpages'),
    totalEntries: data?.headers?.get('Total'),
    isSuccess,
    isError,
    isLoading,
    isFetching
  };
};

export const useAdminParents = (payload) => {
  const { data, isSuccess, isError, isLoading, isFetching } = useQuery(
    ['parentsList', { payload }],
    async () => {
      const resp = await request({
        method: 'GET',
        endpoint: apiEndpoints.admin.parents,
        payload
      });

      return resp;
    },
    {
      enabled: Boolean(payload.enabled)
    }
  );

  return {
    data: data?.response || [],
    header: data?.headers,
    totalPages: data?.headers?.get('Totalpages'),
    totalEntries: data?.headers?.get('Total'),
    isSuccess,
    isError,
    isLoading,
    isFetching
  };
};

export const useAdminProducts = (payload) => {
  const { data, isSuccess, isError, isLoading } = useQuery(
    ['products', { payload }],
    async () => {
      const { response } = await request({
        method: 'GET',
        endpoint: apiEndpoints.admin.products,
        payload
      });
      return response;
    },
    {
      enabled: Boolean(payload.search)
    }
  );
  return { data, isSuccess, isError, isLoading };
};

export const useAddSchool = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (payload) => {
      const { response, status } = await request({
        method: 'POST',
        endpoint: apiEndpoints.admin.addSchool,
        payload
      });

      return { response, status };
    },
    {
      onSettled() {
        // Refetch schools here
        queryClient.refetchQueries('schools');
      }
    }
  );

  const { mutateAsync, data, isError, isSuccess, reset } = mutation;
  return { addSchool: mutateAsync, data, isError, isSuccess, reset };
};

export const useAddStudentToSchool = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (payload) => {
      const { response, status } = await request({
        method: 'POST',
        endpoint: apiEndpoints.admin.addStudentsToSchool(payload.schoolId),
        payload: {
          count: payload.count
        }
      });

      return { response, status };
    },
    {
      onSettled(data, error, variables) {
        queryClient.invalidateQueries('adminSchool', { schoolId: variables.schoolId });
      }
    }
  );

  const { mutateAsync, data, reset, isError, isSuccess } = mutation;
  return { addStudentsToSchool: mutateAsync, data, reset, isError, isSuccess };
};

export const useAssignProductsToSchool = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (payload) => {
      const { response, status } = await request({
        method: 'POST',
        endpoint: apiEndpoints.admin.addProductsToSchool(payload.schoolId),
        payload: {
          quantity: payload.quantity,
          wpProductId: payload.wpProductId
        }
      });

      return { response, status };
    },
    {
      onSettled(data, error, variables) {
        queryClient.invalidateQueries('adminSchool', { schoolId: variables.schoolId });
      }
    }
  );

  const { mutateAsync, data, reset, isError, isSuccess } = mutation;
  return { assignProductsToSchool: mutateAsync, data, reset, isError, isSuccess };
};

export const useAdminTests = () => {
  const FETCHING_LIMIT = 100;
  const { data, isError, isLoading, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      'testhubTests',
      async ({ pageParam = 0 }) => {
        const { response } = await request({
          method: 'GET',
          endpoint: apiEndpoints.admin.getTestshubTests,
          payload: {
            limit: FETCHING_LIMIT,
            offset: pageParam,
            getAllVersions: true
          }
        });
        return response;
      },
      {
        getNextPageParam: (lastPage, allPages) =>
          lastPage.data.length ? allPages.length * FETCHING_LIMIT : undefined
      }
    );

  // Fetch all data upfront
  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  // const [testsData, updateTestsData] = useState(data);
  const testsData = useMemo(() => {
    // if (data && data.data !== 'undefined') {
    if (data && data !== 'undefined' && data.pages.length) {
      // Needed since infinite query returns data in pages. Check react-query docs for more info
      const combinedPagesData = data.pages.map((pageData) => pageData.data).flat();

      return combinedPagesData;
    }
    return [];
  }, [data]);

  const testsGroups = useMemo(() => removeDuplicates(testsData, 'testGroupId'), [testsData]);

  return {
    data: testsData,
    testsGroups,
    isError,
    isLoading,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  };
};

export const useAdminCourses = () => {
  const FETCHING_LIMIT = 100;
  const { data, isError, isLoading, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      'testhubCourses',
      async ({ pageParam = 0 }) => {
        const { response } = await request({
          method: 'GET',
          endpoint: apiEndpoints.admin.getTestshubCourses,
          payload: {
            limit: FETCHING_LIMIT,
            offset: pageParam,
            getAllVersions: true
          }
        });
        return response;
      },
      {
        getNextPageParam: (lastPage, allPages) =>
          lastPage.data.length ? allPages.length * FETCHING_LIMIT : undefined
      }
    );

  // Fetch all data upfront
  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const coursesData = useMemo(() => {
    // if (data && data.data !== 'undefined') {
    if (data && data !== 'undefined' && data.pages.length) {
      // Needed since infinite query returns data in pages. Check react-query docs for more info
      const combinedPagesData = data.pages.map((pageData) => pageData.data).flat();

      return combinedPagesData;
    }
    return [];
  }, [data]);

  return {
    data: coursesData,
    isError,
    isLoading,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  };
};

export const useAdminGetLinkedProducts = () => {
  const FETCHING_LIMIT = 100;

  const { data, isError, isLoading, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      ['linkedWpProducts'],
      async ({ pageParam = 0 }) => {
        console.info(pageParam + 1, 'pageParam');
        const { response } = await request({
          method: 'GET',
          endpoint: apiEndpoints.admin.getLinkedProducts,
          payload: {
            per_page: FETCHING_LIMIT,
            page: pageParam + 1
          }
        });
        return response;
      },
      {
        getNextPageParam: (lastPage, allPages) => (lastPage.length ? allPages.length : undefined)
      }
    );

  // Fetch all data upfront
  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const productsData = useMemo(() => {
    // if (data && data.data !== 'undefined') {
    if (data && data !== 'undefined' && data.pages.length) {
      // Needed since infinite query returns data in pages. Check react-query docs for more info
      const combinedPagesData = data.pages.map((pageData) => pageData).flat();

      return combinedPagesData;
    }
    return [];
  }, [data]);

  return {
    data: productsData,
    isError,
    isLoading,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  };
};

export const useLinkProduct = () => {
  const mutation = useMutation(async (payload) => {
    const { response, status } = await request({
      method: 'PATCH',
      endpoint: apiEndpoints.admin.linkProduct,
      payload
    });

    return { response, status };
  });

  const { mutateAsync, data, isError, isSuccess } = mutation;
  return { linkProduct: mutateAsync, data, isError, isSuccess };
};

export const useAdminParent = (parentId) => {
  const { data, isSuccess, isError } = useQuery(['adminParent', { parentId }], async () => {
    const { response } = await request({
      method: 'GET',
      endpoint: apiEndpoints.admin.parent(parentId)
    });
    return response;
  });
  return { data, isSuccess, isError };
};

export const useAdminSchool = (schoolId) => {
  const { data, isSuccess, isError } = useQuery(['adminSchool', { schoolId }], async () => {
    const { response } = await request({
      method: 'GET',
      endpoint: apiEndpoints.admin.school(schoolId)
    });
    return response;
  });
  return { data, isSuccess, isError };
};

export const useCandidate = (candidateId) => {
  const { data, isError, isSuccess } = useQuery(['getCandidate', { candidateId }], async () => {
    const { response } = await request({
      method: 'GET',
      endpoint: apiEndpoints.admin.getCandidate(candidateId)
    });
    return response;
  });
  return { data, isError, isSuccess };
};

export const useUnassignTestFromCandidate = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (payload) => {
      const { response, status } = await request({
        method: 'POST',
        endpoint: `${apiEndpoints.admin.unassignTestFromCandidate(payload.assignmentId)}` // "?wpProductType=TEST" - Don't need this anymore.
      });

      return { response, status };
    },
    {
      onSettled(data, error, variables) {
        queryClient.invalidateQueries('getAllAssignedTests', {
          candidateId: variables.candidateId
        });
        queryClient.invalidateQueries('getAllAssignedCourses', {
          candidateId: variables.candidateId
        });
      }
    }
  );

  const { mutateAsync, data, isError, isSuccess, isLoading } = mutation;
  return { unassignTest: mutateAsync, data, isError, isSuccess, isLoading };
};

export const useGetDashboardStats = () => {
  const { data, isSuccess, isError } = useQuery('dashboardStats', async () => {
    const { response } = await request({
      method: 'GET',
      endpoint: apiEndpoints.admin.stats
    });
    return response;
  });

  return { data, isSuccess, isError };
};

export const useUpdateCustomerAccess = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ customerId, disable }) => {
      const { response, status } = await request({
        method: 'POST',
        endpoint: apiEndpoints.admin.updateCustomerAccess(customerId),
        payload: {
          roles: [disable ? '' : 'customer']
        }
      });

      return { response, status };
    },
    {
      onSettled() {
        // Refetch all parents and schools on access update
        queryClient.invalidateQueries('parentsList');
        queryClient.invalidateQueries('schoolsList');
      }
    }
  );

  const { mutateAsync, data, isError, isSuccess, isLoading } = mutation;
  return { updateCustomerAccess: mutateAsync, data, isError, isSuccess, isLoading };
};

export const useUpdateCustomerDetails = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ customerId, payload }) => {
      const { response, status } = await request({
        method: 'PATCH',
        endpoint: apiEndpoints.admin.updateCustomerDetails(customerId),
        payload
      });

      return { response, status };
    },
    {
      onSettled(data, error, variables) {
        // Refetch parent or school based on user edited
        if (data.response.meta.user_type[0] === 'school') {
          queryClient.invalidateQueries('adminSchool', { schoolId: variables.customerId });
        } else {
          queryClient.invalidateQueries('adminParent', { parentId: variables.customerId });
        }
      }
    }
  );

  const { mutateAsync, data, isError, isSuccess, isLoading } = mutation;
  return { updateCustomerDetails: mutateAsync, data, isError, isSuccess, isLoading };
};

export const useGetAllTestAttempts = (testId) => {
  const FETCHING_LIMIT = 100;
  const {
    data,
    isError,
    isLoading,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingPreviousPage
  } = useInfiniteQuery(
    `testAttempts-${testId}`,
    async ({ pageParam = 0 }) => {
      const { response } = await request({
        method: 'GET',
        endpoint: apiEndpoints.admin.getTestAttempts(testId),
        payload: {
          limit: FETCHING_LIMIT,
          offset: pageParam,
          getAllVersions: true
        }
      });

      return response;
    },
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage?.data?.length >= FETCHING_LIMIT ? allPages?.length * FETCHING_LIMIT : undefined
    }
  );

  return {
    data: data?.pages ?? [],
    totalAssignments: data?.pages?.[0]?.pagination?.totalItems ?? null,
    isError,
    isLoading,
    isSuccess,
    fetchNextPage,
    isFetchingPreviousPage,
    hasNextPage
  };
};

/// /////////////////////
