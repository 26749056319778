/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabPanel, TabList } from 'components/Tabs/Tabs';
import { bgColor, colors } from 'styles/utilities';
import * as styles from './StyledTabs.styles';

const TabNav = ({ tabs, children, color, background, ...props }) => (
  <Tabs {...props}>
    <div css={bgColor[background]} className="tab-list">
      <TabList>
        {tabs.map((tab, i) => (
          <Tab key={`item-${i.toString()}`} color={color}>
            {tab}
          </Tab>
        ))}
      </TabList>
    </div>

    <div css={styles.PanelWrapper}>
      {children.map((panel, i) => (
        <TabPanel key={`item-${i.toString()}`}>{panel}</TabPanel>
      ))}
    </div>
  </Tabs>
);

TabNav.propTypes = {
  tabs: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  background: PropTypes.string,
  color: PropTypes.string
};

TabNav.defaultProps = {
  background: '',
  color: colors.blackTransparent50
};

export default TabNav;
