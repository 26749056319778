/** @jsxImportSource @emotion/react */
import { Fragment, useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import { parse } from 'querystringify';
import { UserInfoContainer } from 'api/auth';
import MetaWrapper from 'components/MetaWrapper';

import Breadcrumb from 'components/Breadcrumb';
import H1 from 'components/htmlElements/H1';
import P from 'components/htmlElements/P';
import A from 'components/htmlElements/A';
import ModuleBand from 'components/ModuleBand';
import ScoreBand from 'components/ScoreBand';
import ProgressSvg from 'components/ProgressSvg';
import { UnstyledLink, useNavigate, useLocation } from 'components/Router';

import CandidateSidebar from 'components/CandidateSidebar/Loadable';
import useShowTestResult from 'hooks/useShowTestResult';

import AssignedTestContainer from 'hooks/useTest';
import { products } from 'globals/constants';
import { getModulesCount } from 'utils/testsHelpers';

import ModulePreview from 'components/ModulePreview';
import TestNoteBar from 'components/TestNoteBar';
import { useModal } from 'components/Modal';
import { getProductCategory, getProductColor } from 'components/ProductCard/helpers';
import { secondsToHmsNiceName } from 'utils/secondsToHms';
import UnassignTestConfirmationModal from 'components/UnassignTestConfirmationModal';
import ResetModuleConfirmationModal from 'components/ResetModuleConfirmationModal';
import Button from 'components/htmlElements/Button';
import { dateInFMY } from 'utils/dateFormats';
import H4 from 'components/htmlElements/H4';
import { spacer, colors } from 'styles/utilities';
import TestAnalytics from 'components/TestAnalytics';
import { useGetTestAnalytics } from 'api/test';
import PageLoader from 'components/PageLoader/PageLoader';

// Styling
import * as styles from './page.styles';

const getModuleInformation = (
  module,
  { moduleStartCta, continueModuleCta, extraTimeCta, isPretesttest, isLondonConsortiumTest }
) => {
  const moduleProps = {};

  // Module not started
  if (!module.timerData.startedAt) {
    moduleProps.moduleCtaText = 'Start Module';
    moduleProps.moduleCta = moduleStartCta;

    if (module.settings.allowedExtraTime && module.settings.allowedExtraTime > 0) {
      moduleProps.extraTimeCtaText = `Start module with ${module.settings.allowedExtraTime}% extra time`;
      moduleProps.extraTimeCta = extraTimeCta;
    }
  }

  // Module started but not completed
  if (module.timerData.startedAt && !module.timerData.endedAt) {
    moduleProps.moduleCtaText = 'Continue Module';
    moduleProps.moduleCta = continueModuleCta;
  }

  // Module not ended. Can be started or not started
  if (!module.timerData.endedAt) {
    if (isPretesttest || isLondonConsortiumTest) {
      moduleProps.subHeading = `${secondsToHmsNiceName(module.timerData.timeLimit)}`;
    } else {
      moduleProps.subHeading = `${module.totalQuestions} Questions | ${secondsToHmsNiceName(
        module.timerData.timeLimit
      )}`;
    }
  }

  // Module is completed
  if (module.timerData.endedAt && module.resultData) {
    moduleProps.scoreCard = `${module.resultData.scored}/${module.resultData.total}`;

    // Module statistics
    if (module.statistics) {
      moduleProps.statistics = {
        Average: `${module?.statistics?.avg}/${module.resultData.total}`,
        Lowest: `${module?.statistics?.min}/${module.resultData.total}`,
        Highest: `${module?.statistics?.max}/${module.resultData.total}`
      };
    }
  }

  return moduleProps;
};

const TestModules = ({ data }) => {
  const {
    isTestAtSubSection,
    testInfo,
    isCATtest,
    isPROGRESStest,
    handleModalBandClick,
    handleModuleContinue,
    isPretesttest,
    isLondonConsortiumTest,
    userCanUnassignTest,
    handleResetModuleLinkClick,
    userCanAttemptTests
  } = data;

  return (
    <div css={[spacer.mrT20, spacer.padLR20]}>
      {isTestAtSubSection
        ? testInfo.section.map((sectionItem, index) => (
            <Fragment key={sectionItem.sectionId}>
              {isCATtest || isPROGRESStest ? (
                <P css={[styles.sectionHeading]} color={colors.mediumGrey}>
                  PART {index + 1}
                </P>
              ) : null}
              {sectionItem.subSection &&
                sectionItem.subSection.map((subsection) => {
                  const sectionProps = getModuleInformation(subsection, {
                    moduleStartCta: () => handleModalBandClick(subsection),
                    continueModuleCta: () => handleModuleContinue(subsection),
                    extraTimeCta: () => handleModalBandClick(subsection, { hasExtraTime: true }),
                    isPretesttest,
                    isLondonConsortiumTest
                  });

                  return (
                    <ModuleBand
                      key={subsection._id}
                      heading={subsection.name}
                      supHeading={sectionItem.name}
                      disableAttempts={!userCanAttemptTests}
                      isCATtest={isCATtest}
                      {...sectionProps}
                      isTestAtSubSection
                      upsellText={subsection?.metadata?.upsell}
                      showUpsellText={
                        !!subsection.timerData.endedAt && subsection?.metadata?.upsell
                      }
                      endedAt={subsection.timerData?.endedAt}
                    >
                      <div className="d-flex align-items-center">
                        {subsection.timerData.endedAt && (
                          <A
                            medium
                            underline
                            as={UnstyledLink}
                            to={subsection._id}
                            className="solutions-link"
                          >
                            View Solutions
                          </A>
                        )}

                        {/* {subsection.timerData.endedAt &&
                                subsection?.timerData?.secondsConsumed > 0 &&
                                subsection?.timerData?.secondsConsumed <
                                  subsection?.timerData?.timeLimit && (
                                  <Fragment>
                                    <styles.Separator className="line" />
                                    <P noMargin instruction color={colors.mediumGrey2}>
                                      Time Taken:{' '}
                                      {secondsToHms(
                                        subsection?.timerData?.secondsConsumed
                                      ).substring(3)}
                                    </P>
                                  </Fragment>
                                )} */}

                        {userCanUnassignTest && subsection.timerData?.endedAt && (
                          <Fragment>
                            <styles.Separator className="line" />
                            <A
                              href="#"
                              underline
                              medium
                              css={styles.adminLinks}
                              onClick={(e) =>
                                handleResetModuleLinkClick(e, subsection._id, subsection.name)
                              }
                            >
                              Reset Module
                            </A>
                          </Fragment>
                        )}
                      </div>

                      {userCanUnassignTest && subsection.timerData?.endedAt && (
                        <P css={spacer.mrT10} color={colors.mediumGrey2}>
                          Module completed on {dateInFMY(subsection.timerData.endedAt)}
                        </P>
                      )}
                    </ModuleBand>
                  );
                })}
            </Fragment>
          ))
        : testInfo.section.map((sectionItem, index) => {
            const sectionProps = getModuleInformation(sectionItem, {
              moduleStartCta: () => handleModalBandClick(sectionItem),
              continueModuleCta: () => handleModuleContinue(sectionItem),
              extraTimeCta: () => handleModalBandClick(sectionItem, { hasExtraTime: true }),
              isPretesttest,
              isLondonConsortiumTest
            });

            return (
              <Fragment key={sectionItem._id}>
                {isCATtest || isPROGRESStest ? (
                  <P css={[styles.sectionHeading]} color={colors.mediumGrey}>
                    PART {index + 1}
                  </P>
                ) : null}

                <ModuleBand
                  heading={sectionItem.name}
                  disableAttempts={!userCanAttemptTests}
                  isCATtest={isCATtest}
                  {...sectionProps}
                  isTestAtSubSection
                  upsellText={sectionItem?.metadata?.upsell}
                  showUpsellText={!!sectionItem.timerData.endedAt && sectionItem?.metadata?.upsell}
                  endedAt={sectionItem.timerData?.endedAt}
                >
                  <div className="d-flex align-items-center">
                    {sectionItem.timerData.endedAt && (
                      <A
                        medium
                        underline
                        as={UnstyledLink}
                        to={sectionItem._id}
                        className="solutions-link"
                      >
                        View Solutions
                      </A>
                    )}

                    {/* {sectionItem.timerData.endedAt &&
                            sectionItem?.timerData?.secondsConsumed > 0 &&
                            sectionItem?.timerData?.secondsConsumed <
                              sectionItem?.timerData?.timeLimit && (
                              <Fragment>
                                <styles.Separator className="line" />
                                <P noMargin instruction color={colors.mediumGrey2}>
                                  Time Taken:{' '}
                                  {secondsToHms(sectionItem?.timerData?.secondsConsumed).substring(
                                    3
                                  )}
                                </P>
                              </Fragment>
                            )} */}

                    {userCanUnassignTest && sectionItem.timerData?.endedAt && (
                      <Fragment>
                        <styles.Separator className="line" />
                        <A
                          href="#"
                          underline
                          medium
                          css={styles.adminLinks}
                          onClick={(e) =>
                            handleResetModuleLinkClick(e, sectionItem._id, sectionItem.name)
                          }
                        >
                          Reset Module
                        </A>
                      </Fragment>
                    )}
                  </div>

                  {userCanUnassignTest && sectionItem.timerData?.endedAt && (
                    <P css={spacer.mrT10} color={colors.mediumGrey2}>
                      Module completed on {dateInFMY(sectionItem.timerData.endedAt)}
                    </P>
                  )}
                </ModuleBand>
              </Fragment>
            );
          })}
    </div>
  );
};

TestModules.propTypes = {
  data: PropTypes.object.isRequired
};

const TestDetails = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const { isOpen, toggle, openModal } = useModal();
  const {
    testInfo,
    isTestAtSubSection,
    testAssignmentInfo,
    testCompleted,
    hasExtraTime,
    isTestAtSection,
    isLondonConsortiumTest
  } = AssignedTestContainer.useContainer();

  const {
    userCanAttemptTests,
    userCanUnassignTest,
    userCanResetAttemptModules,
    currentCandidate,
    userIs,
    ...userInfo
  } = UserInfoContainer.useContainer();

  const accessorId = useMemo(() => {
    if (userIs === 'candidate') {
      return userInfo?.schoolId;
    }

    return currentCandidate?.schoolId;
  }, [currentCandidate?.schoolId, userInfo?.schoolId, userIs]);

  const analyticsFilters =
    [25012, 8003, 32551].includes(userIs === 'candidate' ? userInfo?.id : currentCandidate?.id) ||
    [21592].includes(accessorId);

  // analytics Data
  const {
    analyticsType,
    candidateAnalytics,
    aggregatedAnalytics,
    showCohortAnalytics,
    cohortAttempts,
    percentileBands,
    isLoading: candidateAnalyticsLoading
  } = useGetTestAnalytics({
    assignmentId: testAssignmentInfo?.id,
    fetchAnalytics: testCompleted && analyticsFilters
  });

  // Test unassignment
  const {
    isOpen: unassignTestModalIsOpen,
    toggle: toggleUnassignTestModal,
    openModal: openUnassignTestModal
  } = useModal();

  const handleUnassignTestLinkClick = (event) => {
    event.preventDefault();
    openUnassignTestModal();
  };

  // Reset attempt module
  const {
    isOpen: resetAttemptModuleModalIsOpen,
    toggle: toggleResetAttemptModuleModal,
    openModal: openResetAttemptModuleModal
  } = useModal();
  const [resetModuleInfo, updateResetModuleInfo] = useState();

  const handleResetModuleLinkClick = useCallback(
    async (event, moduleId, moduleName) => {
      event.preventDefault();

      updateResetModuleInfo({
        moduleId,
        moduleName
      });
      openResetAttemptModuleModal();
    },
    [openResetAttemptModuleModal]
  );

  const params = parse(location.search);

  const isReportsTabActive = params.tab === 'analytics';

  const reportsCTAHandler = () => {
    if (isReportsTabActive) {
      navigate(window.location.pathname);
    } else {
      navigate('?tab=analytics');
    }
  };

  const crumbs = useMemo(
    () => [
      {
        name: 'home',
        link: '/'
      },
      {
        name: testAssignmentInfo.wpMeta.name
      }
    ],
    [testAssignmentInfo.wpMeta.name]
  );

  const getUrlSubpart = useCallback(() => {
    if (userCanUnassignTest) return 'candidate';

    if (userIs === 'school') return 'students';

    return 'child';
  }, [userCanUnassignTest, userIs]);

  useEffect(() => {
    if (userIs !== 'candidate') {
      crumbs.splice(1, 0, {
        name: `${currentCandidate?.firstName}'S TESTS`,
        link: `/${getUrlSubpart()}/${currentCandidate?.id}`
      });
    }
  }, [
    crumbs,
    currentCandidate?.firstName,
    currentCandidate?.id,
    getUrlSubpart,
    userCanUnassignTest,
    userIs
  ]);

  const [currentActiveModule, setCurrentActiveModule] = useState({
    currentModule: ''
  });

  const handleModalBandClick = useCallback(
    (moduleInfo, options) => {
      setCurrentActiveModule({
        currentModule: moduleInfo,
        wpProductInfo: {
          name: testAssignmentInfo.wpMeta.name,
          examType: getProductCategory(testAssignmentInfo.wpMeta?.categories)
        },
        hasExtraTime: options?.hasExtraTime || false
      });
      openModal();
    },
    [openModal, testAssignmentInfo.wpMeta?.categories, testAssignmentInfo.wpMeta.name]
  );

  const handleModuleContinue = useCallback(
    (moduleInfo) => {
      // Navigate here
      navigate(`${location.pathname}/${moduleInfo._id}`);
    },
    [location.pathname, navigate]
  );

  const isCATtest = useMemo(
    () => testAssignmentInfo.wpMeta?.categories[0].slug === products.CAT.name,
    [testAssignmentInfo.wpMeta?.categories]
  );

  const isPROGRESStest = useMemo(
    () => testAssignmentInfo.wpMeta?.categories[0].slug === products.PROGRESS.name,
    [testAssignmentInfo.wpMeta?.categories]
  );

  const isPretesttest = useMemo(
    () => testAssignmentInfo.wpMeta?.categories[0].slug === products.PRETEST.name,
    [testAssignmentInfo.wpMeta?.categories]
  );

  // This is to show/hide test level result
  const getLastCompletedModule = useCallback(() => {
    let endedAtArray = [];
    if (isTestAtSubSection) {
      endedAtArray = _(testInfo.section).flatMap('subSection').flatMap('timerData.endedAt').value();
    } else {
      endedAtArray = _.map(testInfo.section, 'timerData.endedAt');
    }

    // Remove undefined from array
    const filteredEndedAtArray = endedAtArray.filter((item) => item !== undefined);

    // Get latest date from filteredEndedAtArray
    const latestEndedAt = new Date(Math.max(...filteredEndedAtArray.map((item) => new Date(item))));

    return latestEndedAt;
  }, [isTestAtSubSection, testInfo.section]);

  const { showResult } = useShowTestResult({
    endedAt: getLastCompletedModule()
  });

  const handleResetCompletedModules = useCallback(() => {
    const completedModules = testInfo.section.filter((section) => section.timerData?.endedAt);

    updateResetModuleInfo({ completedModules, isResettingAllCompletedModule: true });
    openResetAttemptModuleModal();
  }, [openResetAttemptModuleModal, testInfo.section]);

  const showAnalytics =
    testInfo?.isCompleted &&
    candidateAnalytics &&
    candidateAnalytics?.attemptId &&
    showResult &&
    (analyticsType === 'cohort' ? showCohortAnalytics : true) &&
    analyticsFilters;

  if (candidateAnalyticsLoading) {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  return (
    <Fragment>
      <MetaWrapper title={testAssignmentInfo.wpMeta.name} />

      <div className="d-flex">
        {isReportsTabActive && showAnalytics ? '' : <CandidateSidebar />}

        <div className="w-100" css={styles.testDetailsWrapper}>
          <div>
            <div
              css={[
                spacer.padBT20,
                spacer.padLR20,
                styles.Header({
                  bgColor: getProductColor(
                    getProductCategory(testAssignmentInfo.wpMeta?.categories)
                  )
                })
              ]}
            >
              <div>
                <Breadcrumb links={crumbs} />
                <div
                  className="d-flex align-items-center justify-content-between"
                  css={spacer.mrBT20}
                >
                  <div className="d-flex align-items-center">
                    {testCompleted && (
                      <div css={spacer.mrR20}>
                        <ProgressSvg size={48} progress={1} />
                      </div>
                    )}

                    <H1
                      gBlack
                      color={getProductColor(
                        getProductCategory(testAssignmentInfo.wpMeta?.categories)
                      )}
                      css={spacer.mrBT0}
                    >
                      {testAssignmentInfo.wpMeta.name}
                    </H1>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <H4 as="p" gBold color={colors.mediumGrey} className="m-0">
                    {getModulesCount(testInfo)} Modules
                  </H4>

                  {userCanUnassignTest && (
                    <Fragment>
                      <div className="d-flex align-items-center">
                        <styles.Separator />
                        <A
                          as={H4}
                          large
                          css={styles.adminLinks}
                          href="#"
                          onClick={handleUnassignTestLinkClick}
                          className="m-0"
                        >
                          Unassign Test
                        </A>
                      </div>

                      <div className="d-flex align-items-center">
                        <styles.Separator />
                        <A
                          as={H4}
                          large
                          css={styles.adminLinks}
                          href="#"
                          onClick={handleResetCompletedModules}
                          className="m-0"
                        >
                          Reset Completed Modules
                        </A>
                      </div>
                    </Fragment>
                  )}
                </div>

                {hasExtraTime && !testCompleted && (
                  <div css={spacer.mrT30}>
                    <TestNoteBar testNote="ONLY SELECT THE EXTRA TIME OPTION IF YOU ARE SURE YOU WILL QUALIFY FOR THIS IN THE ACTUAL TEST." />
                  </div>
                )}
              </div>
              {testCompleted && showResult && (
                <div
                  className="d-flex flex-shrink-0"
                  css={[styles.scoreBandWrapper, spacer.padBT10, spacer.padLR20]}
                >
                  {testInfo.resultData && (
                    <ScoreBand
                      score={`${testInfo.resultData.scored}/${testInfo.resultData.total}`}
                      statistics={{
                        Average: `${testInfo?.statistics?.avg}/${testInfo.resultData.total}`,
                        Lowest: `${testInfo?.statistics?.min}/${testInfo.resultData.total}`,
                        Highest: `${testInfo?.statistics?.max}/${testInfo.resultData.total}`
                      }}
                    />
                  )}
                </div>
              )}
            </div>

            {showAnalytics && (
              <div css={[spacer.mrBT40, spacer.padLR20, styles.analyticsBandStyles]}>
                <div css={[spacer.padLR20, spacer.padBT20]} className="band">
                  <div css={spacer.mrB15}>
                    {isReportsTabActive ? (
                      <div>
                        <H4 gBook color={colors.blackTransparent80}>
                          Solutions & Recommendations
                        </H4>
                        <P large color={colors.mediumGrey}>
                          Click the button below to go back to the main test page.
                        </P>
                      </div>
                    ) : (
                      <div>
                        <H4 gBook color={colors.blackTransparent80}>
                          Congratulations on completing the test! Your test report is now available.
                        </H4>
                        <P large color={colors.mediumGrey}>
                          Click the button below to view your report.
                        </P>
                      </div>
                    )}
                  </div>

                  <Button
                    small
                    rounded
                    textCenter
                    css={[spacer.padLR15, spacer.padBT15]}
                    onClick={reportsCTAHandler}
                  >
                    <P instruction noMargin color={colors.white} gBold>
                      {isReportsTabActive ? 'Go Back' : 'View Test Report'}
                    </P>
                  </Button>
                </div>
              </div>
            )}

            {showAnalytics && isReportsTabActive ? (
              <div css={[spacer.mrT20, spacer.padLR20]}>
                <TestAnalytics
                  candidateAnalytics={candidateAnalytics}
                  aggregatedAnalytics={aggregatedAnalytics}
                  isTestAtSubSection={isTestAtSubSection}
                  cohortAttempts={cohortAttempts}
                  testSections={testInfo?.section || []}
                  testName={testAssignmentInfo?.wpMeta?.name}
                  analyticsType={analyticsType}
                  percentileBands={percentileBands}
                />
              </div>
            ) : (
              <TestModules
                data={{
                  isTestAtSubSection,
                  testInfo,
                  isCATtest,
                  isPretesttest,
                  isPROGRESStest,
                  isLondonConsortiumTest,
                  userCanAttemptTests,
                  userCanUnassignTest,
                  handleModuleContinue,
                  handleModalBandClick,
                  handleResetModuleLinkClick
                }}
              />
            )}
          </div>

          {currentActiveModule.wpProductInfo && (
            <ModulePreview
              isOpen={isOpen}
              toggle={toggle}
              moduleInfo={currentActiveModule}
              isPretesttest={isPretesttest}
              isLondonConsortiumTest={isLondonConsortiumTest}
            />
          )}

          {userCanResetAttemptModules && (
            <ResetModuleConfirmationModal
              isOpen={resetAttemptModuleModalIsOpen}
              toggle={toggleResetAttemptModuleModal}
              attemptId={testInfo._id}
              assignmentId={testAssignmentInfo.id}
              isTestAtSection={isTestAtSection}
              {...resetModuleInfo}
            />
          )}

          {userCanUnassignTest && (
            <UnassignTestConfirmationModal
              isOpen={unassignTestModalIsOpen}
              toggle={toggleUnassignTestModal}
              productName={testAssignmentInfo.wpMeta.name}
              candidateId={testAssignmentInfo.candidateId}
              assignmentId={testAssignmentInfo.id}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default TestDetails;
