import React from 'react';

import FieldError from './FieldError';
import InlineFields from './InlineFields';
import Input from './Input';
import Wrapper from './Wrapper';

const Textarea = React.forwardRef((props, ref) => <Input ref={ref} as="textarea" {...props} />);

export { Input, Textarea, Wrapper, FieldError, InlineFields };
