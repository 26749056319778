/** @jsxImportSource @emotion/react */

import { Fragment, useState, useMemo, useEffect } from 'react';

import PropTypes from 'prop-types';

import { bgColor, spacer } from 'styles/utilities';
import A from 'components/htmlElements/A';
import { useComponentVisible } from 'components/Navbar/Navbar';
import removeDuplicates from 'utils/removeDuplicates';
import * as styles from './CohortModal.styles';

const CohortProductSelectionDropdown = ({
  items,
  handleSelect,
  selectedItem,
  isUpdateCohortModal
}) => {
  const { ref, isComponentVisible, toggleVisibility } = useComponentVisible(false);

  const productList = useMemo(() => removeDuplicates(items, 'product_id'), [items]);

  const [trigger, setTrigger] = useState('Select Test');

  useEffect(() => {
    if (isUpdateCohortModal) {
      setTrigger(selectedItem?.name);
    } else {
      setTrigger(items.length > 0 ? 'Select Test' : 'No Products available');
    }
  }, [isUpdateCohortModal, items?.length, selectedItem?.name]);

  return (
    <Fragment>
      <div css={styles.dropdownContainer} className="dropdown-container">
        <div
          ref={ref}
          css={styles.inner}
          onClick={items.length > 0 ? toggleVisibility : null}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
        >
          <div
            css={[
              styles.container,
              isUpdateCohortModal ? bgColor.blackTransparent25 : bgColor.white
            ]}
            className="trigger"
          >
            {trigger}
          </div>

          <ul
            className={`options ${isComponentVisible ? 'show' : ''}`}
            css={[bgColor.white, spacer.padL0, styles.dropdownItemlist]}
          >
            {productList.map((product) => (
              <A
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  setTrigger(product.name);
                  handleSelect(product);
                }}
                css={[spacer.padLR20, spacer.padBT15]}
                className="d-block item-link"
                key={product.name}
              >
                <li className="item" key={product.id}>
                  {product.name}
                </li>
              </A>
            ))}
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

CohortProductSelectionDropdown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSelect: PropTypes.func,
  selectedItem: PropTypes.object,
  isUpdateCohortModal: PropTypes.bool
};

CohortProductSelectionDropdown.defaultProps = {
  handleSelect: null,
  selectedItem: {},
  isUpdateCohortModal: false
};

export default CohortProductSelectionDropdown;
