/** @jsxImportSource @emotion/react */

import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './DataTable.styles';

const Checkbox = React.forwardRef(({ indeterminate, id, children, ...rest }, ref) => {
  const checkRef = React.useRef();
  const resolvedRef = ref || checkRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div css={styles.container}>
      <label className="checkbox-label">
        <input type="checkbox" ref={resolvedRef} {...rest} className="check" />
        {/* <div className="checkmark-svg" /> */}

        <span className="checkbox-box" css={styles.checkboxBox}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xlinkHref="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="32px"
            height="32px"
            viewBox="199.524 41.5 32 32"
            enableBackground="new 199.524 41.5 32 32"
            xmlSpace="preserve"
            className="checkmark"
            css={styles.checkmark}
          >
            <path
              className="checkmark_check"
              fill="none"
              stroke="#000000"
              strokeWidth="1.0752"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="M200.633,60.353l7.981,8.113l21.802-21.932"
            />
          </svg>
        </span>

        <div className="custom-label">{children}</div>
      </label>
    </div>
  );
});

Checkbox.propTypes = {
  indeterminate: PropTypes.any.isRequired,
  id: PropTypes.string,
  children: PropTypes.node
};

Checkbox.defaultProps = {
  id: '',
  children: null
};

export default Checkbox;
