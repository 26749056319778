/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors } from 'styles/utilities';

export const wrapper = css`
  padding: 5px 5px;
  background-color: ${colors.green};
  position: relative;
`;

export const noticeText = css`
  padding: 5px 5px;
  background-color: ${colors.green};
`;

export const closeNotice = css`
  position: absolute;
  top: 50%;
  right: 5px;
  margin: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  &:hover {
    * {
      fill: ${colors.white};
    }
  }
`;
