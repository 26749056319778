import styled from '@emotion/styled';
import styledMap from 'styled-map';
import facepaint from 'facepaint';
import { typography, colors, mixins, misc } from 'styles/utilities';

const breakPoints = facepaint([
  mixins.respondTo(misc.viewport['c-vp']),
  mixins.respondTo(misc.viewport['d-vp'])
]);

const H1 = styled.h1`
  font-family: ${styledMap`
    gBlack: ${typography.fonts.gothamBlack.fontFamily};
    gBold: ${typography.fonts.gothamBold.fontFamily};
    gBook: ${typography.fonts.gothamBook.fontFamily};
    default: ${typography.fonts.gothamBold.fontFamily};
  `};
  font-weight: 'normal';
  line-height: 120%;
  font-style: normal;
  margin: ${misc.defaultTextMargin};
  letter-spacing: -0.51px;
  color: ${(props) => (props.color ? props.color : colors.black2)};
  ${breakPoints({
    fontSize: ['28px', typography.baseSizes.alpha, typography.baseSizes.alpha]
  })};
`;

export default H1;

// ${'' /* font-weight: ${(props) => {
//   if (props.gothamBlack) {
//     if (props.semiBold) {
//       return typography.fonts.gothamBlack.fontWeight.semiBold;
//     }
//     return typography.fonts.gothamBlack.fontWeight.regular;
//   }
//   if (props.gothamBold) {
//     if (props.bold) {
//       return typography.fonts.gothamBold.fontWeight.bold;
//     }
//     if (props.medium) {
//       return typography.fonts.gothamBold.fontWeight.medium;
//     }
//     return typography.fonts.gothamBold.fontWeight.light;
//   }
//   return typography.fonts.gothamBook.fontWeight.light;
// }}; */}
