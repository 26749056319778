import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import Layout from 'layouts';

import { Redirect } from 'components/Router';
import PageLoader from 'components/PageLoader';
import { ROUTE_PATHS } from 'globals/constants';

import ApiRequestHeadersContainer from 'hooks/useExtraApiRequestHeaders';
import { UserInfoContainer } from 'api/auth';
import { useCandidate } from 'api/parents';

const CandidatePageContainer = ({ candidateId, children }) => {
  const { addHeaders, removeHeaders } = ApiRequestHeadersContainer.useContainer();
  const { updateUserInfo } = UserInfoContainer.useContainer();

  const { data: candidateData, isSuccess, isError } = useCandidate(candidateId);

  const [isSettingHeaders, updateIsSettingHeaders] = useState(true);

  useEffect(() => {
    addHeaders({
      candidateId
    });
    updateIsSettingHeaders(false);

    return () => {
      removeHeaders();
      updateIsSettingHeaders(true);
    };
  }, [addHeaders, candidateId, removeHeaders]);

  // Fetch user and update user info container
  useEffect(() => {
    if (isSuccess) {
      updateUserInfo((prevState) => ({ ...prevState, currentCandidate: candidateData }));
    }

    return () => {
      updateUserInfo((prevState) => {
        const { currentCandidate, ...rest } = prevState;
        return { ...rest };
      });
    };
  }, [candidateData, isSuccess, updateUserInfo]);

  if (isError) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (!isSuccess) {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  return <Fragment>{!isSettingHeaders && children}</Fragment>;
};
CandidatePageContainer.propTypes = {
  candidateId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default CandidatePageContainer;
