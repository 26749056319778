import { colors, misc, typography } from 'styles/utilities';

export const scoreBands = [
  { title: 'Band 1', scoreRange: '100% - 81%', color: colors.scoreBandExcellent },
  { title: 'Band 2', scoreRange: '80% - 61%', color: colors.scoreBandGood },
  { title: 'Band 3', scoreRange: '60% - 41%', color: colors.scoreBandAverage },
  { title: 'Band 4', scoreRange: '40% - 21%', color: colors.scoreBandBelowAverage },
  { title: 'Band 5', scoreRange: '20% - 0%', color: colors.scoreBandNeedImprovement }
];

export const donutChartOptions = {
  title: '',
  pieHole: 0.6,
  is3D: false,
  legend: 'none',
  backgroundColor: 'transparent',
  colors: [colors.correctAnswer, colors.incorrectAnswer, colors.blank],
  pieSliceText: 'none',
  chartArea: { width: '80%', height: '80%' },
  tooltip: { trigger: 'none' }
};

const chartStyles = {
  top: window.innerWidth > misc.viewport['f-vp'] ? 0 : 30
};

export const marksDistributionOptions = ({ bars }) => ({
  title: '',
  bars: 'horizontal',
  hAxis: { ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100] },
  vAxis: { textStyle: { fontName: typography.fonts.gothamBold.fontFamily, fontSize: 12 } },
  legend: 'none',
  chartArea: {
    width: '85%',
    height: '90%',
    right: chartStyles.top,
    top: 0
  },
  enableInteractivity: false,
  bar: { groupWidth: 60 - 4 * bars },
  annotations: {
    textStyle: { fontName: typography.fonts.gothamBold.fontFamily, fontSize: 12 }
  }
});

export const getScoreMetric = (score) => {
  if (score) {
    return Math.round(score);
  }

  return 0;
};

// export const toFixed = (value) => {
//   if (value && value % 1 === 0) {
//     return value;
//   }

//   return value?.toFixed(2) || 0;
// };

export const getTopicPerformanceChartData = ({
  modules,
  aggregatedAnalytics,
  currentSelectedModule,
  candidateFirstName
}) =>
  [
    [
      'Topic',
      'studentScore',
      { role: 'annotation', type: 'string' },
      { role: 'tooltip', type: 'string', p: { html: true } },
      'chorotScore',
      { role: 'annotation', type: 'string' },
      { role: 'tooltip', type: 'string', p: { html: true } }
    ]
  ].concat(
    modules.map(({ moduleName, attemptAnalytics, moduleId }) => [
      moduleName, // section name
      getScoreMetric(attemptAnalytics?.percentageScore), // candidate score percentage
      `${getScoreMetric(attemptAnalytics?.percentageScore)}%`, // candidate score percentage label
      `<div class='tooltip-styles'>
              <p>${moduleName}</p>
              <p>${candidateFirstName}'s Score: ${attemptAnalytics?.scoredMarks} out of ${
        attemptAnalytics?.totalMarks
      } (${getScoreMetric(attemptAnalytics?.percentageScore)}%)</p>
              </div>`, // candidate score percentage tooltip
      getScoreMetric(
        aggregatedAnalytics?.section[moduleId]?.attemptAnalytics?.percentageScore ||
          aggregatedAnalytics?.section[currentSelectedModule]?.subSection[moduleId]
            ?.attemptAnalytics?.percentageScore
      ), // cohort score percentage
      `${getScoreMetric(
        aggregatedAnalytics?.section[moduleId]?.attemptAnalytics?.percentageScore ||
          aggregatedAnalytics?.section[currentSelectedModule]?.subSection[moduleId]
            ?.attemptAnalytics?.percentageScore
      )}%`, // cohort score percentage label
      `<div class='tooltip-styles'>
              <p>${moduleName}</p>
              <p>Cohort Score: ${getScoreMetric(
                aggregatedAnalytics?.section[moduleId]?.attemptAnalytics?.scoredMarks ||
                  aggregatedAnalytics?.section[currentSelectedModule]?.subSection?.[moduleId]
                    ?.attemptAnalytics?.scoredMarks
              )} out of ${
        aggregatedAnalytics?.section[moduleId]?.attemptAnalytics?.totalMarks ||
        aggregatedAnalytics?.section[currentSelectedModule]?.subSection?.[moduleId]
          ?.attemptAnalytics?.totalMarks
      } (${getScoreMetric(
        aggregatedAnalytics?.section[moduleId]?.attemptAnalytics?.percentageScore ||
          aggregatedAnalytics?.section[currentSelectedModule]?.subSection[moduleId]
            ?.attemptAnalytics?.percentageScore
      )}%)</p>
              </div>`
    ])
  );

export const topicPerformanceChartOptions = {
  title: '',
  vAxis: { ticks: [0, 25, 50, 75, 100] },
  legend: 'none',
  colors: [colors.scoreBandNeedImprovement, colors.blank],
  chartArea: {
    width: '94%',
    height: '85%',
    right: 0,
    bottom: 25
  },
  bar: { groupWidth: 80 },
  annotations: {
    textStyle: { fontName: typography.fonts.gothamBold.fontFamily, fontSize: 12 }
  },
  tooltip: { isHtml: true }
};

export const getPerformanceChartData = ({ analyticsData }) => [
  ['metrics', 'answers'],
  ['Correct', analyticsData?.rightAnswers],
  ['Incorrect', analyticsData?.wrongAnswers],
  ['Blank', analyticsData?.blankAnswers]
];

export const getDistributionScoresChartData = ({ analyticsData, candidateName }) => [
  ['Metric', 'Score', { role: 'style' }, { role: 'annotation', type: 'string' }],
  [
    `${candidateName}`,
    getScoreMetric(analyticsData?.attemptAnalytics?.percentageScore || 0),
    colors.performance,
    `${analyticsData?.attemptAnalytics?.scoredMarks}/${
      analyticsData?.attemptAnalytics?.totalMarks
    } (${getScoreMetric(analyticsData?.attemptAnalytics?.percentageScore)}%)`
  ],
  [
    'Lowest Score',
    getScoreMetric(
      (analyticsData?.testAnalytics?.statistics.min / analyticsData?.attemptAnalytics?.totalMarks) *
        100 || 0
    ),
    colors.lowestScore,
    `${analyticsData?.testAnalytics?.statistics.min}/${
      analyticsData?.attemptAnalytics?.totalMarks
    } (${getScoreMetric(
      (analyticsData?.testAnalytics?.statistics.min / analyticsData?.attemptAnalytics?.totalMarks) *
        100 || 0
    )}%)`
  ],
  [
    'Average Score',
    getScoreMetric(
      (analyticsData?.testAnalytics?.statistics.avg / analyticsData?.attemptAnalytics?.totalMarks) *
        100 || 0
    ),
    colors.averageScore,
    `${analyticsData?.testAnalytics?.statistics.avg}/${
      analyticsData?.attemptAnalytics?.totalMarks
    } (${getScoreMetric(
      (analyticsData?.testAnalytics?.statistics.avg / analyticsData?.attemptAnalytics?.totalMarks) *
        100 || 0
    )}%)`
  ],
  [
    'Highest Score',
    getScoreMetric(
      (analyticsData?.testAnalytics?.statistics.max / analyticsData?.attemptAnalytics?.totalMarks) *
        100 || 0
    ),
    colors.averageScore,
    `${analyticsData?.testAnalytics?.statistics.max}/${
      analyticsData?.attemptAnalytics?.totalMarks
    } (${getScoreMetric(
      (analyticsData?.testAnalytics?.statistics.max / analyticsData?.attemptAnalytics?.totalMarks) *
        100 || 0
    )}%)`
  ]
];

export const getRank = ({
  currentModule,
  allCompletedAttempts,
  allCompletedModules,
  candidateScore
}) => {
  if (currentModule === 'all') {
    const scores = allCompletedAttempts.map(({ attemptAnalytics: { scoredMarks } }) => scoredMarks);
    return scores.sort((a, b) => b - a).indexOf(candidateScore) + 1;
  }

  const selectedModule = allCompletedModules.filter(({ moduleId }) => moduleId === currentModule);
  const scores = selectedModule.map(({ attemptAnalytics: { scoredMarks } }) => scoredMarks);

  return scores.sort((a, b) => b - a).indexOf(candidateScore) + 1;
};
