/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { restrictToWindowEdges, snapCenterToCursor } from '@dnd-kit/modifiers';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';

import { Container, Row, Col } from 'components/Grid';
import { InstructionLine } from 'components/Instructions';

import { Draggable } from 'components/QuestionTypes/shared-components/DndkitComponents';
import Wordbox, { WordboxPlaceholder } from 'components/QuestionTypes/shared-components/Wordbox';
import Wordbin from 'components/QuestionTypes/shared-components/Wordbin';
import P from 'components/htmlElements/P';
import SolutionExplainer from 'components/SolutionExplainer';

import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';

import { colors, spacer, txtColor } from 'styles/utilities';
import * as sharedStyles from 'components/QuestionTypes/shared-components/shared.styles';
import QuestionsCommonWrapperContainer from 'components/QuestionTypes/shared-components/QuestionsCommonWrapper';

import * as styles from './ShuffuledSentences.styles';

const ShuffuledSentences = ({ currentQuestion }) => {
  // console.info(currentQuestion);
  const { instructionsText, questionText, mixedWordOrder, solutionExplaination, answer } =
    currentQuestion.shuffledSentences;
  const { ttAnswers } = currentQuestion;

  const {
    // attemptFinished,
    skippingAllowed,
    showNextButton,
    answersEditableBeforeSubmission,
    updateAnswerPayload,
    isQuestionUsageFinal
  } = TestModuleInfoContainer.useContainer();

  const { attemptFinished, isNoteOrSampleQuestion } =
    QuestionsCommonWrapperContainer.useContainer();

  // Save checked answer options
  const [checkedAnswers, setCheckedAnswers] = useState([]);
  useEffect(
    // This effect simply clears the checkedAnswers on question change. It ensures on question switch the previous state is cleared
    () => () => {
      setCheckedAnswers([]);
    },
    [currentQuestion._id]
  );

  // Update `checkedAnswers` if already answered and/or attempt is completed
  useEffect(() => {
    if (ttAnswers && ttAnswers.length) {
      setCheckedAnswers(ttAnswers);
    }

    // Clear the checked answers array on unmount
    // Without this there's a bug which messes up the next question if it's of the same type
    return () => {
      setCheckedAnswers([]);
    };
  }, [ttAnswers]);

  // Handle disabling of answering
  const [answeringDisabled, handleAnsweringDisabled] = useState(false);
  useEffect(() => {
    // If editable and not answered - can answer
    // If not editable and not answered - can answer
    // If not editable and answered - cannot answer
    if (!attemptFinished) {
      if (!ttAnswers) {
        // This means it's not answered
        handleAnsweringDisabled(false);
      } else if (!answersEditableBeforeSubmission) {
        handleAnsweringDisabled(true);
      }
    } else {
      // If attempt is over answering is disabled
      handleAnsweringDisabled(true);
    }

    return () => {
      // Reset state
      handleAnsweringDisabled(!answersEditableBeforeSubmission);
    };
  }, [answersEditableBeforeSubmission, attemptFinished, ttAnswers]);

  // Toggle next button visibility
  useEffect(() => {
    if (!skippingAllowed && !checkedAnswers.length && !isNoteOrSampleQuestion) {
      showNextButton(false);
    } else {
      showNextButton(true);
    }
  }, [checkedAnswers.length, isNoteOrSampleQuestion, showNextButton, skippingAllowed]);

  // Handle updating answer payload
  useEffect(() => {
    updateAnswerPayload({
      questionId: currentQuestion._id,
      answerArray: checkedAnswers
    });
  }, [checkedAnswers, currentQuestion._id, updateAnswerPayload]);

  // const [listOfWords, updateListOfWords] = useState(mixedWordOrder);
  // useEffect(() => {
  //   updateListOfWords(mixedWordOrder);
  // }, [mixedWordOrder]);

  // @ref: https://github.com/clauderic/dnd-kit/issues/183#issuecomment-812569512
  // TODO: Sorting on list of words
  // const listOfWordsIds = useMemo(() => listOfWords.map((item) => item.text), [listOfWords]);

  // Drag drop stuff starts here

  const DROPPABLE_ID = 'droppable-bin';
  const [droppableParent, setDroppableParent] = useState(null);
  const [draggedItemId, setDraggedItemId] = useState(null);
  const [activeDrag, setActiveDrag] = useState(null); // Current drag

  // Update draggedItemId and droppableParent if the question is already answered
  // console.info('inside effect', draggedItemId);
  useEffect(() => {
    if (!answeringDisabled || !attemptFinished) {
      if (checkedAnswers.length) {
        // Need to convert to string since this question type doesn't have shortId and `dndkit` requires id as string
        setDraggedItemId((prevDraggedItem) => {
          // Update only if `draggedItemId` is set to null. This would typically run when the answer is sent in api response
          if (!prevDraggedItem) {
            setDroppableParent(DROPPABLE_ID);
            return mixedWordOrder.findIndex((m) => m.text === checkedAnswers[0]).toString();
          }
          return prevDraggedItem;
        });
      }
    }

    // Cleanup
    return () => {
      // This ensures to clean up local state for the currently mounted instance
      setDraggedItemId(null);
      setDroppableParent(null);
    };
  }, [answeringDisabled, attemptFinished, checkedAnswers, currentQuestion.index, mixedWordOrder]);

  function handleDragStart(event) {
    // console.info(event.active, draggedItemId);
    // setDraggedItemId(event.active.id);
    setActiveDrag(event.active.id);
  }

  function handleDragEnd(event) {
    const { over, active } = event;

    setActiveDrag(null);

    console.info(active.id, draggedItemId);

    if (over) {
      setDroppableParent(over.id);
      setDraggedItemId(event.active.id);

      // Set the answer
      setCheckedAnswers([mixedWordOrder[parseInt(active.id)].text]);
    } else if (active.id === draggedItemId) {
      setDroppableParent(null);
      setDraggedItemId(null);

      // Clear checked answers
      setCheckedAnswers([]);
    } else {
      // setDroppableParent((prevState) => prevState);
      // setDraggedItemId((prevState) => prevState);
    }
  }

  // console.info(mixedWordOrder[draggedItemId], draggedItemId);
  // console.info(mixedWordOrder);

  return (
    <div css={[spacer.padT30]}>
      <Container>
        <Row>
          <Col>
            {instructionsText && <InstructionLine text={instructionsText} />}
            <div
              css={[spacer.mrT60, spacer.mrB40, sharedStyles.types, sharedStyles.richTextQuestion]}
              className="ck-content"
            >
              {ReactHtmlParser(DOMPurify.sanitize(questionText))}
            </div>

            <DndContext
              onDragEnd={handleDragEnd}
              onDragStart={handleDragStart}
              modifiers={[restrictToWindowEdges, snapCenterToCursor]}
            >
              <div css={styles.mixedWordOrderWrapper}>
                {mixedWordOrder.map((item, index) =>
                  parseInt(draggedItemId) !== index ? (
                    <Draggable
                      id={index.toString()}
                      key={`${item.text}-${index.toString()}`}
                      disabled={answeringDisabled}
                    >
                      <Wordbox text={item.text} type="square" />
                    </Draggable>
                  ) : (
                    <WordboxPlaceholder
                      text={item.text}
                      key={`${item.text}-${index.toString()}`}
                      type="square"
                    />
                  )
                )}
              </div>

              <div css={[spacer.mrT60, spacer.mrB40]} className="text-center">
                <Wordbin id={DROPPABLE_ID}>
                  {/* {droppableParent && (
                    <Draggable id={draggedItemId} disabled={answeringDisabled}>
                      <Wordbox text={mixedWordOrder[draggedItemId]?.text} type="square" />
                    </Draggable>
                  )} */}
                  {attemptFinished && checkedAnswers.length ? (
                    <Wordbox text={checkedAnswers[0]} type="square" />
                  ) : (
                    droppableParent && (
                      <Draggable id={draggedItemId} disabled={answeringDisabled}>
                        <Wordbox text={mixedWordOrder[draggedItemId]?.text} type="square" />
                      </Draggable>
                    )
                  )}
                </Wordbin>
              </div>

              <DragOverlay key={currentQuestion._id}>
                {activeDrag ? (
                  <Wordbox text={mixedWordOrder[activeDrag]?.text} type="square" />
                ) : null}
              </DragOverlay>
            </DndContext>

            {attemptFinished && !ttAnswers?.length && isQuestionUsageFinal && (
              <P color={colors.red} className="text-center" large>
                Question not attempted.
              </P>
            )}

            {attemptFinished && (
              <Fragment>
                <P large css={[spacer.mrB40, spacer.mrT20]} className="text-center">
                  <span>The correct answer is </span>
                  <span css={txtColor.green}>{answer[0]}</span>
                  {checkedAnswers.includes(answer[0]) ? (
                    <P as="span" large>
                      . You answered correctly.
                    </P>
                  ) : (
                    ''
                  )}
                </P>
                <SolutionExplainer text={solutionExplaination} />
              </Fragment>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ShuffuledSentences.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

const WithQuestionProvider = (props) => {
  const { questionSettings } = props.currentQuestion.shuffledSentences;

  return (
    <QuestionsCommonWrapperContainer.Provider
      initialState={{ currentQuestion: props.currentQuestion, questionSettings }}
    >
      <ShuffuledSentences {...props} />
    </QuestionsCommonWrapperContainer.Provider>
  );
};
WithQuestionProvider.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

export default WithQuestionProvider;
