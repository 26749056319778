/** @jsxImportSource @emotion/react */
import { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import TestModuleInfoContainer from 'hooks/useTestModuleInfo';

import { Container, Row, Col } from 'components/Grid';
import P from 'components/htmlElements/P';
import SolutionExplainer from 'components/SolutionExplainer/SolutionExplainer';
import { InstructionLine } from 'components/Instructions';

import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';

import { spacer, colors } from 'styles/utilities';
import * as sharedStyles from 'components/QuestionTypes/shared-components/shared.styles';
import QuestionsCommonWrapperContainer from 'components/QuestionTypes/shared-components/QuestionsCommonWrapper';

import * as styles from './Cloze.styles';

const Cloze = ({ currentQuestion, isLastQuestion, allowedExtraTime }) => {
  // console.info(currentQuestion);
  const {
    instructionsText,
    questionText,
    solutionExplaination,
    wordList,
    questionSettings
    // answer
  } = currentQuestion.cloze;
  const { ttAnswers } = currentQuestion;

  const {
    // attemptFinished,
    showNextButton,
    answersEditableBeforeSubmission,
    updateAnswerPayload,
    isQuestionUsageFinal,
    updateClozeQuestionType,
    toggleTimerPaused,
    showPrevButton,
    backForthNavigationEnabled,
    enableBackForthNavigation
  } = TestModuleInfoContainer.useContainer();

  const { attemptFinished, isPracticeQuestion } = QuestionsCommonWrapperContainer.useContainer();

  // Save checked answer options
  const [checkedAnswers, setCheckedAnswers] = useState([]);
  useEffect(
    // This effect simply clears the checkedAnswers on question change. It ensures on question switch the previous state is cleared
    () => () => {
      setCheckedAnswers([]);
    },
    [currentQuestion._id]
  );

  // Update `checkedAnswers` if already answered and/or attempt is completed
  useEffect(() => {
    if (ttAnswers && ttAnswers.length) {
      setCheckedAnswers(ttAnswers);
    }

    // Clear the checked answers array on unmount
    // Without this there's a bug which messes up the next question if it's of the same type
    return () => {
      setCheckedAnswers([]);
    };
  }, [ttAnswers]);

  // Set clozeQuestionType which will handle cloze timer
  useEffect(() => {
    updateClozeQuestionType({
      isCloze: true,
      timeLimit: allowedExtraTime
        ? questionSettings.timeLimit + questionSettings.timeLimit * (allowedExtraTime / 100)
        : questionSettings.timeLimit,
      isClozeTimerPaused: questionSettings.pauseTimer,
      id: currentQuestion._id
    });

    // Stop the global timer
    toggleTimerPaused(true);

    // Disable back button
    showPrevButton(false);

    return () => {
      // Reset state
      updateClozeQuestionType({
        isCloze: false,
        timeLimit: 0,
        id: ''
      });

      showPrevButton(backForthNavigationEnabled);
    };
  }, [
    backForthNavigationEnabled,
    currentQuestion._id,
    allowedExtraTime,
    questionSettings.pauseTimer,
    questionSettings.timeLimit,
    showPrevButton,
    toggleTimerPaused,
    updateClozeQuestionType
  ]);

  // Handle disabling of answering
  const [answeringDisabled, handleAnsweringDisabled] = useState(false);
  useEffect(() => {
    // If editable and not answered - can answer
    // If not editable and not answered - can answer
    // If not editable and answered - cannot answer
    if (!attemptFinished) {
      if (!ttAnswers) {
        // This means it's not answered
        handleAnsweringDisabled(false);
      } else if (!answersEditableBeforeSubmission) {
        handleAnsweringDisabled(true);
      }
    } else {
      // If attempt is over answering is disabled
      handleAnsweringDisabled(true);
    }

    return () => {
      // Reset state
      handleAnsweringDisabled(!answersEditableBeforeSubmission);
    };
  }, [answersEditableBeforeSubmission, attemptFinished, ttAnswers]);

  // This simply updates `availableInputs` state which is purely used for local state management and UI rendering
  const [availableInputs, updateAvailableInputs] = useState([]);
  useEffect(() => {
    updateAvailableInputs(
      wordList.map((word, index) => ({
        fullWord: word.fullWord,
        answered: !!(checkedAnswers.length && checkedAnswers[index])
      }))
    );
  }, [checkedAnswers, wordList]);

  // Handle updating answer payload
  useEffect(() => {
    updateAnswerPayload({
      questionId: currentQuestion._id,
      answerArray: availableInputs.map((word) => (word.answered ? word.fullWord : ''))
    });
  }, [availableInputs, currentQuestion._id, updateAnswerPayload]);

  // Toggle next button visibility
  useEffect(() => {
    if (
      availableInputs.length &&
      availableInputs.some((input) => !input.answered) &&
      isQuestionUsageFinal
    ) {
      showNextButton(false);
    } else {
      showNextButton(true);
    }
  }, [availableInputs, isQuestionUsageFinal, showNextButton]);

  // This will disable only "Next" button if question is last one
  useEffect(() => {
    if (isLastQuestion) {
      enableBackForthNavigation(false);
    } else {
      enableBackForthNavigation(true);
    }
  }, [enableBackForthNavigation, isLastQuestion]);

  // Most of Cloze question specific logic starts here
  const handleInputChange = (event) => {
    const wordIs = event.target.getAttribute('data-word');
    const firstTwoLetters = wordIs.substring(0, 2);
    const firstTwoInputLetters = event.target.value?.substring(0, 2);

    const currentInputIndex = event.target.getAttribute('data-cloze-index');
    const nextInput = document.querySelector(
      `input[data-cloze-index="${parseInt(currentInputIndex) + 1}"]`
    );

    if (firstTwoInputLetters?.toLowerCase() === firstTwoLetters?.toLowerCase()) {
      updateAvailableInputs((prevState) => {
        // eslint-disable-next-line no-unused-vars
        const updatedState = [...prevState];

        // const wordIndex = updatedState.findIndex((item) => item.fullWord === wordIs);
        // updatedState[wordIndex].answered = true;

        // Changing the approach here wherein instead of finding an index (above logic)
        // we find all instances of occuring word and update them. This ensures if there are duplicates, both of them get filled up
        updatedState
          // .filter((item) => item.fullWord === wordIs)
          .map((item) => {
            if (item.fullWord === wordIs) {
              // eslint-disable-next-line no-param-reassign
              item.answered = true;
            }
            return item;
          });

        return updatedState;
      });

      // Focus on next input
      if (nextInput) {
        nextInput.focus();
      }

      // eslint-disable-next-line no-param-reassign
      event.target.disabled = true;
      // eslint-disable-next-line no-param-reassign
      event.target.readonly = true;
    }
  };

  // eslint-disable-next-line consistent-return
  function transform(node, index) {
    if (node.type === 'tag' && node.name === 'span' && node.attribs['data-response-plugin-value']) {
      const wordIndex = availableInputs.findIndex(
        (item) => item.fullWord === node.attribs['data-response-plugin-value']
      );
      const isWordAnswered = availableInputs[wordIndex]?.answered;

      // const isCorrect = isPracticeQuestion
      //   ? true
      //   : checkedAnswers.length
      //   ? checkedAnswers[wordIndex]
      //   : false;
      const isCorrect = (() => {
        if (isPracticeQuestion) {
          return true;
        }

        if (isWordAnswered) {
          return true;
        }

        if (checkedAnswers.length) {
          return checkedAnswers[wordIndex];
        }

        return false;
      })();

      return (
        <Fragment>
          {!isWordAnswered && !answeringDisabled ? (
            <input
              key={`${node.attribs['data-response-plugin-value']}-${index}`}
              type="text"
              autoComplete="off"
              data-word={node.attribs['data-response-plugin-value']}
              onChange={handleInputChange}
              css={styles.clozeInput}
              data-cloze-index={wordIndex}
            />
          ) : (
            <span
              css={styles.clozeAnswer({ isCorrect })}
              key={`${node.attribs['data-response-plugin-value']}-${index}`}
            >
              {node.attribs['data-response-plugin-value']}
            </span>
          )}
        </Fragment>
      );
    }
  }

  return (
    <div css={[spacer.mrT30]}>
      <Container>
        <Row>
          <Col>
            {instructionsText && <InstructionLine text={instructionsText} />}

            <div
              css={[
                spacer.mrB40,
                sharedStyles.types,
                sharedStyles.richTextQuestion,
                styles.questionWrapper
              ]}
              className="ck-content"
            >
              {ReactHtmlParser(DOMPurify.sanitize(questionText), { transform })}
            </div>

            {attemptFinished && !ttAnswers?.length && isQuestionUsageFinal && (
              <P color={colors.red} large>
                Question not attempted.
              </P>
            )}

            {attemptFinished && <SolutionExplainer text={solutionExplaination} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Cloze.propTypes = {
  currentQuestion: PropTypes.object.isRequired,
  isLastQuestion: PropTypes.object.isRequired,
  allowedExtraTime: PropTypes.number
};

Cloze.defaultProps = {
  allowedExtraTime: 0
};

const WithQuestionProvider = (props) => {
  const { questionSettings } = props.currentQuestion.cloze;

  return (
    <QuestionsCommonWrapperContainer.Provider
      initialState={{ currentQuestion: props.currentQuestion, questionSettings }}
    >
      <Cloze {...props} />
    </QuestionsCommonWrapperContainer.Provider>
  );
};
WithQuestionProvider.propTypes = {
  currentQuestion: PropTypes.object.isRequired
};

export default WithQuestionProvider;
