/** @jsxImportSource @emotion/react */

import Button from 'components/htmlElements/Button';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';

import P from 'components/htmlElements/P';
import A from 'components/htmlElements/A';

import checkmark from 'images/successful-checkmark.svg';
import { colors, spacer } from 'styles/utilities';
import * as styles from './EndModulePreview.styles';

const EndModulePreview = ({ isOpen, testId }) => (
  <Modal isOpen={isOpen} hideClose shouldCloseOnEsc={false} toggle={() => {}}>
    <div css={[spacer.padLR30, spacer.padBT30]}>
      <div css={styles.iconContainer}>
        <img css={styles.icon} alt="" src={checkmark} />
      </div>
      <P gBold large color={colors.mediumGrey} css={spacer.mrB30} className="text-center">
        Time&apos;s Up!
        <br />
        Module Submitted
      </P>
      <Button rounded textCenter as="a" href={`../../test/${testId}`} type="button" wide>
        View Test
      </Button>
    </div>
  </Modal>
);

EndModulePreview.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  testId: PropTypes.string.isRequired
};

export const EndModuleConfirmation = ({ isOpen, toggle }) => (
  <Modal isOpen={isOpen} toggle={toggle}>
    <div css={styles.iconContainer}>
      <img css={styles.icon} alt="" src={checkmark} />
    </div>
    <P gBold large color={colors.mediumGrey} css={spacer.mrB30} className="text-center">
      Are you sure?
    </P>

    <div
      className="d-flex align-items-center justify-content-center"
      css={[spacer.padLR20, spacer.padB20]}
    >
      <Button type="button">Yes</Button>
      <A as="span" type="button" wide secondary css={[spacer.mrL20]}>
        No
      </A>
    </div>
  </Modal>
);
EndModuleConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default EndModulePreview;
