//* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors, misc, mixins } from 'styles/utilities';

export const wrapper = ({ hideSolutionsLink }) => css`
  border: 1px solid ${colors.mediumGrey};
  border-radius: ${misc.mediumBorderRadius};
  background-color: ${mixins.toRgba(colors.primaryAnchorDefaultColor, 0.1)};
  ${(() => {
    if (hideSolutionsLink) {
      return css`
        .solutions-link {
          display: none;
        }
      `;
    }
    return css``;
  })()}
`;

export const container = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const statistics = css`
  display: flex;
  align-items: flex-start;
  > * {
    &:not(:last-of-type) {
      margin-right: 60px;
    }
  }
`;

export const noAction = css`
  display: flex;
  flex-direction: column;
`;

export const leftSection = css`
  flex: 1 1 auto;
`;

export const rightSection = css`
  display: flex;
`;

export const header = css`
  text-transform: uppercase;
`;

export const upsellText = css`
  padding: 10px 20px;
  border-top: 1px solid ${colors.mediumGrey};
`;

export const Recommended = css`
  border-top: 1px solid ${colors.mediumGrey};
`;
