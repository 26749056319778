/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors, misc, mixins } from 'styles/utilities';

export const sectionHeading = css`
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${colors.grey2};
`;

export const Header = ({ bgColor }) => css`
  background-color: ${mixins.toRgba(bgColor, 0.1)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const tabNav = ({ bgColor }) => css`
  .tab-list {
    background-color: ${mixins.toRgba(bgColor, 0.1)};
    padding: 10px 20px 0px 20px;
  }

  [role='tablist'] > * {
    &:hover {
      border-bottom-color: ${colors.primaryLinkHover};
      color: ${colors.primaryLinkHover};
      background-color: transparent;
    }

    &[aria-selected='true'] {
      background: ${mixins.toRgba(colors.primaryLinkHover, 0.2)};

      &:hover {
        color: ${colors.primaryLinkActive};
        border-bottom-color: ${colors.primaryLinkActive};
      }
    }
  }
`;

export const Separator = styled.div`
  min-width: 8px;
  max-height: 8px;
  min-height: 8px;
  border-radius: 50%;
  margin: 0px 10px;
  background-color: ${colors.mediumGrey2};

  &.line {
    min-width: 1px;
    min-height: 16px;
    border-radius: 0;
  }
`;

export const scoreBandWrapper = css`
  border: 2px solid ${colors.mediumGrey};
  border-radius: ${misc.largeBorderRadius};
`;

export const adminLinks = css`
  color: ${colors.red};

  &:hover {
    color: ${mixins.toRgba(colors.red, 0.8)};
  }
`;

export const testDetailsWrapper = css`
  overflow: hidden;
`;

export const analyticsBandStyles = css`
  .band {
    border: 1px solid ${colors.grey};
    background-color: #e1f3ff;
    border-radius: ${misc.largeBorderRadius};
  }
`;
