/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types';

import { spacer } from 'styles/utilities';
import H3 from 'components/htmlElements/H3';

const ModalHeader = ({ header }) => (
  <div css={spacer.padB20}>
    <H3 gBook css={spacer.mrBT0}>
      {header}
    </H3>
  </div>
);

ModalHeader.propTypes = {
  header: PropTypes.string.isRequired
};

export default ModalHeader;
