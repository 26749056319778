//* eslint-disable import/prefer-default-export */
import { css, keyframes } from '@emotion/react';
import { colors, misc, mixins, typography } from 'styles/utilities';
import styled from '@emotion/styled';
import Button from 'components/htmlElements/Button';
import breakPoints from 'styles/helpers/breakpoints';

const popupEnter = keyframes`
0% {
  opacity: 0;
  transform: scale(0.7);
}

100%{
  opacity: 1;
  transform: scale(1);
}
`;

export const header = css`
  position: sticky;
  top: 0;
  border-bottom: 2px solid ${colors.grey};
  background-color: ${colors.white};
  z-index: 10;

  .features {
    display: flex;
    align-items: center;
    & > :not(:last-child) {
      margin-right: 25px;
    }
  }
`;

export const popupWrapper = ({ right }) => css`
  padding: 24px;
  background: ${colors.white};
  position: absolute;
  min-width: 425px;
  top: 55px;
  max-width: fit-content;
  right: ${right}px;
  border-radius: 12px;
  z-index: 1500;
  overflow-y: auto;
  max-height: calc(100% - 70px);
  transform-origin: 425px 0px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%),
    0px 3px 14px 2px rgb(0 0 0 / 12%);
  animation: ${popupEnter} 246ms cubic-bezier(0.4, 0, 0.2, 1);

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }
`;

export const HeaderButton = styled(Button)`
  background-color: ${mixins.toRgba(colors.violet, 0.15)};
  border: none;
  padding: 0px;
  width: 36px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border-radius: ${misc.largeBorderRadius};
  font-family: ${typography.fonts.gothamBook.fontFamily};

  &.active {
    background-color: ${colors.violet};
  }

  &.fontBtn {
    background-color: transparent;
    min-width: 40px;
    min-height: 40px;
    &:hover {
      background-color: ${colors.blackTransparent10};
    }
  }

  &.tintBtn {
    width: unset;
    height: unset;
    padding: 2px;
    border-radius: 50%;
    margin: 4px;
    vertical-align: middle;
    background-color: transparent;
    border: 2px solid transparent;
    transform: rotate(0.1deg);

    &:hover {
      background-color: ${colors.blackTransparent10};
    }
  }

  &.selected {
    border: 2px solid ${colors.violet};

    &:hover {
      background-color: transparent;
    }
  }

  &:hover {
    background-color: ${mixins.toRgba(colors.violet, 0.3)};
  }

  &.toggle-btn {
    width: auto;
    padding: 2px 14px;
    color: ${colors.violet};
  }
`;

export const progress = styled.div`
  width: auto;
  height: 36px;
  padding: 2px 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${misc.largeBorderRadius};
  background-color: ${mixins.toRgba(colors.violet, 0.15)};

  .progress-bar {
    width: 60px;
    height: 4px;
    background-color: ${colors.white};
    border-radius: ${misc.smallBorderRadius};
    overflow: hidden;
  }

  .progress-bar-fill {
    width: ${({ testProgress }) => testProgress}px;
    background-color: ${colors.violet};
    height: 100%;
    max-width: 100%;
  }
`;

export const TintCircle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${({ tint }) => mixins.toRgba(tint.color, tint.alpha)};
  border: ${({ tint }) => `1px solid ${tint.color === '#000000' ? colors.mediumGrey : tint.color}`};
`;

export const skeletonWrapper = css`
  min-height: 100%;
  display: flex;
  flex-direction: column;

  * {
    font-family: 'proxima-soft', 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  }

  &.medium-font {
    h1 {
      ${breakPoints({
        fontSize: ['36px', '56px', '56px']
      })};
    }

    h2 {
      ${breakPoints({
        fontSize: ['30px', '', '33px', '48px']
      })};
    }

    h3 {
      ${breakPoints({
        fontSize: ['27px', '30px', '33px']
      })};
    }

    h4 {
      font-size: 30px;
    }

    h5 {
      ${breakPoints({
        fontSize: ['18px', '23px']
      })};
    }

    h6 {
      ${breakPoints({
        fontSize: ['20px', '27px']
      })};
    }

    p,
    a:not(.number-wrapper) {
      ${breakPoints({
        fontSize: ['16px', '20px']
      })};
    }

    .help-text * {
      font-size: unset;
    }

    .question-container {
      width: 1000px;
    }
  }

  &.large-font {
    h1 {
      ${breakPoints({
        fontSize: ['42px', '77px', '77px']
      })};
    }

    h2 {
      ${breakPoints({
        fontSize: ['36px', '', '39px', '57px']
      })};
    }

    h3 {
      ${breakPoints({
        fontSize: ['34px', '36px', '39px']
      })};
    }

    h4 {
      font-size: 36px;
    }

    h5 {
      ${breakPoints({
        fontSize: ['22px', '27px']
      })};
    }

    h6 {
      ${breakPoints({
        fontSize: ['24px', '34px']
      })};
    }

    p,
    a:not(.number-wrapper) {
      line-height: 1.2;
      ${breakPoints({
        fontSize: ['24px', '30px']
      })};
    }

    .help-text * {
      font-size: unset;
    }

    .question-container {
      width: 1220px;
    }
  }

  .question-container-bg {
    background-color: #f9fafa;
  }
`;

export const popupBackdrop = css`
  width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
  background: transparent;
  z-index: 1000;
`;

export const contentWrapper = css`
  width: 100%;
  max-width: 1250px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .question-container {
    width: 800px;
    max-width: 100%;
    margin: 24px 0px;
    padding: 0px 16px;
  }
`;

export const content = ({ tint }) => css`
  max-width: 100%;
  margin: 40px 0px 40px 0px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-color: ${mixins.toRgba(tint.color, tint.alpha)};
    border-radius: 12px;
    inset: -25px -25px -10px -25px;
    z-index: 2;
    margin: auto;
    pointer-events: none;
    touch-action: none;
  }
`;

export const SubmitButton = styled(Button)`
  border-radius: ${misc.largeBorderRadius};
  text-transform: capitalize;
  padding: 10px 28px;
  background-color: ${colors.violet};

  &:hover,
  &:active {
    background-color: ${colors.violetButtonHover};
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
      0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
`;

export const progressBarWrapper = (props) => css`
  ${(() => {
    if (props.attemptFinished) {
      return css`
        position: relative;
      `;
    }

    return css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `;
  })()}
`;
