/** @jsxImportSource @emotion/react */

import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Fragment } from 'react-is';
import { useQueryClient } from 'react-query';
import LoaderOverlay from 'components/LoaderOverlay';
import AssignProductDropdown from 'components/AssignProductDropdown/AssignProductDropdown';
import SchoolDataTable from 'components/DataTable/variants/SchoolDataTable';
import { useAssignProduct, useAddCandidateToGroup, useRemoveCandidateFromGroup } from 'api/schools';
import Sidebar, { SidebarHeader } from 'components/Sidebar/Sidebar';
import SchoolGroupItem from 'components/SchoolGroupItem/SchoolGroupItem';
import AddSchoolGroup from 'components/AddSchoolGroup/AddSchoolGroup';
import { spacer, colors } from 'styles/utilities';
import P from 'components/htmlElements/P';
import Link from 'components/htmlElements/Link';
// import DropdownColumnFilter from 'components/DropdownColumnFilter/DropdownColumnFilter';
import SuccessModal from 'components/SuccessModal/SuccessModal';
import Modal, { useModal } from 'components/Modal/Modal';
import { FieldError } from 'components/htmlElements/Fields';
import getAge from 'utils/getAge';

import TooltipTrigger from 'components/Tooltip/TooltipTrigger';
import Icon from 'components/Icon/Icon';
import { useUserAccountContext } from 'components/EditAccount/userAccountContext';
import * as styles from './page.styles';
import EditStudentDetails from './EditStudentDetails';

const TooltipInner = ({ data }) => (
  <div>
    {data.map(({ name }, i) => (
      <P css={styles.tooltipRow} key={`item-${i.toString()}`}>
        {name}
      </P>
    ))}
  </div>
);

const SchoolDashboard = ({ unassignedProducts, groups, candidates }) => {
  // States to toggle between all candidates and group candidates
  const [currentGroup, setCurrentGroup] = useState(0);
  const [schoolData, setSchoolData] = useState(candidates);
  useEffect(() => {
    // This effect ensures the candidates data is refetched after assignment
    setSchoolData(candidates);

    setCurrentGroup(0);
  }, [candidates]);

  useEffect(() => {
    // This effect ensures the candidates data is refetched after update on groups
    if (currentGroup !== 0) {
      // Deliberately keeping "id === 0" in click event since we don't want this effect to run every time candidates list is updated
      const selectedGroup = groups.find((group) => group.id === currentGroup);
      setSchoolData([...selectedGroup.candidates]);
    }
  }, [currentGroup, groups]);

  const handleSchoolItemClick = (id) => {
    setCurrentGroup(id);
    if (id === 0) {
      setSchoolData(candidates);
    }
    // if (id === 0) {
    //   setSchoolData(candidates);
    // } else {
    //   const selectedGroup = groups.find((group) => group.id === id);
    //   setSchoolData([...selectedGroup.candidates]);
    // }
  };

  const [id, setId] = useState(null);
  const { closeEditCandidateModal, isEditCandidateOpen } = useUserAccountContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const queryClient = useQueryClient();

  const editDetailsHandler = useCallback((candidateId) => {
    // set the candidate ID
    setId(candidateId);

    // set modal visility to true
    setIsModalVisible(true);
  }, []);

  useEffect(() => {
    if (!isEditCandidateOpen) {
      setId(null);
      setIsModalVisible(false);
      closeEditCandidateModal();
      queryClient.invalidateQueries('schoolCandidates');
      queryClient.invalidateQueries('schoolGroups');
    }
  }, [isEditCandidateOpen, closeEditCandidateModal, queryClient]);

  const { isOpen: isAddGroupModalOpen, toggle: toggleAddGroupModalOpen } = useModal();
  const [addProductErrorText, setAddProductErrorText] = useState('');

  // Success modal state (This modal is used by multiple functions. Always ensure to clear the message on closing the modal)
  const { isOpen: isSuccessModalOpen, toggle: toggleIsSuccessModalOpen, closeModal } = useModal();
  const [successModalMessage, setSuccessModalMessage] = useState('');
  const closeSuccessModal = () => {
    closeModal();

    queryClient.invalidateQueries('schoolCandidates');
    queryClient.invalidateQueries('schoolGroups');

    // Clear message
    setSuccessModalMessage('');
  };

  // Assigning products
  const [selectedProduct, setProductSelection] = useState();
  const [selectedRows, setSelectedRows] = useState([]); // Array of selected students
  const [clearStudentsSelection, toggleClearStudentsSelection] = useState(false); // This is meant to clear selection after assigning products

  const {
    assignProduct,
    reset: resetAssignProductMutation,
    isLoading: isAssignProductsLoading
  } = useAssignProduct();

  const handleSetProductIdSelection = (selectedProductId) => {
    setProductSelection(selectedProductId);

    // Clear any on page errors after selection
    setAddProductErrorText('');

    // Unset selection of students
    toggleClearStudentsSelection(false);
  };

  const handleProductAssignment = async (event) => {
    event.preventDefault();

    // Clear error
    setAddProductErrorText('');

    if (!selectedProduct) {
      setAddProductErrorText('Please select a product to assign');
      return false;
    }
    if (selectedRows.length === 0) {
      setAddProductErrorText('Please select at least one student');
      return false;
    }
    if (selectedRows.length > selectedProduct.count) {
      setAddProductErrorText(
        `You can only assign this product to ${selectedProduct.count} students. You have selected ${selectedRows.length} students`
      );
      return false;
    }
    if (!unassignedProducts.length) {
      setAddProductErrorText('No products available');
      return false;
    }

    // Call API to assign here
    const multiAssignProducts = await Promise.all(
      selectedRows &&
        selectedRows.map(async (studentRow) => {
          const resp = await assignProduct({
            candidateId: studentRow.original.id,
            wpProductId: selectedProduct.product_id
          });
          return resp;
        })
    );

    if (
      !isAssignProductsLoading &&
      multiAssignProducts.some((resp) => resp.status === 200 || resp.status === 201)
    ) {
      // Show success modal
      setSuccessModalMessage(`${selectedProduct.name} assigned to ${selectedRows.length} students`);
      toggleIsSuccessModalOpen(true);

      // Clear selection
      toggleClearStudentsSelection(true);

      // Reset mutation state
      resetAssignProductMutation();
    } else if (multiAssignProducts.some((resp) => resp.status !== 200 || resp.status !== 201)) {
      setAddProductErrorText('There was an issue with assigning products, please try again');

      throw new Error(
        'There was an issue with assigning products, please try again',
        multiAssignProducts
      ); // To catch in Sentry
    }

    return true;
  };

  // Add/Remove candidates from groups
  const { addCandidateToGroup, isLoading: addCandidateToGroupLoading } = useAddCandidateToGroup();
  const { removeCandidateFromGroup, isLoading: removeCandidateFromGroupLoading } =
    useRemoveCandidateFromGroup();

  const handleToggleGroup = async (groupId) => {
    if (groupId === currentGroup) {
      await Promise.all(
        selectedRows.map(async (v) => {
          const { id: candidateId } = v.original;
          await removeCandidateFromGroup({ candidateId, groupId });
        })
      );
    } else {
      await Promise.all(
        selectedRows.map(async (v) => {
          const { id: candidateId } = v.original;
          await addCandidateToGroup({ candidateId, groupId });
        })
      );
    }

    // Show success modal
    setSuccessModalMessage('Group updated');
    toggleIsSuccessModalOpen(true);

    // Clear selection
    toggleClearStudentsSelection(true);
  };

  const dataTableColumnConfig = useMemo(
    () => [
      {
        Header: 'Username',
        accessor: (row) => `${row.username}`,
        Cell: (row) => <Link to={`../../students/${row.row.original.id}`}>{row.value}</Link>,
        canFilter: false,
        Filter: '',
        disableFilters: true,
        width: 200,
        sticky: 'left'
      },
      {
        id: 'name',
        Header: 'Name',
        accessor: (row) =>
          `${row.firstName ? row.firstName : '-'} ${row.lastName ? row.lastName : ''}`,
        Cell: (row) => (
          <P noMargin color={colors.mediumGrey}>
            {row.value}
          </P>
        ),
        canFilter: false,
        Filter: '',
        fixed: 'left',
        width: 300
      },
      {
        Header: 'Age',
        accessor: (row) => (row.dob ? `${getAge(row.dob)}` : '-'),
        Cell: (row) => (
          <P noMargin color={colors.mediumGrey}>
            {row.value}
          </P>
        ),
        canFilter: false,
        Filter: ''
      },
      {
        id: 'groups',
        Header: 'Groups',
        accessor: (row) => row.groups,
        Cell: (row) => (
          <Fragment>
            {row.value.length > 0 ? (
              row.value.length > 1 ? (
                <TooltipTrigger
                  tooltipInner={<TooltipInner data={row.value} />}
                  placement="bottom"
                  trigger="hover"
                >
                  <P css={styles.groupName} as="span" noMargin color={colors.mediumGrey}>
                    {`${row.value[0].name} + ${row.value.length - 1}`}
                  </P>
                </TooltipTrigger>
              ) : (
                <P as="span" noMargin color={colors.mediumGrey}>
                  {row.value[0].name}
                </P>
              )
            ) : (
              <P as="span" noMargin color={colors.mediumGrey}>
                -
              </P>
            )}
          </Fragment>
        ),
        canFilter: false,
        Filter: '',
        fixed: 'left',
        width: 300
      },
      {
        id: 'products',
        Header: 'Assigned Products',
        accessor: (row) => {
          const tests = row.candidateAssignedTests ? row.candidateAssignedTests : [];
          const courses = row.candidateAssignedCourses ? row.candidateAssignedCourses : [];
          return tests.concat(courses);
        },
        Cell: (row) => {
          const assignedProducts =
            row.value.length > 0
              ? row.value.map((assignedProduct) => assignedProduct.wpMeta.name)
              : [];

          return (
            <Fragment>
              {assignedProducts.length > 0 ? (
                assignedProducts.length > 1 ? (
                  <TooltipTrigger
                    tooltipInner={<TooltipInner data={assignedProducts} />}
                    placement="bottom"
                    trigger="hover"
                  >
                    <P css={styles.groupName} as="span" noMargin color={colors.mediumGrey}>
                      {`${assignedProducts[0]} +${assignedProducts.length}`}
                    </P>
                  </TooltipTrigger>
                ) : (
                  <P as="span" noMargin color={colors.mediumGrey}>
                    {assignedProducts[0]}
                  </P>
                )
              ) : (
                <P noMargin color={colors.mediumGrey}>
                  -
                </P>
              )}
            </Fragment>
          );
        },
        disableSortBy: true,
        Filter: ''
        // eslint-disable-next-line react/prop-types
        // Filter: ({ column }) => (
        //   <div css={styles.select}>
        //     <DropdownColumnFilter column={column} productsList={unassignedProducts} />
        //   </div>
        // )
      },
      {
        id: 'edit',
        Header: () => null,
        accessor: (row) => row,
        Cell: (row) => (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={() => editDetailsHandler(row.row.original.id.toString())}
            css={styles.editIcon}
          >
            <Icon icon="edit" size="18" color={colors.mediumGrey} className="edit-icon" />
          </div>
        ),
        canFilter: false,
        Filter: '',
        disableFilters: true,
        width: 100,
        sticky: 'left'
      }
    ],
    [editDetailsHandler]
  );

  return (
    <Fragment>
      {id && isModalVisible && <EditStudentDetails candidateId={id} />}
      <div className="d-flex">
        <Sidebar>
          <SidebarHeader header="Student Groups" link="+ Add" onClick={toggleAddGroupModalOpen} />

          <Fragment>
            <div css={[styles.schoolGroupItemWrapper({ selected: currentGroup === 0 })]}>
              <SchoolGroupItem
                handleClick={() => handleSchoolItemClick(0)}
                groupName="All Students"
                noOfStudents={candidates.length}
              />
            </div>

            {groups.length
              ? groups.map((group) => (
                  <div
                    key={group.id}
                    css={[styles.schoolGroupItemWrapper({ selected: currentGroup === group.id })]}
                  >
                    <SchoolGroupItem
                      handleClick={() => handleSchoolItemClick(group.id)}
                      groupName={group.name}
                      noOfStudents={group.candidates.length}
                    />
                    {selectedRows.length > 0 ? (
                      <P
                        role="button"
                        color={colors.primaryAnchorDefaultColor}
                        onClick={() => handleToggleGroup(group.id)}
                        css={[styles.groupToggle]}
                        tabIndex={0}
                        onKeyDown={() => {}}
                      >
                        {currentGroup === group.id ? 'Remove' : 'Add'}
                      </P>
                    ) : (
                      ''
                    )}
                  </div>
                ))
              : ''}
          </Fragment>
        </Sidebar>

        <div css={spacer.padT30} className="w-100">
          <div css={styles.candidatesTableSection}>
            <SchoolDataTable
              columnConfig={dataTableColumnConfig}
              rowData={schoolData}
              setSelectedRows={setSelectedRows}
              clearRowSelection={clearStudentsSelection}
            >
              <div css={[styles.assignProductWrapper]} className="is-relative">
                <AssignProductDropdown
                  items={unassignedProducts}
                  handleAssignProductClick={handleProductAssignment}
                  handleSelect={handleSetProductIdSelection}
                  clearSelection={clearStudentsSelection}
                />

                {addProductErrorText.length > 0 && <FieldError>{addProductErrorText}</FieldError>}
                {selectedRows.length && !addProductErrorText.length ? (
                  <FieldError isSuccess>
                    {selectedRows.length} STUDENT{selectedRows.length > 1 && 'S'} SELECTED
                  </FieldError>
                ) : (
                  ''
                )}
              </div>
            </SchoolDataTable>
          </div>
        </div>
      </div>

      {(isAssignProductsLoading ||
        addCandidateToGroupLoading ||
        removeCandidateFromGroupLoading) && <LoaderOverlay />}

      <AddSchoolGroup isOpen={isAddGroupModalOpen} toggleModal={toggleAddGroupModalOpen} />

      <Modal isOpen={isSuccessModalOpen} toggle={toggleIsSuccessModalOpen}>
        <SuccessModal message={successModalMessage} onClick={closeSuccessModal} />
      </Modal>
    </Fragment>
  );
};

SchoolDashboard.propTypes = {
  unassignedProducts: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  candidates: PropTypes.array.isRequired
};

TooltipInner.propTypes = {
  data: PropTypes.array.isRequired
};

export default SchoolDashboard;
