/** @jsxImportSource @emotion/react */
import { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useResetAttemptModule } from 'api/test';

import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader/ModalHeader';
import Button from 'components/htmlElements/Button';

import { spacer, colors } from 'styles/utilities';
import P from 'components/htmlElements/P';

import SuccessModal from 'components/SuccessModal';
import LoaderOverlay from 'components/LoaderOverlay';
// import * as styles from './ResetModuleConfirmationModal.styles';

const ResetModuleConfirmationModal = ({
  isOpen,
  toggle,
  moduleId,
  moduleName,
  attemptId,
  assignmentId,
  isTestAtSection,
  completedModules,
  isResettingAllCompletedModule
}) => {
  const { resetAttemptModule, isSuccess, isLoading, reset } = useResetAttemptModule(
    attemptId,
    assignmentId
  );

  useEffect(
    () =>
      // Clear mutation state when modal is opened

      () => {
        reset();
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen]
  );

  const handleConfirmation = async () => {
    if (completedModules.length > 0) {
      const payload = completedModules.map((module) => {
        if (isTestAtSection) {
          return { sectionId: module?._id };
        }
        return { subSectionId: module?._id };
      });

      // eslint-disable-next-line no-restricted-syntax
      for await (const section of payload) {
        await resetAttemptModule(section);
      }
    } else {
      const payload = {};

      if (isTestAtSection) {
        payload.sectionId = moduleId;
      } else {
        payload.subSectionId = moduleId;
      }

      await resetAttemptModule(payload);
    }
  };

  const handleSuccessModalClick = () => {
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} shouldCloseOnEsc={false}>
      {!isSuccess ? (
        <Fragment>
          <div css={[spacer.padBT30, spacer.padLR30]}>
            <ModalHeader header="Reset Module" />

            <P bold color={colors.mediumGrey} className="no-margin" css={spacer.mrB30}>
              {isResettingAllCompletedModule
                ? 'Are you sure to reset all completed modules'
                : `Are you sure to reset module ${moduleName}`}
            </P>

            <Button wide rounded textCenter onClick={handleConfirmation}>
              Confirm
            </Button>

            {isLoading && <LoaderOverlay />}
          </div>
        </Fragment>
      ) : (
        <SuccessModal
          message={
            isResettingAllCompletedModule
              ? 'All completed modules have been reset'
              : `${moduleName} module has been successfully reset`
          }
          onClick={handleSuccessModalClick}
        />
      )}
    </Modal>
  );
};

ResetModuleConfirmationModal.propTypes = {
  moduleName: PropTypes.string,
  assignmentId: PropTypes.string.isRequired,
  attemptId: PropTypes.number.isRequired,
  isTestAtSection: PropTypes.bool.isRequired,
  moduleId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  completedModules: PropTypes.array,
  isResettingAllCompletedModule: PropTypes.bool
};

ResetModuleConfirmationModal.defaultProps = {
  moduleName: '',
  moduleId: '',
  completedModules: [],
  isResettingAllCompletedModule: false
};

export default ResetModuleConfirmationModal;
