//* eslint-disable import/prefer-default-export */
import { keyframes, css } from '@emotion/react';
import hexToRgba from 'hex-to-rgba';
import { colors, misc } from 'styles/utilities';

const loaderSize = '1em';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`;

export const netDisconnectedOverlay = css`
  background-color: ${colors.blackTransparent90};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${misc.modalZindex};
  overflow: hidden;
  text-align: center;

  .overlay-inner {
    max-width: 500px;
  }
`;

export const spinner = css`
  position: relative;
  &:after {
    content: '';
    display: block;
    border-bottom: ${loaderSize} solid ${hexToRgba(colors.white, '0.1')};
    border-left: ${loaderSize} solid ${hexToRgba(colors.white, '0.1')};
    border-right: ${loaderSize} solid ${hexToRgba(colors.white, '0.1')};
    border-top: ${loaderSize} solid ${hexToRgba(colors.white, '0.4')};
    border-radius: 100%;
    animation: ${rotate} 0.75s infinite linear;
  }
`;
