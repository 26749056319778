import { useState, useEffect, useCallback, useMemo } from 'react';
import { useMutation } from 'react-query';
import { createContainer } from 'unstated-next';
// import { useNavigate } from 'components/Router';
import store from 'store';
import { isEqual } from 'lodash-es';

import LogRocket from 'logrocket';
import { apiEndpoints } from 'globals/constants';
import request from 'utils/request';

const useUserInfo = () => {
  const initialState = () => store.get('pretestUser') || {};
  const [userInfo, updateUserInfo] = useState(initialState);

  useEffect(() => {
    store.set('pretestUser', userInfo);
  }, [userInfo]);

  const setUserInfo = useCallback(
    (updatedUserInfo) => {
      if (!isEqual(userInfo, updatedUserInfo)) {
        updateUserInfo(updatedUserInfo);
      }
    },
    [userInfo]
  );

  const isAdmin = useMemo(() => userInfo.userIs === 'administrator', [userInfo.userIs]);

  const userCanAttemptTests = useMemo(
    () => userInfo.userIs !== 'school' && userInfo.userIs !== 'administrator',
    [userInfo.userIs]
  );

  const userCanViewCourses = useMemo(
    () => userInfo.userIs !== 'school' && userInfo.userIs !== 'administrator',
    [userInfo.userIs]
  );

  const userCanUnassignTest = useMemo(() => userInfo.userIs === 'administrator', [userInfo.userIs]);

  const userCanResetAttemptModules = useMemo(
    () => userInfo.userIs === 'administrator',
    [userInfo.userIs]
  );

  return {
    ...userInfo,
    setUserInfo,
    updateUserInfo,
    isAdmin,
    userCanAttemptTests,
    userCanViewCourses,
    userCanUnassignTest,
    userCanResetAttemptModules
  };
};

export const UserInfoContainer = createContainer(useUserInfo);

export const useLogin = () => {
  // const navigate = useNavigate();
  const { setUserInfo } = UserInfoContainer.useContainer();
  const mutation = useMutation(
    async ({ email, password }) => {
      const { response, status } = await request({
        method: 'POST',
        endpoint: apiEndpoints.auth.login,
        payload: { email, password }
      });

      return { response, status };
    },
    {
      onSuccess(res) {
        if (res.status === 201) {
          setUserInfo({
            token: res.response.accessToken,
            ...res.response.data
          });

          // Set Logrocket info
          LogRocket.identify(res.response?.data?.id, {
            name: `${res.response?.data?.firstName} ${res.response?.data?.lastName}`,
            userIs: res.response?.data?.userIs,
            ...(res.response?.data?.username && { username: res.response?.data?.username }),
            ...(res.response?.data?.email && { email: res.response?.data?.email })
          });
        } else if (res.status === 403) {
          console.info(res);
        }
      },
      onError(err) {
        console.info(err);
        // throw new Error(err);
      }
    }
  );

  const { mutateAsync, data, error, status, isError, isSuccess } = mutation;
  return { login: mutateAsync, data, error, status, isError, isSuccess };
};

export const useLogout = () => {
  const { setUserInfo } = UserInfoContainer.useContainer();

  const logout = useCallback(
    ({ updateUrl = true } = {}) => {
      setUserInfo({});

      // Clear url
      if (updateUrl) {
        window.history.replaceState(null, '', window.location.origin);
      }
    },
    [setUserInfo]
  );
  return logout;
};

export const useValidateToken = () => {
  const { setUserInfo } = UserInfoContainer.useContainer();
  const logout = useLogout();

  const { data, error, status, mutateAsync } = useMutation(
    async () => {
      const { response } = await request({
        method: 'POST',
        endpoint: apiEndpoints.auth.validate
      });
      return response;
    },
    {
      onSuccess(res) {
        // Second check here is for candidate logins
        if (res.code === 'jwt_auth_valid_token' || res.accessToken !== undefined) {
          const { token, ...rest } = res.data;
          setUserInfo((prevState) => ({ ...prevState, ...rest }));
        } else if (res.statusCode === 401) {
          logout();
        }
      }
    }
  );
  return { validateToken: mutateAsync, data, error, status };
};

export const useForgottenPassword = () => {
  const mutation = useMutation(async (body) => {
    const { response } = await request({
      method: 'POST',
      endpoint: apiEndpoints.auth.forgotPassword,
      payload: body
    });
    console.info(response);
    return response;
  });

  const { mutateAsync, data, error, status, isError, isSuccess } = mutation;
  return { forgotPasswordMutation: mutateAsync, data, error, status, isError, isSuccess };
};

export const useResetPassword = () => {
  const mutation = useMutation(async ({ key, login, password }) => {
    const { response } = await request({
      method: 'POST',
      endpoint: apiEndpoints.auth.resetPassword,
      payload: {
        key,
        login,
        password
      }
    });
    return response;
  });
  const { mutateAsync, data, error, status, isError, isSuccess } = mutation;
  return { resetPasswordMutation: mutateAsync, data, error, status, isError, isSuccess };
};

export default useLogin;
