import { colors } from 'styles/utilities';

/* eslint-disable import/prefer-default-export */

export const ROUTE_PATHS = {
  home: '/',
  login: '/login',
  notFound: '/404',

  adminDashboard: '/',
  adminProducts: '/link-products',
  adminParents: '/parents',
  adminSchools: '/schools',
  adminParentsInfo: '/parents/:parentId',
  adminSchoolsInfo: 'schools/:schoolId',
  adminDisabledAccounts: '/disabled-accounts',
  adminCandidate: 'candidate/:candidateId',
  adminAssignedtests: '/assignedTests',

  parentCandidate: '/child/:candidateId',

  schoolCandidate: '/students/:candidateId',
  schoolPurchasedProducts: '/products',
  schoolCohort: '/cohort',
  schoolPurchasedProductDetails: '/products/:productId',

  // schoolStudent: '/dashboard/students/',

  // Candidate routes
  candidate: {
    coursePage: '/course/:courseAssignmentId',
    testDetails: '/test/:testAssignmentId',
    testFlow: '/test/:testAssignmentId/:moduleId'
  }
};

// const apiHost = `${process.env.API_URL}/api/v1`;
export const api = `${process.env.API_URL}`;
export const testsHubApi = `${process.env.TEST_API_URL}`;
export const testsHubDirectApi = `${process.env.TEST_HUB_URL}`;
// export const testsHubApi = 'https://api.testshub.co';

export const apiEndpoints = {
  auth: {
    login: `${api}/login`,
    validate: `${api}/validate-token`,
    forgotPassword: `${api}/forgot-password`,
    resetPassword: `${api}/reset-password`
  },
  admin: {
    schools: `${api}/admin/schools`,
    parents: `${api}/admin/parents`,
    products: `${api}/admin/wc/products`,
    stats: `${api}/admin/stats`,
    addSchool: `${api}/admin/school`,
    addStudentsToSchool: (schoolId) => `${api}/admin/school/${schoolId}/add-candidates`,
    addProductsToSchool: (schoolId) => `${api}/admin/school/${schoolId}/add-products`,
    getTestshubTests: `${api}/admin/testhub/tests`,
    getTestshubCourses: `${api}/admin/testhub/courses`,
    getLinkedProducts: `${api}/admin/linked-products`,
    linkProduct: `${api}/admin/link-product`,
    getTestAttempts: (testId) => `${api}/admin/testhub/testAttempts/${testId}`,
    parent: (parentId) => `${api}/admin/parent/${parentId}`,
    school: (schoolId) => `${api}/admin/school/${schoolId}`,
    getCandidate: (candidateId) => `${api}/admin/candidate/${candidateId}`,
    unassignTestFromCandidate: (assignmentId) => `${api}/admin/candidate/unassign/${assignmentId}`,
    updateCustomerAccess: (customerId) => `${api}/admin/update-access/${customerId}`,
    updateCustomerDetails: (customerId) => `${api}/admin/user/${customerId}/edit-user`
  },
  parent: {
    unassignedProducts: `${api}/parent/unassigned-products`,
    candidates: `${api}/parent/candidates`,
    getCandidate: (candidateId) => `${api}/parent/candidate/${candidateId}`,
    assignProduct: `${api}/parent/assign-product`,
    addChild: `${api}/parent/candidate/add`,
    editAccount: `${api}/parent/edit-account`,
    changePassword: `${api}/parent/change-password`,
    purchasableProducts: `${api}/parent/wc/products`
  },
  school: {
    unassignedProducts: `${api}/school/unassigned-products`,
    candidates: `${api}/school/candidates`,
    getCandidate: (candidateId) => `${api}/school/candidate/${candidateId}`,
    assignProduct: `${api}/school/assign-product`,
    groups: `${api}/school/groups`,
    addCandidateToGroup: (groupId) => `${api}/school/groups/${groupId}/candidates/add`,
    removeCandidateFromGroup: (groupId) => `${api}/school/groups/${groupId}/candidates/remove`,
    editAccount: `${api}/school/edit-account`,
    changePassword: `${api}/school/change-password`,
    getPurchasedProducts: `${api}/school/products`,
    getAllAnalyticsCohort: `${api}/school/get-all-analytics-cohorts`,
    getAnalyticsCohort: (cohortId) => `${api}/school/get-analytics-cohorts/${cohortId}`,
    setAnalyticsCohort: `${api}/school/set-analytics-cohort`,
    updateAnalyticsCohort: (cohortId) => `${api}/school/update-analytics-cohort/${cohortId}`,
    deleteAnalyticsCohort: (cohortId) => `${api}/school/delete-analytics-cohort/${cohortId}`
  },
  candidate: {
    getAssignedTest: (testAssigmentId) => `${api}/candidate/test/${testAssigmentId}`,
    getAllAssignedTests: `${api}/candidate/tests`,
    getAssignedCourse: (courseAssignmentId) => `${api}/candidate/course/${courseAssignmentId}`,
    getAllAssignedCourses: `${api}/candidate/courses`,
    editAccount: `${api}/candidate/edit-account`,
    getProductRecommendations: (productId) =>
      `${api}/candidate/product-recommendations/${productId}`
  },
  course: {
    getCourseAttempt: (courseAttemptId) => `${testsHubApi}/courseAttempt/${courseAttemptId}`,
    getLessons: (courseAttemptId) => `${testsHubApi}/courseAttempt/${courseAttemptId}/lessons`,
    updateLessonWatch: (courseAttemptId, lessonId) =>
      `${testsHubApi}/courseAttempt/${courseAttemptId}/watch/${lessonId}`,
    restartCourse: (courseAttemptId) => `${testsHubApi}/courseAttempt/${courseAttemptId}/restart`,
    bookmarks: {
      getCourseBookmarks: `${testsHubApi}/courseBookmarks`,
      deleteBookmark: (bookmarkId) => `${testsHubApi}/courseBookmark/${bookmarkId}`,
      updateBookmark: (bookmarkId) => `${testsHubApi}/courseBookmark/${bookmarkId}`,
      createBookmark: `${testsHubApi}/courseBookmark`
    }
  },
  test: {
    getAttempt: (attemptId) => `${testsHubApi}/attempt/${attemptId}`,
    getQuestions: (attemptId) => `${testsHubDirectApi}/attempt/${attemptId}/questions`,
    startAttemptModule: (attemptId) => `${testsHubApi}/attempt/${attemptId}/start`,
    attemptModuleIsAlive: (attemptId) => `${testsHubDirectApi}/attempt/${attemptId}/alive`,
    endAttemptModule: (attemptId) => `${testsHubApi}/attempt/${attemptId}/end`,
    answerQuestion: (attemptId, questionId) =>
      `${testsHubDirectApi}/attempt/${attemptId}/answer/${questionId}`,
    resetAttemptModule: (attemptId) => `${testsHubApi}/attempt/${attemptId}/reset`,
    patchAttempt: (attemptId) => `${testsHubApi}/attempt/${attemptId}`,
    getAnalytics: (testAssigmentId) => `${api}/candidate/test/${testAssigmentId}/analytics`
  }
};

export const sentryDSN =
  'https://d89bf1dad9764699a5b5018d4db0204f@o474007.ingest.sentry.io/5813289';

const number = 1 || 2 || 3 || 4;

export const products = {
  PRETEST: {
    name: 'pretest',
    color: colors.yellow
  },
  CAT: {
    name: 'cat' || `cat${number}`,
    color: colors.red
  },
  UKISET: {
    name: 'ukiset',
    color: colors.purple
  },
  CEM: {
    name: 'cem',
    color: colors.lightBlue
  },
  PROGRESS: {
    name: 'progress-test',
    color: colors.progressTestOrange
  },
  LONDON_CONSORTIUM: {
    name: 'london-11-plus-consortium',
    color: colors.londonConsortiumGreen
  },
  SEVEN_PLUS: {
    name: 'seven-plus-exam',
    color: colors.sevenPlus
  },
  EIGHT_PLUS: {
    name: 'eight-plus-exam',
    color: colors.eightPlus
  },
  TEN_PLUS: {
    name: 'ten-plus-exam',
    color: colors.tenPlus
  },
  ELEVEN_PLUS: {
    name: 'eleven-plus-exam',
    color: colors.elevenPlus
  },
  THIRTEEN_PLUS: {
    name: 'thirteen-plus-exam',
    color: colors.thirteenPlus
  },
  INCAS: {
    name: 'incas',
    color: colors.incas
  },
  ADAPTIVE_ADMISSIONS_TEST: {
    name: 'adaptiveAdmissionsTest',
    color: colors.adaptiveAdmissionsTest
  },
  PRE_11_PLUS: {
    name: 'pre11plus',
    color: colors.pre11plus
  }
};

export const PRODUCT_CARD_TYPES = {
  adminAssigned: 'adminAssigned',
  assignmentDetails: 'assignmentDetails',
  assignTest: 'assignTest',
  mockExam: 'mockExam',
  withStats: 'withStats'
};

// Test setting constants
export const TEST_STRUCTURE = {
  test: 'test',
  sections: 'section',
  subsections: 'subSection'
};

export const COURSE_STRUCTURE = {
  course: 'course',
  sections: 'section',
  subsections: 'subSection'
};

// Video Player States
export const COURSE_STATES = {
  NEW: 'NEW',
  PROGRESS: 'PROGRESS',
  COMPLETED: 'COMPLETED',
  PLAY_NEXT: 'PLAY_NEXT'
};

export const PRODUCT_COLLECTION = {
  tests: 'tests',
  skillPractices: 'skill-practice',
  courses: 'courses'
};

export const DISABLE_RIGHT_CLICK = process.env.NODE_ENV === 'production';

export const QUESTION_USAGE_TYPE = {
  final: 'final',
  sample: 'sample',
  practice: 'practice',
  note: 'note'
};

export const site = {
  name: 'Pretest Plus',
  description:
    'Highly effective mock pre-tests for admission to leading independent schools across the UK',
  url: 'https://pretestplus.co.uk',
  themeColor: '#0059b2',
  socialMedia: {
    facebook: '#',
    twitter: '#'
  }
};

export const cutoffDate = '2021-10-18';
export const oldDashboardUrl = `${site.url}/ti`;

export const layoutTypes = {
  DEFAULT: 'default',
  ISEB_LAYOUT: 'isebLayout'
};
