/** @jsxImportSource @emotion/react */
import { useLayoutEffect } from 'react';
import { css } from '@emotion/react';
import facepaint from 'facepaint';

import MetaWrapper from 'components/MetaWrapper';
import NavsContainer from 'hooks/useNavs';
import H1 from 'components/htmlElements/H1';
import H4 from 'components/htmlElements/H4';
import Button from 'components/htmlElements/Button';

import { spacer, colors, mixins, misc } from 'styles/utilities';

const breakPoints = facepaint([mixins.respondTo(misc.viewport['d-vp'])]);
const styles = {
  wrapper: css`
    width: 100vw;
    height: 100vh;
    text-align: center;
  `,
  bgPtn: css`
    background-color: ${colors.blue};
    position: relative;
    z-index: 0;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAJElEQVQIW2P8//+/DyMj4xYGJMAIYqNLgAXRJeCCyBIogjAJAA+zE0+2WHGzAAAAAElFTkSuQmCC);
      background-repeat: repeat;
      opacity: 0.3;
      z-index: -1;
    }
  `,
  container: css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  title: css`
    font-size: 8em;
    ${breakPoints({
      fontSize: ['4em', '8em']
    })};
  `,
  image: css`
    width: 100%;
    max-width: 250px;
    margin-bottom: 30px;
  `
};

export default () => {
  const { hideNav, hideFooter, showNav, showFooter } = NavsContainer.useContainer();

  useLayoutEffect(() => {
    hideNav();
    hideFooter();

    return () => {
      showNav();
      showFooter();
    };
  }, [hideFooter, hideNav, showFooter, showNav]);

  return (
    <div css={[styles.wrapper, styles.bgPtn]} className="d-flex align-items-center">
      <MetaWrapper title="Not Found" />
      <div css={[styles.container, spacer.padBT80]}>
        <div className="container">
          <div className="row">
            <div className="col-12 txt-center">
              {/* <img src={notFoundImage} alt="notice" css={styles.image} /> */}
              <H1 color={colors.white} css={styles.title}>
                Oops
              </H1>
              <H4 as="p" large css={spacer.mrB20} mercury color={colors.white}>
                Something went terribly wrong. <br />
                Try going to your dashboard instead.
              </H4>
              <Button as="a" large href="/">
                Go to Dashboard
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
