import styled from '@emotion/styled';
import styledMap from 'styled-map';
import { colors, misc } from 'styles/utilities';

const FieldError = styled.div`
  font-size: 12px;
  text-align: left;
  color: ${({ isSuccess }) => (isSuccess ? colors.green : colors.red)};
  padding: 0 ${misc.input.paddingHorizontal};
  margin-top: 3px;
  position: ${styledMap`
    absolute: absolute;
    default: relative;
  `};
  top: ${styledMap`
    absolute: 100%;
    default: auto;
  `};
  left: ${styledMap`
    absolute: 0;
    default: auto;
  `};
`;

export default FieldError;
