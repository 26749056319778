/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { spacer, colors } from 'styles/utilities';
import H2 from 'components/htmlElements/H2';
import H4 from 'components/htmlElements/H4';
import P from 'components/htmlElements/P';
import PretestLogo from 'images/logopretest.svg';

import * as styles from './WelcomeTour.styles';

const FirstSlide = ({ header, description }) => (
  <Fragment>
    <div css={[spacer.mrT30, styles.logo]}>
      <img src={PretestLogo} alt="pretest-logo" />
    </div>
    <div css={[styles.alignCenter, spacer.mrBT60, spacer.padLR20]}>
      <H2 mercury color={colors.white}>
        {header}
      </H2>
    </div>
    <div css={[styles.alignCenter, spacer.padLR20, spacer.mrLR60, spacer.mrB80]}>
      <P noMargin color={colors.white}>
        {description}
      </P>
    </div>
    <div css={[spacer.mrB20, styles.emptyDiv]}>..</div>
  </Fragment>
);
FirstSlide.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

const Slide = ({ title, subtitle, gifURL, isFirstSlide }) => {
  if (isFirstSlide) {
    return <FirstSlide header={title} description={subtitle} />;
  }

  return (
    <div css={styles.slideContainerStyles}>
      <div css={styles.imageBorder}>
        <img src={gifURL} alt="help-gifs" width="100%" />
      </div>
      <div
        css={[
          spacer.padLR20,
          spacer.padT20,
          spacer.padB10,
          css`
            margin-top: auto;
            margin-bottom: auto;
          `
        ]}
      >
        <H4 gBold bold color={colors.white} css={spacer.padLR20}>
          {title}
        </H4>
        <P color={colors.white} css={[spacer.padLR40]}>
          {subtitle}
        </P>
      </div>
    </div>
  );
};

Slide.propTypes = {
  gifURL: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  isFirstSlide: PropTypes.bool.isRequired
};

export default Slide;
