const questionTypes = {
  TWO_COL_COMPREHENSION: 'twoColComprehension', // questionText
  TEXT: 'text', // questionText
  SUMMARY: 'summary', // questionText
  SPELLING_PUNCTUATION: 'spellingPunctuation',
  SHUFFLED_SENTENCES: 'shuffledSentences',
  SENTENCE_COMPLETION: 'sentenceCompletion', // questionText
  SELECTED_LETTERS: 'selectedLetters',
  PICTURE_IMAGE: 'pictureImage', // questionText
  ANAGRAMS_MATH_EQ: 'anagramsMathEq',
  IMAGE: 'image',
  CLOZE: 'cloze',
  ANAGRAMS: 'anagrams',
  FILL_IN_THE_BLANKS: 'fillInTheBlanks',
  MATRICES: 'matrices',
  GEOGEBRA: 'geogebra',
  AUDIO: 'audioQuestion',
  INCAS_CLOZE: 'incasClozeQuestion',
  FREETEXT_INPUT: 'freeTextInput'
};

export default questionTypes;
