/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Input, FieldError, Wrapper } from 'components/htmlElements/Fields';

import ModalHeader from 'components/ModalHeader/ModalHeader';
import Button from 'components/htmlElements/Button';
import { spacer } from 'styles/utilities';
import Modal from 'components/Modal/Modal';
import { useLogin, useResetPassword } from 'api/auth';
import LoaderOverlay from 'components/LoaderOverlay';
import { useLocation } from 'components/Router/Router';
import { useEffect, useState } from 'react';
// import * as styles from './ResetPasswordForm.styles';

const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string().required('Required').min(6, 'Must contain atleast 6 characters'),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('newPassword')], 'Password does not match')
});

const ResetPasswordForm = ({ isOpen, toggleModal }) => {
  const { resetPasswordMutation, data, isError } = useResetPassword();
  const { login: doLogin } = useLogin();

  const [key, setKey] = useState('');
  const [login, setLogin] = useState('');
  const [email, setEmail] = useState('');
  const [isFirstTimeSetPassword, setIsFirstTimeSetPassword] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    setKey(search.get('key'));
    setLogin(search.get('login'));

    setIsFirstTimeSetPassword(Boolean(Number(search.get('firstTimeSetPassword'))));
    setEmail(search.get('email'));
  }, [location.search]);

  const handleFormSubmission = async (values, actions) => {
    actions.setSubmitting(true);

    await resetPasswordMutation({ password: values.newPassword, key, login });

    // Login the user
    await doLogin({ email, password: values.newPassword });

    actions.setSubmitting(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <Formik
        initialValues={{ newPassword: '', confirmPassword: '' }}
        validationSchema={resetPasswordSchema}
        onSubmit={handleFormSubmission}
      >
        {({ isSubmitting }) => (
          <Form>
            <div css={[spacer.padLR30, spacer.padBT30]}>
              <ModalHeader
                header={`${isFirstTimeSetPassword ? 'Set Password' : 'Change Password'}`}
              />
              <div>
                <Wrapper>
                  <Field
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    as={Input}
                    placeholder="Enter New Password"
                  />
                  <ErrorMessage name="newPassword" component={FieldError} />
                </Wrapper>
                <Wrapper>
                  <Field
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    as={Input}
                    placeholder="Confirm New Password"
                  />
                  <ErrorMessage name="confirmPassword" component={FieldError} />
                </Wrapper>

                {data && data.error ? (
                  <Wrapper>
                    <FieldError>{data.error}</FieldError>
                  </Wrapper>
                ) : data?.message ? (
                  <Wrapper>
                    <FieldError isSuccess>{data.message}</FieldError>
                  </Wrapper>
                ) : (
                  ''
                )}

                {isError && (
                  <Wrapper>
                    <FieldError>
                      There was a problem resetting password, please try again
                    </FieldError>
                  </Wrapper>
                )}
              </div>

              <Button type="submit" wide>
                {isFirstTimeSetPassword ? 'Set Password' : 'Change Password'}
              </Button>

              {isSubmitting && <LoaderOverlay />}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

ResetPasswordForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default ResetPasswordForm;
