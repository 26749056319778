//* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors, mixins } from 'styles/utilities';

const sidebarWidth = '370px';
export const sidebarContainer = css`
  width: ${sidebarWidth};
  min-width: ${sidebarWidth};
  display: block;
  .sidebar-main {
    padding-bottom: 80px;
    border-right: ${colors.grey2} solid 1px;
    position: fixed;
    width: ${sidebarWidth};
    overflow-y: auto;
    height: 100%;
  }
`;

export const header = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid ${colors.grey2};
`;

export const studentSidebarItemContainer = ({ active }) => css`
  background-color: ${active ? colors.cream : mixins.toRgba(colors.cream, 0.4)};
  border-bottom: 2px solid ${colors.grey2};

  a {
    display: flex;
    align-items: center;
    padding: 0;
    padding: 20px;
    margin: 0px;

    .progress {
      margin-right: 10px;
    }

    span {
      text-transform: capitalize;
    }
  }
`;
