/** @jsxImportSource @emotion/react */
import { Fragment, useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/htmlElements/Fields/Select';
import { colors, spacer } from 'styles/utilities';
import H4 from 'components/htmlElements/H4';
import A from 'components/htmlElements/A';
import { UserInfoContainer } from 'api/auth';
import { UnstyledLink } from 'components/Router';
import secondsToHms from 'utils/secondsToHms';
import {
  getDistributionScoresChartData,
  getPerformanceChartData,
  getRank,
  getScoreMetric,
  getTopicPerformanceChartData
} from './helper';
import {
  AnalyticsScoreBand,
  MarksDistribution,
  PercentileBand,
  PerformanceSummary,
  TestScores,
  TopicPerformanceChart,
  TopicSummaryModules
} from './Components';

import * as styles from './TestAnalytics.styles';

const showScoreWarning = (score) => score === 0;

const TestAnalytics = ({
  candidateAnalytics,
  aggregatedAnalytics,
  isTestAtSubSection,
  cohortAttempts: allCompletedAttempts,
  testSections,
  testName,
  analyticsType,
  percentileBands
}) => {
  const isCohortAnalytics = analyticsType === 'cohort';

  const [currentSelectedModule, setCurrentSelectedModule] = useState('all');
  const [timeConsumed, setTimeConsumed] = useState(0);
  const { currentCandidate, userIs, firstName, lastName } = UserInfoContainer.useContainer();
  const [currentAnalytics, setCurrentAnalytics] = useState(candidateAnalytics);

  const candidateName = useMemo(() => {
    if (userIs === 'candidate') {
      return { firstName: firstName || '', lastName: lastName || '' };
    }

    return {
      firstName: currentCandidate?.firstName || '',
      lastName: currentCandidate?.lastName || ''
    };
  }, [currentCandidate?.firstName, currentCandidate?.lastName, firstName, lastName, userIs]);

  const testScores = [
    {
      title: `${candidateName?.firstName}'s Overall Score`,
      score: `${currentAnalytics?.attemptAnalytics?.scoredMarks || 0}/${
        currentAnalytics?.attemptAnalytics?.totalMarks
      }`,
      showScoreWarning: showScoreWarning(currentAnalytics?.attemptAnalytics?.scoredMarks)
    },
    {
      title: `${candidateName?.firstName}'s Overall % Score`,
      score: `${getScoreMetric(currentAnalytics?.attemptAnalytics?.percentageScore)}%`,
      showScoreWarning: showScoreWarning(currentAnalytics?.attemptAnalytics?.percentageScore)
    },
    ...(currentSelectedModule !== 'all'
      ? [
          {
            title: `${candidateName?.firstName}'s Time`,
            score: `${secondsToHms(timeConsumed || 0).substring(3)}`
          }
        ]
      : []),
    {
      title: 'Average Score',
      score: `${getScoreMetric(
        currentSelectedModule === 'all'
          ? aggregatedAnalytics?.attemptAnalytics?.scoredMarks
          : aggregatedAnalytics?.section[currentSelectedModule]?.attemptAnalytics?.scoredMarks ||
              aggregatedAnalytics?.section[currentAnalytics.sectionId]?.subSection?.[
                currentSelectedModule
              ]?.attemptAnalytics?.scoredMarks
      )}/${currentAnalytics?.attemptAnalytics?.totalMarks}`
    },
    {
      title: 'Average % Score',
      score: `${getScoreMetric(
        currentSelectedModule === 'all'
          ? aggregatedAnalytics?.attemptAnalytics?.percentageScore
          : aggregatedAnalytics?.section[currentSelectedModule]?.attemptAnalytics
              ?.percentageScore ||
              aggregatedAnalytics?.section[currentAnalytics.sectionId]?.subSection?.[
                currentSelectedModule
              ]?.attemptAnalytics?.percentageScore
      )}%`
    }
  ];

  const performanceStats = {
    answers: [
      {
        title: 'Correctly Answered',
        score: `${currentAnalytics?.attemptAnalytics?.rightAnswers}/${
          currentAnalytics?.attemptAnalytics?.totalFinalQuestions ||
          currentAnalytics?.attemptAnalytics?.totalQuestions
        }`
      },
      {
        title: 'Incorrectly Answered',
        score: `${currentAnalytics?.attemptAnalytics?.wrongAnswers}/${
          currentAnalytics?.attemptAnalytics?.totalFinalQuestions ||
          currentAnalytics?.attemptAnalytics?.totalQuestions
        }`
      },
      {
        title: 'Not attempted',
        score: `${currentAnalytics?.attemptAnalytics?.blankAnswers}/${
          currentAnalytics?.attemptAnalytics?.totalFinalQuestions ||
          currentAnalytics?.attemptAnalytics?.totalQuestions
        }`
      }
    ],
    studentPerformanceChart: getPerformanceChartData({
      analyticsData: currentAnalytics?.attemptAnalytics
    }),
    cohortPerformanceChart: getPerformanceChartData({
      analyticsData:
        currentSelectedModule === 'all'
          ? aggregatedAnalytics?.attemptAnalytics
          : aggregatedAnalytics?.section[currentSelectedModule]?.attemptAnalytics ||
            aggregatedAnalytics?.section[currentAnalytics.sectionId]?.subSection?.[
              currentSelectedModule
            ]?.attemptAnalytics
    })
  };

  const distributionScores = {
    table: [
      {
        title: `${candidateName?.firstName} ${candidateName?.lastName}'s Score`,
        score: `${currentAnalytics?.attemptAnalytics?.scoredMarks}/${currentAnalytics?.attemptAnalytics?.totalMarks}`
      },
      {
        title: 'Lowest Score',
        score: `${currentAnalytics?.testAnalytics?.statistics.min}/${currentAnalytics?.attemptAnalytics?.totalMarks}`
      },
      {
        title: 'Average Score',
        score: `${currentAnalytics?.testAnalytics?.statistics.avg}/${currentAnalytics?.attemptAnalytics?.totalMarks}`
      },
      {
        title: 'Highest Score',
        score: `${currentAnalytics?.testAnalytics?.statistics.max}/${currentAnalytics?.attemptAnalytics?.totalMarks}`
      }
    ],
    chart: getDistributionScoresChartData({
      analyticsData: currentAnalytics,
      candidateName: `${candidateName?.firstName} ${candidateName?.lastName}`
    })
  };

  const allModules = useMemo(() => {
    if (candidateAnalytics?.section) {
      return Object.entries(candidateAnalytics?.section).map(([key, value]) => ({
        ...value,
        moduleId: key,
        moduleName: value.sectionName
      }));
    }

    return [];
  }, [candidateAnalytics?.section]);

  const getTimeConsumed = useCallback(
    (timerData) => {
      if (!timerData) {
        return 0;
      }

      if (isTestAtSubSection) {
        return timerData?.reduce(
          (a, c) =>
            (isCohortAnalytics
              ? (new Date(c?.timerData?.endedAt) - new Date(c?.timerData?.startedAt)) / 1000
              : c?.timerData?.secondsConsumed) + a,
          0
        );
      }

      return isCohortAnalytics
        ? (new Date(timerData?.endedAt) - new Date(timerData?.startedAt)) / 1000
        : timerData?.secondsConsumed;
    },
    [isTestAtSubSection, isCohortAnalytics]
  );

  const allSummaryModules = allModules.map(({ moduleName, attemptAnalytics, moduleId }, index) => ({
    title: moduleName,
    totalScore: `${attemptAnalytics?.scoredMarks}/${attemptAnalytics?.totalMarks}`,
    showScoreWarning: showScoreWarning(attemptAnalytics?.scoredMarks),
    scoredMarks: attemptAnalytics?.scoredMarks,
    scorePercentage: getScoreMetric(attemptAnalytics?.percentageScore),
    cohortScore: getScoreMetric(
      aggregatedAnalytics?.section[moduleId]?.attemptAnalytics?.percentageScore || 0
    ),
    moduleId,
    containsSubSections: isTestAtSubSection || false,
    timeTaken: getTimeConsumed(
      isTestAtSubSection ? testSections?.[index]?.subSection : testSections?.[index]?.timerData
    ),
    timeLimit: isTestAtSubSection
      ? testSections?.[index]?.subSection.reduce((a, c) => c?.timerData?.timeLimit + a, 0)
      : testSections?.[index]?.timerData.timeLimit
  }));

  const [summaryModules, setSummaryModules] = useState(allSummaryModules);
  const [topicPerformanceModules, setTopicPerformanceModules] = useState(allModules);

  const topicPerformanceData = getTopicPerformanceChartData({
    candidateFirstName: candidateName?.firstName,
    modules: topicPerformanceModules,
    aggregatedAnalytics,
    currentSelectedModule
  });

  const dropdownOptions = useMemo(() => {
    if (isTestAtSubSection) {
      const allSectionsAndSubSections = [];

      allModules.forEach(({ moduleId, moduleName, subSection }) => {
        allSectionsAndSubSections.push({ moduleName, moduleId });

        const subSectionModules = Object.entries(subSection).map(([key, value]) => ({
          ...value,
          moduleId: key,
          moduleName: value.subSectionName,
          isSubSection: true
        }));

        subSectionModules.forEach(
          ({ moduleId: subSectionId, moduleName: subSectionName, isSubSection }) => {
            allSectionsAndSubSections.push({
              moduleName: subSectionName,
              moduleId: subSectionId,
              isSubSection,
              parentSectionId: moduleId
            });
          }
        );
      });

      return [{ option: 'Overall Test', value: { moduleId: 'all' } }].concat(
        ...allSectionsAndSubSections.map(
          ({ moduleName, moduleId, isSubSection, parentSectionId }) => ({
            option: (
              <span
                css={styles.optionStyles({ isSubSection })}
                className={moduleId === currentSelectedModule ? 'active' : ''}
              >
                {moduleName}
              </span>
            ),
            value: { moduleId, parentSectionId: parentSectionId || '' }
          })
        )
      );
    }

    return [{ option: 'Overall Test', value: { moduleId: 'all' } }].concat(
      ...allSummaryModules.map(({ title, moduleId }) => ({
        option: (
          <span
            css={styles.optionStyles({ isSubSection: false })}
            className={moduleId === currentSelectedModule ? 'active' : ''}
          >
            {title}
          </span>
        ),
        value: { moduleId }
      }))
    );
  }, [allModules, allSummaryModules, currentSelectedModule, isTestAtSubSection]);

  const moduleSelectionHandler = useCallback(
    (selectedModuleId, parentSectionId = null) => {
      if (selectedModuleId === 'all') {
        setCurrentAnalytics(candidateAnalytics);
        setSummaryModules(allSummaryModules);
        setTopicPerformanceModules(allModules);
      } else if (isTestAtSubSection) {
        if (parentSectionId) {
          const selectedModule = allModules.find((module) => module.moduleId === parentSectionId);
          setCurrentAnalytics({
            ...selectedModule.subSection[selectedModuleId],
            moduleName: selectedModule.subSection[selectedModuleId].subSectionName,
            moduleId: selectedModuleId,
            sectionId: parentSectionId
          });
          setSummaryModules([]);
          setTopicPerformanceModules([]);

          const timerData = testSections
            .find((test) => test?.sectionId === parentSectionId)
            ?.subSection.find(({ subSectionId }) => subSectionId === selectedModuleId)?.timerData;

          const moduleTimeConsumed = isCohortAnalytics
            ? (new Date(timerData?.endedAt) - new Date(timerData?.startedAt)) / 1000
            : timerData?.secondsConsumed;

          setTimeConsumed(
            moduleTimeConsumed > timerData?.timeLimit ? timerData?.timeLimit : moduleTimeConsumed
          );
        } else {
          const selectedModule = allModules.find((module) => module.moduleId === selectedModuleId);

          const subSectionModules = Object.entries(selectedModule?.subSection).map(
            ([key, value]) => ({
              ...value,
              moduleId: key,
              moduleName: value.subSectionName
            })
          );

          const getSubSectionTimeConsumed = (timerData) => {
            if (!timerData) {
              return 0;
            }
            return isCohortAnalytics
              ? (new Date(timerData?.endedAt) - new Date(timerData?.startedAt)) / 1000
              : timerData?.secondsConsumed;
          };

          const subSectionSummaryModules = subSectionModules.map(
            ({ moduleName, attemptAnalytics, moduleId }) => ({
              title: moduleName,
              totalScore: `${attemptAnalytics?.scoredMarks}/${attemptAnalytics?.totalMarks}`,
              scoredMarks: attemptAnalytics?.scoredMarks,
              scorePercentage: getScoreMetric(attemptAnalytics?.percentageScore),
              cohortScore: getScoreMetric(
                aggregatedAnalytics?.section[selectedModuleId]?.subSection?.[moduleId]
                  ?.attemptAnalytics?.percentageScore
              ),
              moduleId,
              parentSectionId: selectedModule.moduleId,
              timeTaken: getSubSectionTimeConsumed(
                testSections
                  .find(({ sectionId }) => sectionId === selectedModuleId)
                  ?.subSection.find(({ subSectionId }) => subSectionId === moduleId)?.timerData
              ),
              timeLimit: testSections
                .find(({ sectionId }) => sectionId === selectedModuleId)
                ?.subSection.find(({ subSectionId }) => subSectionId === moduleId)?.timerData
                ?.timeLimit
            })
          );

          const timeConsumedData = testSections
            .find((test) => test?.sectionId === selectedModuleId)
            ?.subSection.reduce(
              (a, c) =>
                (isCohortAnalytics
                  ? (new Date(c?.timerData?.endedAt) - new Date(c?.timerData?.startedAt)) / 1000
                  : c?.timerData?.secondsConsumed) + a,
              0
            );

          const timeLimitData = testSections
            .find((test) => test?.sectionId === selectedModuleId)
            ?.subSection.reduce((a, c) => c?.timerData?.timeLimit + a, 0);

          setTimeConsumed(timeConsumedData > timeLimitData ? timeLimitData : timeConsumedData);

          setCurrentAnalytics(selectedModule);
          setTopicPerformanceModules(subSectionModules);
          setSummaryModules(subSectionSummaryModules);
        }
      } else {
        const selectedModule = allModules.find((module) => module.moduleId === selectedModuleId);

        const timerData = testSections.find(
          (test) => test?.sectionId === selectedModuleId
        )?.timerData;

        const moduleTimeConsumed = isCohortAnalytics
          ? (new Date(timerData?.endedAt) - new Date(timerData?.startedAt)) / 1000
          : timerData?.secondsConsumed;

        setTimeConsumed(
          moduleTimeConsumed > timerData?.timeLimit ? timerData?.timeLimit : moduleTimeConsumed
        );

        setCurrentAnalytics(selectedModule);
        setTopicPerformanceModules([]);
        setSummaryModules([]);
      }

      setCurrentSelectedModule(selectedModuleId);
      window.scroll(0, 0);
    },
    [
      isTestAtSubSection,
      candidateAnalytics,
      allSummaryModules,
      allModules,
      testSections,
      isCohortAnalytics,
      aggregatedAnalytics?.section
    ]
  );

  const allCompletedModules = useMemo(() => {
    const allSectionsAndSubSections = [];

    const allAttemptedSections = allCompletedAttempts.map(({ section }) => section);

    allAttemptedSections.forEach((section) => {
      allSectionsAndSubSections.push(
        ...Object.entries(section).map(([key, value]) => ({
          ...value,
          moduleId: key
        }))
      );
    });

    if (isTestAtSubSection) {
      allSectionsAndSubSections.forEach(({ subSection }) =>
        allSectionsAndSubSections.push(
          ...Object.entries(subSection).map(([key, value]) => ({
            ...value,
            moduleId: key
          }))
        )
      );

      return allSectionsAndSubSections;
    }

    return allSectionsAndSubSections;
  }, [allCompletedAttempts, isTestAtSubSection]);

  const rank = getRank({
    currentModule: currentSelectedModule,
    allCompletedAttempts,
    allCompletedModules,
    candidateScore: currentAnalytics?.attemptAnalytics?.scoredMarks
  });

  const markerPosition = (1 - (rank - 1) / (allCompletedAttempts?.length - 1)) * 100;

  // default values on unmount
  useEffect(() => {
    setCurrentAnalytics(candidateAnalytics);

    return () => {
      setCurrentSelectedModule('all');
      setCurrentAnalytics(candidateAnalytics);
      setTopicPerformanceModules(allModules);
    };
  }, [allModules, candidateAnalytics]);

  return (
    <Fragment>
      <div
        className="d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap"
        css={spacer.mrB40}
      >
        <div css={spacer.mrR20}>
          <H4 css={[spacer.mrB10, spacer.mrT0]}>
            {isCohortAnalytics
              ? `${`Rank ${rank} in ${
                  currentSelectedModule === 'all' ? testName : currentAnalytics?.moduleName
                } out of ${allCompletedAttempts?.length} students`}`
              : `${currentSelectedModule === 'all' ? testName : currentAnalytics?.moduleName}`}
          </H4>

          {currentSelectedModule !== 'all' && !currentAnalytics?.subSection && (
            <A
              gBold
              medium
              to={currentSelectedModule}
              underline
              as={UnstyledLink}
              className="d-inline-block"
              css={[spacer.mrBT10]}
            >
              View Solutions
            </A>
          )}
        </div>

        <div css={styles.selectWrapper}>
          <Select
            options={dropdownOptions}
            placeholder={
              currentSelectedModule !== 'all' ? currentAnalytics?.moduleName : 'Overall Test'
            }
            triggerProps={{ gBold: true, color: colors.black }}
            setValue={(value) => moduleSelectionHandler(value?.moduleId, value?.parentSectionId)}
          />
        </div>
      </div>

      <TestScores scores={testScores} showTiming={currentSelectedModule !== 'all'} />

      <PerformanceSummary
        performanceStats={performanceStats}
        candidateFirstName={candidateName?.firstName || ''}
      />

      <MarksDistribution distributionScores={distributionScores} />

      {isCohortAnalytics && <AnalyticsScoreBand markerPosition={markerPosition} />}
      {analyticsType === 'individual' &&
        percentileBands &&
        currentAnalytics?.attemptAnalytics?.percentageScore && (
          <PercentileBand
            percentiles={percentileBands}
            candidatePercentage={Math.floor(currentAnalytics?.attemptAnalytics?.percentageScore)}
          />
        )}

      {topicPerformanceModules?.length > 0 && (
        <TopicPerformanceChart
          topicPerformanceData={topicPerformanceData}
          candidateFirstName={candidateName?.firstName}
        />
      )}

      {topicPerformanceModules?.length > 0 && (
        <TopicSummaryModules
          summaryModules={summaryModules}
          isCohortAnalytics={isCohortAnalytics}
          allCompletedAttempts={allCompletedAttempts}
          allCompletedModules={allCompletedModules}
          candidateFirstName={candidateName?.firstName}
          reportCTAHandler={(moduleId, parentSectionId) =>
            moduleSelectionHandler(moduleId, parentSectionId)
          }
        />
      )}
    </Fragment>
  );
};

TestAnalytics.propTypes = {
  candidateAnalytics: PropTypes.object.isRequired,
  aggregatedAnalytics: PropTypes.object.isRequired,
  isTestAtSubSection: PropTypes.bool.isRequired,
  cohortAttempts: PropTypes.array.isRequired,
  testSections: PropTypes.array.isRequired,
  testName: PropTypes.string.isRequired,
  analyticsType: PropTypes.string.isRequired,
  percentileBands: PropTypes.object.isRequired
};

export default TestAnalytics;
